unit GA.Web.Utils;

interface

type

TGAWebUtils = class
  class function RegulatorNumberValid(const ARegulator, ANumber: string): Boolean;
  class function EarliestClaimableDate(const ADate: TDate): TDate;
  class function EarliestClaimableDateStr(const ADate: TDate): string;
  class function EndofTaxYear(const ADate: TDate): TDate;
  class function StartOfTaxYear(const ADate: TDate): TDate;
end;

implementation

uses System.SysUtils,
WebLib.RegularExpressions, System.DateUtils;

{ TGAWebUtils }

class function TGAWebUtils.RegulatorNumberValid(const ARegulator, ANumber: string): Boolean;
var lNumber: String;
begin
  lNumber := ANumber.Trim;
  Result := lNumber <> '';

  if not Result then
     Exit;

  { TODO : Check that the CharityId is a valid format TRegEx. }
end;


class function TGAWebUtils.EarliestClaimableDate(const ADate: TDate): TDate;
var
  lTaxYearEnd: TDate;
begin
  lTaxYearEnd := EndofTaxYear(ADate);
  Result := StartOfTaxYear(IncYear(lTaxYearEnd, -4));
end;

class function TGAWebUtils.EarliestClaimableDateStr(const ADate: TDate): string;
var
  lEarliestDate: TDate;
begin
  lEarliestDate := EarliestClaimableDate(ADate);
  result := FormatDateTime('DD/MM/YYYY', lEarliestDate);
end;

class function TGAWebUtils.EndofTaxYear(const ADate: TDate): TDate;
var
  lYear, lMonth, lDay: Word;
begin
  DecodeDate(ADate, lYear, lMonth, lDay);

  if (lMonth = 4) then
  begin
    if lDay > 5 then
      lYear := lYear + 1
  end
  else if lMonth > 4 then
  begin
    lYear := lYear + 1;
  end;

  Result := EncodeDate(lYear, 4, 5);
end;

class function TGAWebUtils.StartOfTaxYear(const ADate: TDate): TDate;
var
  lYear, lMonth, lDay: Word;
begin
  DecodeDate(ADate, lYear, lMonth, lDay);

  if (lMonth = 4) then
  begin
    if lDay < 6 then
      lYear := lYear - 1
  end
  else if lMonth < 4 then
  begin
    lYear := lYear - 1;
  end;

  Result := EncodeDate(lYear, 4, 6);
end;

end.
