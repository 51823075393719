unit OtherClaimsCommunityBuildingsList;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, BaseList, WEBLib.ExtCtrls, WEBLib.Actions,
  Data.DB, WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.Grids, WEBLib.DBCtrls, Vcl.Controls, WEBLib.Lists,
  smx.webcore.types, OtherClaimsCommunityBuildingsEdit;

type
  TOtherClaimsCommunityBuildingsListForm = class(TBaseListForm)
    RefreshTimer: TTimer;
    //BuildingsDataSet: TXDataWebDataSet;
    MainDatasetId: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetExternalId: TStringField;
    MainDatasetTransactionDate: TDateField;
    MainDatasetImportId: TIntegerField;
    MainDatasetClaimId: TIntegerField;
    MainDatasetGiftAid: TFloatField;
    MainDatasetAmount: TFloatField;
    MainDatasetClaimStatus: TStringField;
    MainDatasetClaimDate: TDateTimeField;
    MainDatasetTaxYear: TIntegerField;
    MainDatasetErrorText: TStringField;
    MainDatasetCharityTenant: TStringField;
    MainDatasetSourceType: TStringField;
    MainDatasetComment: TStringField;
    MainDatasetConnectedCharityId: TIntegerField;
    MainDatasetBuildingId: TIntegerField;
    MainDatasetBuildingName: TStringField;

    ConnectedCharityDataSet: TXDataWebDataSet;
    ConnectedCharityDataSetId: TIntegerField;
    ConnectedCharityDataSetDateAdded: TDateTimeField;
    ConnectedCharityDataSetAddedBy: TIntegerField;
    ConnectedCharityDataSetLastUpdatedBy: TIntegerField;
    ConnectedCharityDataSetLastUpdated: TDateTimeField;
    ConnectedCharityDataSetExternalId: TStringField;
    ConnectedCharityDataSetCharityTenant: TStringField;
    ConnectedCharityDataSetHMRCRef: TStringField;
    ConnectedCharityDataSetRegulatorNumber: TStringField;
    ConnectedCharityDataSetCharityName: TStringField;
    ConnectedCharityDataSetGAStatus: TStringField;

    BuildingsDataSet: TXDataWebDataSet;
    BuildingsDataSetId: TIntegerField;
    BuildingsDataSetDateAdded: TDateTimeField;
    BuildingsDataSetAddedBy: TIntegerField;
    BuildingsDataSetLastUpdatedBy: TIntegerField;
    BuildingsDataSetLastUpdated: TDateTimeField;
    BuildingsDataSetExternalId: TStringField;
    BuildingsDataSetCharityTenant: TStringField;
    BuildingsDataSetBuildingName: TStringField;
    BuildingsDataSetAddress: TStringField;
    BuildingsDataSetPostCode: TStringField;
    BuildingsDataSetMatchHash: TStringField;
    BuildingsDataSetGAStatus: TStringField;
    RefreshCharity: TTimer;
    RefreshBuilding: TTimer;
    procedure AddButtonClick(Sender: TObject);
    procedure BuildingsDataSetAfterApplyUpdates(Sender: TDataSet; Info:
        TResolveResults);
    procedure BuildingsDataSetAfterPost(DataSet: TDataSet);
    procedure ConnectedCharityDataSetAfterApplyUpdates(Sender: TDataSet; Info:
        TResolveResults);
    procedure ConnectedCharityDataSetAfterPost(DataSet: TDataSet);
    procedure DataTableGetCellChildren(Sender: TObject; ACol, ARow: Integer;
        AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure MainDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure MainDatasetCalcFields(DataSet: TDataSet);
    procedure RefreshBuildingTimer(Sender: TObject);
    procedure RefreshTimerTimer(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure RefreshCharityTimer(Sender: TObject);
  private
    { Private declarations }
    fOtherClaimsType: string;
    FOtherClaimsCommunityBuildings: TOtherClaimsCommunityBuildingsEditForm;

    [async]
    procedure EditForm(RecordId: integer); async;
    procedure CancelSelectedRecord(RecordId: integer);
    procedure RefreshControls;

  protected
    procedure PrepareForm; override;
    function InitSortCol: Integer; override;
    function InitSortDirection: TSortDirection; override;
    function CreateGridInFormCreate: boolean; override;
    procedure LoadData; override;
    procedure GetBuildingNameFromId(BuildingID: integer; var BuildingName: string);

    procedure RefreshCharities;
    procedure RefreshBuildings;

  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  OtherClaimsCommunityBuildingsListForm: TOtherClaimsCommunityBuildingsListForm;

implementation

{$R *.dfm}

uses
  MainDataModule, WEBLib.WebCtrls, SMX.Web.Layout.Utils,
  XData.Web.Client, SMX.Web.Utils;

procedure TOtherClaimsCommunityBuildingsListForm.AddButtonClick(Sender:
    TObject);
begin
//  inherited;
  EditForm(0);
end;

procedure
    TOtherClaimsCommunityBuildingsListForm.BuildingsDataSetAfterApplyUpdates(
    Sender: TDataSet; Info: TResolveResults);
begin
  inherited;
  RefreshBuildings;
end;

procedure TOtherClaimsCommunityBuildingsListForm.BuildingsDataSetAfterPost(
    DataSet: TDataSet);
begin
  inherited;
  BuildingsDataSet.ApplyUpdates;
end;

procedure TOtherClaimsCommunityBuildingsListForm.DataTableGetCellChildren(
    Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string;
    AElement: TJSHTMLElementRecord);
var
  RecordId: integer;
  Span: THTMLSpan;
  BuildingId: integer;
  BuildingName: string;


  procedure EditClick(Sender: TObject);
  begin
    EditForm(RecordId);
  end;

  procedure CancelClick(Sender: TObject);
  begin
    CancelSelectedRecord(RecordId);
  end;

begin
  if ARow = 0 then
    Exit;


  if not ((DataTable.Columns[ACol].Title = 'Actions')) then
  begin
    Exit;
  end;

  RecordId := MainDataSet.FieldByName('Id').AsInteger;

  if (MainDataSet.FieldByName('ClaimStatus').AsString = 'Unclaimed') or
     (MainDataSet.FieldByName('ClaimStatus').AsString = 'Invalid') then
  begin
    Span := TLayoutUtils.RowActionSpan(AElement.Element, 'fad fa-edit', 'Edit');
    Span.OnClick := @EditClick;

    Span := TLayoutUtils.RowActionSpan(AElement.Element, 'fad fa-trash', 'Cancel');
    Span.OnClick := @CancelClick;
  end;
end;

procedure TOtherClaimsCommunityBuildingsListForm.PrepareForm;
begin
  FIdField := 'Id';

  BuildingsDataSet.QueryString := '$filter=GAStatus eq ''Active'' &$orderby=BuildingName ASC';
  BuildingsDataSet.Load;

  ConnectedCharityDataSet.QueryString := '$filter=GAStatus eq ''Active'' &$orderby=CharityName ASC';
  ConnectedCharityDataSet.Load;

end;

procedure TOtherClaimsCommunityBuildingsListForm.RefreshControls;
var
  FilterStr: string;
begin
  FGridPlugin.SetPreparedFilter('ClaimStatus eq Unclaimed');
end;

procedure TOtherClaimsCommunityBuildingsListForm.EditForm(RecordId: integer);
//var
//  AForm: TOtherClaimsCommunityBuildingsEditForm;

begin
  FOtherClaimsCommunityBuildings := TOtherClaimsCommunityBuildingsEditForm.Create(Self);
  try
    FOtherClaimsCommunityBuildings.Popup := True;
    FOtherClaimsCommunityBuildings.PopupOpacity := 1;
    FOtherClaimsCommunityBuildings.ElementClassName := 'PopUpForm';
    await(TForm, FOtherClaimsCommunityBuildings.Load());

    if RecordId = 0 then
      MainDataset.Insert
    else
    begin
      MainDataset.Locate('Id', RecordId, []);
      MainDataset.Edit;
    end;
    FOtherClaimsCommunityBuildings.DataSet := MainDataset;

    FOtherClaimsCommunityBuildings.CommunityBuildingsDataSet := BuildingsDataSet;
    FOtherClaimsCommunityBuildings.ConnectedCharityDataSet := ConnectedCharityDataSet;
    FOtherClaimsCommunityBuildings.AfterCreated;
    FOtherClaimsCommunityBuildings.SetValidationForEdit;

    await(TModalResult, FOtherClaimsCommunityBuildings.Execute);
  finally
    if FOtherClaimsCommunityBuildings.EditDataApplyUpdates then
    begin
      MainDataSet.ApplyUpdates;
    end;
    FOtherClaimsCommunityBuildings.Free;
    FOtherClaimsCommunityBuildings := nil;
  end;
end;

procedure TOtherClaimsCommunityBuildingsListForm.GetBuildingNameFromId(
  BuildingID: integer; var BuildingName: string);
begin
  if BuildingId > 0 then
  begin
    if BuildingsDataSet.Locate('Id', BuildingId, []) then
    begin
      BuildingName := BuildingsDataSet.FieldByName('BuildingName').AsString;
    end;
  end
  else
  begin
    BuildingName := 'Unknown';
  end;
end;

procedure TOtherClaimsCommunityBuildingsListForm.MainDatasetAfterApplyUpdates(
    Sender: TDataSet; Info: TResolveResults);
begin
  inherited;
  RefreshControls;
end;

procedure TOtherClaimsCommunityBuildingsListForm.RefreshTimerTimer(Sender:
    TObject);
begin
  RefreshTimer.Enabled := false;
  RefreshControls;
end;

procedure TOtherClaimsCommunityBuildingsListForm.LoadData;
begin
  CreateGridPlugin;
  RefreshTimer.Enabled := true;
  //RefreshControls;
end;

function TOtherClaimsCommunityBuildingsListForm.InitSortCol: Integer;
begin
  Result := 0;
end;

function TOtherClaimsCommunityBuildingsListForm.InitSortDirection: TSortDirection;
begin
  Result := sdDesc;
end;

procedure TOtherClaimsCommunityBuildingsListForm.CancelSelectedRecord(RecordId: integer);
begin
  if MainDataSet.Locate('Id', RecordId, []) then
  begin
    try
      MainDataSet.Edit;
      MainDataSet.FieldByName('ClaimStatus').AsString := 'Cancelled';
      MainDataSet.Post;
    finally
      MainDataSet.ApplyUpdates;
    end;
  end;
end;

procedure
    TOtherClaimsCommunityBuildingsListForm.ConnectedCharityDataSetAfterApplyUpdates(
    Sender: TDataSet; Info: TResolveResults);
begin
  inherited;
  RefreshCharities;
end;

procedure
    TOtherClaimsCommunityBuildingsListForm.ConnectedCharityDataSetAfterPost(
    DataSet: TDataSet);
begin
  inherited;
  ConnectedCharityDataset.ApplyUpdates;
end;

function TOtherClaimsCommunityBuildingsListForm.CreateGridInFormCreate: boolean;
begin
  result := false;
end;

procedure TOtherClaimsCommunityBuildingsListForm.MainDatasetCalcFields(DataSet:
    TDataSet);
var
  BuildingId: integer;
  BuildingName: string;
begin
  inherited;

  BuildingId := MainDataset.FieldByName('BuildingId').AsInteger;
  GetBuildingNameFromId(BuildingId, BuildingName);
  MainDatasetBuildingName.Value := BuildingName;
end;

procedure TOtherClaimsCommunityBuildingsListForm.WebFormShow(Sender: TObject);
begin
  inherited;
  LoadData;
end;

procedure TOtherClaimsCommunityBuildingsListForm.RefreshCharities;
begin
  ConnectedCharityDataSet.Close;
  //ConnectedCharityDataSet.QueryString := '$filter=(Id eq 1)';
  ConnectedCharityDataSet.QueryString := '$filter=(GAStatus eq ''Active'')';
  ConnectedCharityDataSet.Load;

  RefreshCharity.Enabled := true;
end;

procedure TOtherClaimsCommunityBuildingsListForm.RefreshCharityTimer(
  Sender: TObject);
begin
  RefreshCharity.Enabled := false;
  if assigned(FOtherClaimsCommunityBuildings) then
    FOtherClaimsCommunityBuildings.RefreshNewCharity.Enabled := true;
end;

procedure TOtherClaimsCommunityBuildingsListForm.RefreshBuildings;
begin
  BuildingsDataSet.Close;
  //ConnectedCharityDataSet.QueryString := '$filter=(Id eq 1)';
  BuildingsDataSet.QueryString := '$filter=(GAStatus eq ''Active'')';
  BuildingsDataSet.Load;

  RefreshBuilding.Enabled := true;
end;

procedure TOtherClaimsCommunityBuildingsListForm.RefreshBuildingTimer(Sender:
    TObject);
begin
  RefreshBuilding.Enabled := false;
  if assigned(FOtherClaimsCommunityBuildings) then
    FOtherClaimsCommunityBuildings.RefreshNewBuilding.Enabled := true;
end;


procedure TOtherClaimsCommunityBuildingsListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetExternalId := TStringField.Create(Self);
  MainDatasetTransactionDate := TDateField.Create(Self);
  MainDatasetImportId := TIntegerField.Create(Self);
  MainDatasetClaimId := TIntegerField.Create(Self);
  MainDatasetGiftAid := TFloatField.Create(Self);
  MainDatasetAmount := TFloatField.Create(Self);
  MainDatasetClaimStatus := TStringField.Create(Self);
  MainDatasetClaimDate := TDateTimeField.Create(Self);
  MainDatasetTaxYear := TIntegerField.Create(Self);
  MainDatasetErrorText := TStringField.Create(Self);
  MainDatasetCharityTenant := TStringField.Create(Self);
  MainDatasetSourceType := TStringField.Create(Self);
  MainDatasetComment := TStringField.Create(Self);
  MainDatasetConnectedCharityId := TIntegerField.Create(Self);
  MainDatasetBuildingId := TIntegerField.Create(Self);
  MainDatasetBuildingName := TStringField.Create(Self);
  RefreshTimer := TTimer.Create(Self);
  BuildingsDataSet := TXDataWebDataSet.Create(Self);
  BuildingsDataSetId := TIntegerField.Create(Self);
  BuildingsDataSetDateAdded := TDateTimeField.Create(Self);
  BuildingsDataSetAddedBy := TIntegerField.Create(Self);
  BuildingsDataSetLastUpdatedBy := TIntegerField.Create(Self);
  BuildingsDataSetLastUpdated := TDateTimeField.Create(Self);
  BuildingsDataSetExternalId := TStringField.Create(Self);
  BuildingsDataSetCharityTenant := TStringField.Create(Self);
  BuildingsDataSetBuildingName := TStringField.Create(Self);
  BuildingsDataSetAddress := TStringField.Create(Self);
  BuildingsDataSetPostCode := TStringField.Create(Self);
  BuildingsDataSetMatchHash := TStringField.Create(Self);
  BuildingsDataSetGAStatus := TStringField.Create(Self);
  ConnectedCharityDataSet := TXDataWebDataSet.Create(Self);
  ConnectedCharityDataSetId := TIntegerField.Create(Self);
  ConnectedCharityDataSetDateAdded := TDateTimeField.Create(Self);
  ConnectedCharityDataSetAddedBy := TIntegerField.Create(Self);
  ConnectedCharityDataSetLastUpdatedBy := TIntegerField.Create(Self);
  ConnectedCharityDataSetLastUpdated := TDateTimeField.Create(Self);
  ConnectedCharityDataSetExternalId := TStringField.Create(Self);
  ConnectedCharityDataSetCharityTenant := TStringField.Create(Self);
  ConnectedCharityDataSetHMRCRef := TStringField.Create(Self);
  ConnectedCharityDataSetRegulatorNumber := TStringField.Create(Self);
  ConnectedCharityDataSetCharityName := TStringField.Create(Self);
  ConnectedCharityDataSetGAStatus := TStringField.Create(Self);
  RefreshCharity := TTimer.Create(Self);
  RefreshBuilding := TTimer.Create(Self);

  DataTable.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetExternalId.BeforeLoadDFMValues;
  MainDatasetTransactionDate.BeforeLoadDFMValues;
  MainDatasetImportId.BeforeLoadDFMValues;
  MainDatasetClaimId.BeforeLoadDFMValues;
  MainDatasetGiftAid.BeforeLoadDFMValues;
  MainDatasetAmount.BeforeLoadDFMValues;
  MainDatasetClaimStatus.BeforeLoadDFMValues;
  MainDatasetClaimDate.BeforeLoadDFMValues;
  MainDatasetTaxYear.BeforeLoadDFMValues;
  MainDatasetErrorText.BeforeLoadDFMValues;
  MainDatasetCharityTenant.BeforeLoadDFMValues;
  MainDatasetSourceType.BeforeLoadDFMValues;
  MainDatasetComment.BeforeLoadDFMValues;
  MainDatasetConnectedCharityId.BeforeLoadDFMValues;
  MainDatasetBuildingId.BeforeLoadDFMValues;
  MainDatasetBuildingName.BeforeLoadDFMValues;
  RefreshTimer.BeforeLoadDFMValues;
  BuildingsDataSet.BeforeLoadDFMValues;
  BuildingsDataSetId.BeforeLoadDFMValues;
  BuildingsDataSetDateAdded.BeforeLoadDFMValues;
  BuildingsDataSetAddedBy.BeforeLoadDFMValues;
  BuildingsDataSetLastUpdatedBy.BeforeLoadDFMValues;
  BuildingsDataSetLastUpdated.BeforeLoadDFMValues;
  BuildingsDataSetExternalId.BeforeLoadDFMValues;
  BuildingsDataSetCharityTenant.BeforeLoadDFMValues;
  BuildingsDataSetBuildingName.BeforeLoadDFMValues;
  BuildingsDataSetAddress.BeforeLoadDFMValues;
  BuildingsDataSetPostCode.BeforeLoadDFMValues;
  BuildingsDataSetMatchHash.BeforeLoadDFMValues;
  BuildingsDataSetGAStatus.BeforeLoadDFMValues;
  ConnectedCharityDataSet.BeforeLoadDFMValues;
  ConnectedCharityDataSetId.BeforeLoadDFMValues;
  ConnectedCharityDataSetDateAdded.BeforeLoadDFMValues;
  ConnectedCharityDataSetAddedBy.BeforeLoadDFMValues;
  ConnectedCharityDataSetLastUpdatedBy.BeforeLoadDFMValues;
  ConnectedCharityDataSetLastUpdated.BeforeLoadDFMValues;
  ConnectedCharityDataSetExternalId.BeforeLoadDFMValues;
  ConnectedCharityDataSetCharityTenant.BeforeLoadDFMValues;
  ConnectedCharityDataSetHMRCRef.BeforeLoadDFMValues;
  ConnectedCharityDataSetRegulatorNumber.BeforeLoadDFMValues;
  ConnectedCharityDataSetCharityName.BeforeLoadDFMValues;
  ConnectedCharityDataSetGAStatus.BeforeLoadDFMValues;
  RefreshCharity.BeforeLoadDFMValues;
  RefreshBuilding.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'TransactionDate';
      Title := 'Transaction Date';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'BuildingName';
      Title := 'Building Name';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Amount';
      Title := 'Amount';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ClaimStatus';
      Title := 'Claim Status';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Comment';
      Title := 'Comment';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    MainDataset.AfterApplyUpdates := MainDatasetAfterApplyUpdates;
    SetEvent(MainDataset, Self, 'OnCalcFields', 'MainDatasetCalcFields');
    MainDataset.EntitySetName := 'CommunityBuildingClaim';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetId.Required := True;
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetDateAdded.Required := True;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetAddedBy.Required := True;
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetExternalId.SetParentComponent(MainDataset);
    MainDatasetExternalId.Name := 'MainDatasetExternalId';
    MainDatasetExternalId.FieldName := 'ExternalId';
    MainDatasetExternalId.Size := 64;
    MainDatasetTransactionDate.SetParentComponent(MainDataset);
    MainDatasetTransactionDate.Name := 'MainDatasetTransactionDate';
    MainDatasetTransactionDate.FieldName := 'TransactionDate';
    MainDatasetTransactionDate.Required := True;
    MainDatasetImportId.SetParentComponent(MainDataset);
    MainDatasetImportId.Name := 'MainDatasetImportId';
    MainDatasetImportId.FieldName := 'ImportId';
    MainDatasetClaimId.SetParentComponent(MainDataset);
    MainDatasetClaimId.Name := 'MainDatasetClaimId';
    MainDatasetClaimId.FieldName := 'ClaimId';
    MainDatasetGiftAid.SetParentComponent(MainDataset);
    MainDatasetGiftAid.Name := 'MainDatasetGiftAid';
    MainDatasetGiftAid.FieldName := 'GiftAid';
    MainDatasetGiftAid.Required := True;
    MainDatasetAmount.SetParentComponent(MainDataset);
    MainDatasetAmount.Name := 'MainDatasetAmount';
    MainDatasetAmount.FieldName := 'Amount';
    MainDatasetAmount.Required := True;
    MainDatasetClaimStatus.SetParentComponent(MainDataset);
    MainDatasetClaimStatus.Name := 'MainDatasetClaimStatus';
    MainDatasetClaimStatus.FieldName := 'ClaimStatus';
    MainDatasetClaimStatus.Required := True;
    MainDatasetClaimStatus.Size := 10;
    MainDatasetClaimDate.SetParentComponent(MainDataset);
    MainDatasetClaimDate.Name := 'MainDatasetClaimDate';
    MainDatasetClaimDate.FieldName := 'ClaimDate';
    MainDatasetTaxYear.SetParentComponent(MainDataset);
    MainDatasetTaxYear.Name := 'MainDatasetTaxYear';
    MainDatasetTaxYear.FieldName := 'TaxYear';
    MainDatasetErrorText.SetParentComponent(MainDataset);
    MainDatasetErrorText.Name := 'MainDatasetErrorText';
    MainDatasetErrorText.FieldName := 'ErrorText';
    MainDatasetErrorText.Size := 1000;
    MainDatasetCharityTenant.SetParentComponent(MainDataset);
    MainDatasetCharityTenant.Name := 'MainDatasetCharityTenant';
    MainDatasetCharityTenant.FieldName := 'CharityTenant';
    MainDatasetSourceType.SetParentComponent(MainDataset);
    MainDatasetSourceType.Name := 'MainDatasetSourceType';
    MainDatasetSourceType.FieldName := 'SourceType';
    MainDatasetSourceType.Size := 9;
    MainDatasetComment.SetParentComponent(MainDataset);
    MainDatasetComment.Name := 'MainDatasetComment';
    MainDatasetComment.FieldName := 'Comment';
    MainDatasetComment.Size := 250;
    MainDatasetConnectedCharityId.SetParentComponent(MainDataset);
    MainDatasetConnectedCharityId.Name := 'MainDatasetConnectedCharityId';
    MainDatasetConnectedCharityId.FieldName := 'ConnectedCharityId';
    MainDatasetBuildingId.SetParentComponent(MainDataset);
    MainDatasetBuildingId.Name := 'MainDatasetBuildingId';
    MainDatasetBuildingId.FieldName := 'BuildingId';
    MainDatasetBuildingName.SetParentComponent(MainDataset);
    MainDatasetBuildingName.Name := 'MainDatasetBuildingName';
    MainDatasetBuildingName.FieldKind := fkCalculated;
    MainDatasetBuildingName.FieldName := 'BuildingName';
    MainDatasetBuildingName.Calculated := True;
    RefreshTimer.SetParentComponent(Self);
    RefreshTimer.Name := 'RefreshTimer';
    RefreshTimer.Enabled := False;
    RefreshTimer.Interval := 200;
    SetEvent(RefreshTimer, Self, 'OnTimer', 'RefreshTimerTimer');
    RefreshTimer.Left := 576;
    RefreshTimer.Top := 16;
    BuildingsDataSet.SetParentComponent(Self);
    BuildingsDataSet.Name := 'BuildingsDataSet';
    BuildingsDataSet.AfterApplyUpdates := BuildingsDataSetAfterApplyUpdates;
    BuildingsDataSet.AfterPost := BuildingsDataSetAfterPost;
    BuildingsDataSet.EntitySetName := 'CommunityBuilding';
    BuildingsDataSet.Connection := MainData.DataConnection;
    BuildingsDataSet.Left := 584;
    BuildingsDataSet.Top := 120;
    BuildingsDataSetId.SetParentComponent(BuildingsDataSet);
    BuildingsDataSetId.Name := 'BuildingsDataSetId';
    BuildingsDataSetId.FieldName := 'Id';
    BuildingsDataSetId.Required := True;
    BuildingsDataSetDateAdded.SetParentComponent(BuildingsDataSet);
    BuildingsDataSetDateAdded.Name := 'BuildingsDataSetDateAdded';
    BuildingsDataSetDateAdded.FieldName := 'DateAdded';
    BuildingsDataSetDateAdded.Required := True;
    BuildingsDataSetAddedBy.SetParentComponent(BuildingsDataSet);
    BuildingsDataSetAddedBy.Name := 'BuildingsDataSetAddedBy';
    BuildingsDataSetAddedBy.FieldName := 'AddedBy';
    BuildingsDataSetAddedBy.Required := True;
    BuildingsDataSetLastUpdatedBy.SetParentComponent(BuildingsDataSet);
    BuildingsDataSetLastUpdatedBy.Name := 'BuildingsDataSetLastUpdatedBy';
    BuildingsDataSetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    BuildingsDataSetLastUpdated.SetParentComponent(BuildingsDataSet);
    BuildingsDataSetLastUpdated.Name := 'BuildingsDataSetLastUpdated';
    BuildingsDataSetLastUpdated.FieldName := 'LastUpdated';
    BuildingsDataSetExternalId.SetParentComponent(BuildingsDataSet);
    BuildingsDataSetExternalId.Name := 'BuildingsDataSetExternalId';
    BuildingsDataSetExternalId.FieldName := 'ExternalId';
    BuildingsDataSetExternalId.Size := 64;
    BuildingsDataSetCharityTenant.SetParentComponent(BuildingsDataSet);
    BuildingsDataSetCharityTenant.Name := 'BuildingsDataSetCharityTenant';
    BuildingsDataSetCharityTenant.FieldName := 'CharityTenant';
    BuildingsDataSetBuildingName.SetParentComponent(BuildingsDataSet);
    BuildingsDataSetBuildingName.Name := 'BuildingsDataSetBuildingName';
    BuildingsDataSetBuildingName.FieldName := 'BuildingName';
    BuildingsDataSetBuildingName.Size := 255;
    BuildingsDataSetAddress.SetParentComponent(BuildingsDataSet);
    BuildingsDataSetAddress.Name := 'BuildingsDataSetAddress';
    BuildingsDataSetAddress.FieldName := 'Address';
    BuildingsDataSetAddress.Size := 255;
    BuildingsDataSetPostCode.SetParentComponent(BuildingsDataSet);
    BuildingsDataSetPostCode.Name := 'BuildingsDataSetPostCode';
    BuildingsDataSetPostCode.FieldName := 'PostCode';
    BuildingsDataSetPostCode.Size := 15;
    BuildingsDataSetMatchHash.SetParentComponent(BuildingsDataSet);
    BuildingsDataSetMatchHash.Name := 'BuildingsDataSetMatchHash';
    BuildingsDataSetMatchHash.FieldName := 'MatchHash';
    BuildingsDataSetMatchHash.Size := 64;
    BuildingsDataSetGAStatus.SetParentComponent(BuildingsDataSet);
    BuildingsDataSetGAStatus.Name := 'BuildingsDataSetGAStatus';
    BuildingsDataSetGAStatus.FieldName := 'GAStatus';
    BuildingsDataSetGAStatus.Size := 10;
    ConnectedCharityDataSet.SetParentComponent(Self);
    ConnectedCharityDataSet.Name := 'ConnectedCharityDataSet';
    ConnectedCharityDataSet.AfterApplyUpdates := ConnectedCharityDataSetAfterApplyUpdates;
    ConnectedCharityDataSet.AfterPost := ConnectedCharityDataSetAfterPost;
    ConnectedCharityDataSet.EntitySetName := 'ConnectedCharity';
    ConnectedCharityDataSet.Connection := MainData.DataConnection;
    ConnectedCharityDataSet.Left := 576;
    ConnectedCharityDataSet.Top := 200;
    ConnectedCharityDataSetId.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetId.Name := 'ConnectedCharityDataSetId';
    ConnectedCharityDataSetId.FieldName := 'Id';
    ConnectedCharityDataSetId.Required := True;
    ConnectedCharityDataSetDateAdded.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetDateAdded.Name := 'ConnectedCharityDataSetDateAdded';
    ConnectedCharityDataSetDateAdded.FieldName := 'DateAdded';
    ConnectedCharityDataSetDateAdded.Required := True;
    ConnectedCharityDataSetAddedBy.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetAddedBy.Name := 'ConnectedCharityDataSetAddedBy';
    ConnectedCharityDataSetAddedBy.FieldName := 'AddedBy';
    ConnectedCharityDataSetAddedBy.Required := True;
    ConnectedCharityDataSetLastUpdatedBy.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetLastUpdatedBy.Name := 'ConnectedCharityDataSetLastUpdatedBy';
    ConnectedCharityDataSetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    ConnectedCharityDataSetLastUpdated.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetLastUpdated.Name := 'ConnectedCharityDataSetLastUpdated';
    ConnectedCharityDataSetLastUpdated.FieldName := 'LastUpdated';
    ConnectedCharityDataSetExternalId.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetExternalId.Name := 'ConnectedCharityDataSetExternalId';
    ConnectedCharityDataSetExternalId.FieldName := 'ExternalId';
    ConnectedCharityDataSetExternalId.Size := 64;
    ConnectedCharityDataSetCharityTenant.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetCharityTenant.Name := 'ConnectedCharityDataSetCharityTenant';
    ConnectedCharityDataSetCharityTenant.FieldName := 'CharityTenant';
    ConnectedCharityDataSetHMRCRef.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetHMRCRef.Name := 'ConnectedCharityDataSetHMRCRef';
    ConnectedCharityDataSetHMRCRef.FieldName := 'HMRCRef';
    ConnectedCharityDataSetRegulatorNumber.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetRegulatorNumber.Name := 'ConnectedCharityDataSetRegulatorNumber';
    ConnectedCharityDataSetRegulatorNumber.FieldName := 'RegulatorNumber';
    ConnectedCharityDataSetRegulatorNumber.Size := 40;
    ConnectedCharityDataSetCharityName.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetCharityName.Name := 'ConnectedCharityDataSetCharityName';
    ConnectedCharityDataSetCharityName.FieldName := 'CharityName';
    ConnectedCharityDataSetCharityName.Required := True;
    ConnectedCharityDataSetCharityName.Size := 255;
    ConnectedCharityDataSetGAStatus.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetGAStatus.Name := 'ConnectedCharityDataSetGAStatus';
    ConnectedCharityDataSetGAStatus.FieldName := 'GAStatus';
    ConnectedCharityDataSetGAStatus.Size := 10;
    RefreshCharity.SetParentComponent(Self);
    RefreshCharity.Name := 'RefreshCharity';
    RefreshCharity.Enabled := False;
    SetEvent(RefreshCharity, Self, 'OnTimer', 'RefreshCharityTimer');
    RefreshCharity.Left := 576;
    RefreshCharity.Top := 272;
    RefreshBuilding.SetParentComponent(Self);
    RefreshBuilding.Name := 'RefreshBuilding';
    RefreshBuilding.Enabled := False;
    SetEvent(RefreshBuilding, Self, 'OnTimer', 'RefreshBuildingTimer');
    RefreshBuilding.Left := 568;
    RefreshBuilding.Top := 344;
  finally
    DataTable.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetExternalId.AfterLoadDFMValues;
    MainDatasetTransactionDate.AfterLoadDFMValues;
    MainDatasetImportId.AfterLoadDFMValues;
    MainDatasetClaimId.AfterLoadDFMValues;
    MainDatasetGiftAid.AfterLoadDFMValues;
    MainDatasetAmount.AfterLoadDFMValues;
    MainDatasetClaimStatus.AfterLoadDFMValues;
    MainDatasetClaimDate.AfterLoadDFMValues;
    MainDatasetTaxYear.AfterLoadDFMValues;
    MainDatasetErrorText.AfterLoadDFMValues;
    MainDatasetCharityTenant.AfterLoadDFMValues;
    MainDatasetSourceType.AfterLoadDFMValues;
    MainDatasetComment.AfterLoadDFMValues;
    MainDatasetConnectedCharityId.AfterLoadDFMValues;
    MainDatasetBuildingId.AfterLoadDFMValues;
    MainDatasetBuildingName.AfterLoadDFMValues;
    RefreshTimer.AfterLoadDFMValues;
    BuildingsDataSet.AfterLoadDFMValues;
    BuildingsDataSetId.AfterLoadDFMValues;
    BuildingsDataSetDateAdded.AfterLoadDFMValues;
    BuildingsDataSetAddedBy.AfterLoadDFMValues;
    BuildingsDataSetLastUpdatedBy.AfterLoadDFMValues;
    BuildingsDataSetLastUpdated.AfterLoadDFMValues;
    BuildingsDataSetExternalId.AfterLoadDFMValues;
    BuildingsDataSetCharityTenant.AfterLoadDFMValues;
    BuildingsDataSetBuildingName.AfterLoadDFMValues;
    BuildingsDataSetAddress.AfterLoadDFMValues;
    BuildingsDataSetPostCode.AfterLoadDFMValues;
    BuildingsDataSetMatchHash.AfterLoadDFMValues;
    BuildingsDataSetGAStatus.AfterLoadDFMValues;
    ConnectedCharityDataSet.AfterLoadDFMValues;
    ConnectedCharityDataSetId.AfterLoadDFMValues;
    ConnectedCharityDataSetDateAdded.AfterLoadDFMValues;
    ConnectedCharityDataSetAddedBy.AfterLoadDFMValues;
    ConnectedCharityDataSetLastUpdatedBy.AfterLoadDFMValues;
    ConnectedCharityDataSetLastUpdated.AfterLoadDFMValues;
    ConnectedCharityDataSetExternalId.AfterLoadDFMValues;
    ConnectedCharityDataSetCharityTenant.AfterLoadDFMValues;
    ConnectedCharityDataSetHMRCRef.AfterLoadDFMValues;
    ConnectedCharityDataSetRegulatorNumber.AfterLoadDFMValues;
    ConnectedCharityDataSetCharityName.AfterLoadDFMValues;
    ConnectedCharityDataSetGAStatus.AfterLoadDFMValues;
    RefreshCharity.AfterLoadDFMValues;
    RefreshBuilding.AfterLoadDFMValues;
  end;
end;

end.
