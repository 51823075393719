unit OtherClaimsGASDSEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, OtherClaimsEdit, Data.DB, WEBLib.DB,
  WEBLib.WebCtrls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.DBCtrls, Vcl.Controls,
  WEBLib.ExtCtrls, XData.Web.JsonDataset, XData.Web.Dataset;

type
  TOtherClaimsGASDSEditForm = class(TOtherClaimsEditForm)
    ConnectedCharityCombo: TComboBox;
    CharityDataSource: TDataSource;
    ConnectedCharityAdd: TButton;
    RefreshNewCharity: TTimer;
    procedure WebFormCreate(Sender: TObject);
    procedure ConnectedCharityAddClick(Sender: TObject);
    procedure ConnectedCharityComboChange(Sender: TObject);
    procedure RefreshNewCharityTimer(Sender: TObject);
  private
    { Private declarations }
    FNewCharityName: string;
    procedure SetConnectedCharityDataSet(const Value: TDataSet);
  protected
    [async]
    procedure GetRules; async; override;
    function GetCharityId: integer;
    procedure SetCharityNameComboBox(CharityId: integer);
    [async]
    procedure AddConnectedCharity; async;
    procedure SetNewCharity;
  public
    { Public declarations }
    property ConnectedCharityDataSet: TDataSet write SetConnectedCharityDataSet;
  protected procedure LoadDFMValues; override; end;

var
  OtherClaimsGASDSEditForm: TOtherClaimsGASDSEditForm;

implementation

{$R *.dfm}


uses
  OtherClaimsGASDSList, ConnectedCharitiesEdit;

procedure TOtherClaimsGASDSEditForm.WebFormCreate(Sender: TObject);
begin
  inherited;
end;

{ TOtherClaimsGASDSEditForm }

procedure TOtherClaimsGASDSEditForm.ConnectedCharityComboChange(Sender: TObject);
begin
  GetCharityId;
end;


function TOtherClaimsGASDSEditForm.GetCharityId: integer;
var
  ValueStr: string;
begin
  ValueStr := ConnectedCharityCombo.Values[ConnectedCharityCombo.ItemIndex];
  EditDataSource.DataSet.FieldByName('ConnectedCharityId').AsInteger := ValueStr.ToInteger;
end;

procedure TOtherClaimsGASDSEditForm.GetRules;
begin
  OtherClaimsType := 'GASDS';
  inherited;
end;

procedure TOtherClaimsGASDSEditForm.SetCharityNameComboBox(
  CharityId: integer);
var
  Idx: integer;
  ValueStr: string;
begin
  for Idx := 0 to ConnectedCharityCombo.Items.Count-1 do
  begin
    ValueStr := ConnectedCharityCombo.Values[Idx];

    try
      if ValueStr.ToInteger() = CharityId then
      begin
        ConnectedCharityCombo.ItemIndex := Idx;
        break;
      end;
    except

    end;
  end;
end;

procedure TOtherClaimsGASDSEditForm.SetConnectedCharityDataSet(
  const Value: TDataSet);
var
  CharityName: string;
  CharityId: integer;
  CurrentCharityId: integer;
begin
  CharityDataSource.DataSet := Value;
  CharityDataSource.DataSet.First;

  ConnectedCharityCombo.Items.AddPair('None', '-1');

  while not CharityDataSource.DataSet.Eof do
  begin
    CharityName := CharityDataSource.DataSet.FieldByName('CharityName').AsString;
    CharityId := CharityDataSource.DataSet.FieldByName('Id').AsInteger;
    ConnectedCharityCombo.Items.AddPair(CharityName, CharityId.ToString);
    CharityDataSource.DataSet.Next;
  end;

  CurrentCharityId := EditDataSource.DataSet.FieldByName('ConnectedCharityId').AsInteger;

  if CurrentCharityId > 0 then
    SetCharityNameComboBox(CurrentCharityId)
  else
    SetCharityNameComboBox(-1)
end;


procedure TOtherClaimsGASDSEditForm.AddConnectedCharity;
var
  Aform: TConnectedCharityEditForm;

begin
  AForm := TConnectedCharityEditForm.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());
    CharityDataSource.DataSet.Insert;
    AForm.EditDataSet := CharityDataSource.DataSet;
    AForm.AfterCreated;
    await(TModalResult, AForm.Execute);
  finally
    if AForm.ModalResult = mrOk then
    begin
      FNewCharityName := AForm.CharityNameEdit.Text;
      CharityDataSource.DataSet.Post;
    end;
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TOtherClaimsGASDSEditForm.ConnectedCharityAddClick(Sender: TObject);
begin
  inherited;
  AddConnectedCharity;
end;

procedure TOtherClaimsGASDSEditForm.RefreshNewCharityTimer(Sender: TObject);
begin
  RefreshNewCharity.Enabled := false;
  SetNewCharity;
  GetCharityId;
end;

procedure TOtherClaimsGASDSEditForm.SetNewCharity;
var
  CharityName: string;
  CharityId: integer;
  CurrentCharityId: integer;
  nIdx: integer;
  sCName: string;
begin
  ConnectedCharityCombo.Clear;
  CharityDataSource.DataSet.First;
  ConnectedCharityCombo.Items.AddPair('None', '-1');

  while not CharityDataSource.DataSet.Eof do
  begin
    CharityName := CharityDataSource.DataSet.FieldByName('CharityName').AsString;
    CharityId := CharityDataSource.DataSet.FieldByName('Id').AsInteger;
    ConnectedCharityCombo.Items.AddPair(CharityName, CharityId.ToString);
    CharityDataSource.DataSet.Next;
  end;
  CurrentCharityId := EditDataSource.DataSet.FieldByName('ConnectedCharityId').AsInteger;

  if CurrentCharityId > 0 then
    SetCharityNameComboBox(CurrentCharityId);

  if FNewCharityName <> '' then
  begin
    sCName := FNewCharityName;
    nIdx := ConnectedCharityCombo.Items.IndexOfName(sCName);
    ConnectedCharityCombo.ItemIndex := nIdx;
    EditDataSource.DataSet.FieldByName('ConnectedCharityId').AsInteger := GetCharityId;
  end;

end;

procedure TOtherClaimsGASDSEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ConnectedCharityCombo := TComboBox.Create('ConnectedCharityCombo');
  ConnectedCharityAdd := TButton.Create('ConnectedCharityAdd');
  CharityDataSource := TDataSource.Create(Self);
  RefreshNewCharity := TTimer.Create(Self);

  ConnectedCharityCombo.BeforeLoadDFMValues;
  ConnectedCharityAdd.BeforeLoadDFMValues;
  CharityDataSource.BeforeLoadDFMValues;
  RefreshNewCharity.BeforeLoadDFMValues;
  try
    Visible := True;
    ConnectedCharityCombo.SetParentComponent(Self);
    ConnectedCharityCombo.Name := 'ConnectedCharityCombo';
    ConnectedCharityCombo.Left := 16;
    ConnectedCharityCombo.Top := 400;
    ConnectedCharityCombo.Width := 185;
    ConnectedCharityCombo.Height := 23;
    ConnectedCharityCombo.ElementClassName := 'form-select';
    ConnectedCharityCombo.ElementPosition := epIgnore;
    ConnectedCharityCombo.HeightStyle := ssAuto;
    ConnectedCharityCombo.HeightPercent := 100.000000000000000000;
    ConnectedCharityCombo.Text := 'ConnectedCharityCombo';
    ConnectedCharityCombo.WidthStyle := ssAuto;
    ConnectedCharityCombo.WidthPercent := 100.000000000000000000;
    SetEvent(ConnectedCharityCombo, Self, 'OnChange', 'ConnectedCharityComboChange');
    ConnectedCharityCombo.ItemIndex := -1;
    ConnectedCharityAdd.SetParentComponent(Self);
    ConnectedCharityAdd.Name := 'ConnectedCharityAdd';
    ConnectedCharityAdd.Left := 216;
    ConnectedCharityAdd.Top := 400;
    ConnectedCharityAdd.Width := 33;
    ConnectedCharityAdd.Height := 25;
    ConnectedCharityAdd.Caption := 'Add Connected Charity';
    ConnectedCharityAdd.ChildOrder := 9;
    ConnectedCharityAdd.ElementClassName := 'btn btn-secondary';
    ConnectedCharityAdd.ElementFont := efCSS;
    ConnectedCharityAdd.ElementPosition := epIgnore;
    ConnectedCharityAdd.HeightStyle := ssAuto;
    ConnectedCharityAdd.HeightPercent := 100.000000000000000000;
    ConnectedCharityAdd.WidthStyle := ssAuto;
    ConnectedCharityAdd.WidthPercent := 100.000000000000000000;
    SetEvent(ConnectedCharityAdd, Self, 'OnClick', 'ConnectedCharityAddClick');
    CharityDataSource.SetParentComponent(Self);
    CharityDataSource.Name := 'CharityDataSource';
    CharityDataSource.Left := 478;
    CharityDataSource.Top := 152;
    RefreshNewCharity.SetParentComponent(Self);
    RefreshNewCharity.Name := 'RefreshNewCharity';
    RefreshNewCharity.Enabled := False;
    RefreshNewCharity.Interval := 10;
    SetEvent(RefreshNewCharity, Self, 'OnTimer', 'RefreshNewCharityTimer');
    RefreshNewCharity.Left := 544;
    RefreshNewCharity.Top := 288;
  finally
    ConnectedCharityCombo.AfterLoadDFMValues;
    ConnectedCharityAdd.AfterLoadDFMValues;
    CharityDataSource.AfterLoadDFMValues;
    RefreshNewCharity.AfterLoadDFMValues;
  end;
end;

end.
