unit RegisterAgreement;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  WEBLib.ExtCtrls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  XData.Web.Client;

type
  TAgreementForm = class(TForm)
    AgreeYesButton: TButton;
    AgreeCancelButton: TButton;
    TermsDocument: THTMLContainer;
    procedure AgreeCancelButtonClick(Sender: TObject);
    procedure AgreeYesButtonClick(Sender: TObject);
  private
    FWebClient: TXDataWebClient;
    [Async]
    procedure LoadAgreement; async;
    procedure SetWebClient(const Value: TXDataWebClient);
  public
    { Public declarations }
    property WebClient: TXDataWebClient write SetWebClient;
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

procedure TAgreementForm.AgreeCancelButtonClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TAgreementForm.AgreeYesButtonClick(Sender: TObject);
begin
  ModalResult := mrYes;
end;

procedure TAgreementForm.LoadAgreement;
const
  Svc_LookUpCharityDetails = 'IGARegisterService.GetTermsAndConditions';
var
  lRetval: TXDataClientResponse;
begin
  lRetval := await(TXDataClientResponse, FWebClient.RawInvokeAsync(Svc_LookUpCharityDetails, []));
  TermsDocument.HTML.Text := string(lRetval.ResultAsObject['value']);
  AgreeYesButton.Enabled := True;
end;

procedure TAgreementForm.SetWebClient(const Value: TXDataWebClient);
begin
  FWebClient := Value;
  LoadAgreement;
end;

procedure TAgreementForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  AgreeYesButton := TButton.Create('AgreeYesButton');
  AgreeCancelButton := TButton.Create('AgreeCancelButton');
  TermsDocument := THTMLContainer.Create('TermsDocument');

  AgreeYesButton.BeforeLoadDFMValues;
  AgreeCancelButton.BeforeLoadDFMValues;
  TermsDocument.BeforeLoadDFMValues;
  try
    Name := 'AgreementForm';
    Width := 640;
    Height := 480;
    ElementClassName := 'PopUpForm SMXWebRegister ';
    AgreeYesButton.SetParentComponent(Self);
    AgreeYesButton.Name := 'AgreeYesButton';
    AgreeYesButton.Left := 496;
    AgreeYesButton.Top := 392;
    AgreeYesButton.Width := 96;
    AgreeYesButton.Height := 25;
    AgreeYesButton.Caption := 'I Agree';
    AgreeYesButton.ElementClassName := 'btn btn-primary';
    AgreeYesButton.Enabled := False;
    AgreeYesButton.HeightStyle := ssAuto;
    AgreeYesButton.HeightPercent := 100.000000000000000000;
    AgreeYesButton.WidthStyle := ssAuto;
    AgreeYesButton.WidthPercent := 100.000000000000000000;
    SetEvent(AgreeYesButton, Self, 'OnClick', 'AgreeYesButtonClick');
    AgreeCancelButton.SetParentComponent(Self);
    AgreeCancelButton.Name := 'AgreeCancelButton';
    AgreeCancelButton.Left := 352;
    AgreeCancelButton.Top := 392;
    AgreeCancelButton.Width := 96;
    AgreeCancelButton.Height := 25;
    AgreeCancelButton.Caption := 'Cancel';
    AgreeCancelButton.ChildOrder := 1;
    AgreeCancelButton.ElementClassName := 'btn btn-secondary';
    AgreeCancelButton.ElementPosition := epIgnore;
    AgreeCancelButton.HeightPercent := 100.000000000000000000;
    AgreeCancelButton.WidthStyle := ssAuto;
    AgreeCancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(AgreeCancelButton, Self, 'OnClick', 'AgreeCancelButtonClick');
    TermsDocument.SetParentComponent(Self);
    TermsDocument.Name := 'TermsDocument';
    TermsDocument.Left := 48;
    TermsDocument.Top := 24;
    TermsDocument.Width := 544;
    TermsDocument.Height := 353;
    TermsDocument.HeightStyle := ssAuto;
    TermsDocument.WidthStyle := ssAuto;
    TermsDocument.ChildOrder := 3;
    TermsDocument.ElementPosition := epIgnore;
    TermsDocument.ScrollStyle := ssVertical;
  finally
    AgreeYesButton.AfterLoadDFMValues;
    AgreeCancelButton.AfterLoadDFMValues;
    TermsDocument.AfterLoadDFMValues;
  end;
end;

end.
