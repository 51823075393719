unit AgentCharitiesList;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WebForm.Core, Vcl.Controls, WEBLib.Grids,
  WEBLib.DBCtrls, Data.DB, WEBLib.DB, WEBLib.CDS, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.ExtCtrls;

type
  TAgentCharitiesListForm = class(TCoreWebForm)
    AgentCharitiesDataset: TClientDataSet;
    AgentCharitiesDataSource: TDataSource;
    AgentCharitiesDataTable: TDBTableControl;
    AgentCharitiesDatasetOrganisationId: TIntegerField;
    AgentCharitiesDatasetOrganisationName: TStringField;
    AgentCharitiesDatasetTenantId: TStringField;
    WebEdit1: TEdit;
    DisplayTimer: TTimer;
    procedure AgentCharitiesDataTableGetCellChildren(Sender: TObject; ACol, ARow:
        Integer; AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure WebFormShow(Sender: TObject);
    procedure DisplayTimerTimer(Sender: TObject);
  private
    { Private declarations }
    FOrganisationId: integer;
    FSelectedTenantId: string;
    //procedure SelectedCharity;
    //procedure SelectedCharity(ARecordId: integer);
    procedure SelectedCharity(ARecordId: string);
  public
    { Public declarations }
    [Async]
    procedure LoadData; async;
    property OrganisationId: integer write FOrganisationId;
    property SelectedTenantId: string read FSelectedTenantId;
  protected procedure LoadDFMValues; override; end;

var
  AgentCharitiesListForm: TAgentCharitiesListForm;

implementation

{$R *.dfm}

uses
  XData.Web.Client,
  WEBLib.WebCtrls,
  SMX.Web.Layout.Utils,
  MainDataModule;


procedure TAgentCharitiesListForm.AgentCharitiesDataTableGetCellChildren(
    Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string;
    AElement: TJSHTMLElementRecord);
var
  RecordId: integer;
  Span: THTMLSpan;
  TenantId: string;


  procedure EditClick(Sender: TObject);
  begin
    //SelectedCharity(RecordId);
    SelectedCharity(TenantId);
  end;

begin
  if ARow = 0 then
    Exit;


  if not ((AgentCharitiesDataTable.Columns[ACol].Title = 'Select Charity')) then
  begin
    Exit;
  end;

  RecordId := AgentCharitiesDataset.FieldByName('OrganisationId').AsInteger;
  TenantId := AgentCharitiesDataset.FieldByName('TenantId').AsString;

  //if (MainDataSet.FieldByName('ClaimStatus').AsString = 'Unclaimed') or
  //   (MainDataSet.FieldByName('ClaimStatus').AsString = 'Invalid') then
  begin
    Span := TLayoutUtils.RowActionSpan(AElement.Element, 'fad fa-edit', 'Edit');
    Span.OnClick := @EditClick;
  end;
end;

procedure TAgentCharitiesListForm.LoadData;
var
  lRetval: TXDataClientResponse;
  lResponse: TJSObject;
  lData: TJSArray;
begin
  AgentCharitiesDataset.Active := False;
  WebEdit1.Text := WebEdit1.Text + inttostr(FOrganisationId);
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync('IOrganisationService.GetAgentCharityList', [FOrganisationId]));

  lResponse := lRetval.ResultAsObject;
  lData := TJSArray(lResponse['value']);

  AgentCharitiesDataset.Rows := lData;
  AgentCharitiesDataset.Active := True;
end;

//procedure TAgentCharitiesListForm.SelectedCharity(ARecordId: integer);
procedure TAgentCharitiesListForm.SelectedCharity(ARecordId: string);
begin
  FSelectedTenantId := ARecordId;
  ModalResult := mrOk;


//
//  WebEdit1.Text := inttostr(ARecordId);
//  WebEdit1.Text := ARecordId;
end;

procedure TAgentCharitiesListForm.WebFormShow(Sender: TObject);
begin
  inherited;
  DisplayTimer.Enabled := true;
end;

procedure TAgentCharitiesListForm.DisplayTimerTimer(Sender: TObject);
begin
  DisplayTimer.Enabled := False;
  LoadData;
end;

procedure TAgentCharitiesListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  AgentCharitiesDataTable := TDBTableControl.Create('UserDataTable');
  WebEdit1 := TEdit.Create(Self);
  AgentCharitiesDataset := TClientDataSet.Create(Self);
  AgentCharitiesDatasetOrganisationId := TIntegerField.Create(Self);
  AgentCharitiesDatasetOrganisationName := TStringField.Create(Self);
  AgentCharitiesDatasetTenantId := TStringField.Create(Self);
  AgentCharitiesDataSource := TDataSource.Create(Self);
  DisplayTimer := TTimer.Create(Self);

  AgentCharitiesDataTable.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  AgentCharitiesDataset.BeforeLoadDFMValues;
  AgentCharitiesDatasetOrganisationId.BeforeLoadDFMValues;
  AgentCharitiesDatasetOrganisationName.BeforeLoadDFMValues;
  AgentCharitiesDatasetTenantId.BeforeLoadDFMValues;
  AgentCharitiesDataSource.BeforeLoadDFMValues;
  DisplayTimer.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    AgentCharitiesDataTable.SetParentComponent(Self);
    AgentCharitiesDataTable.Name := 'AgentCharitiesDataTable';
    AgentCharitiesDataTable.Left := 32;
    AgentCharitiesDataTable.Top := 136;
    AgentCharitiesDataTable.Width := 513;
    AgentCharitiesDataTable.Height := 249;
    AgentCharitiesDataTable.HeightStyle := ssAuto;
    AgentCharitiesDataTable.WidthStyle := ssAuto;
    AgentCharitiesDataTable.BorderColor := clSilver;
    AgentCharitiesDataTable.ChildOrder := 4;
    AgentCharitiesDataTable.ElementFont := efCSS;
    AgentCharitiesDataTable.ElementHeaderClassName := 'thead-light';
    AgentCharitiesDataTable.ElementPosition := epIgnore;
    AgentCharitiesDataTable.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    SetEvent(AgentCharitiesDataTable, Self, 'OnGetCellChildren', 'AgentCharitiesDataTableGetCellChildren');
    AgentCharitiesDataTable.Columns.Clear;
    with AgentCharitiesDataTable.Columns.Add do
    begin
      DataField := 'OrganisationName';
      Title := 'Organisation';
    end;
    with AgentCharitiesDataTable.Columns.Add do
    begin
      Title := 'Select Charity';
    end;
    AgentCharitiesDataTable.DataSource := AgentCharitiesDataSource;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 304;
    WebEdit1.Top := 424;
    WebEdit1.Width := 121;
    WebEdit1.Height := 22;
    WebEdit1.ChildOrder := 1;
    WebEdit1.HeightPercent := 100.000000000000000000;
    WebEdit1.WidthPercent := 100.000000000000000000;
    AgentCharitiesDataset.SetParentComponent(Self);
    AgentCharitiesDataset.Name := 'AgentCharitiesDataset';
    AgentCharitiesDataset.Left := 176;
    AgentCharitiesDataset.Top := 16;
    AgentCharitiesDatasetOrganisationId.SetParentComponent(AgentCharitiesDataset);
    AgentCharitiesDatasetOrganisationId.Name := 'AgentCharitiesDatasetOrganisationId';
    AgentCharitiesDatasetOrganisationId.FieldName := 'OrganisationId';
    AgentCharitiesDatasetOrganisationName.SetParentComponent(AgentCharitiesDataset);
    AgentCharitiesDatasetOrganisationName.Name := 'AgentCharitiesDatasetOrganisationName';
    AgentCharitiesDatasetOrganisationName.FieldName := 'OrganisationName';
    AgentCharitiesDatasetOrganisationName.Size := 250;
    AgentCharitiesDatasetTenantId.SetParentComponent(AgentCharitiesDataset);
    AgentCharitiesDatasetTenantId.Name := 'AgentCharitiesDatasetTenantId';
    AgentCharitiesDatasetTenantId.FieldName := 'TenantId';
    AgentCharitiesDatasetTenantId.Size := 50;
    AgentCharitiesDataSource.SetParentComponent(Self);
    AgentCharitiesDataSource.Name := 'AgentCharitiesDataSource';
    AgentCharitiesDataSource.DataSet := AgentCharitiesDataset;
    AgentCharitiesDataSource.Left := 280;
    AgentCharitiesDataSource.Top := 24;
    DisplayTimer.SetParentComponent(Self);
    DisplayTimer.Name := 'DisplayTimer';
    DisplayTimer.Enabled := False;
    SetEvent(DisplayTimer, Self, 'OnTimer', 'DisplayTimerTimer');
    DisplayTimer.Left := 496;
    DisplayTimer.Top := 40;
  finally
    AgentCharitiesDataTable.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    AgentCharitiesDataset.AfterLoadDFMValues;
    AgentCharitiesDatasetOrganisationId.AfterLoadDFMValues;
    AgentCharitiesDatasetOrganisationName.AfterLoadDFMValues;
    AgentCharitiesDatasetTenantId.AfterLoadDFMValues;
    AgentCharitiesDataSource.AfterLoadDFMValues;
    DisplayTimer.AfterLoadDFMValues;
  end;
end;

end.