unit UserManager.Utils;

interface

uses
  Data.DB,
  GA.Return.Types;

type

  TUserManagerUtils = class
  public
    class function DatasetToUser(Dataset: TDataset): TGiftAidUser;
    class procedure UserToDataset(AUser: TGiftAidUser; Dataset: TDataset);
  end;

implementation

{ TUserManagerUtils }

class function TUserManagerUtils.DatasetToUser(Dataset: TDataset): TGiftAidUser;
begin
  Result := TGiftAidUser.Create;
  Result.InternalId := Dataset.FieldByName('InternalId').AsInteger;
  Result.OrganisationId := Dataset.FieldByName('OrganisationId').AsInteger;
  Result.OrganisationName := Dataset.FieldByName('OrganisationName').AsString;
  Result.TenantId := Dataset.FieldByName('TenantId').AsString;
  Result.JobTitle := Dataset.FieldByName('JobTitle').AsString;
  Result.JobPosition := Dataset.FieldByName('JobPosition').AsString;
  Result.Title := Dataset.FieldByName('Title').AsString;
  Result.GivenName := Dataset.FieldByName('GivenName').AsString;
  Result.LastName := Dataset.FieldByName('LastName').AsString;
  Result.Email := Dataset.FieldByName('Email').AsString;
  Result.UserStatus := Dataset.FieldByName('UserStatus').AsString;
  Result.UserScope := Dataset.FieldByName('UserScope').AsString;
  Result.PhoneNumber := Dataset.FieldByName('PhoneNumber').AsString;
  Result.HMRCPhone := Dataset.FieldByName('HMRCPhone').AsString;
  Result.HMRCPostCode := Dataset.FieldByName('HMRCPostCode').AsString;
  Result.HMRCRegistered := Dataset.FieldByName('HMRCRegistered').AsBoolean;
  Result.CanSubmit := Dataset.FieldByName('CanSubmit').AsBoolean;
end;

class procedure TUserManagerUtils.UserToDataset(AUser: TGiftAidUser; Dataset: TDataset);
begin
  Dataset.FieldByName('InternalId').AsInteger := AUser.InternalId;
  Dataset.FieldByName('OrganisationId').AsInteger := AUser.OrganisationId;
  Dataset.FieldByName('OrganisationName').AsString := AUser.OrganisationName;
  Dataset.FieldByName('TenantId').AsString := AUser.TenantId;
  Dataset.FieldByName('JobTitle').AsString := AUser.JobTitle;
  Dataset.FieldByName('JobPosition').AsString := AUser.JobPosition;
  Dataset.FieldByName('Title').AsString := AUser.Title;
  Dataset.FieldByName('GivenName').AsString := AUser.GivenName;
  Dataset.FieldByName('LastName').AsString := AUser.LastName;
  Dataset.FieldByName('Email').AsString := AUser.Email;
  Dataset.FieldByName('UserStatus').AsString := AUser.UserStatus;
  Dataset.FieldByName('UserScope').AsString := AUser.UserScope;
  Dataset.FieldByName('PhoneNumber').AsString := AUser.PhoneNumber;
  Dataset.FieldByName('HMRCPhone').AsString := AUser.HMRCPhone;
  Dataset.FieldByName('HMRCPostCode').AsString := AUser.HMRCPostCode;
  Dataset.FieldByName('HMRCRegistered').AsBoolean := AUser.HMRCRegistered;
  Dataset.FieldByName('CanSubmit').AsBoolean := AUser.CanSubmit;
end;

end.
