unit ImportForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  JobBaseForm,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.ExtCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls,
  Vcl.Imaging.GIFImg,
  WEBLib.Toast,
  SMX.Web.Service.Consts;

type
  TImportPage = class(TJobBase)
    FilePicker: TFilePicker;
    JobsPlacedDateAdded: TDateTimeField;
    JobsPlacedAddedBy: TIntegerField;
    JobsPlacedLastUpdatedBy: TIntegerField;
    JobsPlacedLastUpdated: TDateTimeField;
    JobsPlacedJobCategory: TStringField;
    JobsPlacedLastPolled: TDateTimeField;
    JobsPlacedPollInterval: TLargeintField;
    JobsPlacedPollCount: TIntegerField;
    NewMappingButton: TButton;
    ImportMappings: TXDataWebDataSet;
    MappingsDS: TDataSource;
    ImportMappingsId: TIntegerField;
    ImportMappingsDateAdded: TDateTimeField;
    ImportMappingsAddedBy: TIntegerField;
    ImportMappingsLastUpdatedBy: TIntegerField;
    ImportMappingsLastUpdated: TDateTimeField;
    ImportMappingsName: TStringField;
    ImportMappingsMapping: TStringField;
    ImportMappingsHasTitles: TBooleanField;
    RefreshTimer: TTimer;
    MappingsCombo: TComboBox;
    WaitForBatchDataTimer: TTimer;
    procedure WebFormCreate(Sender: TObject);
    procedure CancelButtonClick(Sender: TObject);
    procedure FilePickerChange(Sender: TObject);
    procedure ImportMappingsAfterPost(Dataset: TDataSet);
    procedure MappingsComboChange(Sender: TObject);
    procedure NewMappingButtonClick(Sender: TObject);
    procedure PlaceJobButtonClick(Sender: TObject);
    procedure RefreshTimerTimer(Sender: TObject);
    procedure WaitForBatchDataTimerTimer(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
    FImportMapId: integer;
    FMappingName: string;
    FNewMappingName: string;
    FBatchId: integer;
    FFileName: string;
    FTempFileName: string;
    procedure SetImportMapId(MappingName: string);
    [async]
    procedure MappingConfigurationParameters(AJSONFileData: string; ADelimeter: char); async;

    [async]
    procedure GetNewBatchId; async;
    procedure SaveNewImport;

  protected
    procedure JobParams(AJobParams: JS.TJSObject); override;
    function CanPlaceJob: boolean; override;
    function JobDescription: string; override;
    function JobDisplayName: string; override;
    function JobClass: string; override;
    [async]
    procedure PlaceJob; async; override;
    [async]
    procedure NewMapping; async;
    procedure OkToPlaceJob;
    procedure LoadMappingsCombo;
    procedure MappingsEnabled;
    [async]
    procedure SetDefaultImportMapping; async;

  public
    { Public declarations }
    property BatchId: integer read FBatchId;
  protected procedure LoadDFMValues; override; end;

implementation

uses
  XData.Web.Client,
  MainDataModule,
  ImportMappings,
  SMX.Jobs.Shared;

const
  SInvalidFileEncoding =
    'Your file is not encoded in a format that is recognised. Please contact the GiftAider team for advice';

{$R *.dfm}

procedure TImportPage.WebFormCreate(Sender: TObject);
begin
  inherited;
  SetDefaultImportMapping;
end;

procedure TImportPage.CancelButtonClick(Sender: TObject);
begin
  inherited;
end;

{ TImportDonorsJob }

function TImportPage.CanPlaceJob: boolean;
begin
  result := inherited CanPlaceJob;
  // if result then
  // begin
  // while not JobsPlaced.eof do
  // begin
  // if IsJobActive(JobsPlacedStatus.Value) then
  // begin
  // SetJobMessage('You cannot create a new import while there is still one in progress');
  // Exit(False);
  // end;
  // JobsPlaced.Next;
  // end;
  // end;

  if FLoading then
    result := False
  else if (not Assigned(FilePicker.Files)) or (FilePicker.Files.Count = 0) then
  begin
    SetJobMessage('Please select the file to upload');
    result := False;
  end
  else if FilePicker.Files.Count > 1 then
  begin
    SetJobMessage('You can only select one file to upload');
    result := False;
  end;

end;

procedure TImportPage.FilePickerChange(Sender: TObject);
begin
  MappingsEnabled;
  ValidateJob;
  OkToPlaceJob;
end;

procedure TImportPage.ImportMappingsAfterPost(Dataset: TDataSet);
begin
  // inherited;
  ImportMappings.ApplyUpdates;
end;

function TImportPage.JobClass: string;
begin
  result := 'TDonationImportJob';
end;

function TImportPage.JobDescription: string;
begin
  result := 'Import Donations';
end;

function TImportPage.JobDisplayName: string;
begin
  result := 'Donation Import';
end;

procedure TImportPage.PlaceJob;
var
  lParams: string;
  lFile: string;
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;
  sData: string;
  FieldCount: integer;
  DelimeterCharacter: char;
  JSONFileData: string;

begin
  WaitMessage.Show;

  FFileName := FilePicker.Files[0].Name;

  lParams := GetParams;

  if FTempFileName = '' then
  begin
    lFile := await(string, FilePicker.Files[0].FileAsBase64);
    lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IJOBSVC_SCHEDULE_FILE_JOB,
      [JobClass, JobVisibility.Value, lParams, OutputOption.Value, FFileName, lFile]));
  end
  else
  begin
    lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IJOBSVC_SCHEDULEJOB,
      [JobClass, JobVisibility.Value, lParams, OutputOption.Value, 'Production']));
  end;

  FJobId := JS.toInteger(lRetval.ResultAsObject['value']);
  if FJobId = ERR_UNRECOGNISED_ENCODING then
  begin
    ShowMessage(SInvalidFileEncoding);
  end
  else
  begin
    AfterPlaceJob;
    ShowAToast(JobDisplayName, 'Your job ' + JobDescription + ' has been placed. The Job Number is ' + FJobId.ToString,
      'clock.png');
  end;

  FilePicker.Files.Clear;

  CloseTimer.Enabled := True;
  WaitMessage.Hide;
  ModalResult := mrOK;
end;

procedure TImportPage.MappingConfigurationParameters(AJSONFileData: string; ADelimeter: char);
var
  AForm: TMappingsForm;
  lNewMappingName: string;
  lResult: TModalResult;
begin
  AForm := TMappingsForm.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());

    AForm.Delimeter := ADelimeter;
    AForm.JSONFileData := AJSONFileData;

    lResult := await(TModalResult, AForm.Execute);
    if lResult = mrOK then
    begin
      lNewMappingName := AForm.MappingName;
      FTempFileName := AForm.TempFileName;
      ImportMappings.Close;
      ImportMappings.Load;
      FNewMappingName := lNewMappingName;
    end;

  finally
    AForm.Free;
    AForm := nil;
    RefreshTimer.Enabled := True;
    NewMappingButton.Enabled := True;
  end;
end;

procedure TImportPage.NewMapping;
var
  lFileName, lFile: string;
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;
  JSONFileData: string;

begin
  lFileName := FilePicker.Files[0].Name;
  lFile := await(string, FilePicker.Files[0].FileAsBase64);

  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync('IGiftAiderService.ImportMappingUpload',
    [lFileName, lFile]));
  lResult := lRetval.ResultAsObject;
  JSONFileData := JS.ToString(lResult['value']);

  if JSONFileData = 'unrecoginsed-encoding' then
  begin
    ShowMessage(SInvalidFileEncoding);
    Exit;
  end;

  MappingConfigurationParameters(JSONFileData, ',');
  WaitMessage.Hide;
end;

procedure TImportPage.NewMappingButtonClick(Sender: TObject);
begin
  WaitMessage.Show;
  NewMappingButton.Enabled := False;
  NewMapping;
end;

procedure TImportPage.RefreshTimerTimer(Sender: TObject);
begin
  inherited;
  RefreshTimer.Enabled := False;

  LoadMappingsCombo;

  if FNewMappingName <> '' then
    SetImportMapId(FNewMappingName)
  else
    MappingsCombo.Text := '-- Choose Mapping --';

  OkToPlaceJob;
end;

procedure TImportPage.WebFormShow(Sender: TObject);
begin
  inherited;
  ImportMappings.Load;
  RefreshTimer.Enabled := True;
end;

procedure TImportPage.JobParams(AJobParams: JS.TJSObject);
begin
  inherited;
  AJobParams.Properties['ImportMap'] := FImportMapId;
  AJobParams.Properties['BatchId'] := FBatchId;
  //AJobParams.Properties[JOBS_UPLOADED_FILE] := FFileName;
  AJobParams.Properties[JOBS_ORIGINAL_FILENAME] := FFileName;
  if FTempFileName <> '' then
    AJobParams.Properties[JOBS_TEMP_FILE] := FTempFileName;

end;

procedure TImportPage.LoadMappingsCombo;
begin
  MappingsCombo.Clear;

  if not ImportMappings.EOF then
  begin
    ImportMappings.First;

    while not ImportMappings.eof do
    begin
      MappingsCombo.Items.Add(ImportMappings.FieldByName('Name').AsString);
      ImportMappings.Next;
    end;
  end;
end;

procedure TImportPage.MappingsComboChange(Sender: TObject);
var
  lMappingName: string;
begin
  lMappingName := MappingsCombo.Items[MappingsCombo.ItemIndex];
  SetImportMapId(lMappingName);
  OkToPlaceJob;
end;

procedure TImportPage.MappingsEnabled;
var
  lEnabled: boolean;
begin
  lEnabled := Assigned(FilePicker.Files);
  MappingsCombo.Enabled := lEnabled;
  NewMappingButton.Enabled := lEnabled;
end;

procedure TImportPage.SetImportMapId(MappingName: string);
var
  MappingItemIndex: integer;
begin
  if ImportMappings.Locate('Name', MappingName, []) then
  begin
    FImportMapId := ImportMappings.FieldByName('Id').AsInteger;
    MappingItemIndex := MappingsCombo.Items.IndexOf(MappingName);
    MappingsCombo.ItemIndex := MappingItemIndex;
  end;
end;

procedure TImportPage.OkToPlaceJob;
var
  lFileCount: integer;
begin
  lFileCount := FilePicker.Files.Count;

  if (MappingsCombo.ItemIndex <> -1) and (lFileCount > 0) then
  begin
    PlaceJobButton.Enabled := True;
  end
  else
  begin
    PlaceJobButton.Enabled := False;
  end;
end;

procedure TImportPage.GetNewBatchId;
var
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;
begin
  FFileName := FilePicker.Files[0].Name;

  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync('IGiftAiderUtilsService.CreateNewBatch',
    ['Import', FFileName]));
  lResult := lRetval.ResultAsObject;
  FBatchId := JS.toInteger(lResult['value']);

  WaitForBatchDataTimer.Enabled := True;
end;

procedure TImportPage.PlaceJobButtonClick(Sender: TObject);
begin
  // inherited;
  SaveNewImport;
end;

procedure TImportPage.SaveNewImport;
begin
  if FBatchId = 0 then
  begin
    GetNewBatchId;
  end
  else
  begin
    WaitForBatchDataTimer.Enabled := False;
    PlaceJob;
  end;
end;

procedure TImportPage.WaitForBatchDataTimerTimer(Sender: TObject);
begin
  inherited;
  SaveNewImport;
end;

procedure TImportPage.SetDefaultImportMapping;
var
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;
begin
  lRetval := await(TXDataClientResponse,
    MainData.WebClient.RawInvokeAsync('IGiftAiderUtilsService.GetDefaultMapping', []));
  lResult := lRetval.ResultAsObject;
  FNewMappingName := JS.ToString(lResult['value']);

  RefreshTimer.Enabled := True;
end;

procedure TImportPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FilePicker := TFilePicker.Create('FilePicker');
  NewMappingButton := TButton.Create('NewMappingButton');
  MappingsCombo := TComboBox.Create('MappingsCombo');
  JobsPlacedDateAdded := TDateTimeField.Create(Self);
  JobsPlacedAddedBy := TIntegerField.Create(Self);
  JobsPlacedLastUpdatedBy := TIntegerField.Create(Self);
  JobsPlacedLastUpdated := TDateTimeField.Create(Self);
  JobsPlacedJobCategory := TStringField.Create(Self);
  JobsPlacedLastPolled := TDateTimeField.Create(Self);
  JobsPlacedPollInterval := TLargeintField.Create(Self);
  JobsPlacedPollCount := TIntegerField.Create(Self);
  ImportMappings := TXDataWebDataSet.Create(Self);
  ImportMappingsId := TIntegerField.Create(Self);
  ImportMappingsDateAdded := TDateTimeField.Create(Self);
  ImportMappingsAddedBy := TIntegerField.Create(Self);
  ImportMappingsLastUpdatedBy := TIntegerField.Create(Self);
  ImportMappingsLastUpdated := TDateTimeField.Create(Self);
  ImportMappingsName := TStringField.Create(Self);
  ImportMappingsMapping := TStringField.Create(Self);
  ImportMappingsHasTitles := TBooleanField.Create(Self);
  MappingsDS := TDataSource.Create(Self);
  RefreshTimer := TTimer.Create(Self);
  WaitForBatchDataTimer := TTimer.Create(Self);

  JobsPlacedTable.BeforeLoadDFMValues;
  PlaceJobButton.BeforeLoadDFMValues;
  OutputOption.BeforeLoadDFMValues;
  JobVisibility.BeforeLoadDFMValues;
  JobMessage.BeforeLoadDFMValues;
  FilePicker.BeforeLoadDFMValues;
  JobRunType.BeforeLoadDFMValues;
  NewMappingButton.BeforeLoadDFMValues;
  MappingsCombo.BeforeLoadDFMValues;
  JobsPlaced.BeforeLoadDFMValues;
  JobsPlacedDateAdded.BeforeLoadDFMValues;
  JobsPlacedAddedBy.BeforeLoadDFMValues;
  JobsPlacedLastUpdatedBy.BeforeLoadDFMValues;
  JobsPlacedLastUpdated.BeforeLoadDFMValues;
  JobsPlacedJobCategory.BeforeLoadDFMValues;
  JobsPlacedLastPolled.BeforeLoadDFMValues;
  JobsPlacedPollInterval.BeforeLoadDFMValues;
  JobsPlacedPollCount.BeforeLoadDFMValues;
  ImportMappings.BeforeLoadDFMValues;
  ImportMappingsId.BeforeLoadDFMValues;
  ImportMappingsDateAdded.BeforeLoadDFMValues;
  ImportMappingsAddedBy.BeforeLoadDFMValues;
  ImportMappingsLastUpdatedBy.BeforeLoadDFMValues;
  ImportMappingsLastUpdated.BeforeLoadDFMValues;
  ImportMappingsName.BeforeLoadDFMValues;
  ImportMappingsMapping.BeforeLoadDFMValues;
  ImportMappingsHasTitles.BeforeLoadDFMValues;
  MappingsDS.BeforeLoadDFMValues;
  RefreshTimer.BeforeLoadDFMValues;
  WaitForBatchDataTimer.BeforeLoadDFMValues;
  try
    Caption := 'MappingsDS';
    SetEvent(Self, 'OnShow', 'WebFormShow');
    JobsPlacedTable.Columns.Clear;
    with JobsPlacedTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with JobsPlacedTable.Columns.Add do
    begin
      DataField := 'Title';
      Title := 'Title';
    end;
    with JobsPlacedTable.Columns.Add do
    begin
      DataField := 'DateSubmitted';
      Title := 'Submitted';
    end;
    with JobsPlacedTable.Columns.Add do
    begin
      DataField := 'Status';
      Title := 'Status';
    end;
    with JobsPlacedTable.Columns.Add do
    begin
      DataField := 'DateCompleted';
      Title := 'Completed';
    end;
    with JobsPlacedTable.Columns.Add do
    begin
      DataField := 'TimeTakenStr';
      Title := 'Time Taken';
    end;
    PlaceJobButton.Top := 428;
    PlaceJobButton.Enabled := False;
    OutputOption.Visible := False;
    JobVisibility.Visible := False;
    JobMessage.Left := 64;
    JobMessage.Top := 382;
    FilePicker.SetParentComponent(Self);
    FilePicker.Name := 'FilePicker';
    FilePicker.Left := 72;
    FilePicker.Top := 312;
    FilePicker.Width := 321;
    FilePicker.Height := 19;
    FilePicker.ElementClassName := 'form-control';
    FilePicker.HeightStyle := ssAuto;
    FilePicker.WidthStyle := ssAuto;
    FilePicker.Accept := '.csv, .xlsx';
    FilePicker.ChildOrder := 7;
    FilePicker.ElementFont := efCSS;
    FilePicker.ElementPosition := epIgnore;
    SetEvent(FilePicker, Self, 'OnChange', 'FilePickerChange');
    JobRunType.Visible := False;
    NewMappingButton.SetParentComponent(Self);
    NewMappingButton.Name := 'NewMappingButton';
    NewMappingButton.Left := 416;
    NewMappingButton.Top := 336;
    NewMappingButton.Width := 96;
    NewMappingButton.Height := 25;
    NewMappingButton.Caption := 'New Mapping';
    NewMappingButton.ChildOrder := 11;
    NewMappingButton.ElementClassName := 'btn btn-secondary';
    NewMappingButton.Enabled := False;
    NewMappingButton.HeightPercent := 100.000000000000000000;
    NewMappingButton.WidthPercent := 100.000000000000000000;
    SetEvent(NewMappingButton, Self, 'OnClick', 'NewMappingButtonClick');
    MappingsCombo.SetParentComponent(Self);
    MappingsCombo.Name := 'MappingsCombo';
    MappingsCombo.Left := 72;
    MappingsCombo.Top := 337;
    MappingsCombo.Width := 321;
    MappingsCombo.Height := 23;
    MappingsCombo.ElementClassName := 'form-select';
    MappingsCombo.ElementPosition := epIgnore;
    MappingsCombo.HeightStyle := ssAuto;
    MappingsCombo.HeightPercent := 100.000000000000000000;
    MappingsCombo.Text := '-- Choose Mapping --';
    MappingsCombo.WidthStyle := ssAuto;
    MappingsCombo.WidthPercent := 100.000000000000000000;
    SetEvent(MappingsCombo, Self, 'OnChange', 'MappingsComboChange');
    MappingsCombo.ItemIndex := -1;
    JobsPlacedDateAdded.SetParentComponent(JobsPlaced);
    JobsPlacedDateAdded.Name := 'JobsPlacedDateAdded';
    JobsPlacedDateAdded.FieldName := 'DateAdded';
    JobsPlacedDateAdded.Required := True;
    JobsPlacedAddedBy.SetParentComponent(JobsPlaced);
    JobsPlacedAddedBy.Name := 'JobsPlacedAddedBy';
    JobsPlacedAddedBy.FieldName := 'AddedBy';
    JobsPlacedAddedBy.Required := True;
    JobsPlacedLastUpdatedBy.SetParentComponent(JobsPlaced);
    JobsPlacedLastUpdatedBy.Name := 'JobsPlacedLastUpdatedBy';
    JobsPlacedLastUpdatedBy.FieldName := 'LastUpdatedBy';
    JobsPlacedLastUpdated.SetParentComponent(JobsPlaced);
    JobsPlacedLastUpdated.Name := 'JobsPlacedLastUpdated';
    JobsPlacedLastUpdated.FieldName := 'LastUpdated';
    JobsPlacedJobCategory.SetParentComponent(JobsPlaced);
    JobsPlacedJobCategory.Name := 'JobsPlacedJobCategory';
    JobsPlacedJobCategory.FieldName := 'JobCategory';
    JobsPlacedJobCategory.Size := 8;
    JobsPlacedLastPolled.SetParentComponent(JobsPlaced);
    JobsPlacedLastPolled.Name := 'JobsPlacedLastPolled';
    JobsPlacedLastPolled.FieldName := 'LastPolled';
    JobsPlacedPollInterval.SetParentComponent(JobsPlaced);
    JobsPlacedPollInterval.Name := 'JobsPlacedPollInterval';
    JobsPlacedPollInterval.FieldName := 'PollInterval';
    JobsPlacedPollCount.SetParentComponent(JobsPlaced);
    JobsPlacedPollCount.Name := 'JobsPlacedPollCount';
    JobsPlacedPollCount.FieldName := 'PollCount';
    ImportMappings.SetParentComponent(Self);
    ImportMappings.Name := 'ImportMappings';
    ImportMappings.AfterPost := ImportMappingsAfterPost;
    ImportMappings.EntitySetName := 'ImportMappings';
    ImportMappings.Connection := MainData.DataConnection;
    ImportMappings.Left := 576;
    ImportMappings.Top := 192;
    ImportMappingsId.SetParentComponent(ImportMappings);
    ImportMappingsId.Name := 'ImportMappingsId';
    ImportMappingsId.FieldName := 'Id';
    ImportMappingsId.Required := True;
    ImportMappingsDateAdded.SetParentComponent(ImportMappings);
    ImportMappingsDateAdded.Name := 'ImportMappingsDateAdded';
    ImportMappingsDateAdded.FieldName := 'DateAdded';
    ImportMappingsDateAdded.Required := True;
    ImportMappingsAddedBy.SetParentComponent(ImportMappings);
    ImportMappingsAddedBy.Name := 'ImportMappingsAddedBy';
    ImportMappingsAddedBy.FieldName := 'AddedBy';
    ImportMappingsAddedBy.Required := True;
    ImportMappingsLastUpdatedBy.SetParentComponent(ImportMappings);
    ImportMappingsLastUpdatedBy.Name := 'ImportMappingsLastUpdatedBy';
    ImportMappingsLastUpdatedBy.FieldName := 'LastUpdatedBy';
    ImportMappingsLastUpdated.SetParentComponent(ImportMappings);
    ImportMappingsLastUpdated.Name := 'ImportMappingsLastUpdated';
    ImportMappingsLastUpdated.FieldName := 'LastUpdated';
    ImportMappingsName.SetParentComponent(ImportMappings);
    ImportMappingsName.Name := 'ImportMappingsName';
    ImportMappingsName.FieldName := 'Name';
    ImportMappingsName.Size := 255;
    ImportMappingsMapping.SetParentComponent(ImportMappings);
    ImportMappingsMapping.Name := 'ImportMappingsMapping';
    ImportMappingsMapping.FieldName := 'Mapping';
    ImportMappingsMapping.Size := 4096;
    ImportMappingsHasTitles.SetParentComponent(ImportMappings);
    ImportMappingsHasTitles.Name := 'ImportMappingsHasTitles';
    ImportMappingsHasTitles.FieldName := 'HasTitles';
    ImportMappingsHasTitles.Required := True;
    MappingsDS.SetParentComponent(Self);
    MappingsDS.Name := 'MappingsDS';
    MappingsDS.DataSet := ImportMappings;
    MappingsDS.Left := 576;
    MappingsDS.Top := 256;
    RefreshTimer.SetParentComponent(Self);
    RefreshTimer.Name := 'RefreshTimer';
    RefreshTimer.Enabled := False;
    SetEvent(RefreshTimer, Self, 'OnTimer', 'RefreshTimerTimer');
    RefreshTimer.Left := 464;
    RefreshTimer.Top := 72;
    WaitForBatchDataTimer.SetParentComponent(Self);
    WaitForBatchDataTimer.Name := 'WaitForBatchDataTimer';
    WaitForBatchDataTimer.Enabled := False;
    SetEvent(WaitForBatchDataTimer, Self, 'OnTimer', 'WaitForBatchDataTimerTimer');
    WaitForBatchDataTimer.Left := 576;
    WaitForBatchDataTimer.Top := 128;
  finally
    JobsPlacedTable.AfterLoadDFMValues;
    PlaceJobButton.AfterLoadDFMValues;
    OutputOption.AfterLoadDFMValues;
    JobVisibility.AfterLoadDFMValues;
    JobMessage.AfterLoadDFMValues;
    FilePicker.AfterLoadDFMValues;
    JobRunType.AfterLoadDFMValues;
    NewMappingButton.AfterLoadDFMValues;
    MappingsCombo.AfterLoadDFMValues;
    JobsPlaced.AfterLoadDFMValues;
    JobsPlacedDateAdded.AfterLoadDFMValues;
    JobsPlacedAddedBy.AfterLoadDFMValues;
    JobsPlacedLastUpdatedBy.AfterLoadDFMValues;
    JobsPlacedLastUpdated.AfterLoadDFMValues;
    JobsPlacedJobCategory.AfterLoadDFMValues;
    JobsPlacedLastPolled.AfterLoadDFMValues;
    JobsPlacedPollInterval.AfterLoadDFMValues;
    JobsPlacedPollCount.AfterLoadDFMValues;
    ImportMappings.AfterLoadDFMValues;
    ImportMappingsId.AfterLoadDFMValues;
    ImportMappingsDateAdded.AfterLoadDFMValues;
    ImportMappingsAddedBy.AfterLoadDFMValues;
    ImportMappingsLastUpdatedBy.AfterLoadDFMValues;
    ImportMappingsLastUpdated.AfterLoadDFMValues;
    ImportMappingsName.AfterLoadDFMValues;
    ImportMappingsMapping.AfterLoadDFMValues;
    ImportMappingsHasTitles.AfterLoadDFMValues;
    MappingsDS.AfterLoadDFMValues;
    RefreshTimer.AfterLoadDFMValues;
    WaitForBatchDataTimer.AfterLoadDFMValues;
  end;
end;

end.
