unit Sphinx.LoginCommon;

{$I Sphinx.inc}

interface

uses
  {$IFDEF PAS2JS}
  JS, Web,
  {$ELSE}
  {$ENDIF}
  SysUtils, Classes,
  Sphinx.OidcClient.AuthResult,
  Sphinx.OidcClient.AuthResultEntry,
  Sphinx.OidcClient.Storage;

type
  TLoginStorage = class
  strict private
    FStorage: TClientStorage;
    FEntryKey: string;
  strict protected
    property Storage: TClientStorage read FStorage;
    property EntryKey: string read FEntryKey;
  public
    constructor Create(const Authority, ClientId: string);
    destructor Destroy; override;
    procedure Save(AuthResult: TAuthResult);
    function AuthResult: TAuthResult;
    procedure Clear;
  end;

  TUserLoggedInArgs = class
  private
    FLoginResult: TAuthResult;
    FSender: TObject;
  public
    constructor Create(ASender: TObject; ALoginResult: TAuthResult);
    property Sender: TObject read FSender;
    property LoginResult: TAuthResult read FLoginResult;
  end;

  TUserLoggedInEvent = procedure(Args: TUserLoggedInArgs) of object;

implementation

{ TLoginStorage }

function TLoginStorage.AuthResult: TAuthResult;
{$IFDEF PAS2JS}
var
  Entry: TAuthResultEntry;
begin
  Entry := Storage.Get<TAuthResultEntry>(EntryKey);
  if Entry <> nil then
    Result := TAuthResult.Create(Entry)
  else
    Result := nil;
end;
{$ELSE}
begin
  Result := Storage.Get<TAuthResult>(EntryKey);
end;
{$ENDIF}

procedure TLoginStorage.Clear;
begin
  Storage.Remove(EntryKey);
end;

constructor TLoginStorage.Create(const Authority, ClientId: string);
begin
  inherited Create;
  FEntryKey := 'id::' + Authority + '::' + ClientId;
  FStorage := TClientStorage.Create;
end;

destructor TLoginStorage.Destroy;
begin
{$IFNDEF PAS2JS}
  FStorage.Free;
{$ENDIF}
  inherited;
end;

procedure TLoginStorage.Save(AuthResult: TAuthResult);
begin
{$IFDEF PAS2JS}
  Storage.Save<TAuthResultEntry>(EntryKey, AuthResult.Entry);
{$ELSE}
  Storage.Save<TAuthResult>(EntryKey, AuthResult);
{$ENDIF}
end;

{ TUserLoggedInArgs }

constructor TUserLoggedInArgs.Create(ASender: TObject; ALoginResult: TAuthResult);
begin
  inherited Create;
  FSender := ASender;
  FLoginResult := ALoginResult;
end;

end.
