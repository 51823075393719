unit Sphinx.OidcClient.AuthResult;

interface

uses
  SysUtils,
  Sphinx.OidcClient.AuthState,
  Sphinx.OidcClient.AuthResultEntry,
  Sphinx.OidcClient.Profile;

type
  /// <summary>
  ///   Holds the final information of a succesfull client credentials flow.
  /// </summary>
  ITokenResult = interface
  ['{C03E452E-F789-45CB-8381-A0798C2F444F}']
    function GetAccessToken: string;
    function GetRefreshToken: string;
    function GetScope: string;
    function GetTokenType: string;
    function GetExpiration: TDateTime;

    /// <summary>
    ///   Indicates if the current information (i.e., access token) is expired.
    /// </summary>
    function IsExpired: Boolean;

    /// <summary>
    ///   Holds the access token which can be used to authenticate to the API server.
    /// </summary>
    property AccessToken: string read GetAccessToken;
    /// <summary>
    ///   The type of the access token. Usually just contains "Bearer".
    /// </summary>
    property TokenType: string read GetTokenType;
    /// <summary>
    ///   If present, contains a refresh token that can be used to obtain new access tokens.
    /// </summary>
    property RefreshToken: string read GetRefreshToken;
    /// <summary>
    ///   Spaced-separated list of scope tokens granted in the access token.
    /// </summary>
    property Scope: string read GetScope;
    /// <summary>
    ///   The date and time where the access token expires.
    /// </summary>
    property Expiration: TDateTime read GetExpiration;
  end;

  /// <summary>
  ///   Holds the final information of a succesfull authorization flow.
  /// </summary>
  IAuthResult = interface
  ['{780AD575-E3FE-4D14-A18B-B0199F4BAE6F}']
    function GetAccessToken: string;
    function GetIdToken: string;
    function GetRefreshToken: string;
    function GetScope: string;
    function GetTokenType: string;
    function GetExpiration: TDateTime;
    /// <summary>
    ///   Indicates if the current information (i.e., access token) is expired.
    /// </summary>
    function IsExpired: Boolean;
    function GetProfile: TOidcProfile;
    function GetAppState: string;

    /// <summary>
    ///   Holds the access token which can be used to authenticate to the API server.
    /// </summary>
    property AccessToken: string read GetAccessToken;
    /// <summary>
    ///   The type of the access token. Usually just contains "Bearer".
    /// </summary>
    property TokenType: string read GetTokenType;
    /// <summary>
    ///   If present, contains a refresh token that can be used to obtain new access tokens.
    /// </summary>
    property RefreshToken: string read GetRefreshToken;
    /// <summary>
    ///   Spaced-separated list of scope tokens granted in the access token.
    /// </summary>
    property Scope: string read GetScope;
    /// <summary>
    ///   The date and time where the access token expires.
    /// </summary>
    property Expiration: TDateTime read GetExpiration;
    /// <summary>
    ///   A OpenId Connect compatible JSON Web Token holding information about the authenticated user.
    /// </summary>
    property IdToken: string read GetIdToken;
    /// <summary>
    ///   Holds information about the authenticated user, extracted from the <see cref="IdToken">IdToken</see>.
    /// </summary>
    property Profile: TOidcProfile read GetProfile;
    /// <summary>
    ///   Holds the custom application state provided at the beggining of the authorization flow.
    /// </summary>
    property AppState: string read GetAppState;
  end;

  /// <summary>
  ///   Holds the final information of a succesfull authorization flow.
  /// </summary>
  TAuthResult = class(TInterfacedObject, IAuthResult, ITokenResult)
  strict private
    FEntry: TAuthResultEntry;
    FProfile: TOidcProfile;
    function GetAccessToken: string;
    function GetIdToken: string;
    function GetRefreshToken: string;
    function GetScope: string;
    function GetTokenType: string;
    function GetExpiration: TDateTime;
    function GetProfile: TOidcProfile;
    function GetAppState: string;
{$IFDEF PAS2JS}
  public
{$ENDIF}
    property Entry: TAuthResultEntry read FEntry;
  public
    constructor Create(AEntry: TAuthResultEntry);
    destructor Destroy; override;

    /// <summary>
    ///   Indicates if the current information (i.e., access token) is expired.
    /// </summary>
    function IsExpired: Boolean;

    /// <summary>
    ///   Holds the access token which can be used to authenticate to the API server.
    /// </summary>
    property AccessToken: string read GetAccessToken;

    /// <summary>
    ///   The type of the access token. Usually just contains "Bearer".
    /// </summary>
    property TokenType: string read GetTokenType;

    /// <summary>
    ///   If present, contains a refresh token that can be used to obtain new access tokens.
    /// </summary>
    property RefreshToken: string read GetRefreshToken;

    /// <summary>
    ///   Spaced-separated list of scope tokens granted in the access token.
    /// </summary>
    property Scope: string read GetScope;

    /// <summary>
    ///   The date and time where the access token expires.
    /// </summary>
    property Expiration: TDateTime read GetExpiration;

    /// <summary>
    ///   A OpenId Connect compatible JSON Web Token holding information about the authenticated user.
    /// </summary>
    property IdToken: string read GetIdToken;

    /// <summary>
    ///   Holds information about the authenticated user, extracted from the <see cref="IdToken">IdToken</see>.
    /// </summary>
    property Profile: TOidcProfile read GetProfile;

    /// <summary>
    ///   Holds the custom application state provided at the beggining of the authorization flow.
    /// </summary>
    property AppState: string read GetAppState;
  end;

implementation

uses
  Sphinx.Utils;

{ TLoginInfo }

constructor TAuthResult.Create(AEntry: TAuthResultEntry);
begin
  inherited Create;
  FEntry := AEntry;
end;

destructor TAuthResult.Destroy;
begin
{$IFNDEF PAS2JS}
  FProfile.Free;
  FEntry.Free;
{$ENDIF}
  inherited;
end;

function TAuthResult.GetAccessToken: string;
begin
  Result := Entry.AccessToken;
end;

function TAuthResult.GetAppState: string;
begin
  Result := Entry.AppState;
end;

function TAuthResult.GetExpiration: TDateTime;
begin
  Result := Entry.ExpiresAt;
end;

function TAuthResult.GetIdToken: string;
begin
  Result := Entry.IdToken;
end;

function TAuthResult.GetProfile: TOidcProfile;
begin
  if FProfile = nil then
    FProfile := TOidcProfile.Create(GetJwtPayload(IdToken));
  Result := FProfile;
end;

function TAuthResult.GetRefreshToken: string;
begin
  Result := Entry.RefreshToken;
end;

function TAuthResult.GetScope: string;
begin
  Result := Entry.Scope;
end;

function TAuthResult.GetTokenType: string;
begin
  Result := Entry.TokenType;
end;

function TAuthResult.IsExpired: Boolean;
begin
  Result := Now >= Expiration;
end;

end.
