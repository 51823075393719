unit ConnectedCharitiesEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WebForm.Core, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.DBCtrls, WEBLib.ExtCtrls, WEBLib.Actions, Data.DB, WEBLib.DB,
  Vcl.Imaging.GIFImg, Vcl.Controls;

type
  TConnectedCharityEditForm = class(TCoreWebForm)
    FormCaption: TLabel;
    MessageLabel: TLabel;
    CancelButton: TButton;
    SaveButton: TButton;
    WaitMessage: TWaitMessage;
    DataSource: TDataSource;
    EditElements: TElementActionList;
    CloseTimer: TTimer;
    CharityNameEdit: TDBEdit;
    HMRCRefEdit: TDBEdit;
    ExternalIdEdit: TDBEdit;
    RegulatorNumberEdit: TDBEdit;
    StatusCombo: TDBComboBox;
    procedure CancelButtonClick(Sender: TObject);
    procedure SaveButtonClick(Sender: TObject);
    procedure CloseButtonClick(Sender: TObject);
    procedure ValidateClick(Sender: TObject);
  private
    { Private declarations }
    procedure SetDataSet(Value: TDataSet);
    procedure Validate;
  public
    { Public declarations }
    property EditDataSet: TDataset write SetDataSet;
  protected procedure LoadDFMValues; override; end;

var
  ConnectedCharityEditForm: TConnectedCharityEditForm;

implementation

{$R *.dfm}

procedure TConnectedCharityEditForm.CancelButtonClick(Sender: TObject);
begin
  inherited;
  ModalResult := mrCancel;
end;

procedure TConnectedCharityEditForm.SaveButtonClick(Sender: TObject);
begin
  inherited;
  SaveButton.Enabled := false;
  ModalResult := mrOk;
end;

procedure TConnectedCharityEditForm.SetDataSet(Value: TDataSet);
begin
  DataSource.DataSet := Value;

  //Sets Status to Active
  if DataSource.DataSet.State = dsInsert then
    StatusCombo.ItemIndex := 0;
  SaveButton.Enabled := false;
end;

procedure TConnectedCharityEditForm.CloseButtonClick(Sender: TObject);
begin
  inherited;
  ModalResult := mrClose;
end;

procedure TConnectedCharityEditForm.Validate;
begin
  if (CharityNameEdit.Text <> '') and (HMRCRefEdit.Text <> '') and
    // These have been removed but left in just in case we decide to use them in the future
    //(ExternalIdEdit.Text <> '') and (RegulatorNumberEdit.Text <> '') and
    (StatusCombo.ItemIndex > -1) then
    SaveButton.Enabled := true
  else
    SaveButton.Enabled := false;

end;

procedure TConnectedCharityEditForm.ValidateClick(Sender: TObject);
begin
  Validate;
end;

procedure TConnectedCharityEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormCaption := TLabel.Create('FormCaptionCC');
  MessageLabel := TLabel.Create('MessageLabel');
  CancelButton := TButton.Create('CancelCCButton');
  SaveButton := TButton.Create('SaveCCButton');
  WaitMessage := TWaitMessage.Create('WaitMessageONCC');
  CharityNameEdit := TDBEdit.Create('CharityNameEdit');
  HMRCRefEdit := TDBEdit.Create('HMRCRefEdit');
  ExternalIdEdit := TDBEdit.Create('ExternalIdEdit');
  RegulatorNumberEdit := TDBEdit.Create('RegulatorNumberEdit');
  StatusCombo := TDBComboBox.Create('StatusCombo');
  DataSource := TDataSource.Create(Self);
  EditElements := TElementActionList.Create(Self);
  CloseTimer := TTimer.Create(Self);

  FormCaption.BeforeLoadDFMValues;
  MessageLabel.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  WaitMessage.BeforeLoadDFMValues;
  CharityNameEdit.BeforeLoadDFMValues;
  HMRCRefEdit.BeforeLoadDFMValues;
  ExternalIdEdit.BeforeLoadDFMValues;
  RegulatorNumberEdit.BeforeLoadDFMValues;
  StatusCombo.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  EditElements.BeforeLoadDFMValues;
  CloseTimer.BeforeLoadDFMValues;
  try
    Height := 542;
    FormCaption.SetParentComponent(Self);
    FormCaption.Name := 'FormCaption';
    FormCaption.Left := 70;
    FormCaption.Top := 12;
    FormCaption.Width := 122;
    FormCaption.Height := 15;
    FormCaption.Margins.Left := 5;
    FormCaption.Margins.Top := 5;
    FormCaption.Margins.Right := 5;
    FormCaption.Margins.Bottom := 5;
    FormCaption.Caption := 'Connected Charity Edit';
    FormCaption.ElementClassName := 'HeadlineClass';
    FormCaption.ElementFont := efCSS;
    FormCaption.ElementPosition := epIgnore;
    FormCaption.HeightStyle := ssAuto;
    FormCaption.HeightPercent := 100.000000000000000000;
    FormCaption.HTMLType := tDIV;
    FormCaption.WidthStyle := ssAuto;
    FormCaption.WidthPercent := 100.000000000000000000;
    MessageLabel.SetParentComponent(Self);
    MessageLabel.Name := 'MessageLabel';
    MessageLabel.Left := 68;
    MessageLabel.Top := 41;
    MessageLabel.Width := 74;
    MessageLabel.Height := 15;
    MessageLabel.Margins.Left := 5;
    MessageLabel.Margins.Top := 5;
    MessageLabel.Margins.Right := 5;
    MessageLabel.Margins.Bottom := 5;
    MessageLabel.Caption := 'MessageLabel';
    MessageLabel.ElementFont := efCSS;
    MessageLabel.ElementPosition := epIgnore;
    MessageLabel.HeightStyle := ssAuto;
    MessageLabel.HeightPercent := 100.000000000000000000;
    MessageLabel.HTMLType := tDIV;
    MessageLabel.WidthStyle := ssAuto;
    MessageLabel.WidthPercent := 100.000000000000000000;
    CancelButton.SetParentComponent(Self);
    CancelButton.Name := 'CancelButton';
    CancelButton.Left := 252;
    CancelButton.Top := 440;
    CancelButton.Width := 144;
    CancelButton.Height := 38;
    CancelButton.Margins.Left := 5;
    CancelButton.Margins.Top := 5;
    CancelButton.Margins.Right := 5;
    CancelButton.Margins.Bottom := 5;
    CancelButton.Caption := 'Cancel';
    CancelButton.ChildOrder := 3;
    CancelButton.ElementClassName := 'btn btn-primary';
    CancelButton.ElementFont := efCSS;
    CancelButton.ElementPosition := epIgnore;
    CancelButton.HeightStyle := ssAuto;
    CancelButton.HeightPercent := 100.000000000000000000;
    CancelButton.WidthStyle := ssAuto;
    CancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(CancelButton, Self, 'OnClick', 'CancelButtonClick');
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.Left := 98;
    SaveButton.Top := 440;
    SaveButton.Width := 144;
    SaveButton.Height := 38;
    SaveButton.Margins.Left := 5;
    SaveButton.Margins.Top := 5;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 5;
    SaveButton.Caption := 'Save';
    SaveButton.ChildOrder := 2;
    SaveButton.ElementClassName := 'btn btn-secondary';
    SaveButton.ElementFont := efCSS;
    SaveButton.ElementPosition := epIgnore;
    SaveButton.HeightStyle := ssAuto;
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.WidthStyle := ssAuto;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    WaitMessage.SetParentComponent(Self);
    WaitMessage.Name := 'WaitMessage';
    WaitMessage.Left := 156;
    WaitMessage.Top := 368;
    WaitMessage.Width := 24;
    WaitMessage.Height := 24;
    WaitMessage.Margins.Left := 5;
    WaitMessage.Margins.Top := 5;
    WaitMessage.Margins.Right := 5;
    WaitMessage.Margins.Bottom := 5;
    WaitMessage.HeightStyle := ssAuto;
    WaitMessage.WidthStyle := ssAuto;
    WaitMessage.Opacity := 0.200000000000000000;
    WaitMessage.PictureURL := 'images/Whirligig.gif';
    CharityNameEdit.SetParentComponent(Self);
    CharityNameEdit.Name := 'CharityNameEdit';
    CharityNameEdit.Left := 54;
    CharityNameEdit.Top := 128;
    CharityNameEdit.Width := 297;
    CharityNameEdit.Height := 22;
    CharityNameEdit.Hint := 'Hint01';
    CharityNameEdit.ChildOrder := 3;
    CharityNameEdit.ElementClassName := 'form-control';
    CharityNameEdit.ElementPosition := epIgnore;
    CharityNameEdit.HeightStyle := ssAuto;
    CharityNameEdit.HeightPercent := 100.000000000000000000;
    CharityNameEdit.Text := 'CharityNameEdit';
    CharityNameEdit.WidthStyle := ssAuto;
    CharityNameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(CharityNameEdit, Self, 'OnExit', 'ValidateClick');
    CharityNameEdit.DataField := 'CharityName';
    CharityNameEdit.DataSource := DataSource;
    HMRCRefEdit.SetParentComponent(Self);
    HMRCRefEdit.Name := 'HMRCRefEdit';
    HMRCRefEdit.Left := 54;
    HMRCRefEdit.Top := 156;
    HMRCRefEdit.Width := 297;
    HMRCRefEdit.Height := 22;
    HMRCRefEdit.ChildOrder := 4;
    HMRCRefEdit.ElementClassName := 'form-control';
    HMRCRefEdit.ElementPosition := epIgnore;
    HMRCRefEdit.HeightStyle := ssAuto;
    HMRCRefEdit.HeightPercent := 100.000000000000000000;
    HMRCRefEdit.TabOrder := 1;
    HMRCRefEdit.Text := 'HMRCRefEdit';
    HMRCRefEdit.WidthStyle := ssAuto;
    HMRCRefEdit.WidthPercent := 100.000000000000000000;
    SetEvent(HMRCRefEdit, Self, 'OnExit', 'ValidateClick');
    HMRCRefEdit.DataField := 'HMRCRef';
    HMRCRefEdit.DataSource := DataSource;
    ExternalIdEdit.SetParentComponent(Self);
    ExternalIdEdit.Name := 'ExternalIdEdit';
    ExternalIdEdit.Left := 54;
    ExternalIdEdit.Top := 184;
    ExternalIdEdit.Width := 297;
    ExternalIdEdit.Height := 22;
    ExternalIdEdit.ChildOrder := 6;
    ExternalIdEdit.ElementClassName := 'form-control';
    ExternalIdEdit.ElementPosition := epIgnore;
    ExternalIdEdit.HeightStyle := ssAuto;
    ExternalIdEdit.HeightPercent := 100.000000000000000000;
    ExternalIdEdit.TabOrder := 3;
    ExternalIdEdit.Text := 'ExternalIdEdit';
    ExternalIdEdit.Visible := False;
    ExternalIdEdit.WidthStyle := ssAuto;
    ExternalIdEdit.WidthPercent := 100.000000000000000000;
    SetEvent(ExternalIdEdit, Self, 'OnExit', 'ValidateClick');
    ExternalIdEdit.DataField := 'ExternalId';
    ExternalIdEdit.DataSource := DataSource;
    RegulatorNumberEdit.SetParentComponent(Self);
    RegulatorNumberEdit.Name := 'RegulatorNumberEdit';
    RegulatorNumberEdit.Left := 54;
    RegulatorNumberEdit.Top := 212;
    RegulatorNumberEdit.Width := 297;
    RegulatorNumberEdit.Height := 22;
    RegulatorNumberEdit.ChildOrder := 6;
    RegulatorNumberEdit.ElementClassName := 'form-control';
    RegulatorNumberEdit.ElementPosition := epIgnore;
    RegulatorNumberEdit.HeightStyle := ssAuto;
    RegulatorNumberEdit.HeightPercent := 100.000000000000000000;
    RegulatorNumberEdit.TabOrder := 3;
    RegulatorNumberEdit.Text := 'RegulatorNumberEdit';
    RegulatorNumberEdit.Visible := False;
    RegulatorNumberEdit.WidthStyle := ssAuto;
    RegulatorNumberEdit.WidthPercent := 100.000000000000000000;
    SetEvent(RegulatorNumberEdit, Self, 'OnExit', 'ValidateClick');
    RegulatorNumberEdit.DataField := 'RegulatorNumber';
    RegulatorNumberEdit.DataSource := DataSource;
    StatusCombo.SetParentComponent(Self);
    StatusCombo.Name := 'StatusCombo';
    StatusCombo.Left := 54;
    StatusCombo.Top := 240;
    StatusCombo.Width := 145;
    StatusCombo.Height := 23;
    StatusCombo.ElementClassName := 'form-select';
    StatusCombo.ElementPosition := epIgnore;
    StatusCombo.HeightStyle := ssAuto;
    StatusCombo.HeightPercent := 100.000000000000000000;
    StatusCombo.Text := 'StatusCombo';
    StatusCombo.WidthStyle := ssAuto;
    StatusCombo.WidthPercent := 100.000000000000000000;
    SetEvent(StatusCombo, Self, 'OnChange', 'ValidateClick');
    SetEvent(StatusCombo, Self, 'OnExit', 'ValidateClick');
    StatusCombo.ItemIndex := -1;
    StatusCombo.Items.BeginUpdate;
    try
      StatusCombo.Items.Clear;
      StatusCombo.Items.Add('Active');
      StatusCombo.Items.Add('Inactive');
    finally
      StatusCombo.Items.EndUpdate;
    end;
    StatusCombo.DataField := 'GAStatus';
    StatusCombo.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.Left := 280;
    DataSource.Top := 40;
    EditElements.SetParentComponent(Self);
    EditElements.Name := 'EditElements';
    EditElements.Actions.Clear;
    with EditElements.Actions.Add do
    begin
      Enabled := False;
      Event := heKeydown;
      ID := '';
      Name := 'EnterToTab';
      PreventDefault := False;
      Selector := 'input';
      StopPropagation := False;
    end;
    with EditElements.Actions.Add do
    begin
      Event := heBlur;
      ID := '';
      Name := 'ValidateData';
      PreventDefault := False;
      Selector := 'input,select';
      StopPropagation := False;
    end;
    EditElements.Left := 424;
    EditElements.Top := 24;
    CloseTimer.SetParentComponent(Self);
    CloseTimer.Name := 'CloseTimer';
    CloseTimer.Enabled := False;
    CloseTimer.Interval := 2000;
    CloseTimer.Left := 72;
    CloseTimer.Top := 376;
  finally
    FormCaption.AfterLoadDFMValues;
    MessageLabel.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    WaitMessage.AfterLoadDFMValues;
    CharityNameEdit.AfterLoadDFMValues;
    HMRCRefEdit.AfterLoadDFMValues;
    ExternalIdEdit.AfterLoadDFMValues;
    RegulatorNumberEdit.AfterLoadDFMValues;
    StatusCombo.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    EditElements.AfterLoadDFMValues;
    CloseTimer.AfterLoadDFMValues;
  end;
end;

end.
