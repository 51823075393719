unit Simple.Dashboard.Return.Types;

interface

uses System.Generics.Collections  {$IFDEF PAS2JS},JS{$ENDIF};

type

{$SCOPEDENUMS ON}

{
When we start using Entities this should change to  TDashboardFormat = (Money,Number,Integer,Text,Image,Graph);
See XData-Core\Models\Dashboard\SMX.Dashboard.Entities
}
TDashboardResponseType = (NoResult, Money, Number, Integer, Text, Image, Graph, HTML);
//TDashboardValueScale = (None, Unitary,

TDashboardResponse = class
  private
    function GetItemType: TDashboardResponseType;
    procedure SetItemType(const Value: TDashboardResponseType);
  public
  Value: String;
  Key: String;
  Description: String;
  Hint: String;
  ItemType: String;
  Scale: String;
  Expires: TDateTime;
//  ColumnList: string { TODO : for tables and garpahs: a csv list of column headers, which will also show how many columns there are }
  function AsFormatted(const AFormat: string = '#,##0'): string;
  {$IFDEF PAS2JS}
  procedure Assign(Source: TJSObject);
  {$ENDIF}
  property Item_Type: TDashboardResponseType read GetItemType write SetItemType;
//  property Value_Scale: TDashboardValueScale

end;


const
OUTSTANDING_CLAIMS = 'OUTSTANDING_CLAIMS';
DONATIONS_BY_MONTH = 'DONATIONS_BY_MONTH';
TOTAL_GIFTAID_CLAIMED = 'TOTAL_GIFTAID_CLAIMED';
CLAIMS_BREAKDOWN = 'CLAIMS_BREAKDOWN';
DONOR_COUNT = 'DONOR_COUNT';

implementation

uses System.Rtti, System.SysUtils;


{$SCOPEDENUMS OFF}

{ TDashboardResponse }

{ TDashboardResponse }

function TDashboardResponse.AsFormatted(const AFormat: string): string;
var
  lValue: Integer;
begin
  lValue := StrToIntDef(Value, 0);
  Result := FormatFloat(AFormat, lValue);
end;

{$IFDEF PAS2JS}
procedure TDashboardResponse.Assign(Source: TJSObject);
begin
  Value := String(Source.Properties['Value']);
  Key := String(Source.Properties['Key']);
  Description := String(Source.Properties['Description']);
  Hint := String(Source.Properties['Hint']);
  ItemType := String(Source.Properties['ItemType']);
  Scale := String(Source.Properties['Scale']);
  Expires := TDateTime(Source.Properties['Expires']);
end;
{$ENDIF}

function TDashboardResponse.GetItemType: TDashboardResponseType;
begin
  Result := TRttiEnumerationType.GetValue<TDashboardResponseType>(ItemType);
end;

procedure TDashboardResponse.SetItemType(const Value: TDashboardResponseType);
begin
  ItemType := TRttiEnumerationType.GetName<TDashboardResponseType>(Value);
end;

end.
