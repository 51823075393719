unit Job.DetailForm.Manager;

interface

uses
  System.Classes,
  JobDetailBaseForm;

type

  TJobDetailFormClass = class of TJobDetailBase;

procedure RegisterJobDetailForm(const AJobClass: string; AFormClass: TJobDetailFormClass);
function FindJobDetailForm(AOwner: TComponent; const AJobClass: string): TJobDetailBase;

implementation

uses
//  Generics.Collections,
  JobDetailForm;

var
  FJobDetailFormDictionary: TStringList; // TDictionary<string, TJobDetailFormClass>;

function GetJobDetailDictionary: TStringList; // TDictionary<string, TJobDetailFormClass>;
begin
  if not Assigned(FJobDetailFormDictionary) then
    FJobDetailFormDictionary := TStringList.Create; // TDictionary<string, TJobDetailFormClass>.Create;
  Result := FJobDetailFormDictionary;
end;

procedure RegisterJobDetailForm(const AJobClass: string; AFormClass: TJobDetailFormClass);
var
  lDictionary: TStringList;
begin
  lDictionary := GetJobDetailDictionary; // .AddOrSetValue(AJobClass, AFormClass);
  if lDictionary.IndexOfName(AJobClass) < 0 then
  begin
    RegisterClasses([AFormClass]);
    lDictionary.AddPair(AJobClass, AFormClass.ClassName);
  end;
end;

function FindJobDetailForm(AOwner: TComponent; const AJobClass: string): TJobDetailBase;
var
  lClass: TJobDetailFormClass;
  lDictionary: TStringList;
  lClassName: string;
begin
  lDictionary := GetJobDetailDictionary; // .AddOrSetValue(AJobClass, AFormClass);
  if lDictionary.IndexOfName(AJobClass) < 0 then
     Result := TJobDetail.Create(AOwner)
  else
  begin
    lClassName := lDictionary.Values[AJobClass];
    lClass := TJobDetailFormClass(GetClass(lClassName));
    Result := lClass.Create(AOwner);
  end;
end;

end.
