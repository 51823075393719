unit App.Config;

interface

type
  TAppConfig = class
  private
    FBaseUrl: string;
    FAuthURL: string;
    FSphinxClientId: string;
    FRegisterURL: string;
  public
    constructor Create;
    procedure Load(AResponse: string);

    property BaseUrl: string read FBaseUrl write FBaseUrl;
    /// <summary>
    /// for Sphinx based Systems this points to the Sphinx Server
    /// </summary>
    property AuthURL: string read FAuthURL write FAuthURL;
    property RegisterURL: string read FRegisterURL write FRegisterURL;
    property SphinxClientId: string read FSphinxClientId write FSphinxClientId;
  end;

function AppConfig: TAppConfig;

implementation

uses
  JS,
  XData.Web.Connection,
  XData.Web.Request;

var
  FAppConfig: TAppConfig;

function AppConfig: TAppConfig;
begin
  if not Assigned(FAppConfig) then
    FAppConfig := TAppConfig.Create;
  Result := FAppConfig;
end;

{ TAppConfig }

constructor TAppConfig.Create;
begin
  FBaseUrl := '';
  FAuthURL := '';
end;

procedure TAppConfig.Load(AResponse: string);
var
  Obj: TJSObject;
begin
  Obj := TJSObject(TJSJSON.parse(AResponse));

  if JS.toString(Obj['BaseUrl']) <> '' then
    FBaseUrl := JS.toString(Obj['BaseUrl']);

  if JS.toString(Obj['AuthUrl']) <> '' then
    FAuthURL := JS.toString(Obj['AuthUrl']);

  if FAuthURL = '' then
    FAuthURL := FBaseUrl;

  if JS.toString(Obj['RegisterUrl']) <> '' then
    FRegisterURL := JS.toString(Obj['RegisterUrl'])
  else
    FRegisterURL := FBaseUrl;

  FSphinxClientId := JS.toString(Obj['SphinxId']);

end;

end.
