unit RegisterForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DropDown,
  WEBLib.Country,
  WEBLib.ExtCtrls,
  XData.Web.Connection,
  XData.Web.Client,
  SMX.Web.Utils,
  WEBLib.WebCtrls;

type
  TRegisterWebForm = class(TForm)
    OrganisationName: TEdit;
    Add1: TEdit;
    Add2: TEdit;
    Add3: TEdit;
    Add4: TEdit;
    Add5: TEdit;
    PostCode: TEdit;
    OtherRegulator: TEdit;
    RegulatorNumber: TEdit;
    FirstName: TEdit;
    ApplyButton: TButton;
    Regulator: TLookupComboBox;
    LastName: TEdit;
    EmailAddress: TEdit;
    Telephone: TEdit;
    RegisterClient: TXDataWebClient;
    RegisterConnection: TXDataWebConnection;
    MessageSpan: THTMLSpan;
    Country: TCountryComboBox;
    ValidateNumberButton: TButton;
    AnnualDonations: TEdit;
    RegisteredName: TLabel;
    RegulatorStatus: TLabel;
    RegisterWaitMessage: TWaitMessage;
    procedure RegulatorChange(Sender: TObject);
    procedure RegulatorExit(Sender: TObject);
    procedure RegulatorNumberChange(Sender: TObject);
    procedure RegulatorNumberExit(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure ApplyButtonClick(Sender: TObject);
    procedure EmailAddressExit(Sender: TObject);
    procedure FirstNameExit(Sender: TObject);
    procedure ValidateNumberButtonClick(Sender: TObject);
    procedure RegulatorStatusClick(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
    FRegistrationStatus: TsmxCheckStatus;
    FRegulatorNumberValid: Boolean;
    FRegulatorStatus: string;
    FTermsAgreed: Boolean;
    FLastValidatedNumber: string;
    procedure ValidateForm;
    // [async] procedure LoadCountries;
    // [async] procedure LookUpRegulatorNumber;
    [async]
    procedure SetUpForm; async;
    [async]
    procedure LookUpCharityDetails(const ANumber: string); async;
    [async]
    procedure CheckCharityNotRegistered; async;
    [async]
    procedure RegisterOrganisation; async;

    [async]
    function AgreeTerms: Boolean; async;

    procedure DisplayMessage(const AMessage: string);
    [async]
    procedure LoadThankYou; async;
    [async]
    procedure TestHello; async;
    function GetCharityNumber: string;
  public
    { Public declarations }
    class procedure DisplayPage(const AElementId: string);
    procedure SetLabels;
    procedure InitForm;
    property CharityNumber: string read GetCharityNumber;
  protected procedure LoadDFMValues; override; end;

var
  RegisterWebForm: TRegisterWebForm;

implementation

{$R *.dfm}

uses
  WEBLib.RegularExpressions,
  SMX.Web.Document.Utils,
  SMX.CRM.Utils,
  GA.Registration.Classes,
  RegisterThankYou,
  GA.Web.Utils,
  MainForm,
  MainDataModule,
  Auth.Service,
  SMX.Auth.Shared,
  SMX.webcore.types,
  RegisterAgreement;

const
  CHARITY_REGISTERED = 'This Charity has already been registered on GiftAider. Please check.';

procedure TRegisterWebForm.CheckCharityNotRegistered;
const
  Svc_CharityAlreadyRegistered = 'IGARegisterService.CharityAlreadyRegistered';
  Svc_LookUpCharityByNumber = 'IGARegisterService.LookUpCharityByNumber';
var
  lRetval: TXDataClientResponse;
  lResult: Boolean;
  lCharity: TJSObject;
begin
  if Regulator.ItemIndex = -1 then
    Exit;

  if (Regulator.Value = 'OTH') and (OtherRegulator.Text = '') then
    Exit;

  RegisterWaitMessage.Show;

  FRegulatorNumberValid := TGAWebUtils.RegulatorNumberValid(Regulator.Value, RegulatorNumber.Text);

  if not FRegulatorNumberValid then
  begin
    TDocUtils.setControlValidity('RegulatorNumber', Valid_Check[False]);
    RegisterWaitMessage.Hide;
    Exit;
  end
  else
    TDocUtils.setControlValidity('RegulatorNumber', Valid_Check[True]);

  lRetval := await(TXDataClientResponse, RegisterClient.RawInvokeAsync(Svc_CharityAlreadyRegistered,
    [Regulator.Value, Trim(RegulatorNumber.Text)]));

  lResult := Boolean(TJSObject(lRetval.Result)['value']);
  if lResult then
  begin
    FRegistrationStatus := TsmxCheckStatus.csFailed;
    RegisterWaitMessage.Hide;
    DisplayMessage(CHARITY_REGISTERED);
    ValidateForm;
  end
  else
  begin
    FRegistrationStatus := TsmxCheckStatus.csOk;
    lRetval := await(TXDataClientResponse, RegisterClient.RawInvokeAsync(Svc_LookUpCharityByNumber,
      [Regulator.Value, Trim(RegulatorNumber.Text)]));

    lCharity := lRetval.ResultAsObject;

    { TODO : check registration status }
    RegulatorStatus.Caption := JS.ToString(lCharity['RegStatus']);

    if RegulatorStatus.Caption = 'notfound' then
    begin
      DisplayMessage('The registration number was not found. Please try again');
      // RegulatorNumber.Hint := 'The registration number was not found. Please try again';
      TDocUtils.setControlValidity('RegulatorNumber', Valid_Check[False]);
      RegulatorNumber.SetFocus;
      RegisterWaitMessage.Hide;
    end
    else
    begin
      DisplayMessage('');
      RegisteredName.Caption := JS.ToString(lCharity['CharityName']);
      TDocUtils.setControlValidity('RegulatorNumber', Valid_Check[True]);
      LookUpCharityDetails(RegulatorNumber.Text);
    end;

  end;

end;

procedure TRegisterWebForm.DisplayMessage(const AMessage: string);
begin
  MessageSpan.HTML.Text := AMessage;
  MessageSpan.Visible := (AMessage <> '');
end;

class procedure TRegisterWebForm.DisplayPage(const AElementId: string);

  procedure AfterCreate(AForm: TObject);
  begin
    TRegisterWebForm(AForm).SetLabels;
    TRegisterWebForm(AForm).InitForm;
  end;

begin
  if not Assigned(MainPage) then
  begin
    RegisterWebForm := TRegisterWebForm.CreateNew(AElementId, @AfterCreate);
  end
end;

procedure TRegisterWebForm.LoadThankYou;
var
  thanks: TThankYouForm;
begin
  thanks := TThankYouForm.Create(self);
  window.location.hash := 'thankyou';
  await(TThankYouForm, thanks.load());
  MainData.SphinxLogin.Logout;
  await(TModalResult, thanks.Execute);
end;

procedure TRegisterWebForm.RegisterOrganisation;
const
  IRegisterSvc_Register = 'IGARegisterService.RegisterOrganisation';
var
  lOrg: TRegisterOrganisation;
  lUser: TRegisterUser;
  lRetval: TXDataClientResponse;
  lResult, lAgreeTerms: Boolean;
  lDonations: Double;
  sDonations: string;
begin

  lAgreeTerms := await(AgreeTerms);

  if not lAgreeTerms then
    Exit;

  lOrg := TRegisterOrganisation.Create;
  lUser := TRegisterUser.Create;
  try
    lUser.GivenName := FirstName.Text;
    lUser.LastName := LastName.Text;
    lUser.Email := EmailAddress.Text;
    lUser.Phone := Telephone.Text;
    lUser.IsImport := False;
    lUser.InternalId := AuthService.GetClaimValue(CLAIM_USERID).ToInteger;

    lOrg.OrganisationName := OrganisationName.Text;
    lOrg.Add1 := Add1.Text;
    lOrg.Add2 := Add2.Text;
    lOrg.Add3 := Add3.Text;
    lOrg.Add4 := Add4.Text;
    lOrg.Add5 := Add5.Text;
    lOrg.PostCode := PostCode.Text;
    lOrg.Country := GetCountryCode(Country.Text);

    lOrg.Regulator := Regulator.Value;
    lOrg.OtherRegulator := OtherRegulator.Text;
    lOrg.RegulatorNumber := RegulatorNumber.Text;
    lOrg.RegulatorStatus := RegulatorStatus.Caption;
    lOrg.RegisteredName := RegisteredName.Caption;

    sDonations := AnnualDonations.Text;
    lDonations := StrToFloatDef(sDonations, 0);
    lOrg.AnnualDonations := lDonations;
    lOrg.TermsSigned := lAgreeTerms;

    lRetval := await(TXDataClientResponse, RegisterClient.RawInvokeAsync(IRegisterSvc_Register, [lOrg, lUser]));
    lResult := Boolean(TJSObject(lRetval.Result)['value']);
    if lResult then
      LoadThankYou;
  finally
    lOrg.Free;
    MainData.DeleteLocalValue('charity-id');
    MainData.DeleteLocalValue('user-email');
  end;

end;

procedure TRegisterWebForm.RegulatorChange(Sender: TObject);
begin
  FRegistrationStatus := TsmxCheckStatus.csNotChecked;
  RegulatorStatus.Caption := 'unknown';
  if Regulator.Value = 'OTH' then
    TDocUtils.showElement('OtherRegulatorDiv')
  else
    TDocUtils.hideElement('OtherRegulatorDiv');
end;

procedure TRegisterWebForm.RegulatorExit(Sender: TObject);
begin
  CheckCharityNotRegistered;
end;

procedure TRegisterWebForm.RegulatorNumberChange(Sender: TObject);
begin
  FRegistrationStatus := TsmxCheckStatus.csNotChecked;
end;

procedure TRegisterWebForm.RegulatorNumberExit(Sender: TObject);
begin
  if FLastValidatedNumber <> RegulatorNumber.Text then
    CheckCharityNotRegistered;
end;

procedure TRegisterWebForm.ValidateForm;
var
  lValid, AllValid: Boolean;
  lMsg: string;
  lStatus: TsmxCheckStatus;
begin

  lStatus := FRegistrationStatus;

  AllValid := (lStatus = TsmxCheckStatus.csOk);

  case lStatus of
    csNotChecked:
      lMsg := '';
    csFailed:
      lMsg := CHARITY_REGISTERED;
    csOk:
      lMsg := '';
  end;

  AllValid := AllValid and FRegulatorNumberValid;

  lValid := (Trim(OrganisationName.Text) <> '');
  AllValid := AllValid and lValid;
  TDocUtils.setControlValidity('OrganisationName', Valid_Check[lValid]);

  lValid := (Trim(Add1.Text) <> '');
  AllValid := AllValid and lValid;
  TDocUtils.setControlValidity('Add1', Valid_Check[lValid]);

  lValid := (Trim(PostCode.Text) <> '');
  AllValid := AllValid and lValid;
  TDocUtils.setControlValidity('PostCode', Valid_Check[lValid]);

  lValid := (Trim(FirstName.Text) <> '');
  AllValid := AllValid and lValid;
  TDocUtils.setControlValidity('FirstName', Valid_Check[lValid]);

  lValid := (Trim(LastName.Text) <> '');
  AllValid := AllValid and lValid;
  TDocUtils.setControlValidity('LastName', Valid_Check[lValid]);

  lValid := TCRMUtils.IsEmailValid(EmailAddress.Text);
  AllValid := AllValid and lValid;
  TDocUtils.setControlValidity('EmailAddress', Valid_Check[lValid]);

  // lValid := (Trim(Telephone.Text) <> '');
  // AllValid := AllValid and lValid;
  // TDocUtils.setControlValidity('Telephone', Valid_Check[lValid]);

  ApplyButton.Enabled := AllValid;

end;

procedure TRegisterWebForm.WebFormCreate(Sender: TObject);
begin
  SetUpForm;
end;

function TRegisterWebForm.AgreeTerms: Boolean;
var
  lForm: TAgreementForm;
  lResult: TModalResult;
begin
  lResult := mrCancel;
  lForm := TAgreementForm.Create(self);
  try
    lForm.Popup := True;
    lForm.PopupOpacity := 1;
    await(TForm, lForm.load());
    lForm.WebClient := RegisterClient;
    lResult := await(TModalResult, lForm.Execute);
  finally
    lForm.Free;
    lForm := nil;
  end;

  Result := lResult = mrYes;
  FTermsAgreed := Result;
end;

procedure TRegisterWebForm.ApplyButtonClick(Sender: TObject);
begin
  ApplyButton.Enabled := False;
  RegisterOrganisation;
end;

procedure TRegisterWebForm.EmailAddressExit(Sender: TObject);
begin
  ValidateForm;
end;

procedure TRegisterWebForm.FirstNameExit(Sender: TObject);
begin
  ValidateForm;
end;

function TRegisterWebForm.GetCharityNumber: string;
begin
  Result := RegulatorNumber.Text;
  Result.Trim;
end;

procedure TRegisterWebForm.InitForm;
begin
end;

procedure TRegisterWebForm.LookUpCharityDetails(const ANumber: string);
const
  Svc_LookUpCharityDetails = 'IGARegisterService.LookUpCharityDetails';
var
  lRetval: TXDataClientResponse;
  lObj: JS.TJSObject;
begin
  lRetval := await(TXDataClientResponse, RegisterClient.RawInvokeAsync(Svc_LookUpCharityDetails,
    [Regulator.Value, ANumber]));

  lObj := lRetval.ResultAsObject;

  Add1.Text := TUtils.CapitaliseWords(string(lObj['Add1']));
  Add2.Text := TUtils.CapitaliseWords(string(lObj['Add2']));
  Add3.Text := TUtils.CapitaliseWords(string(lObj['Add3']));
  Add4.Text := TUtils.CapitaliseWords(string(lObj['Add4']));
  Add5.Text := TUtils.CapitaliseWords(string(lObj['Add5']));
  PostCode.Text := string(lObj['PostCode']);

//  if OrganisationName.Text = '' then
  OrganisationName.Text := TUtils.CapitaliseWords(string(lObj['CharityName']));

  ValidateForm;
  RegisterWaitMessage.Hide;
end;

procedure TRegisterWebForm.ValidateNumberButtonClick(Sender: TObject);
begin
  // Dummy Button
end;

(*
  procedure TRegisterWebForm.LookUpRegulatorNumber;
  var
  lForm: TLookUpNumberForm;
  lRetval: TModalResult;
  begin
  lForm := TLookUpNumberForm.Create(self, RegisterClient);
  try
  lForm.Popup := True;
  lForm.PopupOpacity := 1;
  lForm.ElementClassName := POPUP_FORM_CLASS;
  await(TWebForm, lForm.load());
  lForm.SelectedRegulator := Regulator.Value;
  lRetval := await(TModalResult, lForm.Execute);
  if (lRetval = mrOK) then
  begin
  RegulatorNumber.Text := lForm.SelectedCharityNumber;
  RegisteredName.Caption := lForm.SelectedCharityName;
  RegulatorStatus.Caption := lForm.SelectedRegulatorStatus;
  if OrganisationName.Text = '' then
  OrganisationName.Text := TWebUtils.CapitaliseWords(RegisteredName.Caption);
  FRegulatorNumberValid := True;

  TDocUtils.setControlValidity('RegulatorNumber', Valid_Check[True]);

  FRegistrationStatus := TsmxCheckStatus.csOk;
  FLastValidatedNumber := RegulatorNumber.Text;

  RegisterWaitMessage.Show;
  LookUpCharityDetails(RegulatorNumber.Text);

  OrganisationName.SetFocus;
  end;
  finally
  lForm.Free;
  lForm := nil;
  end;
  end;
*)

procedure TRegisterWebForm.RegulatorStatusClick(Sender: TObject);
begin
  TestHello;
end;

procedure TRegisterWebForm.SetLabels;
begin

end;

procedure TRegisterWebForm.SetUpForm;
var
  lCharityId: string;
begin
  RegisterConnection.URL := MainData.Config.RegisterURL;
  Await(RegisterConnection.OpenAsync);
  Country.ItemIndex := Country.Items.IndexOf(GetCountryByCode('GB'));
  FRegistrationStatus := TsmxCheckStatus.csNotChecked;
  FRegulatorNumberValid := False;
  TDocUtils.hideElement('OtherRegulatorDiv');
  EmailAddress.Text := AuthService.GetClaimValue(CLAIM_EMAIL);
  EmailAddress.Enabled := (EmailAddress.Text = '');
  Regulator.ItemIndex := 0;
  RegisteredName.Caption := '';
  RegulatorStatus.Caption := '';

  { TODO : Get this form working before worrying about this }
  lCharityId := MainData.LocalValue['charity-id'];
  if lCharityId <> '' then
  begin
    Regulator.ItemIndex := 0;
    RegulatorNumber.Text := lCharityId;
    CheckCharityNotRegistered;
  end;
end;

procedure TRegisterWebForm.TestHello;
var
  lRetval: TXDataClientResponse;
begin
  lRetval := await(TXDataClientResponse, RegisterClient.RawInvokeAsync('IGARegisterService.Hello', []));
  ShowMessage(string(TJSObject(lRetval.Result)['value']));
end;

procedure TRegisterWebForm.WebFormShow(Sender: TObject);
begin
  if CharityNumber <> '' then
    CheckCharityNotRegistered;
end;

procedure TRegisterWebForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  RegisteredName := TLabel.Create('RegisteredName');
  RegulatorStatus := TLabel.Create('RegulatorStatus');
  OrganisationName := TEdit.Create('OrganisationName');
  Add1 := TEdit.Create('Add1');
  Add2 := TEdit.Create('Add2');
  Add3 := TEdit.Create('Add3');
  Add4 := TEdit.Create('Add4');
  Add5 := TEdit.Create('Add5');
  PostCode := TEdit.Create('PostCode');
  OtherRegulator := TEdit.Create('OtherRegulator');
  RegulatorNumber := TEdit.Create('RegulatorNumber');
  FirstName := TEdit.Create('FirstName');
  ApplyButton := TButton.Create('ApplyButton');
  Regulator := TLookupComboBox.Create('Regulator');
  LastName := TEdit.Create('LastName');
  EmailAddress := TEdit.Create('EmailAddress');
  Telephone := TEdit.Create('Telephone');
  MessageSpan := THTMLSpan.Create('MessageSpan');
  Country := TCountryComboBox.Create('Country');
  ValidateNumberButton := TButton.Create('ValidateNumberButton');
  AnnualDonations := TEdit.Create('AnnualDonations');
  RegisterWaitMessage := TWaitMessage.Create('RegisterWaitMessage');
  RegisterClient := TXDataWebClient.Create(Self);
  RegisterConnection := TXDataWebConnection.Create(Self);

  RegisteredName.BeforeLoadDFMValues;
  RegulatorStatus.BeforeLoadDFMValues;
  OrganisationName.BeforeLoadDFMValues;
  Add1.BeforeLoadDFMValues;
  Add2.BeforeLoadDFMValues;
  Add3.BeforeLoadDFMValues;
  Add4.BeforeLoadDFMValues;
  Add5.BeforeLoadDFMValues;
  PostCode.BeforeLoadDFMValues;
  OtherRegulator.BeforeLoadDFMValues;
  RegulatorNumber.BeforeLoadDFMValues;
  FirstName.BeforeLoadDFMValues;
  ApplyButton.BeforeLoadDFMValues;
  Regulator.BeforeLoadDFMValues;
  LastName.BeforeLoadDFMValues;
  EmailAddress.BeforeLoadDFMValues;
  Telephone.BeforeLoadDFMValues;
  MessageSpan.BeforeLoadDFMValues;
  Country.BeforeLoadDFMValues;
  ValidateNumberButton.BeforeLoadDFMValues;
  AnnualDonations.BeforeLoadDFMValues;
  RegisterWaitMessage.BeforeLoadDFMValues;
  RegisterClient.BeforeLoadDFMValues;
  RegisterConnection.BeforeLoadDFMValues;
  try
    Name := 'RegisterWebForm';
    Width := 640;
    Height := 480;
    Caption := 'Find My Regulator Number';
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    RegisteredName.SetParentComponent(Self);
    RegisteredName.Name := 'RegisteredName';
    RegisteredName.Left := 224;
    RegisteredName.Top := 108;
    RegisteredName.Width := 18;
    RegisteredName.Height := 15;
    RegisteredName.Caption := 'xxx';
    RegisteredName.ElementFont := efCSS;
    RegisteredName.ElementPosition := epIgnore;
    RegisteredName.HeightStyle := ssAuto;
    RegisteredName.HeightPercent := 100.000000000000000000;
    RegisteredName.HTMLType := tSPAN;
    RegisteredName.WidthStyle := ssAuto;
    RegisteredName.WidthPercent := 100.000000000000000000;
    RegulatorStatus.SetParentComponent(Self);
    RegulatorStatus.Name := 'RegulatorStatus';
    RegulatorStatus.Left := 264;
    RegulatorStatus.Top := 108;
    RegulatorStatus.Width := 18;
    RegulatorStatus.Height := 15;
    RegulatorStatus.Caption := 'xxx';
    RegulatorStatus.ElementFont := efCSS;
    RegulatorStatus.ElementPosition := epIgnore;
    RegulatorStatus.HeightStyle := ssAuto;
    RegulatorStatus.HeightPercent := 100.000000000000000000;
    RegulatorStatus.HTMLType := tSPAN;
    RegulatorStatus.WidthStyle := ssAuto;
    RegulatorStatus.WidthPercent := 100.000000000000000000;
    SetEvent(RegulatorStatus, Self, 'OnClick', 'RegulatorStatusClick');
    OrganisationName.SetParentComponent(Self);
    OrganisationName.Name := 'OrganisationName';
    OrganisationName.Left := 24;
    OrganisationName.Top := 24;
    OrganisationName.Width := 121;
    OrganisationName.Height := 22;
    OrganisationName.ElementClassName := 'form-control';
    OrganisationName.ElementPosition := epIgnore;
    OrganisationName.HeightStyle := ssAuto;
    OrganisationName.HeightPercent := 100.000000000000000000;
    OrganisationName.WidthStyle := ssAuto;
    OrganisationName.WidthPercent := 100.000000000000000000;
    Add1.SetParentComponent(Self);
    Add1.Name := 'Add1';
    Add1.Left := 24;
    Add1.Top := 49;
    Add1.Width := 121;
    Add1.Height := 22;
    Add1.ChildOrder := 1;
    Add1.ElementClassName := 'form-control';
    Add1.ElementPosition := epIgnore;
    Add1.HeightStyle := ssAuto;
    Add1.HeightPercent := 100.000000000000000000;
    Add1.WidthStyle := ssAuto;
    Add1.WidthPercent := 100.000000000000000000;
    Add2.SetParentComponent(Self);
    Add2.Name := 'Add2';
    Add2.Left := 24;
    Add2.Top := 75;
    Add2.Width := 121;
    Add2.Height := 22;
    Add2.ChildOrder := 2;
    Add2.ElementClassName := 'form-control';
    Add2.ElementPosition := epIgnore;
    Add2.HeightStyle := ssAuto;
    Add2.HeightPercent := 100.000000000000000000;
    Add2.WidthStyle := ssAuto;
    Add2.WidthPercent := 100.000000000000000000;
    Add3.SetParentComponent(Self);
    Add3.Name := 'Add3';
    Add3.Left := 24;
    Add3.Top := 100;
    Add3.Width := 121;
    Add3.Height := 22;
    Add3.ChildOrder := 3;
    Add3.ElementClassName := 'form-control';
    Add3.ElementPosition := epIgnore;
    Add3.HeightStyle := ssAuto;
    Add3.HeightPercent := 100.000000000000000000;
    Add3.WidthStyle := ssAuto;
    Add3.WidthPercent := 100.000000000000000000;
    Add4.SetParentComponent(Self);
    Add4.Name := 'Add4';
    Add4.Left := 24;
    Add4.Top := 126;
    Add4.Width := 121;
    Add4.Height := 22;
    Add4.ChildOrder := 4;
    Add4.ElementClassName := 'form-control';
    Add4.ElementPosition := epIgnore;
    Add4.HeightStyle := ssAuto;
    Add4.HeightPercent := 100.000000000000000000;
    Add4.WidthStyle := ssAuto;
    Add4.WidthPercent := 100.000000000000000000;
    Add5.SetParentComponent(Self);
    Add5.Name := 'Add5';
    Add5.Left := 24;
    Add5.Top := 152;
    Add5.Width := 121;
    Add5.Height := 22;
    Add5.ChildOrder := 5;
    Add5.ElementClassName := 'form-control';
    Add5.ElementPosition := epIgnore;
    Add5.HeightStyle := ssAuto;
    Add5.HeightPercent := 100.000000000000000000;
    Add5.WidthStyle := ssAuto;
    Add5.WidthPercent := 100.000000000000000000;
    PostCode.SetParentComponent(Self);
    PostCode.Name := 'PostCode';
    PostCode.Left := 24;
    PostCode.Top := 177;
    PostCode.Width := 121;
    PostCode.Height := 22;
    PostCode.CharCase := wecUpperCase;
    PostCode.ChildOrder := 6;
    PostCode.ElementClassName := 'form-control';
    PostCode.ElementPosition := epIgnore;
    PostCode.HeightStyle := ssAuto;
    PostCode.HeightPercent := 100.000000000000000000;
    PostCode.WidthStyle := ssAuto;
    PostCode.WidthPercent := 100.000000000000000000;
    OtherRegulator.SetParentComponent(Self);
    OtherRegulator.Name := 'OtherRegulator';
    OtherRegulator.Left := 224;
    OtherRegulator.Top := 52;
    OtherRegulator.Width := 121;
    OtherRegulator.Height := 22;
    OtherRegulator.ChildOrder := 7;
    OtherRegulator.ElementClassName := 'form-control';
    OtherRegulator.ElementPosition := epIgnore;
    OtherRegulator.HeightStyle := ssAuto;
    OtherRegulator.HeightPercent := 100.000000000000000000;
    OtherRegulator.WidthStyle := ssAuto;
    OtherRegulator.WidthPercent := 100.000000000000000000;
    RegulatorNumber.SetParentComponent(Self);
    RegulatorNumber.Name := 'RegulatorNumber';
    RegulatorNumber.Left := 224;
    RegulatorNumber.Top := 80;
    RegulatorNumber.Width := 121;
    RegulatorNumber.Height := 22;
    RegulatorNumber.CharCase := wecUpperCase;
    RegulatorNumber.ChildOrder := 8;
    RegulatorNumber.ElementClassName := 'form-control';
    RegulatorNumber.ElementPosition := epIgnore;
    RegulatorNumber.HeightStyle := ssAuto;
    RegulatorNumber.HeightPercent := 100.000000000000000000;
    RegulatorNumber.WidthStyle := ssAuto;
    RegulatorNumber.WidthPercent := 100.000000000000000000;
    SetEvent(RegulatorNumber, Self, 'OnChange', 'RegulatorNumberChange');
    SetEvent(RegulatorNumber, Self, 'OnExit', 'RegulatorNumberExit');
    FirstName.SetParentComponent(Self);
    FirstName.Name := 'FirstName';
    FirstName.Left := 455;
    FirstName.Top := 24;
    FirstName.Width := 121;
    FirstName.Height := 22;
    FirstName.ChildOrder := 10;
    FirstName.ElementClassName := 'form-control';
    FirstName.ElementPosition := epIgnore;
    FirstName.HeightStyle := ssAuto;
    FirstName.HeightPercent := 100.000000000000000000;
    FirstName.WidthStyle := ssAuto;
    FirstName.WidthPercent := 100.000000000000000000;
    SetEvent(FirstName, Self, 'OnExit', 'FirstNameExit');
    ApplyButton.SetParentComponent(Self);
    ApplyButton.Name := 'ApplyButton';
    ApplyButton.Left := 392;
    ApplyButton.Top := 224;
    ApplyButton.Width := 96;
    ApplyButton.Height := 25;
    ApplyButton.Caption := 'Apply';
    ApplyButton.ChildOrder := 12;
    ApplyButton.ElementPosition := epIgnore;
    ApplyButton.HeightStyle := ssAuto;
    ApplyButton.HeightPercent := 100.000000000000000000;
    ApplyButton.WidthStyle := ssAuto;
    ApplyButton.WidthPercent := 100.000000000000000000;
    SetEvent(ApplyButton, Self, 'OnClick', 'ApplyButtonClick');
    Regulator.SetParentComponent(Self);
    Regulator.Name := 'Regulator';
    Regulator.Left := 224;
    Regulator.Top := 24;
    Regulator.Width := 145;
    Regulator.Height := 22;
    Regulator.ElementClassName := 'form-select';
    Regulator.ElementPosition := epIgnore;
    Regulator.HeightStyle := ssAuto;
    Regulator.HeightPercent := 100.000000000000000000;
    Regulator.WidthStyle := ssAuto;
    Regulator.WidthPercent := 100.000000000000000000;
    SetEvent(Regulator, Self, 'OnChange', 'RegulatorChange');
    SetEvent(Regulator, Self, 'OnExit', 'RegulatorExit');
    Regulator.ItemIndex := -1;
    Regulator.LookupValues.Clear;
    with Regulator.LookupValues.Add do
    begin
      Value := 'CCEW';
      DisplayText := 'Charity Commission for England and Wales';
    end;
    with Regulator.LookupValues.Add do
    begin
      Value := 'CCNI';
      DisplayText := 'Charity Commission for Northern Ireland';
    end;
    with Regulator.LookupValues.Add do
    begin
      Value := 'OSCR';
      DisplayText := 'Office of the Scottish Charity Regulator';
    end;
    with Regulator.LookupValues.Add do
    begin
      Value := 'OTH';
      DisplayText := 'Other';
    end;
    LastName.SetParentComponent(Self);
    LastName.Name := 'LastName';
    LastName.Left := 455;
    LastName.Top := 52;
    LastName.Width := 121;
    LastName.Height := 22;
    LastName.ChildOrder := 10;
    LastName.ElementClassName := 'form-control';
    LastName.ElementPosition := epIgnore;
    LastName.HeightStyle := ssAuto;
    LastName.HeightPercent := 100.000000000000000000;
    LastName.WidthStyle := ssAuto;
    LastName.WidthPercent := 100.000000000000000000;
    SetEvent(LastName, Self, 'OnExit', 'FirstNameExit');
    EmailAddress.SetParentComponent(Self);
    EmailAddress.Name := 'EmailAddress';
    EmailAddress.Left := 455;
    EmailAddress.Top := 80;
    EmailAddress.Width := 121;
    EmailAddress.Height := 22;
    EmailAddress.ChildOrder := 10;
    EmailAddress.ElementClassName := 'form-control';
    EmailAddress.ElementPosition := epIgnore;
    EmailAddress.HeightStyle := ssAuto;
    EmailAddress.HeightPercent := 100.000000000000000000;
    EmailAddress.WidthStyle := ssAuto;
    EmailAddress.WidthPercent := 100.000000000000000000;
    SetEvent(EmailAddress, Self, 'OnExit', 'EmailAddressExit');
    Telephone.SetParentComponent(Self);
    Telephone.Name := 'Telephone';
    Telephone.Left := 455;
    Telephone.Top := 108;
    Telephone.Width := 121;
    Telephone.Height := 22;
    Telephone.ChildOrder := 10;
    Telephone.ElementClassName := 'form-control';
    Telephone.ElementPosition := epIgnore;
    Telephone.HeightStyle := ssAuto;
    Telephone.HeightPercent := 100.000000000000000000;
    Telephone.WidthStyle := ssAuto;
    Telephone.WidthPercent := 100.000000000000000000;
    SetEvent(Telephone, Self, 'OnExit', 'FirstNameExit');
    MessageSpan.SetParentComponent(Self);
    MessageSpan.Name := 'MessageSpan';
    MessageSpan.Left := 224;
    MessageSpan.Top := 177;
    MessageSpan.Width := 100;
    MessageSpan.Height := 40;
    MessageSpan.HeightStyle := ssAuto;
    MessageSpan.WidthStyle := ssAuto;
    MessageSpan.ChildOrder := 19;
    MessageSpan.ElementPosition := epIgnore;
    MessageSpan.ElementFont := efCSS;
    MessageSpan.Role := '';
    Country.SetParentComponent(Self);
    Country.Name := 'Country';
    Country.Left := 24;
    Country.Top := 205;
    Country.Width := 145;
    Country.Height := 23;
    Country.ElementClassName := 'form-control';
    Country.ElementFont := efCSS;
    Country.ElementPosition := epIgnore;
    Country.HeightStyle := ssAuto;
    Country.HeightPercent := 100.000000000000000000;
    Country.WidthStyle := ssAuto;
    Country.WidthPercent := 100.000000000000000000;
    Country.ItemIndex := -1;
    ValidateNumberButton.SetParentComponent(Self);
    ValidateNumberButton.Name := 'ValidateNumberButton';
    ValidateNumberButton.Left := 264;
    ValidateNumberButton.Top := 125;
    ValidateNumberButton.Width := 96;
    ValidateNumberButton.Height := 25;
    ValidateNumberButton.Caption := 'Validate Number';
    ValidateNumberButton.ChildOrder := 18;
    ValidateNumberButton.ElementClassName := 'btn btn-light';
    ValidateNumberButton.ElementFont := efCSS;
    ValidateNumberButton.ElementPosition := epIgnore;
    ValidateNumberButton.HeightStyle := ssAuto;
    ValidateNumberButton.HeightPercent := 100.000000000000000000;
    ValidateNumberButton.WidthStyle := ssAuto;
    ValidateNumberButton.WidthPercent := 100.000000000000000000;
    SetEvent(ValidateNumberButton, Self, 'OnClick', 'ValidateNumberButtonClick');
    AnnualDonations.SetParentComponent(Self);
    AnnualDonations.Name := 'AnnualDonations';
    AnnualDonations.Left := 24;
    AnnualDonations.Top := 248;
    AnnualDonations.Width := 121;
    AnnualDonations.Height := 22;
    AnnualDonations.ChildOrder := 19;
    AnnualDonations.ElementClassName := 'form-control';
    AnnualDonations.ElementFont := efCSS;
    AnnualDonations.ElementPosition := epIgnore;
    AnnualDonations.HeightStyle := ssAuto;
    AnnualDonations.HeightPercent := 100.000000000000000000;
    AnnualDonations.WidthStyle := ssAuto;
    AnnualDonations.WidthPercent := 100.000000000000000000;
    RegisterWaitMessage.SetParentComponent(Self);
    RegisterWaitMessage.Name := 'RegisterWaitMessage';
    RegisterWaitMessage.Left := 308;
    RegisterWaitMessage.Top := 228;
    RegisterWaitMessage.Width := 24;
    RegisterWaitMessage.Height := 24;
    RegisterWaitMessage.Center.Horizontal := True;
    RegisterWaitMessage.Center.Vertical := True;
    RegisterWaitMessage.Opacity := 0.200000000000000000;
    RegisterWaitMessage.PictureURL := 'images/whirligig.gif';
    RegisterClient.SetParentComponent(Self);
    RegisterClient.Name := 'RegisterClient';
    RegisterClient.Connection := RegisterConnection;
    RegisterClient.Left := 208;
    RegisterClient.Top := 288;
    RegisterConnection.SetParentComponent(Self);
    RegisterConnection.Name := 'RegisterConnection';
    RegisterConnection.URL := 'http://localhost:2018/GARegister';
    RegisterConnection.Left := 208;
    RegisterConnection.Top := 232;
  finally
    RegisteredName.AfterLoadDFMValues;
    RegulatorStatus.AfterLoadDFMValues;
    OrganisationName.AfterLoadDFMValues;
    Add1.AfterLoadDFMValues;
    Add2.AfterLoadDFMValues;
    Add3.AfterLoadDFMValues;
    Add4.AfterLoadDFMValues;
    Add5.AfterLoadDFMValues;
    PostCode.AfterLoadDFMValues;
    OtherRegulator.AfterLoadDFMValues;
    RegulatorNumber.AfterLoadDFMValues;
    FirstName.AfterLoadDFMValues;
    ApplyButton.AfterLoadDFMValues;
    Regulator.AfterLoadDFMValues;
    LastName.AfterLoadDFMValues;
    EmailAddress.AfterLoadDFMValues;
    Telephone.AfterLoadDFMValues;
    MessageSpan.AfterLoadDFMValues;
    Country.AfterLoadDFMValues;
    ValidateNumberButton.AfterLoadDFMValues;
    AnnualDonations.AfterLoadDFMValues;
    RegisterWaitMessage.AfterLoadDFMValues;
    RegisterClient.AfterLoadDFMValues;
    RegisterConnection.AfterLoadDFMValues;
  end;
end;

end.
