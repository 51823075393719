{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2019                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.RegularExpressions;

interface

uses js, web, sysutils, types;

type
  TRegExOption = (roNone, roIgnoreCase, roMultiLine,
    roSingleLine, roJSUnicode);

  TRegExOptions = set of TRegExOption;

  { Difference from Delphi TGroup:
    JS does not return Index and Length for a Group. So
    these propertiess are absent.
  }
  TGroup = record
  private
    FValue: String;
    FSuccess: Boolean;
    constructor Create(const AValue: String; ASuccess: Boolean);
  public
    property Value: String read FValue;
    property Success: Boolean read FSuccess;
  end;

  TGroupCollectionEnumerator = class;

  TGroupArray = array of TGroup;

  TGroupCollection = record
  private
    FList: TGroupArray;
    FNamedGroups: TJSObject;
    // Redesigned as in Pas2JS record constructor does not work properly
    // All New functions in this file are coded for this reason only
    class function New(execResultArray: TStringDynArray): TGroupCollection; static;
    function GetCount: Integer;
    function GetItem(Index: JSValue): TGroup;
  public
    function GetEnumerator: TGroupCollectionEnumerator;
    property Count: Integer read GetCount;
    property Item[Index: JSValue]: TGroup read GetItem; default;
  end;

  TGroupCollectionEnumerator = class
  private
    FCollection: TGroupCollection;
    FIndex: Integer;
  public
    constructor Create(const ACollection: TGroupCollection);
    function GetCurrent: TGroup;
    function MoveNext: Boolean;
    property Current: TGroup read GetCurrent;
  end;

  TMatch = record
  private
    FIndex: Integer;
    FLength: Integer;
    FSuccess: Boolean;
    FGroups: TGroupCollection;
    FValue: String;
    FInput: String;
    FJSRegExp: TJSRegexp;
    constructor Create(AJSRegExp: TJSRegexp; execResultArray: TStringDynArray); overload;
    class function New: TMatch; static;
    function GetGroups: TGroupCollection;
  public
    function NextMatch: TMatch;
    property Groups: TGroupCollection read GetGroups;
    property Index: Integer read FIndex;
    property Length: Integer read FLength;
    property Success: Boolean read FSuccess write FSuccess;
    property Value: String read FValue;
  end;

  TMatchCollectionEnumerator = class;

  TMatchArray = array of TMatch;

  TMatchCollection = record
  private
    FList: TMatchArray;
    // Redesigned as Constructor does not work properly for records
    class function New(aMatch: TMatch): TMatchCollection; static;
    function GetCount: Integer;
    function GetItem(Index: Integer): TMatch;
  public
    function GetEnumerator: TMatchCollectionEnumerator;
    property Count: Integer read GetCount;
    property Item[Index: Integer]: TMatch read GetItem; default;
  end;

  TMatchCollectionEnumerator = class
  private
    FCollection: TMatchCollection;
    FIndex: Integer;
  public
    constructor Create(const ACollection: TMatchCollection);
    function GetCurrent: TMatch;
    function MoveNext: Boolean;
    property Current: TMatch read GetCurrent;
  end;

  TMatchEvaluator = function(const Match: TMatch): String of object;

  TRegEx = record
  private
    FJSRegExp: TJSRegexp;
    FPattern: String;
    FOptions: TRegExOptions;
    FMatchEvaluator: TMatchEvaluator;

    function GetJSRegExFlags(AddJSFlags: String=''): String;
    Function DoOnReplace(Const match: String; offsetDontUse: Integer; InputDontUse: String) : String;
  public
    constructor Create(const Pattern: String); overload;
    constructor Create(const Pattern: String; Options: TRegExOptions); overload;

    function IsMatch(const Input: String): Boolean; overload;
    function IsMatch(const Input: String; StartPos: Integer): Boolean; overload;
    class function IsMatch(const Input, Pattern: String): Boolean; overload; static;
    class function IsMatch(const Input, Pattern: String; Options: TRegExOptions): Boolean; overload; static;

    class function Escape(const Str: String; UseWildCards: Boolean = False): String; static;

    function Match(const Input: String): TMatch; overload;
    function Match(const Input: String; StartPos: Integer): TMatch; overload;
    function Match(const Input: String; StartPos, Length: Integer): TMatch; overload;
    class function Match(const Input, Pattern: String): TMatch; overload; static;
    class function Match(const Input, Pattern: String; Options: TRegExOptions): TMatch; overload; static;

    function Matches(const Input: String): TMatchCollection; overload;
    function Matches(const Input: String; StartPos: Integer): TMatchCollection; overload;
    class function Matches(const Input, Pattern: String): TMatchCollection; overload; static;
    class function Matches(const Input, Pattern: String; Options: TRegExOptions): TMatchCollection; overload; static;

    function Replace(const Input, Replacement: String): String; overload;
    function Replace(const Input: String; Evaluator: TMatchEvaluator): String; overload;
    // Note: overloaded Replace functions with Count are not supported.
    class function Replace(const Input, Pattern, Replacement: String): String; overload; static;
    class function Replace(const Input, Pattern: String; Evaluator: TMatchEvaluator): String; overload; static;
    class function Replace(const Input, Pattern, Replacement: String; Options: TRegExOptions): String; overload; static;
    class function Replace(const Input, Pattern: String; Evaluator: TMatchEvaluator; Options: TRegExOptions): String; overload; static;

    function Split(const Input: String): TStringDynArray; overload;
    // Note: overloaded Split function with Count are not supported.
    class function Split(const Input, Pattern: String): TStringDynArray; overload; static;
    class function Split(const Input, Pattern: String; Options: TRegExOptions): TStringDynArray; overload; static;
  end;

implementation

uses strutils;

function GetJSExceptionDetails(ExceptObject: TObject; out errorName: String; out errorMsg: String): Boolean; forward;
function FormatErrorMessage(errorName: String; errorMessage: String): String; forward;

{ TGroup }

constructor TGroup.Create(const AValue: String; ASuccess: Boolean);
begin
  FValue := AValue;
  FSuccess := ASuccess;
end;

{ TGroupCollection }

class function TGroupCollection.New(execResultArray: TStringDynArray): TGroupCollection;
var
  arrayLength, i: Integer;
  resObject: TJSObject;
  svalue: String;
begin
  SetLength(Result.FList, 0);
  Result.FNamedGroups := TJSObject.new;
  if execResultArray = nil then
    exit;
  resObject := TJSObject(execResultArray);
  arrayLength := Integer(resObject['length']);
  SetLength(Result.FList, arrayLength);
  for i := 0 to arrayLength - 1 do
  begin
    // A group can be undefined
    if not isUndefined(execResultArray[i]) then
      svalue := execResultArray[i]
    else
      svalue := ''; // We add an empty group for undefined
                    // group sent back. Delphi in some cases
                    // does this and sometimes doesn't. Hence,
                    // the decision.
    Result.FList[i] := TGroup.Create(svalue, True);
  end;
  if not isUndefined(resObject['groups']) then
  begin
    Result.FNamedGroups := TJSObject(resObject['groups']);
  end;
end;


function TGroupCollection.GetCount: Integer;
begin
  Result := Length(FList);
end;

function TGroupCollection.GetEnumerator: TGroupCollectionEnumerator;
begin
  Result := TGroupCollectionEnumerator.Create(Self);
end;

function TGroupCollection.GetItem(Index: JSValue): TGroup;
var
  LIndex: Integer;
begin
  Case GetValueType(Index) of
    jvtString:
      begin
        if isUndefined(FNamedGroups[String(Index)]) then
          Result := TGroup.Create('', False)
        else
          Result := TGroup.Create(String(FNamedGroups[String(Index)]), True);
      end;
    jvtInteger:
      begin
        LIndex := Integer(Index);
        Result := FList[LIndex];
      end;
  else
    raise Exception.Create('Invalid group index passed');
  end;
end;

{ TGroupCollectionEnumerator }

constructor TGroupCollectionEnumerator.Create(const ACollection: TGroupCollection);
begin
  FCollection := ACollection;
  FIndex := -1;
end;

function TGroupCollectionEnumerator.GetCurrent: TGroup;
begin
  Result := FCollection.Item[FIndex];
end;

function TGroupCollectionEnumerator.MoveNext: Boolean;
begin
  Result := FIndex < FCollection.Count - 1;
  if Result then
    Inc(FIndex);
end;

{ TMatch }
constructor TMatch.Create(AJSRegExp: TJSRegexp; execResultArray: TStringDynArray);
var
  resObject: TJSObject;
  str: String;
begin
  FJSRegExp := AJSRegExp;
  if execResultArray = nil then
  begin
    FValue := '';
    FIndex := 0;
    FLength := 0;
    FSuccess := False;
  end
  else
  begin
    resObject := TJSObject(execResultArray);
    FInput := String(resObject['input']);
    str := execResultArray[0];

    FValue := str;
    FIndex := Integer(resObject['index']) + 1; //Delphi index
    FLength := System.length(str);
    FSuccess := True;
  end;
  FGroups := TGroupCollection.New(execResultArray);
end;

// To create empty
class function TMatch.New: TMatch;
begin
  Result.FValue := '';
  Result.FIndex := 0;
  Result.FLength := 0;
  Result.FSuccess := False;
end;

function TMatch.GetGroups: TGroupCollection;
begin
  Result := FGroups;
end;

function TMatch.NextMatch: TMatch;
var
  execResultArray: TStringDynArray;
begin
  if FJSRegExp = nil then
  begin
    Result := TMatch.New;
    exit;
  end;
  execResultArray := FJSRegExp.exec(FInput);
  Result := TMatch.Create(FJSRegExp, execResultArray);
end;

{ TMatchCollection }

class function TMatchCollection.New(aMatch: TMatch): TMatchCollection;
var
  Count: Integer;
begin
  Count := 0;
  SetLength(Result.FList, 0);
  if not aMatch.Success then
    exit;
  while aMatch.Success do
  begin
    if Count mod 10 = 0 then
      SetLength(Result.FList, Length(Result.FList) + 10);
    Result.FList[Count] := aMatch;
    aMatch := aMatch.NextMatch;
    Inc(Count);
  end;
  if Length(Result.FList) > Count then
    SetLength(Result.FList, Count);
end;

function TMatchCollection.GetCount: Integer;
begin
  Result := Length(FList);
end;

function TMatchCollection.GetEnumerator: TMatchCollectionEnumerator;
begin
  Result := TMatchCollectionEnumerator.Create(Self);
end;

function TMatchCollection.GetItem(Index: Integer): TMatch;
begin
  if (Index >= 0) and (Index < Length(FList)) then
    Result := FList[Index]
  else
    raise Exception.Create('Invalid match collection index passed.');
end;

{ TMatchCollectionEnumerator }

constructor TMatchCollectionEnumerator.Create(const ACollection: TMatchCollection);
begin
  FCollection := ACollection;
  FIndex := -1;
end;

function TMatchCollectionEnumerator.GetCurrent: TMatch;
begin
  Result := FCollection.Item[FIndex];
end;

function TMatchCollectionEnumerator.MoveNext: Boolean;
begin
  Result := FIndex < FCollection.Count - 1;
  if Result then
    Inc(FIndex);
end;

{ TRegEx related }

function GetJSExceptionDetails(ExceptObject: TObject; out errorName: String; out errorMsg: String): Boolean;
var
  erName, erMsg: String;
begin
  result := False;
  if ExceptObject is Exception then
    exit;
  result := True;
  asm
    erName = ExceptObject.name;
    erMsg = ExceptObject.message;
  end;
  errorName := erName;
  errorMsg := erMsg;
end;

function FormatErrorMessage(errorName: String; errorMessage: String): String;
begin
  Result := Format('%s: %s',
                   [errorName, errorMessage]);
end;

{ TRegEx }
function TRegEx.GetJSRegExFlags(AddJSFlags: String=''): String;
begin
  // Meaning of new JSflags
  // By Default: use g is required for our implementation
  //   g means keep state (lastindex)
  // Used for IsMatch
  //   y means sticky, compare exactly at lastindex
  Result := 'g' + AddJSFlags;
  if (roIgnoreCase in FOptions) then
    Result := Result + 'i';
  if (roMultiLine in FOptions) then
    Result := Result + 'm';
  if (roSingleLine in FOptions) then
    Result := Result + 's';
  if (roJSUnicode in FOptions) then
    Result := Result + 'u';
end;

constructor TRegEx.Create(const Pattern: String);
begin
  Create(Pattern, []);
end;

function RemoveLineEnding(aPattern: String): String;
begin
  Result := TrimRightSet(APattern, [#10, #13]);
  Result := TrimLeftSet(Result, [#10, #13]);
end;

constructor TRegEx.Create(const Pattern: String; Options: TRegExOptions); overload;
var
  exe: Exception;
  excName, excMsg: String;
begin
  FPattern := RemoveLineEnding(Pattern);
  FOptions := Options;
  try
    FJSRegExp := TJSRegexp.New(FPattern, GetJSRegExFlags);
  except
    asm
      exe = $e;
    end;
    if not GetJSExceptionDetails(exe, excName, excMsg) then
      raise exe;
    excMsg := FormatErrorMessage(excName, excMsg);
    raise Exception.Create(excmsg);
  end;
end;

function TRegEx.IsMatch(const Input: String): Boolean;
begin
  Result := IsMatch(input, 1); //Start at first position
end;

function TRegEx.IsMatch(const Input: String; StartPos: Integer): Boolean;
var
  exe: Exception;
  excName, excMsg: String;
begin
  try
    // We need to recreate the regexp object with sticky flag y
    FJSRegExp := TJSRegexp.New(FPattern, GetJSRegExFlags('y'));
    FJSRegExp.lastIndex := StartPos - 1; //Delphi pos starts at 1
    // We can not use test method as that does not honor flag y for
    // exact comparison. Instead, use exec and analyze result.
    Result := FJSRegExp.exec(input) <> nil;
  except
    asm
      exe = $e;
    end;
    if not GetJSExceptionDetails(exe, excName, excMsg) then
      raise exe;
    excMsg := FormatErrorMessage(excName, excMsg);
    raise Exception.Create(excmsg);
  end;
end;

class function TRegEx.IsMatch(const Input, Pattern: String): Boolean;
var
  aRegEx: TRegEx;
begin
  aRegEx := TRegEx.Create(Pattern);
  Result := aRegEx.IsMatch(Input);
end;

class function TRegEx.IsMatch(const Input, Pattern: String; Options: TRegExOptions): Boolean;
var
  aRegEx: TRegEx;
begin
  aRegEx := TRegEx.Create(Pattern, Options);
  Result := aRegEx.IsMatch(Input);
end;

// Straight from Delphi. Needs to be tested.
class function TRegEx.Escape(const Str: String; UseWildCards: Boolean): String;
const
  Special: array [1 .. 14] of String = ('\', '[', ']', '^', '$', '.', '|', '?',
    '*', '+', '(', ')', '{', '}'); // do not localize
var
  I: Integer;
begin
  Result := Str;
  for I := Low(Special) to High(Special) do
  begin
    Result := StringReplace(Result, Special[I], '\' + Special[I], [rfReplaceAll]); // do not localize
  end;
  // CRLF becomes \r\n
  Result := StringReplace(Result, #13#10, '\r\n', [rfReplaceAll]); // do not localize
  // LF becomes \n
  Result := StringReplace(Result, #10, '\n', [rfReplaceAll]); // do not localize

  // If we're matching wildcards, make them Regex Groups so we can read them back if necessary
  if UseWildCards then
  begin
    // Replace all \*s with (.*)
    Result := StringReplace(Result, '\*', '(.*)', [rfReplaceAll]); // do not localize
    // Replace any \?s with (.)
    Result := StringReplace(Result, '\?', '(.)', [rfReplaceAll]); // do not localize

    // Wildcards can be escaped as ** or ??
    // Change back any escaped wildcards
    Result := StringReplace(Result, '(.*)(.*)', '\*', [rfReplaceAll]); // do not localize
    Result := StringReplace(Result, '(.)(.)', '\?', [rfReplaceAll]); // do not localize
  end;
end;

function TRegEx.Match(const Input: String): TMatch;
begin
  Result := Match(Input, 1); //Start at first position
end;

function TRegEx.Match(const Input: String; StartPos: Integer): TMatch; overload;
var
  execResultArray: TStringDynArray;
begin
  FJSRegExp.lastIndex := StartPos - 1; //Delphi pos starts at 1
  execResultArray := FJSRegExp.exec(input);
  Result := TMatch.Create(FJSRegExp, execResultArray);
end;

function TRegEx.Match(const Input: String; StartPos, Length: Integer): TMatch; overload;
var
  substr: String;
begin
  substr := copy(input, 1, StartPos + Length-1);
  Result := Match(substr, StartPos);
end;

class function TRegEx.Match(const Input, Pattern: String): TMatch;
var
  aRegEx: TRegEx;
begin
  aRegEx := TRegEx.Create(Pattern);
  Result := aRegEx.Match(Input);
end;

class function TRegEx.Match(const Input, Pattern: String; Options: TRegExOptions): TMatch;
var
  aRegEx: TRegEx;
begin
  aRegEx := TRegEx.Create(Pattern, Options);
  Result := aRegEx.Match(Input);
end;

function TRegEx.Matches(const Input: String): TMatchCollection;
begin
  Result := Matches(Input, 1);
end;

function TRegEx.Matches(const Input: String; StartPos: Integer): TMatchCollection;
var
  aMatch: TMatch;
begin
  aMatch := Match(Input, StartPos);
  Result := TMatchCollection.New(aMatch);
end;

class function TRegEx.Matches(const Input, Pattern: String): TMatchCollection;
var
  aRegEx: TRegEx;
begin
  aRegEx := TRegEx.Create(Pattern);
  Result := aRegEx.Matches(Input);
end;

class function TRegEx.Matches(const Input, Pattern: String; Options: TRegExOptions): TMatchCollection;
var
  aRegEx: TRegEx;
begin
  aRegEx := TRegEx.Create(Pattern, Options);
  Result := aRegEx.Matches(Input);
end;

function TRegEx.Replace(const Input, Replacement: String): String;
var
  anStr: TJSString;
begin
  anStr := TJSString.New(Input);
  Result := anStr.replace(FJSRegExp, Replacement);
end;

// Note that information passed to offset and input will be wrong
// because depending on groups present first group will come as offset parameter
// and so on. There is no way we can create a match only from these arguments.
// We rather inspect JSArguments to find what we need.
Function TRegEx.DoOnReplace(Const match: String; offsetDontUse: Integer; InputDontUse: String) : String;
var
  execResultArray: TStringDynArray;
  i, numArgs: Integer;
  aMatch: TMatch;
begin
  { we make up an array same as execResultArray from arguments
    so that a TMatch can be constructed from it by existing
    constructors.
    The indexes needed:
    0 -- the match
    1, n -- group strings found
    property index - offset of match
    property input -- Input String
    -------------
    Now, we inspect JSArguments array to see actual arguments
    passed to this function to get the groups which are not
    passed in actual arguments above.
    Logic: JSArguments array contains offset at n+1 and input at n+2
    So we simply copy length-2 elements and then add properties
    from the last 2 arguments of JSArguments
  }
  numArgs := JSArguments.length;
  setLength(execResultArray, numArgs-2);
  for i := 0 to length(execResultArray)-1 do
    execResultArray[i] := String(JSArguments[i]);
  TJSObject(execResultArray)['index'] := Integer(JSArguments[numArgs-2]);
  TJSObject(execResultArray)['input'] := String(JSArguments[numArgs-1]);

  aMatch := TMatch.Create(FJSRegExp, execResultArray);
  Result := FMatchEvaluator(aMatch);
end;

function TRegEx.Replace(const Input: String; Evaluator: TMatchEvaluator): String; overload;
var
  anStr: TJSString;
begin
  anStr := TJSString.New(Input);
  FMatchEvaluator := Evaluator;
  try
    Result := anStr.replace(FJSRegExp, @DoOnReplace);
  finally
    FMatchEvaluator := nil;
  end;
end;

class function TRegEx.Replace(const Input, Pattern, Replacement: String): String;
var
  aRegEx: TRegEx;
begin
  aRegEx := TRegEx.Create(Pattern);
  Result := aRegEx.Replace(Input, Replacement);
end;

class function TRegEx.Replace(const Input, Pattern: String; Evaluator: TMatchEvaluator): String;
var
  aRegEx: TRegEx;
begin
  aRegEx := TRegEx.Create(Pattern);
  Result := aRegEx.Replace(Input, Evaluator);
end;

class function TRegEx.Replace(const Input, Pattern, Replacement: String; Options: TRegExOptions): String;
var
  aRegEx: TRegEx;
begin
  aRegEx := TRegEx.Create(Pattern, Options);
  Result := aRegEx.Replace(Input, Replacement);
end;

class function TRegEx.Replace(const Input, Pattern: String; Evaluator: TMatchEvaluator; Options: TRegExOptions): String;
var
  aRegEx: TRegEx;
begin
  aRegEx := TRegEx.Create(Pattern, Options);
  Result := aRegEx.Replace(Input, Evaluator);
end;

function TRegEx.Split(const Input: String): TStringDynArray;
var
  anStr: TJSString;
begin
  anStr := TJSString.New(Input);
  Result := anStr.split(FJSRegExp);
end;

class function TRegEx.Split(const Input, Pattern: String): TStringDynArray;
var
  aRegEx: TRegEx;
begin
  aRegEx := TRegEx.Create(Pattern);
  Result := aRegEx.Split(Input);
end;

class function TRegEx.Split(const Input, Pattern: String; Options: TRegExOptions): TStringDynArray;
var
  aRegEx: TRegEx;
begin
  aRegEx := TRegEx.Create(Pattern, Options);
  Result := aRegEx.Split(Input);
end;

end.
