unit InputQueryModule;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TInputQueryForm = class(TForm)
    InputQueryPrompt: TLabel;
    InputQueryEdit: TEdit;
    InputQueryCancelButton: TButton;
    InputQueryOKButton: TButton;
    procedure InputQueryCancelButtonClick(Sender: TObject);
    procedure InputQueryOKButtonClick(Sender: TObject);
  private
    function GetInputValue: string;
    procedure SetEditType(const Value: TEditType);
    procedure SetInputValue(const Value: string);
    procedure SetPrompt(const Value: String);
    { Private declarations }
  public
    { Public declarations }
    property Prompt: String write SetPrompt;
    property EditType: TEditType write SetEditType;
    property InputValue: string read GetInputValue write SetInputValue;
  protected procedure LoadDFMValues; override; end;

var
  InputQueryForm: TInputQueryForm;

implementation

{$R *.dfm}

function TInputQueryForm.GetInputValue: string;
begin
  Result := InputQueryEdit.Text;
end;

procedure TInputQueryForm.InputQueryCancelButtonClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TInputQueryForm.InputQueryOKButtonClick(Sender: TObject);
begin
  ModalResult := mrOK;
end;

procedure TInputQueryForm.SetEditType(const Value: TEditType);
begin
  InputQueryEdit.EditType := Value;
end;

procedure TInputQueryForm.SetInputValue(const Value: string);
begin
  InputQueryEdit.Text := Value;
end;

procedure TInputQueryForm.SetPrompt(const Value: String);
begin
  InputQueryPrompt.Caption := Value;
end;

procedure TInputQueryForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  InputQueryPrompt := TLabel.Create('InputQueryPrompt');
  InputQueryEdit := TEdit.Create('InputQueryEdit');
  InputQueryCancelButton := TButton.Create('InputQueryCancelButton');
  InputQueryOKButton := TButton.Create('InputQueryOKButton');

  InputQueryPrompt.BeforeLoadDFMValues;
  InputQueryEdit.BeforeLoadDFMValues;
  InputQueryCancelButton.BeforeLoadDFMValues;
  InputQueryOKButton.BeforeLoadDFMValues;
  try
    Name := 'InputQueryForm';
    Width := 339;
    Height := 199;
    CSSLibrary := cssBootstrap;
    ElementClassName := 'PopUpForm';
    ElementFont := efCSS;
    InputQueryPrompt.SetParentComponent(Self);
    InputQueryPrompt.Name := 'InputQueryPrompt';
    InputQueryPrompt.Left := 56;
    InputQueryPrompt.Top := 48;
    InputQueryPrompt.Width := 100;
    InputQueryPrompt.Height := 15;
    InputQueryPrompt.Caption := 'InputQueryPrompt';
    InputQueryPrompt.ElementFont := efCSS;
    InputQueryPrompt.HeightStyle := ssAuto;
    InputQueryPrompt.HeightPercent := 100.000000000000000000;
    InputQueryPrompt.WidthPercent := 100.000000000000000000;
    InputQueryEdit.SetParentComponent(Self);
    InputQueryEdit.Name := 'InputQueryEdit';
    InputQueryEdit.Left := 56;
    InputQueryEdit.Top := 80;
    InputQueryEdit.Width := 257;
    InputQueryEdit.Height := 22;
    InputQueryEdit.ChildOrder := 2;
    InputQueryEdit.ElementClassName := 'form-control';
    InputQueryEdit.ElementFont := efCSS;
    InputQueryEdit.HeightStyle := ssAuto;
    InputQueryEdit.HeightPercent := 100.000000000000000000;
    InputQueryEdit.WidthPercent := 100.000000000000000000;
    InputQueryCancelButton.SetParentComponent(Self);
    InputQueryCancelButton.Name := 'InputQueryCancelButton';
    InputQueryCancelButton.AlignWithMargins := True;
    InputQueryCancelButton.Left := 133;
    InputQueryCancelButton.Top := 139;
    InputQueryCancelButton.Width := 96;
    InputQueryCancelButton.Height := 33;
    InputQueryCancelButton.Caption := 'Cancel';
    InputQueryCancelButton.ChildOrder := 1;
    InputQueryCancelButton.ElementClassName := 'btn btn-primary';
    InputQueryCancelButton.ElementFont := efCSS;
    InputQueryCancelButton.HeightStyle := ssAuto;
    InputQueryCancelButton.HeightPercent := 100.000000000000000000;
    InputQueryCancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(InputQueryCancelButton, Self, 'OnClick', 'InputQueryCancelButtonClick');
    InputQueryOKButton.SetParentComponent(Self);
    InputQueryOKButton.Name := 'InputQueryOKButton';
    InputQueryOKButton.AlignWithMargins := True;
    InputQueryOKButton.Left := 235;
    InputQueryOKButton.Top := 139;
    InputQueryOKButton.Width := 96;
    InputQueryOKButton.Height := 33;
    InputQueryOKButton.Caption := 'OK';
    InputQueryOKButton.ChildOrder := 4;
    InputQueryOKButton.ElementClassName := 'btn btn-primary';
    InputQueryOKButton.ElementFont := efCSS;
    InputQueryOKButton.HeightStyle := ssAuto;
    InputQueryOKButton.HeightPercent := 100.000000000000000000;
    InputQueryOKButton.WidthPercent := 100.000000000000000000;
    SetEvent(InputQueryOKButton, Self, 'OnClick', 'InputQueryOKButtonClick');
  finally
    InputQueryPrompt.AfterLoadDFMValues;
    InputQueryEdit.AfterLoadDFMValues;
    InputQueryCancelButton.AfterLoadDFMValues;
    InputQueryOKButton.AfterLoadDFMValues;
  end;
end;

end.