unit Sphinx.OidcClient.AuthResultEntry;

{$I Sphinx.inc}

interface

type
  TAuthResultEntry = class {$IFDEF Pas2JS}external name 'Object'{$ENDIF}
  strict private
    FClientId: string {$IFDEF Pas2JS}external name 'client_id'{$ENDIF};
    FAccessToken: string {$IFDEF Pas2JS}external name 'access_token'{$ENDIF};
    FIdToken: string {$IFDEF Pas2JS}external name 'id_token'{$ENDIF};
    FRefreshToken: string {$IFDEF Pas2JS}external name 'refresh_token'{$ENDIF};
    FTokenType: string {$IFDEF Pas2JS}external name 'token_type'{$ENDIF};
    FExpiresAt: TDateTime {$IFDEF Pas2JS}external name 'expires_at'{$ENDIF};
    FAudience: string {$IFDEF Pas2JS}external name 'audience'{$ENDIF};
    FScope: string {$IFDEF Pas2JS}external name 'scope'{$ENDIF};
    FAppState: string {$IFDEF Pas2JS}external name 'app_state'{$ENDIF};
  public
{$IFDEF Pas2JS}
    constructor new;
{$ENDIF}
    property ClientId: string read FClientId write FClientId;
    property AccessToken: string read FAccessToken write FAccessToken;
    property IdToken: string read FIdToken write FIdToken;
    property RefreshToken: string read FRefreshToken write FRefreshToken;
    property TokenType: string read FTokenType write FTokenType;
    property Scope: string read FScope write FScope;
    property Audience: string read FAudience write FAudience;
    property ExpiresAt: TDateTime read FExpiresAt write FExpiresAt;
    property AppState: string read FAppState write FAppState;
  end;

implementation

end.
