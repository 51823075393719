unit Bcl.Lang;

interface

uses
  SysUtils, Classes, Types,
{$IFDEF PAS2JS}
  JS, Web,
{$ELSE}
  Bcl.Json,
  Bcl.Json.Classes,
  IOUtils,
{$ENDIF}
  Generics.Collections;

type
  ITextLocalizer = interface
  ['{74288F6D-26AE-45C1-AEF4-5C7DABBC1F1F}']
    function Language: string;
    function GetText(const MsgId: string): string;
  end;

  TDictionaryLocalizer = class(TInterfacedObject, ITextLocalizer)
  strict private
    FLanguage: string;
    FResources: TDictionary<string, string>;
  public
    constructor Create(const ALanguage: string);
    destructor Destroy; override;
    function Language: string;
    function GetText(const MsgId: string): string;
    procedure AddText(const MsgId, MsgStr: string);
  end;

  ITextLocalizerFactory = interface
    function GetLocalizer(const LocaleName: string): ITextLocalizer; {$IFDEF PAS2JS}async;{$ENDIF}
  end;

  TLocalizer = class
  strict private class var
//    FDefaultLanguage: string;
    FGlobal: ITextLocalizer;
  strict private
    class function FindLocalizer(Factory: ITextLocalizerFactory;
      const LocaleName: string): ITextLocalizer; {$IFDEF PAS2JS}async;{$ENDIF}
  public
    class constructor Create;
    class destructor Destroy;
    class function Global: ITextLocalizer;
    class function CurrentThread: ITextLocalizer;
    class function Current: ITextLocalizer;

    class procedure SetGlobalLanguage(const Language: string); {$IFDEF PAS2JS}async;{$ENDIF}
    class procedure SetCurrentThreadLanguage(const Language: string); {$IFDEF PAS2JS}async;{$ENDIF}
    class procedure FinalizeCurrentThreadLanguage;
  end;

  TLang = class
  class var
    FLocalizerFactory: ITextLocalizerFactory;
  protected
    class function LocalizerFactory: ITextLocalizerFactory;
  public
    class procedure Init;
    class procedure SetLocalizerFactory(ALocalizerFactory: ITextLocalizerFactory);
  end;

{$IFDEF PAS2JS}
  TProc = reference to procedure;

  TJsonTextLocalizer = class(TInterfacedObject, ITextLocalizer)
  strict private
    FLanguage: string;
    FObj: TJSObject;
  public
    constructor Create(const ALanguage: string; AObj: TJSObject);
    function Language: string;
    function GetText(const MsgId: string): string;
  end;

  TXhrLocalizerFactory = class(TInterfacedObject, ITextLocalizerFactory)
  strict private
    FObj: TJSObject;
    function LoadJsonFromUrl(const Url: string): TJSObject; {$IFDEF PAS2JS}async;{$ENDIF}
    function LoadJsonResources(const Language: string): TJSObject; {$IFDEF PAS2JS}async;{$ENDIF}
    function GetLocalizer(const LocaleName: string): ITextLocalizer; {$IFDEF PAS2JS}async;{$ENDIF}
  public
    constructor Create;
  end;
{$ENDIF}

{$IFNDEF PAS2JS}
  TDefaultLocalizerFactory = class(TInterfacedObject, ITextLocalizerFactory)
  strict private
    FLocalizers: TDictionary<string, ITextLocalizer>;
    function GetLocalizer(const LocaleName: string): ITextLocalizer;
    procedure AddLanguageFromJson(const LanguageName: string; Data: TJObject);
    procedure LoadFromFolder(const Folder: string);
    procedure LoadFromResource(const ResourceName: string);
  public
    constructor Create(const LangResource: string = ''; LangFolder: string = '');
    destructor Destroy; override;
  end;
{$ENDIF}

{$IFDEF PAS2JS}
procedure Translate(Element: TJSElement);
{$ELSE}
procedure Translate(Element: TObject);
{$ENDIF}
function GetText(const MsgId: string): string;
function _(const MsgId: string): string;

{$IFDEF DEBUG}
{$IFDEF PAS2JS}
var
  Texts: TJSObject;
{$ENDIF}
{$ENDIF}

type
  TLanguageTag = record
    Code: string;
    Description: string;
  end;

const
  LanguageTags: array[0..131] of TLanguageTag = (
    (Code: 'af-ZA'; Description: 'Afrikaans - South Africa'),
    (Code: 'sq-AL'; Description: 'Albanian - Albania'),
    (Code: 'ar-DZ'; Description: 'Arabic - Algeria'),
    (Code: 'ar-BH'; Description: 'Arabic - Bahrain'),
    (Code: 'ar-EG'; Description: 'Arabic - Egypt'),
    (Code: 'ar-IQ'; Description: 'Arabic - Iraq'),
    (Code: 'ar-JO'; Description: 'Arabic - Jordan'),
    (Code: 'ar-KW'; Description: 'Arabic - Kuwait'),
    (Code: 'ar-LB'; Description: 'Arabic - Lebanon'),
    (Code: 'ar-LY'; Description: 'Arabic - Libya'),
    (Code: 'ar-MA'; Description: 'Arabic - Morocco'),
    (Code: 'ar-OM'; Description: 'Arabic - Oman'),
    (Code: 'ar-QA'; Description: 'Arabic - Qatar'),
    (Code: 'ar-SA'; Description: 'Arabic - Saudi Arabia'),
    (Code: 'ar-SY'; Description: 'Arabic - Syria'),
    (Code: 'ar-TN'; Description: 'Arabic - Tunisia'),
    (Code: 'ar-AE'; Description: 'Arabic - United Arab Emirates'),
    (Code: 'ar-YE'; Description: 'Arabic - Yemen'),
    (Code: 'hy-AM'; Description: 'Armenian - Armenia'),
    (Code: 'eu-ES'; Description: 'Basque - Basque'),
    (Code: 'be-BY'; Description: 'Belarusian - Belarus'),
    (Code: 'bg-BG'; Description: 'Bulgarian - Bulgaria'),
    (Code: 'ca-ES'; Description: 'Catalan - Catalan'),
    (Code: 'zh-CN'; Description: 'Chinese - China'),
    (Code: 'zh-HK'; Description: 'Chinese - Hong Kong SAR'),
    (Code: 'zh-MO'; Description: 'Chinese - Macau SAR'),
    (Code: 'zh-SG'; Description: 'Chinese - Singapore'),
    (Code: 'zh-TW'; Description: 'Chinese - Taiwan'),
    (Code: 'hr-HR'; Description: 'Croatian - Croatia'),
    (Code: 'cs-CZ'; Description: 'Czech - Czech Republic'),
    (Code: 'da-DK'; Description: 'Danish - Denmark'),
    (Code: 'div-M'; Description: 'Dhivehi - Maldives'),
    (Code: 'nl-BE'; Description: 'Dutch - Belgium'),
    (Code: 'nl-NL'; Description: 'Dutch - The Netherlands'),
    (Code: 'en-AU'; Description: 'English - Australia'),
    (Code: 'en-BZ'; Description: 'English - Belize'),
    (Code: 'en-CA'; Description: 'English - Canada'),
    (Code: 'en-CB'; Description: 'English - Caribbean'),
    (Code: 'en-IE'; Description: 'English - Ireland'),
    (Code: 'en-JM'; Description: 'English - Jamaica'),
    (Code: 'en-NZ'; Description: 'English - New Zealand'),
    (Code: 'en-PH'; Description: 'English - Philippines'),
    (Code: 'en-ZA'; Description: 'English - South Africa'),
    (Code: 'en-TT'; Description: 'English - Trinidad and Tobago'),
    (Code: 'en-GB'; Description: 'English - United Kingdom'),
    (Code: 'en-US'; Description: 'English - United States'),
    (Code: 'en-ZW'; Description: 'English - Zimbabwe'),
    (Code: 'et-EE'; Description: 'Estonian - Estonia'),
    (Code: 'fo-FO'; Description: 'Faroese - Faroe Islands'),
    (Code: 'fa-IR'; Description: 'Farsi - Iran'),
    (Code: 'fi-FI'; Description: 'Finnish - Finland'),
    (Code: 'fr-BE'; Description: 'French - Belgium'),
    (Code: 'fr-CA'; Description: 'French - Canada'),
    (Code: 'fr-FR'; Description: 'French - France'),
    (Code: 'fr-LU'; Description: 'French - Luxembourg'),
    (Code: 'fr-MC'; Description: 'French - Monaco'),
    (Code: 'fr-CH'; Description: 'French - Switzerland'),
    (Code: 'gl-ES'; Description: 'Galician - Galician'),
    (Code: 'ka-GE'; Description: 'Georgian - Georgia'),
    (Code: 'de-AT'; Description: 'German - Austria'),
    (Code: 'de-DE'; Description: 'German - Germany'),
    (Code: 'de-LI'; Description: 'German - Liechtenstein'),
    (Code: 'de-LU'; Description: 'German - Luxembourg'),
    (Code: 'de-CH'; Description: 'German - Switzerland'),
    (Code: 'el-GR'; Description: 'Greek - Greece'),
    (Code: 'gu-IN'; Description: 'Gujarati - India'),
    (Code: 'he-IL'; Description: 'Hebrew - Israel'),
    (Code: 'hi-IN'; Description: 'Hindi - India'),
    (Code: 'hu-HU'; Description: 'Hungarian - Hungary'),
    (Code: 'is-IS'; Description: 'Icelandic - Iceland'),
    (Code: 'id-ID'; Description: 'Indonesian - Indonesia'),
    (Code: 'it-IT'; Description: 'Italian - Italy'),
    (Code: 'it-CH'; Description: 'Italian - Switzerland'),
    (Code: 'ja-JP'; Description: 'Japanese - Japan'),
    (Code: 'kn-IN'; Description: 'Kannada - India'),
    (Code: 'kk-KZ'; Description: 'Kazakh - Kazakhstan'),
    (Code: 'kok-I'; Description: 'Konkani - India'),
    (Code: 'ko-KR'; Description: 'Korean - Korea'),
    (Code: 'ky-KZ'; Description: 'Kyrgyz - Kazakhstan'),
    (Code: 'lv-LV'; Description: 'Latvian - Latvia'),
    (Code: 'lt-LT'; Description: 'Lithuanian - Lithuania'),
    (Code: 'mk-MK'; Description: 'Macedonian (FYROM)'),
    (Code: 'ms-BN'; Description: 'Malay - Brunei'),
    (Code: 'ms-MY'; Description: 'Malay - Malaysia'),
    (Code: 'mr-IN'; Description: 'Marathi - India'),
    (Code: 'mn-MN'; Description: 'Mongolian - Mongolia'),
    (Code: 'nb-NO'; Description: 'Norwegian (Bokmål) - Norway'),
    (Code: 'nn-NO'; Description: 'Norwegian (Nynorsk) - Norway'),
    (Code: 'pl-PL'; Description: 'Polish - Poland'),
    (Code: 'pt-BR'; Description: 'Portuguese - Brazil'),
    (Code: 'pt-PT'; Description: 'Portuguese - Portugal'),
    (Code: 'pa-IN'; Description: 'Punjabi - India'),
    (Code: 'ro-RO'; Description: 'Romanian - Romania'),
    (Code: 'ru-RU'; Description: 'Russian - Russia'),
    (Code: 'sa-IN'; Description: 'Sanskrit - India'),
    (Code: 'Cy-sr-SP'; Description: 'Serbian (Cyrillic) - Serbia'),
    (Code: 'Lt-sr-SP'; Description: 'Serbian (Latin) - Serbia'),
    (Code: 'sk-SK'; Description: 'Slovak - Slovakia'),
    (Code: 'sl-SI'; Description: 'Slovenian - Slovenia'),
    (Code: 'es-AR'; Description: 'Spanish - Argentina'),
    (Code: 'es-BO'; Description: 'Spanish - Bolivia'),
    (Code: 'es-CL'; Description: 'Spanish - Chile'),
    (Code: 'es-CO'; Description: 'Spanish - Colombia'),
    (Code: 'es-CR'; Description: 'Spanish - Costa Rica'),
    (Code: 'es-DO'; Description: 'Spanish - Dominican Republic'),
    (Code: 'es-EC'; Description: 'Spanish - Ecuador'),
    (Code: 'es-SV'; Description: 'Spanish - El Salvador'),
    (Code: 'es-GT'; Description: 'Spanish - Guatemala'),
    (Code: 'es-HN'; Description: 'Spanish - Honduras'),
    (Code: 'es-MX'; Description: 'Spanish - Mexico'),
    (Code: 'es-NI'; Description: 'Spanish - Nicaragua'),
    (Code: 'es-PA'; Description: 'Spanish - Panama'),
    (Code: 'es-PY'; Description: 'Spanish - Paraguay'),
    (Code: 'es-PE'; Description: 'Spanish - Peru'),
    (Code: 'es-PR'; Description: 'Spanish - Puerto Rico'),
    (Code: 'es-ES'; Description: 'Spanish - Spain'),
    (Code: 'es-UY'; Description: 'Spanish - Uruguay'),
    (Code: 'es-VE'; Description: 'Spanish - Venezuela'),
    (Code: 'sw-KE'; Description: 'Swahili - Kenya'),
    (Code: 'sv-FI'; Description: 'Swedish - Finland'),
    (Code: 'sv-SE'; Description: 'Swedish - Sweden'),
    (Code: 'syr-S'; Description: 'Syriac - Syria'),
    (Code: 'ta-IN'; Description: 'Tamil - India'),
    (Code: 'tt-RU'; Description: 'Tatar - Russia'),
    (Code: 'te-IN'; Description: 'Telugu - India'),
    (Code: 'th-TH'; Description: 'Thai - Thailand'),
    (Code: 'tr-TR'; Description: 'Turkish - Turkey'),
    (Code: 'uk-UA'; Description: 'Ukrainian - Ukraine'),
    (Code: 'ur-PK'; Description: 'Urdu - Pakistan'),
    (Code: 'Cy-uz-UZ'; Description: 'Uzbek (Cyrillic) - Uzbekistan'),
    (Code: 'Lt-uz-UZ'; Description: 'Uzbek (Latin) - Uzbekistan'),
    (Code: 'vi-VN'; Description: 'Vietnamese - Vietnam')
  );

implementation

uses
  Bcl.Utils;

{$IFDEF PAS2JS}
var
{$ELSE}
threadvar
{$ENDIF}
  FCurrentThread: ITextLocalizer;

function _(const MsgId: string): string;
begin
  Result := GetText(MsgId);
end;

function GetText(const MsgId: string): string;
var
  Localizer: ITextLocalizer;
begin
  Localizer := TLocalizer.Current;
  if Localizer <> nil then
    Result := Localizer.GetText(MsgId)
  else
    Result := MsgId;

{$IFDEF DEBUG}
{$IFDEF PAS2JS}
  if Texts <> nil then
    Texts[MsgId] := '';
{$ENDIF}
{$ENDIF}
end;

{$IFDEF PAS2JS}
procedure Translate(Element: TJSElement);
const
  cTranslatableClass = 'translatable';
var
  Elements: TJSHtmlCollection;
  Node: TJSNode;
  I: Integer;
begin
  Elements := document.getElementsByClassName(cTranslatableClass);
  for I := 0 to Elements.length - 1 do
  begin
    Node := Elements.item(I);
    Node.textContent := GetText(TJSString(Node.textContent).trim);
  end;
end;
{$ELSE}
procedure Translate(Element: TObject);
begin
end;
{$ENDIF}

{ TTextLocalizer }

procedure TDictionaryLocalizer.AddText(const MsgId, MsgStr: string);
begin
  FResources.AddOrSetValue(Msgid, MsgStr);
end;

constructor TDictionaryLocalizer.Create(const ALanguage: string);
begin
  inherited Create;
  FLanguage := ALanguage;
  FResources := TDictionary<string, string>.Create;
end;

destructor TDictionaryLocalizer.Destroy;
begin
  FResources.Free;
  inherited;
end;

function TDictionaryLocalizer.GetText(const MsgId: string): string;
begin
  if not FResources.TryGetValue(MsgId, Result) then
    Result := MsgId;
end;

function TDictionaryLocalizer.Language: string;
begin
  Result := FLanguage;
end;

{ TLocalizer }

class constructor TLocalizer.Create;
begin
{$IFDEF PAS2JS}
//  if JS.isString(window.navigator.language) then
//    FDefaultLanguage := string(window.navigator.language)
//  else
//    FDefaultLanguage := 'en-us';
{$ELSE}

{$ENDIF}
end;

class function TLocalizer.Current: ITextLocalizer;
begin
  if FCurrentThread <> nil then
    Result := FCurrentThread
  else
    Result := FGlobal;
end;

class function TLocalizer.CurrentThread: ITextLocalizer;
begin
  Result := FCurrentThread;
end;

class destructor TLocalizer.Destroy;
begin
end;

class procedure TLocalizer.FinalizeCurrentThreadLanguage;
begin
  FCurrentThread := nil;
end;

class function TLocalizer.FindLocalizer(Factory: ITextLocalizerFactory;
  const LocaleName: string): ITextLocalizer;
begin
  if Factory = nil then
    Exit(nil);

{$IFDEF PAS2JS}
  Result := await(Factory.GetLocalizer(LocaleName));
  if Result = nil then
    Result := await(Factory.GetLocalizer(Copy(LocaleName, 1, Pos('-', LocaleName) - 1)));
{$ELSE}
  Result := Factory.GetLocalizer(LocaleName);
  if Result = nil then
    Result := Factory.GetLocalizer(Copy(LocaleName, 1, Pos('-', LocaleName) - 1));
{$ENDIF}
end;

class function TLocalizer.Global: ITextLocalizer;
begin
  Result := FGlobal;
end;

class procedure TLocalizer.SetCurrentThreadLanguage(const Language: string);
begin
{$IFDEF PAS2JS}
  FCurrentThread := await(FindLocalizer(TLang.LocalizerFactory, Language));
{$ELSE}
  FCurrentThread := FindLocalizer(TLang.LocalizerFactory, Language);
{$ENDIF}
end;

class procedure TLocalizer.SetGlobalLanguage(const Language: string);
var
  TempGlobal: ITextLocalizer;
begin
{$IFDEF PAS2JS}
  TempGlobal := await(FindLocalizer(TLang.LocalizerFactory, Language));
{$ELSE}
  TempGlobal := FindLocalizer(TLang.LocalizerFactory, Language);
{$ENDIF}
  FGlobal := TempGlobal;
end;

{ TLang }

class procedure TLang.Init;
begin
{$IFDEF PAS2JS}
  SetLocalizerFactory(TXhrLocalizerFactory.Create);
{$ELSE}
  SetLocalizerFactory(TDefaultLocalizerFactory.Create);
{$ENDIF}
end;

class function TLang.LocalizerFactory: ITextLocalizerFactory;
begin
  Result := FLocalizerFactory;
end;

class procedure TLang.SetLocalizerFactory(ALocalizerFactory: ITextLocalizerFactory);
begin
  FLocalizerFactory := ALocalizerFactory;
end;

{ TXhrLocalizerFactory }

{$IFDEF PAS2JS}
constructor TXhrLocalizerFactory.Create;
begin
  inherited Create;
end;

function TXhrLocalizerFactory.GetLocalizer(const LocaleName: string): ITextLocalizer;
begin
  if FObj = nil then
  begin
//    FObj := await(LoadJsonResources('global'));
    if FObj = nil then
      FObj := TJSObject.new;
  end;

  if JS.IsUndefined(FObj[LocaleName]) then
    FObj[LocaleName] := await(LoadJsonResources(LocaleName));

  if JS.IsObject(FObj[LocaleName]) then
    Result := TJsonTextLocalizer.Create(LocaleName, JS.ToObject(FObj[LocaleName]))
  else
    Result := nil;
end;

procedure InternalSendRequest(Xhr: TJSXmlHttpRequest;
  SuccessProc: TProc; ErrorProc: TProc);

  procedure XhrLoad;
  begin
    if Assigned(SuccessProc) then
      SuccessProc();
  end;

  procedure XhrError;
  begin
    if Assigned(ErrorProc) then
      ErrorProc();
  end;

begin
  Xhr.addEventListener('load', @XhrLoad);
  Xhr.addEventListener('error', @XhrError);
  Xhr.addEventListener('timeout', @XhrError);
  Xhr.send;
end;

function TXhrLocalizerFactory.LoadJsonResources(const Language: string): TJSObject;
const
  LangFileUrl = 'languages/%s.json';
var
  RequestUrl: string;
begin
  RequestUrl := Format(LangFileUrl, [Language]);
  Result := await(LoadJsonFromUrl(RequestUrl));
end;

function TXhrLocalizerFactory.LoadJsonFromUrl(const Url: string): TJSObject;
var
  Promise: TJSPromise;
begin
  Promise := TJSPromise.new(
    procedure(resolve, reject: TJSPromiseResolver)
    var
      Xhr: TJSXMLHttpRequest;
    begin
      Xhr := TJSXMLHttpRequest.new;
      Xhr.open('GET', Url, True);
      InternalSendRequest(
        Xhr,
        procedure
        var
          Obj: TJSObject;
        begin
          Obj := nil;
          if Xhr.status = 200 then
          try
            Obj := TJSJson.parseObject(Xhr.responseText);
          except
          end;
          resolve(Obj);
        end,
        procedure
        begin
          resolve(nil);
        end
      );
    end
  );
  Exit(Promise);
end;
{$ENDIF}

{ TJsonTextLocalizer }

{$IFDEF PAS2JS}
constructor TJsonTextLocalizer.Create(const ALanguage: string; AObj: TJSObject);
begin
  inherited Create;
  FLanguage := ALanguage;
  FObj := AObj;
end;

function TJsonTextLocalizer.GetText(const MsgId: string): string;
var
  Value: JSValue;
begin
  Value := FObj[MsgId];
  if JS.IsString(Value) then
    Result := string(Value)
  else
    Result := MsgId;
end;

function TJsonTextLocalizer.Language: string;
begin
  Result := FLanguage;
end;
{$ENDIF}

{ TDefaultLocalizerFactory }

{$IFNDEF PAS2JS}
procedure TDefaultLocalizerFactory.AddLanguageFromJson(const LanguageName: string; Data: TJObject);
var
  Localizer: ITextLocalizer;
  Member: TJMember;
begin
  if not FLocalizers.TryGetValue(LanguageName, Localizer) then
  begin
    Localizer := TDictionaryLocalizer.Create(LanguageName);
    FLocalizers.Add(Localizer.Language, Localizer);
  end;
  for Member in Data do
    if Member.Value.IsString then
      (Localizer as TDictionaryLocalizer).AddText(Member.Name, Member.Value.AsString);
end;

constructor TDefaultLocalizerFactory.Create(const LangResource: string = ''; LangFolder: string = '');
begin
  inherited Create;
  FLocalizers := TDictionary<string, ITextLocalizer>.Create;
  if LangResource <> '' then
    LoadFromResource(LangResource)
  else
    LoadFromResource('BIZ_LANGUAGES');

  if LangFolder <> '' then
    LoadFromFolder(LangFolder)
  else
    LoadFromFolder(TPath.Combine(TPath.GetDirectoryName(ParamStr(0)), 'languages'))
end;

destructor TDefaultLocalizerFactory.Destroy;
begin
  FLocalizers.Free;
  inherited;
end;

function TDefaultLocalizerFactory.GetLocalizer(const LocaleName: string): ITextLocalizer;
begin
  if not FLocalizers.TryGetValue(LocaleName, Result) then
    Result := nil;
end;

procedure TDefaultLocalizerFactory.LoadFromFolder(const Folder: string);
var
  FileName: string;
  Json: string;
  Data: TJObject;
begin
  if not TDirectory.Exists(Folder) then
    Exit;

  for FileName in TDirectory.GetFiles(Folder, '*.json', TSearchOption.soTopDirectoryOnly) do
  begin
    Json := TFile.ReadAllText(FileName, TEncoding.UTF8);
    try
      Data := TJson.Deserialize<TJObject>(Json);
    except
      on E: Exception do
        raise Exception.CreateFmt('Error loading language file %s: %s (%s)',
          [TPath.GetFileName(FileName), E.Message, E.ClassName]);
    end;
    try
      AddLanguageFromJson(TPath.GetFileNameWithoutExtension(FileName), Data);
    finally
      Data.Free;
    end;
  end;
end;
procedure TDefaultLocalizerFactory.LoadFromResource(const ResourceName: string);
var
  Stream: TStream;
  Languages: TJObject;
  Member: TJMember;
begin
  if FindResource(HInstance, PChar(ResourceName), RT_RCDATA) = 0 then Exit;

  Stream := TResourceStream.Create(HInstance, PChar(ResourceName), RT_RCDATA);
  try
    try
      Languages := TJson.Deserialize<TJObject>(Stream);
      try
        for Member in Languages do
          if Member.Value.IsObject then
            AddLanguageFromJson(Member.Name, Member.Value.AsObject);
      finally
        Languages.Free;
      end;
    except
      on E: Exception do
        raise Exception.CreateFmt('Error loading language resource %s (%s)',
          [ResourceName, E.Message, E.ClassName]);
    end;
  finally
    Stream.Free;
  end;
end;
{$ENDIF}

end.
