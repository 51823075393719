unit SMX.ReturnTypes;

interface

uses System.Generics.Collections;

type
  TResponseOutcome = (responseOK, responseUpdated, responseError, responseException, responseFileNotFound);
  TResponseValueType = (rspString, rspInteger, rspFloat, rspBoolean, rspObject);


  TFileResponse = class
    TheFileName: string;
    Data: string;
    Outcome: TResponseOutcome;
  end;

  TStandardReponse = class
  public
    Outcome: TResponseOutcome;
    ValueType: TResponseValueType;
    Value: String;
  end;

  TLocationItem = class
  public
    Name: string;
    Ref: string;
    Status: string;
    constructor Create(const ARef, AName, AStatus: string);
  end;

  TAuditUsers = class
  public
    AddedBy: string;
    UpdatedBy: string;
  end;

  TAuditItem = class
  public
    Id: Integer;
    FirstName: string;
    LastName: string;
    FullName: string;
    ActionDate: TDateTime;
  end;

  TRecordAudit = class
  public
    Added: TAuditItem;
    Modified: TAuditItem;
  end;

  TItemStatus = class
    Status: string;
    StatusMessage: string;
  end;

  TJobUsers = class
  public
    SubmittedBy: string;
    OwnedBy: string;
    CompletedBy: string;
  end;

  TThingsToDo = class
    LinkedId: String;
    LinkType: String;
    Title: string;
    Caption: String;
    ItemStatus: String;
    constructor Create(const ALinkedId: String; const ALinkType, ATitle, ACaption,
        AItemStatus: String);
  end;

  { TODO 1 -oAFP : What goes in this class? Do we need a class? Just return a list of tenant ids instead? }
  TRecordTenant = class
    Id: string;

    constructor Create(AId: string);

  end;

  TBatchData = class
    BatchTotal: double;
    NoOfDonations: integer;
    NoOfErrors: integer;
  end;

  TOtherClaimsData = class
    CurrentClaimAmount: currency;
    MaxClaimLimit: currency;
    TaxYear: integer;
  end;


const
FILE_NOT_FOUND = 'file-not-found';

implementation

{ TRegionItem }

constructor TLocationItem.Create(const ARef, AName, AStatus: string);
begin
  Ref := ARef;
  name := AName;
  Status := AStatus;
end;

{ TThingsToDo }

constructor TThingsToDo.Create(const ALinkedId: String; const ALinkType,
    ATitle, ACaption, AItemStatus: String);
begin
  LinkedId := ALinkedId;
  LinkType := ALinkType;
  Title := ATitle;
  Caption := ACaption;
  ItemStatus := AItemStatus;
end;

constructor TRecordTenant.Create(AId: string);
begin
  inherited Create;

  Id := AId;

end;


end.
