unit Sphinx.OidcClient.Storage;

{$I Sphinx.inc}

interface

uses
{$IFDEF PAS2JS}
  JS, Web;
{$ELSE}
  Generics.Collections;
{$ENDIF}

type
  TClientStorage = class
  strict private const
    DefaultStoragePrefix = 'sphinx.client.';
  strict private
{$IFNDEF PAS2JS}
    FData: TObjectDictionary<string, TObject>;
{$ENDIF}
    FPrefix: string;
    property Prefix: string read FPrefix;
  public
    constructor Create; overload;
    constructor Create(const APrefix: string); overload;
    destructor Destroy; override;
{$IFDEF PAS2JS}
    function Get<T>(const Key: string): T;
    procedure Save<T>(const Key: string; const Value: T);
{$ELSE}
    function Get<T: class>(const Key: string): T;
    procedure Save<T: class>(const Key: string; const Value: T);
{$ENDIF}
    procedure Remove(const Key: string);
  end;

implementation

{ TClientStorage }

constructor TClientStorage.Create(const APrefix: string);
begin
  inherited Create;
  FPrefix := APrefix;
{$IFNDEF PAS2JS}
  FData := TObjectDictionary<string, TObject>.Create([doOwnsValues]);
{$ENDIF}
end;

constructor TClientStorage.Create;
begin
  Create(DefaultStoragePrefix);
end;

destructor TClientStorage.Destroy;
begin
{$IFNDEF PAS2JS}
  FData.Free;
{$ENDIF}
  inherited;
end;

function TClientStorage.Get<T>(const Key: string): T;
{$IFDEF PAS2JS}
var
  Value: JSValue;
begin
  Value := window.localStorage.getItem(Prefix + Key);
  if not JS.isString(Value) then
    Exit(nil);
  try
    Result := T(TJSJSON.parse(string(Value)));
  except
    Result := nil;
  end;
end;
{$ELSE}
var
  Value: TObject;
begin
  if FData.TryGetValue(Prefix + Key, Value) then
    Result := T(Value)
  else
    Result := nil;
end;
{$ENDIF}

procedure TClientStorage.Remove(const Key: string);
begin
{$IFDEF PAS2JS}
  window.localStorage.removeItem(Prefix + Key);
{$ELSE}
  FData.Remove(Prefix + Key);
{$ENDIF}
end;

procedure TClientStorage.Save<T>(const Key: string; const Value: T);
begin
{$IFDEF PAS2JS}
  window.localStorage.setItem(Prefix + Key, TJSJSON.stringify(Value));
{$ELSE}
  FData.AddOrSetValue(Prefix + Key, Value);
{$ENDIF}
end;

end.
