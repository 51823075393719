unit JobDetailBaseForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WebForm.Core,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  Data.DB,
  WEBLib.DB,
  WEBLib.WebCtrls,
  WEBLib.Lists,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.ExtCtrls;

type
  TJobDetailBase = class(TCoreWebForm)
    PlaceJobButton: TButton;
    ErrorMessage: THTMLSpan;
    ErrorsButton: TButton;
    CompleteButton: TButton;
    CloseButton: TButton;
    WebMessageDlg1: TMessageDlg;
    DataSource: TDataSource;
    CompletedBy: TLabel;
    DateCompleted: TLabel;
    OwnedBy: TLabel;
    SubmittedBy: TLabel;
    DateSubmitted: TDBLabel;
    RunLevelLabel: TDBLabel;
    WebDBLabel4: TDBLabel;
    DateRun: TDBLabel;
    JobType: TLabel;
    FormCaption: TLabel;
    DownloadFiles: THTMLDiv;
    FileList: TListControl;
    procedure CloseButtonClick(Sender: TObject);
    procedure CompleteButtonClick(Sender: TObject);
    procedure ErrorsButtonClick(Sender: TObject);
    procedure FileListItemClick(Sender: TObject; AListItem: TListItem);
    procedure PlaceJobButtonClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    procedure MarkCompleted;
    procedure SetDataset(const Value: TDataset);
    [async]
    procedure GetUserDetails; async;
    procedure LoadErrorMessage;
    [async]
    procedure LoadFileList(AData: JS.TJSObject); async;
    [async]
    procedure SendJobForProduction; async;
    [async]
    procedure ShowErrors; async;
  protected
    FCloseModal: TModalResult;
    procedure LoadStats(AData: JS.TJSObject); virtual;

  public
    { Public declarations }
    property Dataset: TDataset write SetDataset;
  protected procedure LoadDFMValues; override; end;

var
  JobDetailBase: TJobDetailBase;

implementation

{$R *.dfm}

uses
  Auth.Service,
  MainDataModule,
  XData.Web.Client,
  MainForm,
  SMX.Web.Utils,
  SMX.Shared,
  JobErrorForm,
  SMX.Web.Service.Consts;

procedure TJobDetailBase.CloseButtonClick(Sender: TObject);
begin
  ModalResult := FCloseModal;
end;

procedure TJobDetailBase.CompleteButtonClick(Sender: TObject);
begin
  MarkCompleted;
end;

procedure TJobDetailBase.ErrorsButtonClick(Sender: TObject);
begin
  ShowErrors;
end;

procedure TJobDetailBase.FileListItemClick(Sender: TObject; AListItem: TListItem);
var
  lDownloads, AURL, lName, lURL: string;
  lJS, jItem: JS.TJSObject;
  aJS: JS.TJSArray;
  I: Integer;
  lCount, lDownloaded: Integer;
  lCompleteEnabled: Boolean;
begin
  lCompleteEnabled := CompleteButton.Enabled;
  CompleteButton.Enabled := False;

  lDownloads := DataSource.Dataset.FieldByName('Downloads').AsString;

  lJS := JS.TJSJSON.parseObject(lDownloads);

  aJS := JS.toArray(lJS['value']);
  if aJS = nil then
    Exit;
  lURL := '';
  lCount := 0;
  lDownloaded := 0;
  for I := 0 to aJS.Length - 1 do
  begin
    jItem := JS.toObject(aJS[I]);
    lName := JS.toString(jItem['Name']);

    if lName = AListItem.Text then
    begin
      if jItem.hasOwnProperty('DownloadCount') then
        lCount := JS.toInteger(jItem['DownloadCount'])
      else
        lCount := 0;
      jItem['DownloadCount'] := lCount + 1;
      jItem['LastDownloadedBy'] := AuthService.UserId;
      lURL := 'store/' + JS.toString(jItem['File']);
      lDownloaded := lDownloaded + 1;
    end
    else
    begin
      if jItem.hasOwnProperty('DownloadCount') and (JS.toInteger(jItem['DownloadCount']) > 0) then
        lDownloaded := lDownloaded + 1;
    end;
  end;

  if lURL <> '' then
  begin
    DataSource.Dataset.Edit;
    DataSource.Dataset.FieldByName('Downloads').AsString := JS.TJSJSON.stringify(lJS);
    if lDownloaded = aJS.Length then
    begin
      CompleteButton.Enabled := lCompleteEnabled;
      MarkCompleted;
    end
    else
    begin
      DataSource.Dataset.Post;
      CompleteButton.Enabled := lCompleteEnabled;
    end;
    { TODO : This isn't going to work on MultiTenant, is it? Need to Use JobsService.GetFile}
    Application.Navigate(lURL);
  end;
end;

procedure TJobDetailBase.GetUserDetails;
var
  lRetval: TXDataClientResponse;
  lResult: TJSObject;
  lS, lO, lC: Integer;
begin

  lS := DataSource.Dataset.FieldByName('SubmittedBy').AsInteger;
  lO := DataSource.Dataset.FieldByName('OwnedBy').AsInteger;
  lC := DataSource.Dataset.FieldByName('CompletedBy').AsInteger;

{$IFDEF STANDALONE}
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IJOBSVC_USERS, [lS, lO, lC]));
{$ELSE}
  { TODO : Needs to call to Sphinx (via server or direct?) }
  lRetval := nil;
{$ENDIF}
  if not Assigned(lRetval) then
    Exit;

  lResult := lRetval.ResultAsObject;

  SubmittedBy.Caption := JS.toString(lResult['SubmittedBy']);
  OwnedBy.Caption := JS.toString(lResult['OwnedBy']);

  if lC > 0 then
    CompletedBy.Caption := JS.toString(lResult['CompletedBy']);

end;

procedure TJobDetailBase.LoadErrorMessage;
var
  lJS: JS.TJSObject;
begin
  lJS := JS.TJSJSON.parseObject(DataSource.Dataset.FieldByName('Data').AsString);
  ErrorMessage.HTML.Text := JS.toString(lJS['Exception']);
  ErrorMessage.Visible := True;
end;

procedure TJobDetailBase.LoadFileList(AData: JS.TJSObject);
var
  lDownloads, AURL, lName: string;
  jItem: JS.TJSObject;
  aJS: JS.TJSArray;
  I: Integer;
  lItem: TListItem;
begin

  lDownloads := DataSource.Dataset.FieldByName('Downloads').AsString;
  if lDownloads = '' then
  begin
    DownloadFiles.Visible := False;
    Exit;
  end;

  aJS := JS.toArray(AData['value']);
  if (aJS = nil) or (aJS.Length = 0) then
  begin
    DownloadFiles.Visible := False;
    Exit;
  end;

  for I := 0 to aJS.Length - 1 do
  begin
    jItem := JS.toObject(aJS[I]);
    // AURL := 'store/' + JS.toString(jItem['File']);
    lName := JS.toString(jItem['Name']);

    lItem := FileList.Items.Add;
    // lItem.AddLink(AURL, '_blank');
    lItem.Text := lName;
  end;
end;

procedure TJobDetailBase.LoadStats(AData: JS.TJSObject);
begin

end;

procedure TJobDetailBase.MarkCompleted;
begin
  if DataSource.Dataset.FieldByName('Status').AsString = 'Complete' then
    Exit;

  DataSource.Dataset.DisableControls;
  try
    if not(DataSource.State in dsEditModes) then
      DataSource.Dataset.Edit;
    DataSource.Dataset.FieldByName('Status').AsString := 'Complete';
    DataSource.Dataset.FieldByName('CompletedBy').AsInteger := AuthService.UserId;
    DataSource.Dataset.FieldByName('DateCompleted').AsDateTime := Now;
    DataSource.Dataset.Post;

    CompletedBy.Caption := AuthService.UserFullName;
    DateCompleted.Caption := FormatDateTime('dd-mmm-yyyy, hh:nn', Now);
    CompleteButton.Visible := False;
  finally
    DataSource.Dataset.EnableControls;
  end;
  FCloseModal := mrOK;
end;

procedure TJobDetailBase.PlaceJobButtonClick(Sender: TObject);
begin
  SendJobForProduction;
end;

procedure TJobDetailBase.SendJobForProduction;
var
  lParams, lJobClass, lJobVisibility, lOutput: string;
  lRetval: TXDataClientResponse;
begin
  lParams := DataSource.Dataset.FieldByName('Data').AsString;
  lJobClass := DataSource.Dataset.FieldByName('JobType').AsString;
  lJobVisibility := DataSource.Dataset.FieldByName('Visibility').AsString;
  lOutput := DataSource.Dataset.FieldByName('OutputOption').AsString;

  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IJOBSVC_SCHEDULEJOB,
    [lJobClass, lJobVisibility, lParams, lOutput, 'Production']));

  ShowAToast(DataSource.Dataset.FieldByName('Title').AsString, 'Your job has been sent for production', 'clock.png');

  PlaceJobButton.Visible := False;

  MarkCompleted;
end;

procedure TJobDetailBase.SetDataset(const Value: TDataset);
var
  lStatus, S: string;
  lJS: JS.TJSObject;
begin
  DataSource.Dataset := Value;
  FormCaption.Caption := Value.FieldByName('Id').AsString + ': ' + Value.FieldByName('Title').AsString;

  JobType.Caption := TUtils.SplitOnCaps(DataSource.Dataset.FieldByName('JobType').AsString.Substring(1));

  lStatus := DataSource.Dataset.FieldByName('Status').AsString;

  CompleteButton.Visible := (lStatus = 'Processed') or (lStatus = 'Preview');
  CompleteButton.Enabled := CompleteButton.Visible;

  if lStatus = 'Complete' then
    DateCompleted.Caption := FormatDateTime('dd-mmm-yyyy, hh:nn', Value.FieldByName('DateCompleted').AsDateTime);

  if (lStatus = 'Failed') then
  begin
    DownloadFiles.Visible := False;
    lJS := JS.TJSJSON.parseObject(DataSource.Dataset.FieldByName('Data').AsString);
    LoadErrorMessage;
    LoadStats(lJS);
  end
  else
  begin
    PlaceJobButton.Visible := lStatus = 'Preview';

    if lStatus <> 'Cancelled' then
    begin
      lJS := JS.TJSJSON.parseObject(DataSource.Dataset.FieldByName('Downloads').AsString);
      LoadFileList(lJS);
    end;

    lJS := JS.TJSJSON.parseObject(DataSource.Dataset.FieldByName('Stats').AsString);
    LoadStats(lJS);
  end;

  ErrorsButton.Visible := False; // Value.FieldByName('HasErrors').AsBoolean;

  try
    GetUserDetails;
  except
  end;

end;

procedure TJobDetailBase.ShowErrors;
var
  AForm: TJobErrors;
begin
  AForm := TJobErrors.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TJobErrors, AForm.Load());
    AForm.Dataset := DataSource.Dataset;
    await(TModalResult, AForm.Execute);
  finally
    AForm.Free;
    AForm := nil;
  end;

end;

procedure TJobDetailBase.WebFormCreate(Sender: TObject);
begin
  inherited;
  SubmittedBy.Caption := ' ';
  OwnedBy.Caption := ' ';
  CompletedBy.Caption := ' ';
  DateCompleted.Caption := ' ';
  FCloseModal := mrClose;
end;

procedure TJobDetailBase.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CompletedBy := TLabel.Create('CompletedBy');
  DateCompleted := TLabel.Create('DateCompleted');
  OwnedBy := TLabel.Create('OwnedBy');
  SubmittedBy := TLabel.Create('SubmittedBy');
  DateSubmitted := TDBLabel.Create('DateSubmitted');
  RunLevelLabel := TDBLabel.Create('RunLevel');
  WebDBLabel4 := TDBLabel.Create('JobStatus');
  DateRun := TDBLabel.Create('DateRun');
  JobType := TLabel.Create('JobType');
  FormCaption := TLabel.Create('FormCaption');
  PlaceJobButton := TButton.Create('PlaceJobButton');
  ErrorMessage := THTMLSpan.Create('JobErrorMessage');
  ErrorsButton := TButton.Create('ErrorsButton');
  CompleteButton := TButton.Create('CompleteButton');
  CloseButton := TButton.Create('CloseButton');
  WebMessageDlg1 := TMessageDlg.Create(Self);
  DownloadFiles := THTMLDiv.Create('DownloadFiles');
  FileList := TListControl.Create('FileList');
  DataSource := TDataSource.Create(Self);

  CompletedBy.BeforeLoadDFMValues;
  DateCompleted.BeforeLoadDFMValues;
  OwnedBy.BeforeLoadDFMValues;
  SubmittedBy.BeforeLoadDFMValues;
  DateSubmitted.BeforeLoadDFMValues;
  RunLevelLabel.BeforeLoadDFMValues;
  WebDBLabel4.BeforeLoadDFMValues;
  DateRun.BeforeLoadDFMValues;
  JobType.BeforeLoadDFMValues;
  FormCaption.BeforeLoadDFMValues;
  PlaceJobButton.BeforeLoadDFMValues;
  ErrorMessage.BeforeLoadDFMValues;
  ErrorsButton.BeforeLoadDFMValues;
  CompleteButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  WebMessageDlg1.BeforeLoadDFMValues;
  DownloadFiles.BeforeLoadDFMValues;
  FileList.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    CompletedBy.SetParentComponent(Self);
    CompletedBy.Name := 'CompletedBy';
    CompletedBy.Left := 176;
    CompletedBy.Top := 162;
    CompletedBy.Width := 72;
    CompletedBy.Height := 15;
    CompletedBy.Caption := 'CompletedBy';
    CompletedBy.ElementFont := efCSS;
    CompletedBy.ElementPosition := epIgnore;
    CompletedBy.HeightStyle := ssAuto;
    CompletedBy.HeightPercent := 100.000000000000000000;
    CompletedBy.HTMLType := tSPAN;
    CompletedBy.WidthPercent := 100.000000000000000000;
    DateCompleted.SetParentComponent(Self);
    DateCompleted.Name := 'DateCompleted';
    DateCompleted.Left := 91;
    DateCompleted.Top := 162;
    DateCompleted.Width := 83;
    DateCompleted.Height := 15;
    DateCompleted.Caption := 'DateCompleted';
    DateCompleted.ElementFont := efCSS;
    DateCompleted.ElementPosition := epIgnore;
    DateCompleted.HeightStyle := ssAuto;
    DateCompleted.HeightPercent := 100.000000000000000000;
    DateCompleted.HTMLType := tSPAN;
    DateCompleted.WidthStyle := ssAuto;
    DateCompleted.WidthPercent := 100.000000000000000000;
    OwnedBy.SetParentComponent(Self);
    OwnedBy.Name := 'OwnedBy';
    OwnedBy.Left := 224;
    OwnedBy.Top := 91;
    OwnedBy.Width := 51;
    OwnedBy.Height := 15;
    OwnedBy.Caption := 'OwnedBy';
    OwnedBy.ElementFont := efCSS;
    OwnedBy.ElementPosition := epIgnore;
    OwnedBy.HeightStyle := ssAuto;
    OwnedBy.HeightPercent := 100.000000000000000000;
    OwnedBy.HTMLType := tSPAN;
    OwnedBy.WidthPercent := 100.000000000000000000;
    SubmittedBy.SetParentComponent(Self);
    SubmittedBy.Name := 'SubmittedBy';
    SubmittedBy.Left := 224;
    SubmittedBy.Top := 72;
    SubmittedBy.Width := 68;
    SubmittedBy.Height := 15;
    SubmittedBy.Caption := 'SubmittedBy';
    SubmittedBy.ElementFont := efCSS;
    SubmittedBy.HeightStyle := ssAuto;
    SubmittedBy.HeightPercent := 100.000000000000000000;
    SubmittedBy.HTMLType := tSPAN;
    SubmittedBy.WidthPercent := 100.000000000000000000;
    DateSubmitted.SetParentComponent(Self);
    DateSubmitted.Name := 'DateSubmitted';
    DateSubmitted.Left := 96;
    DateSubmitted.Top := 72;
    DateSubmitted.Width := 79;
    DateSubmitted.Height := 15;
    DateSubmitted.Caption := 'DateSubmitted';
    DateSubmitted.ElementFont := efCSS;
    DateSubmitted.ElementPosition := epIgnore;
    DateSubmitted.HeightStyle := ssAuto;
    DateSubmitted.HeightPercent := 100.000000000000000000;
    DateSubmitted.HTMLType := tSPAN;
    DateSubmitted.WidthPercent := 100.000000000000000000;
    DateSubmitted.DataField := 'DateSubmitted';
    DateSubmitted.DataSource := DataSource;
    RunLevelLabel.SetParentComponent(Self);
    RunLevelLabel.Name := 'RunLevelLabel';
    RunLevelLabel.Left := 336;
    RunLevelLabel.Top := 46;
    RunLevelLabel.Width := 48;
    RunLevelLabel.Height := 15;
    RunLevelLabel.Caption := 'RunLevel';
    RunLevelLabel.ElementFont := efCSS;
    RunLevelLabel.ElementPosition := epIgnore;
    RunLevelLabel.HeightStyle := ssAuto;
    RunLevelLabel.HeightPercent := 100.000000000000000000;
    RunLevelLabel.HTMLType := tSPAN;
    RunLevelLabel.WidthPercent := 100.000000000000000000;
    RunLevelLabel.DataField := 'RunLevel';
    RunLevelLabel.DataSource := DataSource;
    WebDBLabel4.SetParentComponent(Self);
    WebDBLabel4.Name := 'WebDBLabel4';
    WebDBLabel4.Left := 248;
    WebDBLabel4.Top := 46;
    WebDBLabel4.Width := 50;
    WebDBLabel4.Height := 15;
    WebDBLabel4.Caption := 'JobStatus';
    WebDBLabel4.ElementFont := efCSS;
    WebDBLabel4.ElementPosition := epIgnore;
    WebDBLabel4.HeightStyle := ssAuto;
    WebDBLabel4.HeightPercent := 100.000000000000000000;
    WebDBLabel4.HTMLType := tSPAN;
    WebDBLabel4.WidthPercent := 100.000000000000000000;
    WebDBLabel4.DataField := 'Status';
    WebDBLabel4.DataSource := DataSource;
    DateRun.SetParentComponent(Self);
    DateRun.Name := 'DateRun';
    DateRun.Left := 176;
    DateRun.Top := 46;
    DateRun.Width := 45;
    DateRun.Height := 15;
    DateRun.Caption := 'DateRun';
    DateRun.ElementFont := efCSS;
    DateRun.ElementPosition := epIgnore;
    DateRun.HeightStyle := ssAuto;
    DateRun.HeightPercent := 100.000000000000000000;
    DateRun.HTMLType := tSPAN;
    DateRun.WidthPercent := 100.000000000000000000;
    DateRun.DataField := 'DateRun';
    DateRun.DataSource := DataSource;
    JobType.SetParentComponent(Self);
    JobType.Name := 'JobType';
    JobType.Left := 88;
    JobType.Top := 48;
    JobType.Width := 42;
    JobType.Height := 15;
    JobType.Caption := 'JobType';
    JobType.ElementFont := efCSS;
    JobType.HeightStyle := ssAuto;
    JobType.HeightPercent := 100.000000000000000000;
    JobType.HTMLType := tSPAN;
    JobType.WidthPercent := 100.000000000000000000;
    FormCaption.SetParentComponent(Self);
    FormCaption.Name := 'FormCaption';
    FormCaption.Left := 48;
    FormCaption.Top := 8;
    FormCaption.Width := 73;
    FormCaption.Height := 15;
    FormCaption.Caption := 'Form Caption';
    FormCaption.ElementClassName := 'HeadlineClass';
    FormCaption.ElementFont := efCSS;
    FormCaption.ElementPosition := epIgnore;
    FormCaption.HeightStyle := ssAuto;
    FormCaption.HeightPercent := 100.000000000000000000;
    FormCaption.HTMLType := tDIV;
    FormCaption.WidthStyle := ssAuto;
    FormCaption.WidthPercent := 100.000000000000000000;
    PlaceJobButton.SetParentComponent(Self);
    PlaceJobButton.Name := 'PlaceJobButton';
    PlaceJobButton.Left := 432;
    PlaceJobButton.Top := 256;
    PlaceJobButton.Width := 96;
    PlaceJobButton.Height := 25;
    PlaceJobButton.Caption := 'Place Job';
    PlaceJobButton.ChildOrder := 17;
    PlaceJobButton.ElementClassName := 'btn btn-light';
    PlaceJobButton.ElementFont := efCSS;
    PlaceJobButton.ElementPosition := epIgnore;
    PlaceJobButton.HeightStyle := ssAuto;
    PlaceJobButton.HeightPercent := 100.000000000000000000;
    PlaceJobButton.Visible := False;
    PlaceJobButton.WidthStyle := ssAuto;
    PlaceJobButton.WidthPercent := 100.000000000000000000;
    SetEvent(PlaceJobButton, Self, 'OnClick', 'PlaceJobButtonClick');
    ErrorMessage.SetParentComponent(Self);
    ErrorMessage.Name := 'ErrorMessage';
    ErrorMessage.Left := 96;
    ErrorMessage.Top := 272;
    ErrorMessage.Width := 100;
    ErrorMessage.Height := 41;
    ErrorMessage.HeightStyle := ssAuto;
    ErrorMessage.WidthStyle := ssAuto;
    ErrorMessage.ChildOrder := 13;
    ErrorMessage.ElementPosition := epIgnore;
    ErrorMessage.ElementFont := efCSS;
    ErrorMessage.Role := '';
    ErrorMessage.Visible := False;
    ErrorsButton.SetParentComponent(Self);
    ErrorsButton.Name := 'ErrorsButton';
    ErrorsButton.Left := 304;
    ErrorsButton.Top := 256;
    ErrorsButton.Width := 96;
    ErrorsButton.Height := 25;
    ErrorsButton.Caption := 'Errors';
    ErrorsButton.ChildOrder := 15;
    ErrorsButton.ElementClassName := 'btn btn-warning';
    ErrorsButton.ElementFont := efCSS;
    ErrorsButton.ElementPosition := epIgnore;
    ErrorsButton.HeightStyle := ssAuto;
    ErrorsButton.HeightPercent := 100.000000000000000000;
    ErrorsButton.WidthStyle := ssAuto;
    ErrorsButton.WidthPercent := 100.000000000000000000;
    SetEvent(ErrorsButton, Self, 'OnClick', 'ErrorsButtonClick');
    CompleteButton.SetParentComponent(Self);
    CompleteButton.Name := 'CompleteButton';
    CompleteButton.Left := 176;
    CompleteButton.Top := 256;
    CompleteButton.Width := 96;
    CompleteButton.Height := 25;
    CompleteButton.Caption := 'Completed!';
    CompleteButton.ChildOrder := 10;
    CompleteButton.ElementClassName := 'btn btn-primary';
    CompleteButton.ElementFont := efCSS;
    CompleteButton.ElementPosition := epIgnore;
    CompleteButton.Enabled := False;
    CompleteButton.HeightStyle := ssAuto;
    CompleteButton.HeightPercent := 100.000000000000000000;
    CompleteButton.WidthStyle := ssAuto;
    CompleteButton.WidthPercent := 100.000000000000000000;
    SetEvent(CompleteButton, Self, 'OnClick', 'CompleteButtonClick');
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.Left := 48;
    CloseButton.Top := 256;
    CloseButton.Width := 96;
    CloseButton.Height := 25;
    CloseButton.Caption := 'Close';
    CloseButton.ChildOrder := 9;
    CloseButton.ElementClassName := 'btn btn-secondary';
    CloseButton.ElementFont := efCSS;
    CloseButton.ElementPosition := epIgnore;
    CloseButton.HeightStyle := ssAuto;
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.WidthStyle := ssAuto;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    WebMessageDlg1.SetParentComponent(Self);
    WebMessageDlg1.Name := 'WebMessageDlg1';
    WebMessageDlg1.Left := 480;
    WebMessageDlg1.Top := 176;
    WebMessageDlg1.Width := 24;
    WebMessageDlg1.Height := 24;
    WebMessageDlg1.HeightStyle := ssAuto;
    WebMessageDlg1.WidthStyle := ssAuto;
    WebMessageDlg1.Buttons := [];
    WebMessageDlg1.Opacity := 0.200000000000000000;
    WebMessageDlg1.ElementButtonClassName := 'btn';
    WebMessageDlg1.ElementDialogClassName := 'shadow-lg p-3 mb-5 bg-white rounded';
    WebMessageDlg1.ElementTitleClassName := 'text-body';
    WebMessageDlg1.ElementContentClassName := 'text-body';
    DownloadFiles.SetParentComponent(Self);
    DownloadFiles.Name := 'DownloadFiles';
    DownloadFiles.Left := 48;
    DownloadFiles.Top := 344;
    DownloadFiles.Width := 227;
    DownloadFiles.Height := 113;
    DownloadFiles.HeightStyle := ssAuto;
    DownloadFiles.WidthStyle := ssAuto;
    DownloadFiles.ChildOrder := 19;
    DownloadFiles.ElementPosition := epIgnore;
    DownloadFiles.ElementFont := efCSS;
    DownloadFiles.Role := '';
    FileList.SetParentComponent(DownloadFiles);
    FileList.Name := 'FileList';
    FileList.Left := 44;
    FileList.Top := 29;
    FileList.Width := 129;
    FileList.Height := 60;
    FileList.Cursor := crHandPoint;
    FileList.HeightStyle := ssAuto;
    FileList.HeightPercent := 100.000000000000000000;
    FileList.WidthPercent := 100.000000000000000000;
    FileList.ChildOrder := 16;
    FileList.DefaultItemClassName := 'list-group-item';
    FileList.DefaultItemLinkClassName := 'list-group-link';
    FileList.ElementFont := efCSS;
    FileList.ElementPosition := epIgnore;
    FileList.ElementListClassName := 'list-group';
    FileList.Items.Clear;
    with FileList.Items.Add do
    begin
      ItemClassName := 'list-group-item';
      LinkClassName := 'list-group-link';
      Text := 'Item 0';
    end;
    with FileList.Items.Add do
    begin
      ItemClassName := 'list-group-item';
      LinkClassName := 'list-group-link';
      Text := 'Item 1';
    end;
    with FileList.Items.Add do
    begin
      ItemClassName := 'list-group-item';
      LinkClassName := 'list-group-link';
      Text := 'Item 2';
    end;
    FileList.Style := lsListGroup;
    SetEvent(FileList, Self, 'OnItemClick', 'FileListItemClick');
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.Left := 488;
    DataSource.Top := 112;
  finally
    CompletedBy.AfterLoadDFMValues;
    DateCompleted.AfterLoadDFMValues;
    OwnedBy.AfterLoadDFMValues;
    SubmittedBy.AfterLoadDFMValues;
    DateSubmitted.AfterLoadDFMValues;
    RunLevelLabel.AfterLoadDFMValues;
    WebDBLabel4.AfterLoadDFMValues;
    DateRun.AfterLoadDFMValues;
    JobType.AfterLoadDFMValues;
    FormCaption.AfterLoadDFMValues;
    PlaceJobButton.AfterLoadDFMValues;
    ErrorMessage.AfterLoadDFMValues;
    ErrorsButton.AfterLoadDFMValues;
    CompleteButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    WebMessageDlg1.AfterLoadDFMValues;
    DownloadFiles.AfterLoadDFMValues;
    FileList.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
  end;
end;

end.
