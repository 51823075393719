unit RegisterThankYou;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs;

type
  TThankYouForm = class(TForm)
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  ThankYouForm: TThankYouForm;

implementation

{$R *.dfm}

procedure TThankYouForm.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'ThankYouForm';
    Width := 640;
    Height := 480;
    ElementClassName := 'SMXWebRegister';
  finally
  end;
end;

end.
