unit OtherClaimsGASDSList;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, BaseList, WEBLib.ExtCtrls, WEBLib.Actions,
  Data.DB, WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.Grids, WEBLib.DBCtrls, Vcl.Controls, WEBLib.Lists,
  smx.webcore.types, OtherClaimsGASDSEdit;

type
  TOtherClaimsGASDSListForm = class(TBaseListForm)
    ConnectedCharityDataSet: TXDataWebDataSet;
    RefreshTimer: TTimer;
    MainDatasetId: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetExternalId: TStringField;
    MainDatasetTransactionDate: TDateField;
    MainDatasetImportId: TIntegerField;
    MainDatasetClaimId: TIntegerField;
    MainDatasetGiftAid: TFloatField;
    MainDatasetAmount: TFloatField;
    MainDatasetClaimStatus: TStringField;
    MainDatasetClaimDate: TDateTimeField;
    MainDatasetTaxYear: TIntegerField;
    MainDatasetErrorText: TStringField;
    MainDatasetCharityTenant: TStringField;
    MainDatasetSourceType: TStringField;
    MainDatasetComment: TStringField;
    MainDatasetConnectedCharityId: TIntegerField;
    MainDatasetCharityName: TStringField;
    RefreshCharity: TTimer;
    ConnectedCharityDataSetId: TIntegerField;
    ConnectedCharityDataSetDateAdded: TDateTimeField;
    ConnectedCharityDataSetAddedBy: TIntegerField;
    ConnectedCharityDataSetLastUpdatedBy: TIntegerField;
    ConnectedCharityDataSetLastUpdated: TDateTimeField;
    ConnectedCharityDataSetExternalId: TStringField;
    ConnectedCharityDataSetCharityTenant: TStringField;
    ConnectedCharityDataSetHMRCRef: TStringField;
    ConnectedCharityDataSetRegulatorNumber: TStringField;
    ConnectedCharityDataSetCharityName: TStringField;
    ConnectedCharityDataSetGAStatus: TStringField;

    procedure WebFormDestroy(Sender: TObject);
    procedure AddButtonClick(Sender: TObject);
    procedure ConnectedCharityDataSetAfterApplyUpdates(Sender: TDataSet; Info:
        TResolveResults);
    procedure ConnectedCharityDataSetAfterPost(DataSet: TDataSet);
    procedure DataTableGetCellChildren(Sender: TObject; ACol, ARow: Integer;
        AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure MainDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure MainDatasetCalcFields(DataSet: TDataSet);
    procedure RefreshCharityTimer(Sender: TObject);
    procedure RefreshTimerTimer(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
    fOtherClaimsType: string;
    FGASDSEditForm: TOtherClaimsGASDSEditForm;

    [async]
    procedure EditForm(RecordId: integer); async;
    procedure CancelSelectedRecord(RecordId: integer);

    procedure RefreshControls;
  protected
    procedure PrepareForm; override;
    function InitSortCol: Integer; override;
    function InitSortDirection: TSortDirection; override;
    function CreateGridInFormCreate: boolean; override;
    procedure LoadData; override;
    procedure GetConnectedCharityNameFromId(CharityID: integer; var CharityName: string);
    procedure RefreshCharities;

  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  OtherClaimsGASDSListForm: TOtherClaimsGASDSListForm;

implementation

{$R *.dfm}

uses
  MainDataModule, WEBLib.WebCtrls, SMX.Web.Layout.Utils,
  XData.Web.Client, SMX.Web.Utils;


procedure TOtherClaimsGASDSListForm.WebFormDestroy(Sender: TObject);
begin
  MainDataset.Active := false;
  ConnectedCharityDataSet.Active := false;
  inherited;
end;

procedure TOtherClaimsGASDSListForm.AddButtonClick(Sender:
    TObject);
begin
  inherited;
  EditForm(0);
end;

procedure TOtherClaimsGASDSListForm.DataTableGetCellChildren(
    Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string;
    AElement: TJSHTMLElementRecord);
var
  RecordId: integer;
  Span: THTMLSpan;
  BuildingId: integer;
  BuildingName: string;


  procedure EditClick(Sender: TObject);
  begin
    EditForm(RecordId);
  end;

  procedure CancelClick(Sender: TObject);
  begin
    CancelSelectedRecord(RecordId);
  end;

begin
  if ARow = 0 then
    Exit;


  if not ((DataTable.Columns[ACol].Title = 'Actions')) then
  begin
    Exit;
  end;

  RecordId := MainDataSet.FieldByName('Id').AsInteger;

  if (MainDataSet.FieldByName('ClaimStatus').AsString = 'Unclaimed') or
     (MainDataSet.FieldByName('ClaimStatus').AsString = 'Invalid') then
  begin
    Span := TLayoutUtils.RowActionSpan(AElement.Element, 'fad fa-edit', 'Edit');
    Span.OnClick := @EditClick;

    Span := TLayoutUtils.RowActionSpan(AElement.Element, 'fad fa-trash', 'Cancel');
    Span.OnClick := @CancelClick;
  end;
end;

procedure TOtherClaimsGASDSListForm.PrepareForm;
begin
  FIdField := 'Id';
  MainDataSet.Active := true;
  RefreshCharities;
end;

procedure TOtherClaimsGASDSListForm.RefreshControls;
begin
  FGridPlugin.SetPreparedFilter('ClaimStatus eq Unclaimed');
end;

procedure TOtherClaimsGASDSListForm.EditForm(RecordId: integer);
begin
  FGASDSEditForm := TOtherClaimsGASDSEditForm.Create(Self);
  try
    FGASDSEditForm.Popup := True;
    FGASDSEditForm.PopupOpacity := 1;
    FGASDSEditForm.ElementClassName := 'PopUpForm';
    await(TForm, FGASDSEditForm.Load());

    if RecordId = 0 then
      MainDataset.Insert
    else
    begin
      MainDataset.Locate('Id', RecordId, []);
      MainDataset.Edit;
    end;
    FGASDSEditForm.DataSet := MainDataset;
    ConnectedCharityDataSet.First;
    FGASDSEditForm.ConnectedCharityDataSet := ConnectedCharityDataSet;
    FGASDSEditForm.OtherClaimsType := 'GASDS';
    FGASDSEditForm.ClaimsTypeCaption := 'GASDS';
    FGASDSEditForm.AfterCreated;
    await(TModalResult, FGASDSEditForm.Execute);
  finally
    if FGASDSEditForm.EditDataApplyUpdates then
    begin
      MainDataSet.ApplyUpdates;
    end;
    FGASDSEditForm.Free;
    FGASDSEditForm := nil;
  end;
end;

procedure TOtherClaimsGASDSListForm.GetConnectedCharityNameFromId(
  CharityID: integer; var CharityName: string);
begin
  if CharityId > 0 then
  begin
    if ConnectedCharityDataSet.Locate('Id', CharityId, []) then
    begin
      CharityName := ConnectedCharityDataSet.FieldByName('CharityName').AsString;
    end;
  end
  else
  begin
    CharityName := '';
  end;
end;

procedure TOtherClaimsGASDSListForm.MainDatasetAfterApplyUpdates(
    Sender: TDataSet; Info: TResolveResults);
begin
  inherited;
  RefreshControls;
end;

procedure TOtherClaimsGASDSListForm.RefreshTimerTimer(Sender:
    TObject);
begin
  RefreshTimer.Enabled := false;
  RefreshControls;
end;

procedure TOtherClaimsGASDSListForm.LoadData;
begin
  CreateGridPlugin;
  RefreshControls;
end;

function TOtherClaimsGASDSListForm.InitSortCol: Integer;
begin
  Result := 0;
end;

function TOtherClaimsGASDSListForm.InitSortDirection: TSortDirection;
begin
  Result := sdDesc;
end;

procedure TOtherClaimsGASDSListForm.CancelSelectedRecord(RecordId: integer);
begin
  if MainDataSet.Locate('Id', RecordId, []) then
  begin
    try
      MainDataSet.Edit;
      MainDataSet.FieldByName('ClaimStatus').AsString := 'Cancelled';
      MainDataSet.Post;
    finally
      MainDataSet.ApplyUpdates;
    end;
  end;
end;

procedure TOtherClaimsGASDSListForm.ConnectedCharityDataSetAfterApplyUpdates(
    Sender: TDataSet; Info: TResolveResults);
begin
  inherited;
  RefreshCharities;
end;

procedure TOtherClaimsGASDSListForm.ConnectedCharityDataSetAfterPost(DataSet:
    TDataSet);
begin
  inherited;
  ConnectedCharityDataSet.ApplyUpdates;
end;

function TOtherClaimsGASDSListForm.CreateGridInFormCreate: boolean;
begin
  result := false;
end;

procedure TOtherClaimsGASDSListForm.MainDatasetCalcFields(DataSet:
    TDataSet);
var
  CharityId: integer;
  CharityName: string;
begin
  inherited;
  CharityId := MainDataset.FieldByName('ConnectedCharityId').AsInteger;
  GetConnectedCharityNameFromId(CharityId, CharityName);
  MainDatasetCharityName.Value := CharityName;
end;

procedure TOtherClaimsGASDSListForm.WebFormShow(Sender: TObject);
begin
  inherited;
  LoadData;
end;

procedure TOtherClaimsGASDSListForm.RefreshCharities;
begin
  ConnectedCharityDataSet.Close;
  //ConnectedCharityDataSet.QueryString := '$filter=(Id eq 1)';
  ConnectedCharityDataSet.QueryString := '$filter=(GAStatus eq ''Active'')';
  ConnectedCharityDataSet.Load;

  RefreshCharity.Enabled := true;
end;

procedure TOtherClaimsGASDSListForm.RefreshCharityTimer(Sender: TObject);
begin
  RefreshCharity.Enabled := false;
  if assigned(FGASDSEditForm) then
    FGASDSEditForm.RefreshNewCharity.Enabled := true;
end;

procedure TOtherClaimsGASDSListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetExternalId := TStringField.Create(Self);
  MainDatasetTransactionDate := TDateField.Create(Self);
  MainDatasetImportId := TIntegerField.Create(Self);
  MainDatasetClaimId := TIntegerField.Create(Self);
  MainDatasetGiftAid := TFloatField.Create(Self);
  MainDatasetAmount := TFloatField.Create(Self);
  MainDatasetClaimStatus := TStringField.Create(Self);
  MainDatasetClaimDate := TDateTimeField.Create(Self);
  MainDatasetTaxYear := TIntegerField.Create(Self);
  MainDatasetErrorText := TStringField.Create(Self);
  MainDatasetCharityTenant := TStringField.Create(Self);
  MainDatasetSourceType := TStringField.Create(Self);
  MainDatasetComment := TStringField.Create(Self);
  MainDatasetConnectedCharityId := TIntegerField.Create(Self);
  MainDatasetCharityName := TStringField.Create(Self);
  ConnectedCharityDataSet := TXDataWebDataSet.Create(Self);
  ConnectedCharityDataSetId := TIntegerField.Create(Self);
  ConnectedCharityDataSetDateAdded := TDateTimeField.Create(Self);
  ConnectedCharityDataSetAddedBy := TIntegerField.Create(Self);
  ConnectedCharityDataSetLastUpdatedBy := TIntegerField.Create(Self);
  ConnectedCharityDataSetLastUpdated := TDateTimeField.Create(Self);
  ConnectedCharityDataSetExternalId := TStringField.Create(Self);
  ConnectedCharityDataSetCharityTenant := TStringField.Create(Self);
  ConnectedCharityDataSetHMRCRef := TStringField.Create(Self);
  ConnectedCharityDataSetRegulatorNumber := TStringField.Create(Self);
  ConnectedCharityDataSetCharityName := TStringField.Create(Self);
  ConnectedCharityDataSetGAStatus := TStringField.Create(Self);
  RefreshTimer := TTimer.Create(Self);
  RefreshCharity := TTimer.Create(Self);

  lcPaginator.BeforeLoadDFMValues;
  DataTable.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetExternalId.BeforeLoadDFMValues;
  MainDatasetTransactionDate.BeforeLoadDFMValues;
  MainDatasetImportId.BeforeLoadDFMValues;
  MainDatasetClaimId.BeforeLoadDFMValues;
  MainDatasetGiftAid.BeforeLoadDFMValues;
  MainDatasetAmount.BeforeLoadDFMValues;
  MainDatasetClaimStatus.BeforeLoadDFMValues;
  MainDatasetClaimDate.BeforeLoadDFMValues;
  MainDatasetTaxYear.BeforeLoadDFMValues;
  MainDatasetErrorText.BeforeLoadDFMValues;
  MainDatasetCharityTenant.BeforeLoadDFMValues;
  MainDatasetSourceType.BeforeLoadDFMValues;
  MainDatasetComment.BeforeLoadDFMValues;
  MainDatasetConnectedCharityId.BeforeLoadDFMValues;
  MainDatasetCharityName.BeforeLoadDFMValues;
  ConnectedCharityDataSet.BeforeLoadDFMValues;
  ConnectedCharityDataSetId.BeforeLoadDFMValues;
  ConnectedCharityDataSetDateAdded.BeforeLoadDFMValues;
  ConnectedCharityDataSetAddedBy.BeforeLoadDFMValues;
  ConnectedCharityDataSetLastUpdatedBy.BeforeLoadDFMValues;
  ConnectedCharityDataSetLastUpdated.BeforeLoadDFMValues;
  ConnectedCharityDataSetExternalId.BeforeLoadDFMValues;
  ConnectedCharityDataSetCharityTenant.BeforeLoadDFMValues;
  ConnectedCharityDataSetHMRCRef.BeforeLoadDFMValues;
  ConnectedCharityDataSetRegulatorNumber.BeforeLoadDFMValues;
  ConnectedCharityDataSetCharityName.BeforeLoadDFMValues;
  ConnectedCharityDataSetGAStatus.BeforeLoadDFMValues;
  RefreshTimer.BeforeLoadDFMValues;
  RefreshCharity.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'TransactionDate';
      Title := 'Transaction Date';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Amount';
      Title := 'Amount';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ClaimStatus';
      Title := 'Claim Status';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'CharityName';
      Title := 'Connected Charity';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Comment';
      Title := 'Comment';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    AddButton.ElementClassName := 'btn btn-secondary';
    MainDataset.AfterApplyUpdates := MainDatasetAfterApplyUpdates;
    SetEvent(MainDataset, Self, 'OnCalcFields', 'MainDatasetCalcFields');
    MainDataset.EntitySetName := 'GASDS';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetId.Required := True;
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetDateAdded.Required := True;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetAddedBy.Required := True;
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetExternalId.SetParentComponent(MainDataset);
    MainDatasetExternalId.Name := 'MainDatasetExternalId';
    MainDatasetExternalId.FieldName := 'ExternalId';
    MainDatasetExternalId.Size := 64;
    MainDatasetTransactionDate.SetParentComponent(MainDataset);
    MainDatasetTransactionDate.Name := 'MainDatasetTransactionDate';
    MainDatasetTransactionDate.FieldName := 'TransactionDate';
    MainDatasetTransactionDate.Required := True;
    MainDatasetImportId.SetParentComponent(MainDataset);
    MainDatasetImportId.Name := 'MainDatasetImportId';
    MainDatasetImportId.FieldName := 'ImportId';
    MainDatasetClaimId.SetParentComponent(MainDataset);
    MainDatasetClaimId.Name := 'MainDatasetClaimId';
    MainDatasetClaimId.FieldName := 'ClaimId';
    MainDatasetGiftAid.SetParentComponent(MainDataset);
    MainDatasetGiftAid.Name := 'MainDatasetGiftAid';
    MainDatasetGiftAid.FieldName := 'GiftAid';
    MainDatasetGiftAid.Required := True;
    MainDatasetAmount.SetParentComponent(MainDataset);
    MainDatasetAmount.Name := 'MainDatasetAmount';
    MainDatasetAmount.FieldName := 'Amount';
    MainDatasetAmount.Required := True;
    MainDatasetClaimStatus.SetParentComponent(MainDataset);
    MainDatasetClaimStatus.Name := 'MainDatasetClaimStatus';
    MainDatasetClaimStatus.FieldName := 'ClaimStatus';
    MainDatasetClaimStatus.Required := True;
    MainDatasetClaimStatus.Size := 10;
    MainDatasetClaimDate.SetParentComponent(MainDataset);
    MainDatasetClaimDate.Name := 'MainDatasetClaimDate';
    MainDatasetClaimDate.FieldName := 'ClaimDate';
    MainDatasetTaxYear.SetParentComponent(MainDataset);
    MainDatasetTaxYear.Name := 'MainDatasetTaxYear';
    MainDatasetTaxYear.FieldName := 'TaxYear';
    MainDatasetErrorText.SetParentComponent(MainDataset);
    MainDatasetErrorText.Name := 'MainDatasetErrorText';
    MainDatasetErrorText.FieldName := 'ErrorText';
    MainDatasetErrorText.Size := 1000;
    MainDatasetCharityTenant.SetParentComponent(MainDataset);
    MainDatasetCharityTenant.Name := 'MainDatasetCharityTenant';
    MainDatasetCharityTenant.FieldName := 'CharityTenant';
    MainDatasetSourceType.SetParentComponent(MainDataset);
    MainDatasetSourceType.Name := 'MainDatasetSourceType';
    MainDatasetSourceType.FieldName := 'SourceType';
    MainDatasetSourceType.Size := 9;
    MainDatasetComment.SetParentComponent(MainDataset);
    MainDatasetComment.Name := 'MainDatasetComment';
    MainDatasetComment.FieldName := 'Comment';
    MainDatasetComment.Size := 250;
    MainDatasetConnectedCharityId.SetParentComponent(MainDataset);
    MainDatasetConnectedCharityId.Name := 'MainDatasetConnectedCharityId';
    MainDatasetConnectedCharityId.FieldName := 'ConnectedCharityId';
    MainDatasetCharityName.SetParentComponent(MainDataset);
    MainDatasetCharityName.Name := 'MainDatasetCharityName';
    MainDatasetCharityName.FieldKind := fkCalculated;
    MainDatasetCharityName.FieldName := 'CharityName';
    MainDatasetCharityName.Calculated := True;
    ConnectedCharityDataSet.SetParentComponent(Self);
    ConnectedCharityDataSet.Name := 'ConnectedCharityDataSet';
    ConnectedCharityDataSet.AfterApplyUpdates := ConnectedCharityDataSetAfterApplyUpdates;
    ConnectedCharityDataSet.AfterPost := ConnectedCharityDataSetAfterPost;
    ConnectedCharityDataSet.EntitySetName := 'ConnectedCharity';
    ConnectedCharityDataSet.Connection := MainData.DataConnection;
    ConnectedCharityDataSet.Left := 560;
    ConnectedCharityDataSet.Top := 120;
    ConnectedCharityDataSetId.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetId.Name := 'ConnectedCharityDataSetId';
    ConnectedCharityDataSetId.FieldName := 'Id';
    ConnectedCharityDataSetId.Required := True;
    ConnectedCharityDataSetDateAdded.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetDateAdded.Name := 'ConnectedCharityDataSetDateAdded';
    ConnectedCharityDataSetDateAdded.FieldName := 'DateAdded';
    ConnectedCharityDataSetDateAdded.Required := True;
    ConnectedCharityDataSetAddedBy.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetAddedBy.Name := 'ConnectedCharityDataSetAddedBy';
    ConnectedCharityDataSetAddedBy.FieldName := 'AddedBy';
    ConnectedCharityDataSetAddedBy.Required := True;
    ConnectedCharityDataSetLastUpdatedBy.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetLastUpdatedBy.Name := 'ConnectedCharityDataSetLastUpdatedBy';
    ConnectedCharityDataSetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    ConnectedCharityDataSetLastUpdated.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetLastUpdated.Name := 'ConnectedCharityDataSetLastUpdated';
    ConnectedCharityDataSetLastUpdated.FieldName := 'LastUpdated';
    ConnectedCharityDataSetExternalId.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetExternalId.Name := 'ConnectedCharityDataSetExternalId';
    ConnectedCharityDataSetExternalId.FieldName := 'ExternalId';
    ConnectedCharityDataSetExternalId.Size := 64;
    ConnectedCharityDataSetCharityTenant.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetCharityTenant.Name := 'ConnectedCharityDataSetCharityTenant';
    ConnectedCharityDataSetCharityTenant.FieldName := 'CharityTenant';
    ConnectedCharityDataSetHMRCRef.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetHMRCRef.Name := 'ConnectedCharityDataSetHMRCRef';
    ConnectedCharityDataSetHMRCRef.FieldName := 'HMRCRef';
    ConnectedCharityDataSetRegulatorNumber.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetRegulatorNumber.Name := 'ConnectedCharityDataSetRegulatorNumber';
    ConnectedCharityDataSetRegulatorNumber.FieldName := 'RegulatorNumber';
    ConnectedCharityDataSetRegulatorNumber.Size := 40;
    ConnectedCharityDataSetCharityName.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetCharityName.Name := 'ConnectedCharityDataSetCharityName';
    ConnectedCharityDataSetCharityName.FieldName := 'CharityName';
    ConnectedCharityDataSetCharityName.Required := True;
    ConnectedCharityDataSetCharityName.Size := 255;
    ConnectedCharityDataSetGAStatus.SetParentComponent(ConnectedCharityDataSet);
    ConnectedCharityDataSetGAStatus.Name := 'ConnectedCharityDataSetGAStatus';
    ConnectedCharityDataSetGAStatus.FieldName := 'GAStatus';
    ConnectedCharityDataSetGAStatus.Size := 10;
    RefreshTimer.SetParentComponent(Self);
    RefreshTimer.Name := 'RefreshTimer';
    RefreshTimer.Enabled := False;
    SetEvent(RefreshTimer, Self, 'OnTimer', 'RefreshTimerTimer');
    RefreshTimer.Left := 576;
    RefreshTimer.Top := 16;
    RefreshCharity.SetParentComponent(Self);
    RefreshCharity.Name := 'RefreshCharity';
    RefreshCharity.Enabled := False;
    SetEvent(RefreshCharity, Self, 'OnTimer', 'RefreshCharityTimer');
    RefreshCharity.Left := 560;
    RefreshCharity.Top := 224;
  finally
    lcPaginator.AfterLoadDFMValues;
    DataTable.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetExternalId.AfterLoadDFMValues;
    MainDatasetTransactionDate.AfterLoadDFMValues;
    MainDatasetImportId.AfterLoadDFMValues;
    MainDatasetClaimId.AfterLoadDFMValues;
    MainDatasetGiftAid.AfterLoadDFMValues;
    MainDatasetAmount.AfterLoadDFMValues;
    MainDatasetClaimStatus.AfterLoadDFMValues;
    MainDatasetClaimDate.AfterLoadDFMValues;
    MainDatasetTaxYear.AfterLoadDFMValues;
    MainDatasetErrorText.AfterLoadDFMValues;
    MainDatasetCharityTenant.AfterLoadDFMValues;
    MainDatasetSourceType.AfterLoadDFMValues;
    MainDatasetComment.AfterLoadDFMValues;
    MainDatasetConnectedCharityId.AfterLoadDFMValues;
    MainDatasetCharityName.AfterLoadDFMValues;
    ConnectedCharityDataSet.AfterLoadDFMValues;
    ConnectedCharityDataSetId.AfterLoadDFMValues;
    ConnectedCharityDataSetDateAdded.AfterLoadDFMValues;
    ConnectedCharityDataSetAddedBy.AfterLoadDFMValues;
    ConnectedCharityDataSetLastUpdatedBy.AfterLoadDFMValues;
    ConnectedCharityDataSetLastUpdated.AfterLoadDFMValues;
    ConnectedCharityDataSetExternalId.AfterLoadDFMValues;
    ConnectedCharityDataSetCharityTenant.AfterLoadDFMValues;
    ConnectedCharityDataSetHMRCRef.AfterLoadDFMValues;
    ConnectedCharityDataSetRegulatorNumber.AfterLoadDFMValues;
    ConnectedCharityDataSetCharityName.AfterLoadDFMValues;
    ConnectedCharityDataSetGAStatus.AfterLoadDFMValues;
    RefreshTimer.AfterLoadDFMValues;
    RefreshCharity.AfterLoadDFMValues;
  end;
end;

end.
