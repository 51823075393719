unit JobBaseForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Data.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.DB,
  WEBLib.ExtCtrls,
  WEBLib.WebCtrls,
  Vcl.Imaging.GIFImg,
  WEBLib.Toast,
  WebForm.Core;

type
  TJobBase = class(TCoreWebForm)
    JobsPlaced: TXDataWebDataSet;
    JobsPlacedDataSource: TDataSource;
    JobsPlacedId: TIntegerField;
    JobsPlacedJobType: TStringField;
    JobsPlacedSubmittedBy: TIntegerField;
    JobsPlacedDateSubmitted: TDateTimeField;
    JobsPlacedStatus: TStringField;
    JobsPlacedTimeTaken: TFloatField;
    JobsPlacedDateRun: TDateTimeField;
    JobsPlacedVisibility: TStringField;
    JobsPlacedData: TStringField;
    JobsPlacedOutputOption: TStringField;
    JobsPlacedCompletedBy: TIntegerField;
    JobsPlacedOwnedBy: TIntegerField;
    JobsPlacedTitle: TStringField;
    JobsPlacedNotifyOnCompletion: TBooleanField;
    JobsPlacedDateCompleted: TDateTimeField;
    JobsPlacedTable: TDBTableControl;
    JobsPlacedTimeTakenStr: TStringField;
    CancelButton: TButton;
    PlaceJobButton: TButton;
    OutputOption: TLookupComboBox;
    JobVisibility: TLookupComboBox;
    JobMessage: THTMLSpan;
    RecentJobsCaption: THTMLSpan;
    WaitMessage: TWaitMessage;
    CloseTimer: TTimer;
    JobRunType: TLookupComboBox;
    JobsPlacedStats: TStringField;
    JobsPlacedDownloads: TStringField;
    JobsPlacedHasErrors: TBooleanField;
    JobsPlacedProgress: TFloatField;
    JobsPlacedRunLevel: TStringField;
    JobsPlacedCompleteDate: TDateTimeField;
    procedure WebFormCreate(Sender: TObject);
    procedure CancelButtonClick(Sender: TObject);
    procedure CloseTimerTimer(Sender: TObject);
    procedure JobRunTypeChange(Sender: TObject);
    procedure JobsPlacedAfterOpen(Dataset: TDataSet);
    procedure JobsPlacedCalcFields(Dataset: TDataSet);
    procedure JobsPlacedDateRunGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure JobsPlacedDateSubmittedGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure OutputOptionChange(Sender: TObject);
    procedure PlaceJobButtonClick(Sender: TObject);
  private
    FPlaceJobManagedExternally: Boolean;
    procedure SetButtonValues;
    { Private declarations }

  protected
    FJobId: Integer;
    FLoading: Boolean;
    function IsPreviewRun: Boolean;
    function GetParams: string;
    procedure ValidateJob;
    procedure SetJobMessage(const Value: string);
    procedure ClearJobMessage;
    function NoRecentJobsMessage: string; virtual;
    procedure AfterPlaceJob; virtual;
    procedure JobParams(AJobParams: JS.TJSObject); virtual;
    function JobDescription: string; virtual;
    function JobDisplayName: string; virtual;
    function MinimumProcessLevel: string; virtual;
    function JobClass: string; virtual; abstract;
    function CanPlaceJob: Boolean; virtual;
    function RecentJobsTitle: string; virtual;
    function IsJobActive(const AJobStatus: string): Boolean;
  public
    { Public declarations }
    [async]
    procedure PlaceJob; async; virtual;

    property PlaceJobManagedExternally: Boolean read FPlaceJobManagedExternally write FPlaceJobManagedExternally;
  protected procedure LoadDFMValues; override; end;

implementation

// 'New,Queued,Processing,SentToProduction,Processed,Complete,Failed'

uses
  XData.Web.Client,
  MainDataModule,
  SMX.Web.Utils,
  SMX.Web.Document.Utils,
  Auth.Service,
  SMX.Web.Service.Consts;

{$R *.dfm}

procedure TJobBase.WebFormCreate(Sender: TObject);
begin
  FLoading := True;
  JobsPlaced.QueryString := Format('$filter=JobType eq ''%s''&$orderby=DateSubmitted DESC', [JobClass]);
  JobRunType.ItemIndex := 0;
  OutputOption.ItemIndex := 0;

  JobVisibility.LookupValues.AddPair('OwnerOnly', 'Only me');

  if AuthService.IsAdministrator then
  begin
    JobVisibility.LookupValues.AddPair('AnyAdministrator', 'Any administrator');

    if (MinimumProcessLevel = 'AnyAdministrator') then
      JobVisibility.Value := 'AnyAdministrator';
  end;

  if (MinimumProcessLevel = 'AnyAdminUser') or (MinimumProcessLevel = 'Anyone') then
  begin
    JobVisibility.LookupValues.AddPair('AnyAdminUser', 'Any admin user');
    JobVisibility.Value := 'AnyAdminUser';
  end;

  if (MinimumProcessLevel = 'Anyone') then
  begin
    JobVisibility.LookupValues.AddPair('AnyOne', 'Any one');
    JobVisibility.Value := 'AnyAdminUser';
  end;

  JobsPlaced.Load;
end;

procedure TJobBase.AfterPlaceJob;
begin
  //
end;

procedure TJobBase.CancelButtonClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

function TJobBase.CanPlaceJob: Boolean;
begin
  result := (JobVisibility.ItemIndex > -1);
  if result then
    TDocUtils.setControlValidity(JobVisibility.ElementID, TValidityState.vsValid)
  else
    TDocUtils.setControlValidity(JobVisibility.ElementID, TValidityState.vsInvalid);

  if (OutputOption.ItemIndex > -1) then
    TDocUtils.setControlValidity(OutputOption.ElementID, TValidityState.vsValid)
  else
  begin
    TDocUtils.setControlValidity(OutputOption.ElementID, TValidityState.vsInvalid);
    result := result and (not OutputOption.Visible);
  end;

end;

procedure TJobBase.ClearJobMessage;
begin
  JobMessage.HTML.Text := '';
  JobMessage.Visible := False;
end;

procedure TJobBase.CloseTimerTimer(Sender: TObject);
begin
  CloseTimer.Enabled := False;
  WaitMessage.Hide;
  ModalResult := mrOK;
end;

function TJobBase.GetParams: string;
var
  lJobParams: JS.TJSObject;
begin

  lJobParams := JS.TJSObject.new;
  JobParams(lJobParams);
  result := JS.TJSJSON.stringify(lJobParams);
end;

function TJobBase.IsJobActive(const AJobStatus: string): Boolean;
begin
  result := (AJobStatus <> 'Complete') and (AJobStatus <> 'Failed') and (AJobStatus <> 'Cancelled');
end;

function TJobBase.IsPreviewRun: Boolean;
begin
  result := JobRunType.Visible and (JobRunType.Value = 'Preview');
end;

function TJobBase.JobDescription: string;
begin
  result := 'This job has been placed';
end;

function TJobBase.JobDisplayName: string;
begin
  result := 'Your Job';
end;

procedure TJobBase.JobParams(AJobParams: JS.TJSObject);
begin
  //
end;

procedure TJobBase.JobRunTypeChange(Sender: TObject);
begin
  SetButtonValues;
end;

procedure TJobBase.JobsPlacedAfterOpen(Dataset: TDataSet);
begin
  if JobsPlaced.IsEmpty then
  begin
    RecentJobsCaption.HTML.Text := NoRecentJobsMessage;
    JobsPlacedTable.Visible := False;
  end
  else
  begin
    RecentJobsCaption.HTML.Text := RecentJobsTitle;
    JobsPlacedTable.Visible := True;
  end;

  ValidateJob;

  FLoading := False;
end;

procedure TJobBase.JobsPlacedCalcFields(Dataset: TDataSet);
begin
  if JobsPlacedTimeTaken.Value > 0 then
    JobsPlacedTimeTakenStr.Value := TUtils.SecondsAsTime(Trunc(JobsPlacedTimeTaken.Value));
  if JobsPlacedDateCompleted.Value > 100 then
    JobsPlacedCompleteDate.Value := JobsPlacedDateCompleted.Value;
end;

procedure TJobBase.JobsPlacedDateRunGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := FormatDateTime('dd/mm/yyyy hh:nn', Sender.AsDateTime);
end;

procedure TJobBase.JobsPlacedDateSubmittedGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := FormatDateTime('dd/mm/yyyy hh:nn', Sender.AsDateTime);
end;

function TJobBase.MinimumProcessLevel: string;
begin
  result := 'AnyAdminUser';
end;

procedure TJobBase.OutputOptionChange(Sender: TObject);
begin
  if not FLoading then
    ValidateJob;
end;

procedure TJobBase.PlaceJob;
var
  lParams, lPreview, lRunLevel: string;
  lRetval: TXDataClientResponse;
begin
  lParams := GetParams;
  if JobRunType.Visible then
    lRunLevel := JobRunType.Value
  else
    lRunLevel := 'Production';

  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IJOBSVC_SCHEDULEJOB,
    [JobClass, JobVisibility.Value, lParams, OutputOption.Value, lRunLevel]));

  FJobId := JS.toInteger(lRetval.ResultAsObject['value']);

  AfterPlaceJob;

  if IsPreviewRun then
    lPreview := 'preview '
  else
    lPreview := '';

  ShowAToast(JobDisplayName, Format('Your job %s%s has been placed. The Job Number is %d',
    [lPreview, JobDescription, FJobId]), 'clock.png');

  if not PlaceJobManagedExternally then
  begin
    WaitMessage.Show;
    CloseTimer.Enabled := True;
  end;
end;

procedure TJobBase.PlaceJobButtonClick(Sender: TObject);
begin
  if PlaceJobManagedExternally then
  begin
    ModalResult := mrYes;
  end
  else
    PlaceJob;
end;

function TJobBase.NoRecentJobsMessage: string;
begin
  result := 'There have been no recent ' + JobDisplayName + 's run';
end;

function TJobBase.RecentJobsTitle: string;
begin
  result := 'Recent ' + JobDisplayName + 's';
end;

procedure TJobBase.SetButtonValues;
begin
  if JobRunType.Value = 'Preview' then
    PlaceJobButton.Caption := 'Run Preview'
  else
    PlaceJobButton.Caption := 'Place Job';
end;

procedure TJobBase.SetJobMessage(const Value: string);
begin
  if Value.IsEmpty then
    ClearJobMessage
  else
  begin
    JobMessage.HTML.Text := Value;
    JobMessage.Visible := True;
  end;
end;

procedure TJobBase.ValidateJob;
begin
  ButtonValidity(PlaceJobButton, CanPlaceJob);
end;

procedure TJobBase.LoadDFMValues;
begin
  inherited LoadDFMValues;

  JobsPlacedTable := TDBTableControl.Create('JobsPlacedTable');
  CancelButton := TButton.Create('CancelButton');
  PlaceJobButton := TButton.Create('PlaceJobButton');
  OutputOption := TLookupComboBox.Create('OutputOption');
  JobVisibility := TLookupComboBox.Create('JobVisibility');
  JobMessage := THTMLSpan.Create('JobMessage');
  RecentJobsCaption := THTMLSpan.Create('RecentJobsCaption');
  WaitMessage := TWaitMessage.Create(Self);
  JobRunType := TLookupComboBox.Create('JobRunType');
  JobsPlaced := TXDataWebDataSet.Create(Self);
  JobsPlacedId := TIntegerField.Create(Self);
  JobsPlacedJobType := TStringField.Create(Self);
  JobsPlacedSubmittedBy := TIntegerField.Create(Self);
  JobsPlacedDateSubmitted := TDateTimeField.Create(Self);
  JobsPlacedStatus := TStringField.Create(Self);
  JobsPlacedTimeTaken := TFloatField.Create(Self);
  JobsPlacedDateRun := TDateTimeField.Create(Self);
  JobsPlacedVisibility := TStringField.Create(Self);
  JobsPlacedData := TStringField.Create(Self);
  JobsPlacedOutputOption := TStringField.Create(Self);
  JobsPlacedCompletedBy := TIntegerField.Create(Self);
  JobsPlacedOwnedBy := TIntegerField.Create(Self);
  JobsPlacedTitle := TStringField.Create(Self);
  JobsPlacedNotifyOnCompletion := TBooleanField.Create(Self);
  JobsPlacedDateCompleted := TDateTimeField.Create(Self);
  JobsPlacedTimeTakenStr := TStringField.Create(Self);
  JobsPlacedStats := TStringField.Create(Self);
  JobsPlacedDownloads := TStringField.Create(Self);
  JobsPlacedHasErrors := TBooleanField.Create(Self);
  JobsPlacedProgress := TFloatField.Create(Self);
  JobsPlacedRunLevel := TStringField.Create(Self);
  JobsPlacedCompleteDate := TDateTimeField.Create(Self);
  JobsPlacedDataSource := TDataSource.Create(Self);
  CloseTimer := TTimer.Create(Self);

  JobsPlacedTable.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  PlaceJobButton.BeforeLoadDFMValues;
  OutputOption.BeforeLoadDFMValues;
  JobVisibility.BeforeLoadDFMValues;
  JobMessage.BeforeLoadDFMValues;
  RecentJobsCaption.BeforeLoadDFMValues;
  WaitMessage.BeforeLoadDFMValues;
  JobRunType.BeforeLoadDFMValues;
  JobsPlaced.BeforeLoadDFMValues;
  JobsPlacedId.BeforeLoadDFMValues;
  JobsPlacedJobType.BeforeLoadDFMValues;
  JobsPlacedSubmittedBy.BeforeLoadDFMValues;
  JobsPlacedDateSubmitted.BeforeLoadDFMValues;
  JobsPlacedStatus.BeforeLoadDFMValues;
  JobsPlacedTimeTaken.BeforeLoadDFMValues;
  JobsPlacedDateRun.BeforeLoadDFMValues;
  JobsPlacedVisibility.BeforeLoadDFMValues;
  JobsPlacedData.BeforeLoadDFMValues;
  JobsPlacedOutputOption.BeforeLoadDFMValues;
  JobsPlacedCompletedBy.BeforeLoadDFMValues;
  JobsPlacedOwnedBy.BeforeLoadDFMValues;
  JobsPlacedTitle.BeforeLoadDFMValues;
  JobsPlacedNotifyOnCompletion.BeforeLoadDFMValues;
  JobsPlacedDateCompleted.BeforeLoadDFMValues;
  JobsPlacedTimeTakenStr.BeforeLoadDFMValues;
  JobsPlacedStats.BeforeLoadDFMValues;
  JobsPlacedDownloads.BeforeLoadDFMValues;
  JobsPlacedHasErrors.BeforeLoadDFMValues;
  JobsPlacedProgress.BeforeLoadDFMValues;
  JobsPlacedRunLevel.BeforeLoadDFMValues;
  JobsPlacedCompleteDate.BeforeLoadDFMValues;
  JobsPlacedDataSource.BeforeLoadDFMValues;
  CloseTimer.BeforeLoadDFMValues;
  try
    ElementClassName := 'PopUpForm';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    JobsPlacedTable.SetParentComponent(Self);
    JobsPlacedTable.Name := 'JobsPlacedTable';
    JobsPlacedTable.Left := 63;
    JobsPlacedTable.Top := 128;
    JobsPlacedTable.Width := 489;
    JobsPlacedTable.Height := 105;
    JobsPlacedTable.HeightStyle := ssAuto;
    JobsPlacedTable.WidthStyle := ssAuto;
    JobsPlacedTable.BorderColor := clSilver;
    JobsPlacedTable.ChildOrder := 1;
    JobsPlacedTable.ElementFont := efCSS;
    JobsPlacedTable.ElementHeaderClassName := 'thead-light';
    JobsPlacedTable.ElementPosition := epIgnore;
    JobsPlacedTable.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    JobsPlacedTable.Columns.Clear;
    with JobsPlacedTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with JobsPlacedTable.Columns.Add do
    begin
      DataField := 'Title';
      Title := 'Title';
    end;
    with JobsPlacedTable.Columns.Add do
    begin
      DataField := 'DateSubmitted';
      Title := 'Submitted';
    end;
    with JobsPlacedTable.Columns.Add do
    begin
      DataField := 'Status';
      Title := 'Status';
    end;
    with JobsPlacedTable.Columns.Add do
    begin
      DataField := 'RunLevel';
      Title := 'Level';
    end;
    with JobsPlacedTable.Columns.Add do
    begin
      DataField := 'Visibility';
      Title := 'Visibility';
    end;
    with JobsPlacedTable.Columns.Add do
    begin
      DataField := 'CompleteDate';
      Title := 'Completed';
    end;
    with JobsPlacedTable.Columns.Add do
    begin
      DataField := 'TimeTakenStr';
      Title := 'Time Taken';
    end;
    JobsPlacedTable.DataSource := JobsPlacedDataSource;
    CancelButton.SetParentComponent(Self);
    CancelButton.Name := 'CancelButton';
    CancelButton.Left := 456;
    CancelButton.Top := 424;
    CancelButton.Width := 96;
    CancelButton.Height := 25;
    CancelButton.Caption := 'Cancel';
    CancelButton.ChildOrder := 2;
    CancelButton.ElementClassName := 'btn btn-secondary';
    CancelButton.ElementFont := efCSS;
    CancelButton.ElementPosition := epIgnore;
    CancelButton.HeightStyle := ssAuto;
    CancelButton.HeightPercent := 100.000000000000000000;
    CancelButton.WidthStyle := ssAuto;
    CancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(CancelButton, Self, 'OnClick', 'CancelButtonClick');
    PlaceJobButton.SetParentComponent(Self);
    PlaceJobButton.Name := 'PlaceJobButton';
    PlaceJobButton.Left := 312;
    PlaceJobButton.Top := 424;
    PlaceJobButton.Width := 96;
    PlaceJobButton.Height := 25;
    PlaceJobButton.Caption := 'Place Job';
    PlaceJobButton.ChildOrder := 3;
    PlaceJobButton.ElementClassName := 'btn btn-secondary';
    PlaceJobButton.ElementFont := efCSS;
    PlaceJobButton.ElementPosition := epIgnore;
    PlaceJobButton.HeightStyle := ssAuto;
    PlaceJobButton.HeightPercent := 100.000000000000000000;
    PlaceJobButton.WidthStyle := ssAuto;
    PlaceJobButton.WidthPercent := 100.000000000000000000;
    SetEvent(PlaceJobButton, Self, 'OnClick', 'PlaceJobButtonClick');
    OutputOption.SetParentComponent(Self);
    OutputOption.Name := 'OutputOption';
    OutputOption.Left := 248;
    OutputOption.Top := 264;
    OutputOption.Width := 145;
    OutputOption.Height := 22;
    OutputOption.ElementClassName := 'form-select';
    OutputOption.ElementFont := efCSS;
    OutputOption.ElementPosition := epIgnore;
    OutputOption.HeightStyle := ssAuto;
    OutputOption.HeightPercent := 100.000000000000000000;
    OutputOption.TabOrder := 3;
    OutputOption.WidthStyle := ssAuto;
    OutputOption.WidthPercent := 100.000000000000000000;
    SetEvent(OutputOption, Self, 'OnChange', 'OutputOptionChange');
    OutputOption.ItemIndex := -1;
    OutputOption.LookupValues.Clear;
    with OutputOption.LookupValues.Add do
    begin
      Value := 'Undecided';
      DisplayText := 'I'#39'll decide later';
    end;
    JobVisibility.SetParentComponent(Self);
    JobVisibility.Name := 'JobVisibility';
    JobVisibility.Left := 64;
    JobVisibility.Top := 264;
    JobVisibility.Width := 145;
    JobVisibility.Height := 22;
    JobVisibility.ElementClassName := 'form-select';
    JobVisibility.ElementFont := efCSS;
    JobVisibility.HeightPercent := 100.000000000000000000;
    JobVisibility.TabOrder := 2;
    JobVisibility.WidthPercent := 100.000000000000000000;
    SetEvent(JobVisibility, Self, 'OnChange', 'OutputOptionChange');
    JobVisibility.ItemIndex := -1;
    JobMessage.SetParentComponent(Self);
    JobMessage.Name := 'JobMessage';
    JobMessage.Left := 63;
    JobMessage.Top := 304;
    JobMessage.Width := 489;
    JobMessage.Height := 40;
    JobMessage.HeightStyle := ssAuto;
    JobMessage.WidthStyle := ssAuto;
    JobMessage.ChildOrder := 6;
    JobMessage.ElementPosition := epIgnore;
    JobMessage.ElementFont := efCSS;
    JobMessage.Role := '';
    RecentJobsCaption.SetParentComponent(Self);
    RecentJobsCaption.Name := 'RecentJobsCaption';
    RecentJobsCaption.Left := 248;
    RecentJobsCaption.Top := 81;
    RecentJobsCaption.Width := 160;
    RecentJobsCaption.Height := 41;
    RecentJobsCaption.HeightStyle := ssAuto;
    RecentJobsCaption.WidthStyle := ssAuto;
    RecentJobsCaption.ChildOrder := 7;
    RecentJobsCaption.ElementPosition := epIgnore;
    RecentJobsCaption.ElementFont := efCSS;
    RecentJobsCaption.Role := '';
    WaitMessage.SetParentComponent(Self);
    WaitMessage.Name := 'WaitMessage';
    WaitMessage.Left := 152;
    WaitMessage.Top := 384;
    WaitMessage.Width := 24;
    WaitMessage.Height := 24;
    WaitMessage.HeightStyle := ssAuto;
    WaitMessage.WidthStyle := ssAuto;
    WaitMessage.Opacity := 0.200000000000000000;
    WaitMessage.PictureURL := 'images/Whirligig.gif';
    JobRunType.SetParentComponent(Self);
    JobRunType.Name := 'JobRunType';
    JobRunType.Left := 416;
    JobRunType.Top := 264;
    JobRunType.Width := 145;
    JobRunType.Height := 22;
    JobRunType.ElementClassName := 'form-select';
    JobRunType.ElementFont := efCSS;
    JobRunType.HeightPercent := 100.000000000000000000;
    JobRunType.WidthPercent := 100.000000000000000000;
    SetEvent(JobRunType, Self, 'OnChange', 'JobRunTypeChange');
    JobRunType.ItemIndex := -1;
    JobRunType.LookupValues.Clear;
    with JobRunType.LookupValues.Add do
    begin
      Value := 'Production';
      DisplayText := 'Production';
    end;
    with JobRunType.LookupValues.Add do
    begin
      Value := 'Preview';
      DisplayText := 'Preview';
    end;
    JobsPlaced.SetParentComponent(Self);
    JobsPlaced.Name := 'JobsPlaced';
    JobsPlaced.AfterOpen := JobsPlacedAfterOpen;
    SetEvent(JobsPlaced, Self, 'OnCalcFields', 'JobsPlacedCalcFields');
    JobsPlaced.EntitySetName := 'Job';
    JobsPlaced.Connection := MainData.DataConnection;
    JobsPlaced.QueryTop := 3;
    JobsPlaced.Left := 80;
    JobsPlaced.Top := 72;
    JobsPlacedId.SetParentComponent(JobsPlaced);
    JobsPlacedId.Name := 'JobsPlacedId';
    JobsPlacedId.FieldName := 'Id';
    JobsPlacedJobType.SetParentComponent(JobsPlaced);
    JobsPlacedJobType.Name := 'JobsPlacedJobType';
    JobsPlacedJobType.FieldName := 'JobType';
    JobsPlacedJobType.Size := 25;
    JobsPlacedSubmittedBy.SetParentComponent(JobsPlaced);
    JobsPlacedSubmittedBy.Name := 'JobsPlacedSubmittedBy';
    JobsPlacedSubmittedBy.FieldName := 'SubmittedBy';
    JobsPlacedDateSubmitted.SetParentComponent(JobsPlaced);
    JobsPlacedDateSubmitted.Name := 'JobsPlacedDateSubmitted';
    JobsPlacedDateSubmitted.FieldName := 'DateSubmitted';
    SetEvent(JobsPlacedDateSubmitted, Self, 'OnGetText', 'JobsPlacedDateSubmittedGetText');
    JobsPlacedStatus.SetParentComponent(JobsPlaced);
    JobsPlacedStatus.Name := 'JobsPlacedStatus';
    JobsPlacedStatus.FieldName := 'Status';
    JobsPlacedStatus.Size := 16;
    JobsPlacedTimeTaken.SetParentComponent(JobsPlaced);
    JobsPlacedTimeTaken.Name := 'JobsPlacedTimeTaken';
    JobsPlacedTimeTaken.FieldName := 'TimeTaken';
    JobsPlacedDateRun.SetParentComponent(JobsPlaced);
    JobsPlacedDateRun.Name := 'JobsPlacedDateRun';
    JobsPlacedDateRun.FieldName := 'DateRun';
    SetEvent(JobsPlacedDateRun, Self, 'OnGetText', 'JobsPlacedDateRunGetText');
    JobsPlacedVisibility.SetParentComponent(JobsPlaced);
    JobsPlacedVisibility.Name := 'JobsPlacedVisibility';
    JobsPlacedVisibility.FieldName := 'Visibility';
    JobsPlacedVisibility.Size := 9;
    JobsPlacedData.SetParentComponent(JobsPlaced);
    JobsPlacedData.Name := 'JobsPlacedData';
    JobsPlacedData.FieldName := 'Data';
    JobsPlacedData.Size := 4096;
    JobsPlacedOutputOption.SetParentComponent(JobsPlaced);
    JobsPlacedOutputOption.Name := 'JobsPlacedOutputOption';
    JobsPlacedOutputOption.FieldName := 'OutputOption';
    JobsPlacedOutputOption.Size := 50;
    JobsPlacedCompletedBy.SetParentComponent(JobsPlaced);
    JobsPlacedCompletedBy.Name := 'JobsPlacedCompletedBy';
    JobsPlacedCompletedBy.FieldName := 'CompletedBy';
    JobsPlacedOwnedBy.SetParentComponent(JobsPlaced);
    JobsPlacedOwnedBy.Name := 'JobsPlacedOwnedBy';
    JobsPlacedOwnedBy.FieldName := 'OwnedBy';
    JobsPlacedTitle.SetParentComponent(JobsPlaced);
    JobsPlacedTitle.Name := 'JobsPlacedTitle';
    JobsPlacedTitle.FieldName := 'Title';
    JobsPlacedTitle.Size := 255;
    JobsPlacedNotifyOnCompletion.SetParentComponent(JobsPlaced);
    JobsPlacedNotifyOnCompletion.Name := 'JobsPlacedNotifyOnCompletion';
    JobsPlacedNotifyOnCompletion.FieldName := 'NotifyOnCompletion';
    JobsPlacedDateCompleted.SetParentComponent(JobsPlaced);
    JobsPlacedDateCompleted.Name := 'JobsPlacedDateCompleted';
    JobsPlacedDateCompleted.FieldName := 'DateCompleted';
    JobsPlacedTimeTakenStr.SetParentComponent(JobsPlaced);
    JobsPlacedTimeTakenStr.Name := 'JobsPlacedTimeTakenStr';
    JobsPlacedTimeTakenStr.FieldKind := fkCalculated;
    JobsPlacedTimeTakenStr.FieldName := 'TimeTakenStr';
    JobsPlacedTimeTakenStr.Size := 540;
    JobsPlacedTimeTakenStr.Calculated := True;
    JobsPlacedStats.SetParentComponent(JobsPlaced);
    JobsPlacedStats.Name := 'JobsPlacedStats';
    JobsPlacedStats.FieldName := 'Stats';
    JobsPlacedStats.Size := 4096;
    JobsPlacedDownloads.SetParentComponent(JobsPlaced);
    JobsPlacedDownloads.Name := 'JobsPlacedDownloads';
    JobsPlacedDownloads.FieldName := 'Downloads';
    JobsPlacedDownloads.Size := 4096;
    JobsPlacedHasErrors.SetParentComponent(JobsPlaced);
    JobsPlacedHasErrors.Name := 'JobsPlacedHasErrors';
    JobsPlacedHasErrors.FieldName := 'HasErrors';
    JobsPlacedProgress.SetParentComponent(JobsPlaced);
    JobsPlacedProgress.Name := 'JobsPlacedProgress';
    JobsPlacedProgress.FieldName := 'Progress';
    JobsPlacedRunLevel.SetParentComponent(JobsPlaced);
    JobsPlacedRunLevel.Name := 'JobsPlacedRunLevel';
    JobsPlacedRunLevel.FieldName := 'RunLevel';
    JobsPlacedRunLevel.Size := 10;
    JobsPlacedCompleteDate.SetParentComponent(JobsPlaced);
    JobsPlacedCompleteDate.Name := 'JobsPlacedCompleteDate';
    JobsPlacedCompleteDate.FieldKind := fkCalculated;
    JobsPlacedCompleteDate.FieldName := 'CompleteDate';
    JobsPlacedCompleteDate.Calculated := True;
    JobsPlacedDataSource.SetParentComponent(Self);
    JobsPlacedDataSource.Name := 'JobsPlacedDataSource';
    JobsPlacedDataSource.DataSet := JobsPlaced;
    JobsPlacedDataSource.Left := 168;
    JobsPlacedDataSource.Top := 72;
    CloseTimer.SetParentComponent(Self);
    CloseTimer.Name := 'CloseTimer';
    CloseTimer.Enabled := False;
    CloseTimer.Interval := 1500;
    SetEvent(CloseTimer, Self, 'OnTimer', 'CloseTimerTimer');
    CloseTimer.Left := 72;
    CloseTimer.Top := 376;
  finally
    JobsPlacedTable.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    PlaceJobButton.AfterLoadDFMValues;
    OutputOption.AfterLoadDFMValues;
    JobVisibility.AfterLoadDFMValues;
    JobMessage.AfterLoadDFMValues;
    RecentJobsCaption.AfterLoadDFMValues;
    WaitMessage.AfterLoadDFMValues;
    JobRunType.AfterLoadDFMValues;
    JobsPlaced.AfterLoadDFMValues;
    JobsPlacedId.AfterLoadDFMValues;
    JobsPlacedJobType.AfterLoadDFMValues;
    JobsPlacedSubmittedBy.AfterLoadDFMValues;
    JobsPlacedDateSubmitted.AfterLoadDFMValues;
    JobsPlacedStatus.AfterLoadDFMValues;
    JobsPlacedTimeTaken.AfterLoadDFMValues;
    JobsPlacedDateRun.AfterLoadDFMValues;
    JobsPlacedVisibility.AfterLoadDFMValues;
    JobsPlacedData.AfterLoadDFMValues;
    JobsPlacedOutputOption.AfterLoadDFMValues;
    JobsPlacedCompletedBy.AfterLoadDFMValues;
    JobsPlacedOwnedBy.AfterLoadDFMValues;
    JobsPlacedTitle.AfterLoadDFMValues;
    JobsPlacedNotifyOnCompletion.AfterLoadDFMValues;
    JobsPlacedDateCompleted.AfterLoadDFMValues;
    JobsPlacedTimeTakenStr.AfterLoadDFMValues;
    JobsPlacedStats.AfterLoadDFMValues;
    JobsPlacedDownloads.AfterLoadDFMValues;
    JobsPlacedHasErrors.AfterLoadDFMValues;
    JobsPlacedProgress.AfterLoadDFMValues;
    JobsPlacedRunLevel.AfterLoadDFMValues;
    JobsPlacedCompleteDate.AfterLoadDFMValues;
    JobsPlacedDataSource.AfterLoadDFMValues;
    CloseTimer.AfterLoadDFMValues;
  end;
end;

end.
