unit SMX.Jobs.Shared;

interface



const
  JOBS_UPLOADED_FILE = 'UploadedFile';
  JOBS_TEMP_FILE = 'TempFileName';
  JOBS_ORIGINAL_FILENAME = 'OriginalFileName';
  ERR_UNRECOGNISED_ENCODING = -4001;

implementation

end.
