unit DonationsForm;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, BaseList, Data.DB, WEBLib.ExtCtrls, WEBLib.Actions, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.Grids, WEBLib.DBCtrls, Vcl.Controls, WEBLib.Lists, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.WebCtrls, smx.webcore.types;

type
  TDonationsList = class(TBaseListForm)
    CaptionSpan: THTMLSpan;
    MainDatasetId: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetExternalId: TStringField;
    MainDatasetSponsored: TBooleanField;
    MainDatasetTransactionDate: TDateTimeField;
    MainDatasetImportId: TIntegerField;
    MainDatasetClaimId: TIntegerField;
    MainDatasetGiftAid: TFloatField;
    MainDatasetAmount: TFloatField;
    MainDatasetDonorId: TIntegerField;
    MainDatasetClaimStatus: TStringField;
    MainDatasetClaimDate: TDateTimeField;
    MainDatasetTaxYear: TIntegerField;
    MainDatasetClaimRef: TStringField;
    MainDatasetDonorPostCode: TStringField;
    MainDatasetDonorName: TStringField;
    MainDatasetErrorText: TStringField;
    MainDatasetCharityTenant: TStringField;
    MainDatasetSourceType: TStringField;
    WebTimer1: TTimer;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure SearchButtonClick(Sender: TObject);
  private
    FShowStatus: String;
    procedure SetShowCaption(const Value: String);
    { Private declarations }
  protected
    procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement); override;
    procedure PrepareForm; override;
    procedure LoadData; override;

    //function GetEditFormClass: TWebFormClass;
    function InitSortCol: Integer;override;
    function InitSortDirection: TSortDirection;override;
    function CreateGridInFormCreate: boolean;override;
    procedure SearchData(const Value: string);override;

  public
    { Public declarations }
    property ShowStatus: String read FShowStatus write FShowStatus;
    property ShowCaption: String write SetShowCaption;
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

procedure TDonationsList.WebFormCreate(Sender: TObject);
begin
  inherited;
end;

{ TUnclaimedDonations }

procedure TDonationsList.AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);
begin
  inherited;

end;

procedure TDonationsList.LoadData;
begin
  CreateGridPlugin;
  FGridPlugin.SetPreparedFilter('ClaimStatus eq Unclaimed');
  //FGridPlugin.Load;
end;

procedure TDonationsList.PrepareForm;
begin
  FIdField := 'Id';
end;

procedure TDonationsList.SearchButtonClick(Sender: TObject);
begin
  //inherited;
  //ClaimStatus eq Unclaimed
  if edSearch.Text <> '' then
    SearchData(edSearch.Text);

end;

procedure TDonationsList.SetShowCaption(const Value: String);
begin
 CaptionSpan.HTML.Text := Value;
end;

procedure TDonationsList.WebFormShow(Sender: TObject);
begin
  inherited;
  LoadData;
end;

procedure TDonationsList.SearchData(const Value: string);
begin
  FGridPlugin.SetFilterText(Value, True);
end;


//function TDonationsList.GetEditFormClass: TWebFormClass;
//begin
//  Result := TImportBatchForm;
//end;

function TDonationsList.InitSortCol: Integer;
begin
  Result := 1;
end;

function TDonationsList.InitSortDirection: TSortDirection;
begin
  Result := TSortDirection.sdDesc;
end;

function TDonationsList.CreateGridInFormCreate: boolean;
begin
  result := false;
end;


procedure TDonationsList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CaptionSpan := THTMLSpan.Create('CaptionSpan');
  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetExternalId := TStringField.Create(Self);
  MainDatasetSponsored := TBooleanField.Create(Self);
  MainDatasetTransactionDate := TDateTimeField.Create(Self);
  MainDatasetImportId := TIntegerField.Create(Self);
  MainDatasetClaimId := TIntegerField.Create(Self);
  MainDatasetGiftAid := TFloatField.Create(Self);
  MainDatasetAmount := TFloatField.Create(Self);
  MainDatasetDonorId := TIntegerField.Create(Self);
  MainDatasetClaimStatus := TStringField.Create(Self);
  MainDatasetClaimDate := TDateTimeField.Create(Self);
  MainDatasetTaxYear := TIntegerField.Create(Self);
  MainDatasetClaimRef := TStringField.Create(Self);
  MainDatasetDonorPostCode := TStringField.Create(Self);
  MainDatasetDonorName := TStringField.Create(Self);
  MainDatasetErrorText := TStringField.Create(Self);
  MainDatasetCharityTenant := TStringField.Create(Self);
  MainDatasetSourceType := TStringField.Create(Self);
  WebTimer1 := TTimer.Create(Self);

  DataTable.BeforeLoadDFMValues;
  CaptionSpan.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetExternalId.BeforeLoadDFMValues;
  MainDatasetSponsored.BeforeLoadDFMValues;
  MainDatasetTransactionDate.BeforeLoadDFMValues;
  MainDatasetImportId.BeforeLoadDFMValues;
  MainDatasetClaimId.BeforeLoadDFMValues;
  MainDatasetGiftAid.BeforeLoadDFMValues;
  MainDatasetAmount.BeforeLoadDFMValues;
  MainDatasetDonorId.BeforeLoadDFMValues;
  MainDatasetClaimStatus.BeforeLoadDFMValues;
  MainDatasetClaimDate.BeforeLoadDFMValues;
  MainDatasetTaxYear.BeforeLoadDFMValues;
  MainDatasetClaimRef.BeforeLoadDFMValues;
  MainDatasetDonorPostCode.BeforeLoadDFMValues;
  MainDatasetDonorName.BeforeLoadDFMValues;
  MainDatasetErrorText.BeforeLoadDFMValues;
  MainDatasetCharityTenant.BeforeLoadDFMValues;
  MainDatasetSourceType.BeforeLoadDFMValues;
  WebTimer1.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    DataTable.Top := 110;
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'TransactionDate';
      Title := 'Transaction Date';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'DonorName';
      Title := 'Donor';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'DonorPostCode';
      Title := 'Post Code';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Amount';
      Title := 'Amount';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ClaimStatus';
      Title := 'ClaimStatus';
    end;
    CaptionSpan.SetParentComponent(Self);
    CaptionSpan.Name := 'CaptionSpan';
    CaptionSpan.Left := 632;
    CaptionSpan.Top := 48;
    CaptionSpan.Width := 100;
    CaptionSpan.Height := 40;
    CaptionSpan.HeightStyle := ssAuto;
    CaptionSpan.WidthStyle := ssAuto;
    CaptionSpan.ChildOrder := 10;
    CaptionSpan.ElementPosition := epIgnore;
    CaptionSpan.ElementFont := efCSS;
    CaptionSpan.Role := '';
    MainDataset.EntitySetName := 'Donation';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetId.Required := True;
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetDateAdded.Required := True;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetAddedBy.Required := True;
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetExternalId.SetParentComponent(MainDataset);
    MainDatasetExternalId.Name := 'MainDatasetExternalId';
    MainDatasetExternalId.FieldName := 'ExternalId';
    MainDatasetExternalId.Required := True;
    MainDatasetExternalId.Size := 255;
    MainDatasetSponsored.SetParentComponent(MainDataset);
    MainDatasetSponsored.Name := 'MainDatasetSponsored';
    MainDatasetSponsored.FieldName := 'Sponsored';
    MainDatasetSponsored.Required := True;
    MainDatasetTransactionDate.SetParentComponent(MainDataset);
    MainDatasetTransactionDate.Name := 'MainDatasetTransactionDate';
    MainDatasetTransactionDate.FieldName := 'TransactionDate';
    MainDatasetTransactionDate.Required := True;
    MainDatasetImportId.SetParentComponent(MainDataset);
    MainDatasetImportId.Name := 'MainDatasetImportId';
    MainDatasetImportId.FieldName := 'ImportId';
    MainDatasetClaimId.SetParentComponent(MainDataset);
    MainDatasetClaimId.Name := 'MainDatasetClaimId';
    MainDatasetClaimId.FieldName := 'ClaimId';
    MainDatasetGiftAid.SetParentComponent(MainDataset);
    MainDatasetGiftAid.Name := 'MainDatasetGiftAid';
    MainDatasetGiftAid.FieldName := 'GiftAid';
    MainDatasetGiftAid.Required := True;
    MainDatasetAmount.SetParentComponent(MainDataset);
    MainDatasetAmount.Name := 'MainDatasetAmount';
    MainDatasetAmount.FieldName := 'Amount';
    MainDatasetAmount.Required := True;
    MainDatasetDonorId.SetParentComponent(MainDataset);
    MainDatasetDonorId.Name := 'MainDatasetDonorId';
    MainDatasetDonorId.FieldName := 'DonorId';
    MainDatasetDonorId.Required := True;
    MainDatasetClaimStatus.SetParentComponent(MainDataset);
    MainDatasetClaimStatus.Name := 'MainDatasetClaimStatus';
    MainDatasetClaimStatus.FieldName := 'ClaimStatus';
    MainDatasetClaimStatus.Required := True;
    MainDatasetClaimStatus.Size := 9;
    MainDatasetClaimDate.SetParentComponent(MainDataset);
    MainDatasetClaimDate.Name := 'MainDatasetClaimDate';
    MainDatasetClaimDate.FieldName := 'ClaimDate';
    MainDatasetTaxYear.SetParentComponent(MainDataset);
    MainDatasetTaxYear.Name := 'MainDatasetTaxYear';
    MainDatasetTaxYear.FieldName := 'TaxYear';
    MainDatasetClaimRef.SetParentComponent(MainDataset);
    MainDatasetClaimRef.Name := 'MainDatasetClaimRef';
    MainDatasetClaimRef.FieldName := 'ClaimRef';
    MainDatasetClaimRef.Size := 32;
    MainDatasetDonorPostCode.SetParentComponent(MainDataset);
    MainDatasetDonorPostCode.Name := 'MainDatasetDonorPostCode';
    MainDatasetDonorPostCode.FieldName := 'DonorPostCode';
    MainDatasetDonorPostCode.Size := 15;
    MainDatasetDonorName.SetParentComponent(MainDataset);
    MainDatasetDonorName.Name := 'MainDatasetDonorName';
    MainDatasetDonorName.FieldName := 'DonorName';
    MainDatasetDonorName.Size := 50;
    MainDatasetErrorText.SetParentComponent(MainDataset);
    MainDatasetErrorText.Name := 'MainDatasetErrorText';
    MainDatasetErrorText.FieldName := 'ErrorText';
    MainDatasetErrorText.Size := 1000;
    MainDatasetCharityTenant.SetParentComponent(MainDataset);
    MainDatasetCharityTenant.Name := 'MainDatasetCharityTenant';
    MainDatasetCharityTenant.FieldName := 'CharityTenant';
    MainDatasetSourceType.SetParentComponent(MainDataset);
    MainDatasetSourceType.Name := 'MainDatasetSourceType';
    MainDatasetSourceType.FieldName := 'SourceType';
    MainDatasetSourceType.Size := 9;
    WebTimer1.SetParentComponent(Self);
    WebTimer1.Name := 'WebTimer1';
    WebTimer1.Left := 48;
    WebTimer1.Top := 432;
  finally
    DataTable.AfterLoadDFMValues;
    CaptionSpan.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetExternalId.AfterLoadDFMValues;
    MainDatasetSponsored.AfterLoadDFMValues;
    MainDatasetTransactionDate.AfterLoadDFMValues;
    MainDatasetImportId.AfterLoadDFMValues;
    MainDatasetClaimId.AfterLoadDFMValues;
    MainDatasetGiftAid.AfterLoadDFMValues;
    MainDatasetAmount.AfterLoadDFMValues;
    MainDatasetDonorId.AfterLoadDFMValues;
    MainDatasetClaimStatus.AfterLoadDFMValues;
    MainDatasetClaimDate.AfterLoadDFMValues;
    MainDatasetTaxYear.AfterLoadDFMValues;
    MainDatasetClaimRef.AfterLoadDFMValues;
    MainDatasetDonorPostCode.AfterLoadDFMValues;
    MainDatasetDonorName.AfterLoadDFMValues;
    MainDatasetErrorText.AfterLoadDFMValues;
    MainDatasetCharityTenant.AfterLoadDFMValues;
    MainDatasetSourceType.AfterLoadDFMValues;
    WebTimer1.AfterLoadDFMValues;
  end;
end;

end.