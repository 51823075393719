unit GA.Return.Types;

interface

{$IFDEF PAS2JS}

uses
  JS;
{$ENDIF}

type

  TGiftAigOrgConfig = class
    Id: Integer;
    TenantId: string;
    AgentId: Integer;
    Depth: Integer;
    RefId: string;
    Name: string;
    Status: string;
    LicenseType: string;
    LicenseExpires: TDate;
    AuthUserCount: Integer;
  end;

  TGiftAidUser = class
    InternalId: Integer;
    // Sphinx App Values - read from JWT?
    OrganisationId: Integer;
    OrganisationName: string;
    TenantId: string;
    JobTitle: string;
    JobPosition: string;
    Title: string;
    GivenName: string;
    LastName: string;
    Email: string;
    EmailConfirmed: Boolean;
    PhoneNumber: string;

    UserStatus: string; // TUserStatus;
    UserScope: string; // TUserScope;

    // Local App Values
    HMRCPhone: string;
    HMRCPostCode: string;
    HMRCRegistered: Boolean;
    CanSubmit: Boolean;

    function Initials: string;
    function IsAdmin: Boolean;
{$IFDEF PAS2JS}
    procedure Assign(Source: JS.TJSObject);
{$ENDIF}
  end;

  TUserCounts = class
    Licensed: Integer;
    Active: Integer;
    Inactive: Integer;
    Archived: Integer;
{$IFDEF PAS2JS}
    procedure Assign(Source: JS.TJSObject);
{$ENDIF}
    function AvailableSeats: Integer;
  end;

  TAgentCharity = class
    OrganisationId: Integer;
    OrganisationName: string;
    TenantId: string;
{$IFDEF PAS2JS}
    procedure Assign(Source: JS.TJSObject);
{$ENDIF}
  end;


implementation

uses
  System.SysUtils,
  SMX.Auth.Shared;

{ TGiftAidUser }

{$IFDEF PAS2JS}

procedure TGiftAidUser.Assign(Source: JS.TJSObject);
begin
  InternalId := Integer(Source.Properties['InternalId']);
  OrganisationId := Integer(Source.Properties['OrganisationId']);
  OrganisationName := string(Source.Properties['OrganisationName']);
  TenantId := string(Source.Properties['TenantId']);
  JobTitle := string(Source.Properties['JobTitle']);
  JobPosition := string(Source.Properties['JobPosition']);
  Title := string(Source.Properties['Title']);
  GivenName := string(Source.Properties['GivenName']);
  LastName := string(Source.Properties['LastName']);
  Email := string(Source.Properties['Email']);
  EmailConfirmed := Boolean(Source.Properties['EmailConfirmed']);
  UserStatus := string(Source.Properties['UserStatus']);
  UserScope := string(Source.Properties['UserScope']);
  PhoneNumber := string(Source.Properties['PhoneNumber']);
  HMRCPhone := string(Source.Properties['HMRCPhone']);
  HMRCPostCode := string(Source.Properties['HMRCPostCode']);
  HMRCRegistered := Boolean(Source.Properties['HMRCRegistered']);
  CanSubmit := Boolean(Source.Properties['CanSubmit']);
end;
{$ENDIF}

function TGiftAidUser.Initials: string;
begin
  result := '';
  if not GivenName.IsEmpty then
    result := GivenName.Substring(0, 1).ToUpper;
  if not LastName.IsEmpty then
    result := result + LastName.Substring(0, 1).ToUpper;
  result := result.TrimRight;
end;

function TGiftAidUser.IsAdmin: Boolean;
begin
  result := (UserScope = SCOPE_ADMIN) or (UserScope = SCOPE_SUPERUSER);
end;

{ TUserCounts }

{$IFDEF PAS2JS}

procedure TUserCounts.Assign(Source: JS.TJSObject);
begin
  Licensed := Integer(Source['Licensed']);
  Active := Integer(Source['Active']);
  Inactive := Integer(Source['Inactive']);
  Archived := Integer(Source['Archived']);
end;
{$ENDIF}
{ TUserCounts }

function TUserCounts.AvailableSeats: Integer;
begin
  result := Licensed - Active;
end;

{ TAgentCharity }

{$IFDEF PAS2JS}

procedure TAgentCharity.Assign(Source: JS.TJSObject);
begin
  OrganisationId := Integer(Source['OrganisationId']);
  OrganisationName := string(Source['OrganisationName']);
  TenantId := string(Source['TenantId']);
end;
{$ENDIF}
{ TAgentCharity }

end.
