unit GA.Registration.Classes;

interface

type

  TRegisterOrganisation = class
    Id: Integer;
    TenantId: string;
    OrganisationName: string;
    RegisteredName: string;
    Add1: string;
    Add2: string;
    Add3: string;
    Add4: string;
    Add5: string;
    Country: string;
    PostCode: string;

    Regulator: string;
    OtherRegulator: string;
    RegulatorNumber: string;
    RegulatorStatus: string;

    CharityType: string;
    OrganisationType: string;
    HMRCCharityRef: string;
    HMRCGatewayId: string;

    AnnualDonations: Double;
    TermsSigned: Boolean;
  end;

  TRegisterUser = class
    OrganisationId: Integer;
    InternalId: Integer;
    TenantId: string;
    JobTitle: string;
    Title: string;
    GivenName: string;
    LastName: string;
    UserName: string;
    Email: string;
    Mobile: string;
    Phone: string;
    Password: string;
    UserStatus: string;
    UserScope: string;
    JobPosition: string;
    PostCode: string;
    HMRCRegistered: Boolean;
    IsImport: Boolean;
  end;

  TCharityLookup = class
    CharityName: string;
    DateOfRegistration: TDate;
    RegCharityNumber: string;
    RegStatus: string;
  end;

  TCharityDetails = class
    CharityName: string;
    RegCharityNumber: string;
    RegStatus: string;
    Add1: string;
    Add2: string;
    Add3: string;
    Add4: string;
    Add5: string;
    PostCode: string;
  end;

implementation

end.
