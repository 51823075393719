unit OtherClaimsConnectedCharitiesEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, OtherClaimsEdit, Data.DB, WEBLib.DB,
  WEBLib.WebCtrls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.DBCtrls, Vcl.Controls;

type
  TOtherClaimsConnectedCharitiesEditForm = class(TOtherClaimsEditForm)
    CharityComboBox: TComboBox;
    CharityDataSource: TDataSource;
    procedure CharityComboBoxChange(Sender: TObject);
  private
    { Private declarations }
    procedure SetCommunityCharityDataSet(const Value: TDataSet);
  protected
    [async]
    procedure GetRules; async; override;
    function GetCharityId: integer;
    procedure SetCharityNameComboBox(CharityId: integer);

  public
    { Public declarations }
    property ConnectedCharityDataSet: TDataSet write SetCommunityCharityDataSet;
  protected procedure LoadDFMValues; override; end;

var
  OtherClaimsConnectedCharitiesEditForm: TOtherClaimsConnectedCharitiesEditForm;

implementation

{$R *.dfm}

uses
  OtherClaimsConnectedCharitiesList;

{ TOtherClaimsConnectedCharitiesEditForm }

procedure TOtherClaimsConnectedCharitiesEditForm.CharityComboBoxChange(Sender:
    TObject);
begin
  GetCharityId;
end;


function TOtherClaimsConnectedCharitiesEditForm.GetCharityId: integer;
var
  Idx: integer;
  ValueStr: string;
begin
  ValueStr := CharityComboBox.Values[CharityComboBox.ItemIndex];
  EditDataSource.DataSet.FieldByName('ConnectedCharityId').AsInteger := ValueStr.ToInteger;
end;

procedure TOtherClaimsConnectedCharitiesEditForm.GetRules;
begin
  OtherClaimsType := 'ConnectedCharities';
  inherited;
end;

procedure TOtherClaimsConnectedCharitiesEditForm.SetCharityNameComboBox(
  CharityId: integer);
var
  Idx: integer;
  ValueStr: string;
begin
  for Idx := 0 to CharityComboBox.Items.Count-1 do
  begin
    ValueStr := CharityComboBox.Values[Idx];

    try
      if ValueStr.ToInteger() = CharityId then
      begin
        CharityComboBox.ItemIndex := Idx;
        break;
      end;
    except

    end;
  end;
end;

procedure TOtherClaimsConnectedCharitiesEditForm.SetCommunityCharityDataSet(
  const Value: TDataSet);
var
  CharityName: string;
  CharityId: integer;
  CurrentCharityId: integer;
begin
  CharityDataSource.DataSet := Value;
  CharityDataSource.DataSet.First;

  while not CharityDataSource.DataSet.Eof do
  begin
    CharityName := CharityDataSource.DataSet.FieldByName('CharityName').AsString;
    CharityId := CharityDataSource.DataSet.FieldByName('Id').AsInteger;
    CharityComboBox.Items.AddPair(CharityName, CharityId.ToString);
    CharityDataSource.DataSet.Next;
  end;

  CurrentCharityId := EditDataSource.DataSet.FieldByName('ConnectedCharityId').AsInteger;

  if CurrentCharityId > 0 then
    SetCharityNameComboBox(CurrentCharityId);
end;

procedure TOtherClaimsConnectedCharitiesEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CharityComboBox := TComboBox.Create('CharityComboBox');
  CharityDataSource := TDataSource.Create(Self);

  AmountEdit.BeforeLoadDFMValues;
  TransactionDateEdit.BeforeLoadDFMValues;
  NoteMemo.BeforeLoadDFMValues;
  CharityComboBox.BeforeLoadDFMValues;
  CharityDataSource.BeforeLoadDFMValues;
  try
    AmountEdit.Top := 148;
    TransactionDateEdit.Top := 112;
    NoteMemo.Top := 192;
    CharityComboBox.SetParentComponent(Self);
    CharityComboBox.Name := 'CharityComboBox';
    CharityComboBox.Left := 16;
    CharityComboBox.Top := 75;
    CharityComboBox.Width := 185;
    CharityComboBox.Height := 23;
    CharityComboBox.ElementClassName := 'form-select';
    CharityComboBox.ElementPosition := epIgnore;
    CharityComboBox.HeightStyle := ssAuto;
    CharityComboBox.HeightPercent := 100.000000000000000000;
    CharityComboBox.TabOrder := 1;
    CharityComboBox.Text := 'CharityComboBox';
    CharityComboBox.WidthStyle := ssAuto;
    CharityComboBox.WidthPercent := 100.000000000000000000;
    SetEvent(CharityComboBox, Self, 'OnChange', 'CharityComboBoxChange');
    CharityComboBox.ItemIndex := -1;
    CharityDataSource.SetParentComponent(Self);
    CharityDataSource.Name := 'CharityDataSource';
    CharityDataSource.Left := 478;
    CharityDataSource.Top := 152;
  finally
    AmountEdit.AfterLoadDFMValues;
    TransactionDateEdit.AfterLoadDFMValues;
    NoteMemo.AfterLoadDFMValues;
    CharityComboBox.AfterLoadDFMValues;
    CharityDataSource.AfterLoadDFMValues;
  end;
end;

end.
