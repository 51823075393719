unit Sphinx.OidcClient.AuthState;

{$I Sphinx.inc}

interface

type
  TAuthState = class {$IFDEF Pas2JS}external name 'Object'{$ENDIF}
  private
    FAuthorizeUrl: string{$IFDEF Pas2JS} external name 'authorize_url'{$ENDIF};
    FState: string{$IFDEF Pas2JS} external name 'state'{$ENDIF};
    FScope: string{$IFDEF Pas2JS} external name 'scope'{$ENDIF};
    FClientId: string{$IFDEF Pas2JS} external name 'client_id'{$ENDIF};
    FAppState: string{$IFDEF Pas2JS} external name 'app_state'{$ENDIF};
    FRedirectUri: string{$IFDEF Pas2JS} external name 'redirect_uri'{$ENDIF};
    FCodeVerifier: string{$IFDEF Pas2JS} external name 'code_verifier'{$ENDIF};
    FCodeChallenge: string{$IFDEF Pas2JS} external name 'code_challenge'{$ENDIF};

    FCreatedOn: TDateTime{$IFDEF Pas2JS} external name 'created_on'{$ENDIF};
    FNonce: string {$IFDEF Pas2JS}external name 'nonce'{$ENDIF};
    FResponseMode: string {$IFDEF Pas2JS}external name 'response_mode'{$ENDIF};
  public
{$IFDEF Pas2JS}
    constructor new;
{$ENDIF}
    property AuthorizeUrl: string read FAuthorizeUrl write FAuthorizeUrl;
    property State: string read FState write FState;
    property Scope: string read FScope write FScope;
    property ClientId: string read FClientId write FClientId;
    property AppState: string read FAppState write FAppState;
    property RedirectUri: string read FRedirectUri write FRedirectUri;
    property CodeVerifier: string read FCodeVerifier write FCodeVerifier;
    property CodeChallenge: string read FCodeChallenge write FCodeChallenge;

    property CreatedOn: TDateTime read FCreatedOn write FCreatedOn;
    property Nonce: string read FNonce write FNonce;
    property ResponseMode: string read FResponseMode write FResponseMode;
  end;

implementation

end.
