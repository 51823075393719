unit UserEdit;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WebForm.Core,
  Data.DB,
  WEBLib.DB,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.ExtCtrls,
  WEBLib.DBCtrls,
  WEBLib.StdCtrls,
  GA.Return.Types,
  MainDataModule;

type
  TUserEditForm = class(TCoreWebForm)
    UserDataSource: TDataSource;
    InternalIdLabel: TDBLabel;
    GivenNameEdit: TDBEdit;
    LastNameEdit: TDBEdit;
    TitleEdit: TDBEdit;
    PhoneNumberEdit: TDBEdit;
    EmailEdit: TDBEdit;
    HMRCPostCodeEdit: TDBEdit;
    HMRCPhoneEdit: TDBEdit;
    UserScopeEdit: TDBLookupComboBox;
    UserStatusEdit: TDBLookupComboBox;
    CanSubmitEdit: TDBCheckBox;
    HMRCRegEdit: TDBCheckBox;
    UserEditCancelButton: TButton;
    UserEditSaveButton: TButton;
    UserEditMessage: TLabel;
    UserChange: TMessageDlg;
    procedure WebFormDestroy(Sender: TObject);
    procedure HMRCRegEditClick(Sender: TObject);
    procedure UserEditCancelButtonClick(Sender: TObject);
    procedure UserEditSaveButtonClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    FUser: TGiftAidUser;
    FAvailableSeats: Integer;
    function GetDataset: TDataset;
    procedure SetDataset(const Value: TDataset);
    procedure LoadScope;
    procedure LoadStatus;
    procedure HMRCRegUpdate(const Value: Boolean);
    [async]
    procedure Validate; async;
    //function Validate: Boolean;
    procedure SetAvailableSeats(const Value: Integer);
    procedure DisplayUserMessage(const Value: string);
    function GetIsNewUser: Boolean;
    { Private declarations }
    property IsNewUser: Boolean read GetIsNewUser;
  public
    { Public declarations }
    property Dataset: TDataset read GetDataset write SetDataset;
    property AvailableSeats: Integer read FAvailableSeats write SetAvailableSeats;
  protected procedure LoadDFMValues; override; end;

var
  UserEditForm: TUserEditForm;

implementation

{$R *.dfm}

uses
  UserManager,
  SMX.Auth.Shared,
  UserManager.Utils,
  SMX.Web.Document.Utils;

procedure TUserEditForm.WebFormDestroy(Sender: TObject);
begin
  if Assigned(FUser) then
    FUser.Free;
  inherited;
end;

procedure TUserEditForm.WebFormCreate(Sender: TObject);
begin
  inherited;

end;

{ TUserEditForm }

procedure TUserEditForm.DisplayUserMessage(const Value: string);
begin
  UserEditMessage.Caption := Value;
  UserEditMessage.Visible := (Value <> '');
end;

function TUserEditForm.GetDataset: TDataset;
begin
  Result := UserDataSource.Dataset;
end;

function TUserEditForm.GetIsNewUser: Boolean;
begin
  Result := UserDataSource.State = TDataSetState.dsInsert;
end;

procedure TUserEditForm.HMRCRegEditClick(Sender: TObject);
begin
  HMRCRegUpdate(HMRCRegEdit.Checked);
end;

procedure TUserEditForm.HMRCRegUpdate(const Value: Boolean);
begin
  HMRCPostCodeEdit.Enabled := Value;
  HMRCPhoneEdit.Enabled := Value;
end;

procedure TUserEditForm.LoadScope;
begin
  UserScopeEdit.LookupValues.AddPair(SCOPE_HMRC_ONLY, 'Only for HMRC Submissions');
  if FAvailableSeats > 0 then
  begin
    UserScopeEdit.LookupValues.AddPair(SCOPE_USER, 'Standard User');
    UserScopeEdit.LookupValues.AddPair(SCOPE_ADMIN, 'Administrator');
  end;
end;

procedure TUserEditForm.LoadStatus;
begin
  if FAvailableSeats > 0 then
  begin
    UserStatusEdit.LookupValues.AddPair('Pending', 'Pending');
    UserStatusEdit.LookupValues.AddPair('Active', 'Active');
    UserStatusEdit.LookupValues.AddPair('Left', 'Left');
    UserStatusEdit.LookupValues.AddPair('Retired', 'Retired');
    UserStatusEdit.LookupValues.AddPair('Suspended', 'Suspended');
    UserStatusEdit.LookupValues.AddPair('Deceased', 'Deceased');
  end
  else
    UserStatusEdit.LookupValues.AddPair('Inactive', 'Inactive');
end;

procedure TUserEditForm.Validate;
var
  lValid, AllValid, HMRCValid: Boolean;
  lMessage: string;

  mr: TModalResult;
begin
  AllValid := True;

  lValid := (Dataset.FieldByName('GivenName').AsString.Trim <> '');
  AllValid := AllValid and lValid;
  TDocUtils.setControlValidity('GivenNameEdit', Valid_Check[lValid]);

  lValid := (Dataset.FieldByName('LastName').AsString.Trim <> '');
  AllValid := AllValid and lValid;
  TDocUtils.setControlValidity('LastNameEdit', Valid_Check[lValid]);

  lValid := (Dataset.FieldByName('Email').AsString.Trim <> '');
  AllValid := AllValid and lValid;
  TDocUtils.setControlValidity('EmailEdit', Valid_Check[lValid]);

  HMRCValid := True;

  if Dataset.FieldByName('HMRCRegistered').AsBoolean then
  begin

    lValid := (Dataset.FieldByName('HMRCPostCode').AsString.Trim <> '');
    HMRCValid := HMRCValid and lValid;
    TDocUtils.setControlValidity('HMRCPostCodeEdit', Valid_Check[lValid]);

    lValid := (Dataset.FieldByName('HMRCPhone').AsString.Trim <> '');
    HMRCValid := HMRCValid and lValid;
    TDocUtils.setControlValidity('HMRCPhoneEdit', Valid_Check[lValid]);

  end;

  if (not AllValid) or (not HMRCValid) then
  begin
    lMessage := 'Please check the hightlighted fields.';
    if not HMRCValid then
      lMessage := 'If a user is HMRC registered then these fields must be completed. ' + lMessage;

    DisplayUserMessage(lMessage);

    Exit;
  end;

  // New user, no need to check for changes
  if FUser.InternalId = 0 then
  begin
    ModalResult := mrOk;
    Exit;
  end;

  if Dataset.FieldByName('HMRCRegistered').AsBoolean then
  begin
    HMRCValid := True;
    lValid := (Dataset.FieldByName('GivenName').AsString = FUser.GivenName);
    HMRCValid := HMRCValid and lValid;
    TDocUtils.setControlValidity('GivenNameEdit', Valid_Check[lValid]);

    lValid := (Dataset.FieldByName('LastName').AsString = FUser.LastName);
    HMRCValid := HMRCValid and lValid;
    TDocUtils.setControlValidity('LastNameEdit', Valid_Check[lValid]);

    lValid := (Dataset.FieldByName('HMRCPostCode').AsString.Trim <> '') and (Dataset.FieldByName('HMRCPostCode').AsString = FUser.HMRCPostCode);
    HMRCValid := HMRCValid and lValid;
    TDocUtils.setControlValidity('HMRCPostCodeEdit', Valid_Check[lValid]);

    lValid := (Dataset.FieldByName('HMRCPhone').AsString.Trim <> '') and (Dataset.FieldByName('HMRCPhone').AsString = FUser.HMRCPhone);
    HMRCValid := HMRCValid and lValid;
    TDocUtils.setControlValidity('HMRCPhoneEdit', Valid_Check[lValid]);

    if not HMRCValid then
    begin
      lMessage :=
        'This user is HMRC registered. If you change any of the fields highlighted, or leave them blank, then future submissions may from this user may fail. Are you sure you want to save the changes?';
      mr := await(TModalResult, UserChange.ShowDialog(lMessage, WEBLib.Dialogs.mtConfirmation, [mbYes, mbNo]));

      if mr <> mrYes then
        Exit;
    end;
  end;

  ModalResult := mrOk;
end;

procedure TUserEditForm.SetAvailableSeats(const Value: Integer);
begin
  FAvailableSeats := Value;

  { TODO : Need to be much cleverer with these. have to look at their status and available seats to see if their status and/or scope can be edited }

  LoadScope;
  LoadStatus;
end;

procedure TUserEditForm.SetDataset(const Value: TDataset);
begin
  UserDataSource.Dataset := Value;
  EmailEdit.Enabled := (not Dataset.FieldByName('EmailConfirmed').AsBoolean);
  FUser := TUserManagerUtils.DatasetToUser(Value);
  HMRCRegUpdate(UserDataSource.Dataset.FieldByName('HMRCRegistered').AsBoolean);
end;

procedure TUserEditForm.UserEditCancelButtonClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TUserEditForm.UserEditSaveButtonClick(Sender: TObject);
begin
  Dataset.FieldByName('HMRCRegistered').AsBoolean := HMRCRegEdit.Checked;
  Dataset.FieldByName('CanSubmit').AsBoolean := CanSubmitEdit.Checked;

  Validate;
end;

procedure TUserEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  InternalIdLabel := TDBLabel.Create('InternalIdLabel');
  UserEditMessage := TLabel.Create('UserEditMessage');
  GivenNameEdit := TDBEdit.Create('GivenNameEdit');
  LastNameEdit := TDBEdit.Create('LastNameEdit');
  TitleEdit := TDBEdit.Create('TitleEdit');
  PhoneNumberEdit := TDBEdit.Create('PhoneNumberEdit');
  EmailEdit := TDBEdit.Create('EmailEdit');
  HMRCPostCodeEdit := TDBEdit.Create('HMRCPostCodeEdit');
  HMRCPhoneEdit := TDBEdit.Create('HMRCPhoneEdit');
  UserScopeEdit := TDBLookupComboBox.Create('UserScopeEdit');
  UserStatusEdit := TDBLookupComboBox.Create('UserStatusEdit');
  CanSubmitEdit := TDBCheckBox.Create('CanSubmitEdit');
  HMRCRegEdit := TDBCheckBox.Create('HMRCRegEdit');
  UserEditCancelButton := TButton.Create('UserEditCancelButton');
  UserEditSaveButton := TButton.Create('UserEditSaveButton');
  UserChange := TMessageDlg.Create(Self);
  UserDataSource := TDataSource.Create(Self);

  InternalIdLabel.BeforeLoadDFMValues;
  UserEditMessage.BeforeLoadDFMValues;
  GivenNameEdit.BeforeLoadDFMValues;
  LastNameEdit.BeforeLoadDFMValues;
  TitleEdit.BeforeLoadDFMValues;
  PhoneNumberEdit.BeforeLoadDFMValues;
  EmailEdit.BeforeLoadDFMValues;
  HMRCPostCodeEdit.BeforeLoadDFMValues;
  HMRCPhoneEdit.BeforeLoadDFMValues;
  UserScopeEdit.BeforeLoadDFMValues;
  UserStatusEdit.BeforeLoadDFMValues;
  CanSubmitEdit.BeforeLoadDFMValues;
  HMRCRegEdit.BeforeLoadDFMValues;
  UserEditCancelButton.BeforeLoadDFMValues;
  UserEditSaveButton.BeforeLoadDFMValues;
  UserChange.BeforeLoadDFMValues;
  UserDataSource.BeforeLoadDFMValues;
  try
    CSSLibrary := cssBootstrap;
    ElementClassName := 'PopUpForm';
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnDestroy', 'WebFormDestroy');
    InternalIdLabel.SetParentComponent(Self);
    InternalIdLabel.Name := 'InternalIdLabel';
    InternalIdLabel.Left := 48;
    InternalIdLabel.Top := 32;
    InternalIdLabel.Width := 50;
    InternalIdLabel.Height := 15;
    InternalIdLabel.Caption := 'InternalId';
    InternalIdLabel.ElementClassName := 'form-control-manual';
    InternalIdLabel.ElementPosition := epIgnore;
    InternalIdLabel.HeightStyle := ssAuto;
    InternalIdLabel.HeightPercent := 100.000000000000000000;
    InternalIdLabel.HTMLType := tSPAN;
    InternalIdLabel.WidthStyle := ssAuto;
    InternalIdLabel.WidthPercent := 100.000000000000000000;
    InternalIdLabel.DataField := 'InternalId';
    InternalIdLabel.DataSource := UserDataSource;
    UserEditMessage.SetParentComponent(Self);
    UserEditMessage.Name := 'UserEditMessage';
    UserEditMessage.Left := 288;
    UserEditMessage.Top := 232;
    UserEditMessage.Width := 89;
    UserEditMessage.Height := 15;
    UserEditMessage.Caption := 'UserEditMessage';
    UserEditMessage.ElementClassName := 'UserEditMessage';
    UserEditMessage.ElementFont := efCSS;
    UserEditMessage.ElementPosition := epIgnore;
    UserEditMessage.HeightStyle := ssAuto;
    UserEditMessage.HeightPercent := 100.000000000000000000;
    UserEditMessage.HTMLType := tSPAN;
    UserEditMessage.Visible := False;
    UserEditMessage.WidthStyle := ssAuto;
    UserEditMessage.WidthPercent := 100.000000000000000000;
    GivenNameEdit.SetParentComponent(Self);
    GivenNameEdit.Name := 'GivenNameEdit';
    GivenNameEdit.Left := 48;
    GivenNameEdit.Top := 80;
    GivenNameEdit.Width := 121;
    GivenNameEdit.Height := 22;
    GivenNameEdit.ChildOrder := 1;
    GivenNameEdit.ElementClassName := 'form-control';
    GivenNameEdit.ElementFont := efCSS;
    GivenNameEdit.ElementPosition := epIgnore;
    GivenNameEdit.HeightStyle := ssAuto;
    GivenNameEdit.HeightPercent := 100.000000000000000000;
    GivenNameEdit.Text := 'GivenNameEdit';
    GivenNameEdit.WidthStyle := ssAuto;
    GivenNameEdit.WidthPercent := 100.000000000000000000;
    GivenNameEdit.DataField := 'GivenName';
    GivenNameEdit.DataSource := UserDataSource;
    LastNameEdit.SetParentComponent(Self);
    LastNameEdit.Name := 'LastNameEdit';
    LastNameEdit.Left := 48;
    LastNameEdit.Top := 108;
    LastNameEdit.Width := 121;
    LastNameEdit.Height := 22;
    LastNameEdit.ChildOrder := 1;
    LastNameEdit.ElementClassName := 'form-control';
    LastNameEdit.ElementFont := efCSS;
    LastNameEdit.ElementPosition := epIgnore;
    LastNameEdit.HeightStyle := ssAuto;
    LastNameEdit.HeightPercent := 100.000000000000000000;
    LastNameEdit.Text := 'LastNameEdit';
    LastNameEdit.WidthStyle := ssAuto;
    LastNameEdit.WidthPercent := 100.000000000000000000;
    LastNameEdit.DataField := 'LastName';
    LastNameEdit.DataSource := UserDataSource;
    TitleEdit.SetParentComponent(Self);
    TitleEdit.Name := 'TitleEdit';
    TitleEdit.Left := 48;
    TitleEdit.Top := 53;
    TitleEdit.Width := 121;
    TitleEdit.Height := 22;
    TitleEdit.ChildOrder := 1;
    TitleEdit.ElementClassName := 'form-control';
    TitleEdit.ElementFont := efCSS;
    TitleEdit.ElementPosition := epIgnore;
    TitleEdit.HeightStyle := ssAuto;
    TitleEdit.HeightPercent := 100.000000000000000000;
    TitleEdit.Text := 'TitleEdit';
    TitleEdit.WidthStyle := ssAuto;
    TitleEdit.WidthPercent := 100.000000000000000000;
    TitleEdit.DataField := 'Title';
    TitleEdit.DataSource := UserDataSource;
    PhoneNumberEdit.SetParentComponent(Self);
    PhoneNumberEdit.Name := 'PhoneNumberEdit';
    PhoneNumberEdit.Left := 48;
    PhoneNumberEdit.Top := 164;
    PhoneNumberEdit.Width := 121;
    PhoneNumberEdit.Height := 22;
    PhoneNumberEdit.ChildOrder := 1;
    PhoneNumberEdit.ElementClassName := 'form-control';
    PhoneNumberEdit.ElementFont := efCSS;
    PhoneNumberEdit.ElementPosition := epIgnore;
    PhoneNumberEdit.HeightStyle := ssAuto;
    PhoneNumberEdit.HeightPercent := 100.000000000000000000;
    PhoneNumberEdit.Text := 'PhoneNumberEdit';
    PhoneNumberEdit.WidthStyle := ssAuto;
    PhoneNumberEdit.WidthPercent := 100.000000000000000000;
    PhoneNumberEdit.DataField := 'PhoneNumber';
    PhoneNumberEdit.DataSource := UserDataSource;
    EmailEdit.SetParentComponent(Self);
    EmailEdit.Name := 'EmailEdit';
    EmailEdit.Left := 48;
    EmailEdit.Top := 136;
    EmailEdit.Width := 121;
    EmailEdit.Height := 22;
    EmailEdit.ChildOrder := 1;
    EmailEdit.ElementClassName := 'form-control';
    EmailEdit.ElementFont := efCSS;
    EmailEdit.ElementPosition := epIgnore;
    EmailEdit.HeightStyle := ssAuto;
    EmailEdit.HeightPercent := 100.000000000000000000;
    EmailEdit.Text := 'EmailEdit';
    EmailEdit.WidthStyle := ssAuto;
    EmailEdit.WidthPercent := 100.000000000000000000;
    EmailEdit.DataField := 'Email';
    EmailEdit.DataSource := UserDataSource;
    HMRCPostCodeEdit.SetParentComponent(Self);
    HMRCPostCodeEdit.Name := 'HMRCPostCodeEdit';
    HMRCPostCodeEdit.Left := 48;
    HMRCPostCodeEdit.Top := 336;
    HMRCPostCodeEdit.Width := 121;
    HMRCPostCodeEdit.Height := 22;
    HMRCPostCodeEdit.CharCase := wecUpperCase;
    HMRCPostCodeEdit.ChildOrder := 1;
    HMRCPostCodeEdit.ElementClassName := 'form-control';
    HMRCPostCodeEdit.ElementFont := efCSS;
    HMRCPostCodeEdit.ElementPosition := epIgnore;
    HMRCPostCodeEdit.HeightStyle := ssAuto;
    HMRCPostCodeEdit.HeightPercent := 100.000000000000000000;
    HMRCPostCodeEdit.Text := 'HMRCPostCodeEdit';
    HMRCPostCodeEdit.WidthStyle := ssAuto;
    HMRCPostCodeEdit.WidthPercent := 100.000000000000000000;
    HMRCPostCodeEdit.DataField := 'HMRCPostCode';
    HMRCPostCodeEdit.DataSource := UserDataSource;
    HMRCPhoneEdit.SetParentComponent(Self);
    HMRCPhoneEdit.Name := 'HMRCPhoneEdit';
    HMRCPhoneEdit.Left := 48;
    HMRCPhoneEdit.Top := 308;
    HMRCPhoneEdit.Width := 121;
    HMRCPhoneEdit.Height := 22;
    HMRCPhoneEdit.ChildOrder := 1;
    HMRCPhoneEdit.ElementClassName := 'form-control';
    HMRCPhoneEdit.ElementFont := efCSS;
    HMRCPhoneEdit.ElementPosition := epIgnore;
    HMRCPhoneEdit.HeightStyle := ssAuto;
    HMRCPhoneEdit.HeightPercent := 100.000000000000000000;
    HMRCPhoneEdit.Text := 'HMRCPhoneEdit';
    HMRCPhoneEdit.WidthStyle := ssAuto;
    HMRCPhoneEdit.WidthPercent := 100.000000000000000000;
    HMRCPhoneEdit.DataField := 'HMRCPhone';
    HMRCPhoneEdit.DataSource := UserDataSource;
    UserScopeEdit.SetParentComponent(Self);
    UserScopeEdit.Name := 'UserScopeEdit';
    UserScopeEdit.Left := 48;
    UserScopeEdit.Top := 196;
    UserScopeEdit.Width := 145;
    UserScopeEdit.Height := 22;
    UserScopeEdit.ElementClassName := 'form-select form-control';
    UserScopeEdit.ElementFont := efCSS;
    UserScopeEdit.ElementPosition := epIgnore;
    UserScopeEdit.HeightStyle := ssAuto;
    UserScopeEdit.HeightPercent := 100.000000000000000000;
    UserScopeEdit.WidthStyle := ssAuto;
    UserScopeEdit.WidthPercent := 100.000000000000000000;
    UserScopeEdit.DataField := 'UserScope';
    UserScopeEdit.DataSource := UserDataSource;
    UserScopeEdit.ListSource := MainData.DummySource;
    UserStatusEdit.SetParentComponent(Self);
    UserStatusEdit.Name := 'UserStatusEdit';
    UserStatusEdit.Left := 48;
    UserStatusEdit.Top := 224;
    UserStatusEdit.Width := 145;
    UserStatusEdit.Height := 22;
    UserStatusEdit.ElementClassName := 'form-select form-control';
    UserStatusEdit.ElementFont := efCSS;
    UserStatusEdit.ElementPosition := epIgnore;
    UserStatusEdit.HeightStyle := ssAuto;
    UserStatusEdit.HeightPercent := 100.000000000000000000;
    UserStatusEdit.WidthStyle := ssAuto;
    UserStatusEdit.WidthPercent := 100.000000000000000000;
    UserStatusEdit.DataField := 'UserStatus';
    UserStatusEdit.DataSource := UserDataSource;
    UserStatusEdit.ListSource := MainData.DummySource;
    CanSubmitEdit.SetParentComponent(Self);
    CanSubmitEdit.Name := 'CanSubmitEdit';
    CanSubmitEdit.Left := 48;
    CanSubmitEdit.Top := 252;
    CanSubmitEdit.Width := 113;
    CanSubmitEdit.Height := 22;
    CanSubmitEdit.Caption := 'Allowed to submit claims';
    CanSubmitEdit.ChildOrder := 10;
    CanSubmitEdit.ElementClassName := 'form-check-input';
    CanSubmitEdit.ElementLabelClassName := 'custom-control-label';
    CanSubmitEdit.ElementFont := efCSS;
    CanSubmitEdit.ElementPosition := epIgnore;
    CanSubmitEdit.HeightStyle := ssAuto;
    CanSubmitEdit.HeightPercent := 100.000000000000000000;
    CanSubmitEdit.WidthStyle := ssAuto;
    CanSubmitEdit.WidthPercent := 100.000000000000000000;
    CanSubmitEdit.DataField := 'CanSubmit';
    CanSubmitEdit.DataSource := UserDataSource;
    HMRCRegEdit.SetParentComponent(Self);
    HMRCRegEdit.Name := 'HMRCRegEdit';
    HMRCRegEdit.Left := 48;
    HMRCRegEdit.Top := 280;
    HMRCRegEdit.Width := 145;
    HMRCRegEdit.Height := 22;
    HMRCRegEdit.Caption := 'Registered with HMRC';
    HMRCRegEdit.ChildOrder := 10;
    HMRCRegEdit.ElementClassName := 'form-check-input';
    HMRCRegEdit.ElementButtonClassName := 'custom-control-input';
    HMRCRegEdit.ElementLabelClassName := 'custom-control-label';
    HMRCRegEdit.ElementFont := efCSS;
    HMRCRegEdit.ElementPosition := epIgnore;
    HMRCRegEdit.HeightStyle := ssAuto;
    HMRCRegEdit.HeightPercent := 100.000000000000000000;
    HMRCRegEdit.WidthStyle := ssAuto;
    HMRCRegEdit.WidthPercent := 100.000000000000000000;
    SetEvent(HMRCRegEdit, Self, 'OnClick', 'HMRCRegEditClick');
    HMRCRegEdit.DataField := 'HMRCRegistered';
    HMRCRegEdit.DataSource := UserDataSource;
    UserEditCancelButton.SetParentComponent(Self);
    UserEditCancelButton.Name := 'UserEditCancelButton';
    UserEditCancelButton.Left := 48;
    UserEditCancelButton.Top := 424;
    UserEditCancelButton.Width := 96;
    UserEditCancelButton.Height := 25;
    UserEditCancelButton.Caption := 'Cancel';
    UserEditCancelButton.ChildOrder := 12;
    UserEditCancelButton.ElementClassName := 'btn btn-primary';
    UserEditCancelButton.ElementFont := efCSS;
    UserEditCancelButton.ElementPosition := epIgnore;
    UserEditCancelButton.HeightStyle := ssAuto;
    UserEditCancelButton.HeightPercent := 100.000000000000000000;
    UserEditCancelButton.WidthStyle := ssAuto;
    UserEditCancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(UserEditCancelButton, Self, 'OnClick', 'UserEditCancelButtonClick');
    UserEditSaveButton.SetParentComponent(Self);
    UserEditSaveButton.Name := 'UserEditSaveButton';
    UserEditSaveButton.Left := 150;
    UserEditSaveButton.Top := 424;
    UserEditSaveButton.Width := 96;
    UserEditSaveButton.Height := 25;
    UserEditSaveButton.Caption := 'Save';
    UserEditSaveButton.ChildOrder := 12;
    UserEditSaveButton.ElementClassName := 'btn btn-secondary';
    UserEditSaveButton.ElementFont := efCSS;
    UserEditSaveButton.ElementPosition := epIgnore;
    UserEditSaveButton.HeightStyle := ssAuto;
    UserEditSaveButton.HeightPercent := 100.000000000000000000;
    UserEditSaveButton.WidthStyle := ssAuto;
    UserEditSaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(UserEditSaveButton, Self, 'OnClick', 'UserEditSaveButtonClick');
    UserChange.SetParentComponent(Self);
    UserChange.Name := 'UserChange';
    UserChange.Left := 584;
    UserChange.Top := 136;
    UserChange.Width := 24;
    UserChange.Height := 24;
    UserChange.HeightStyle := ssAuto;
    UserChange.WidthStyle := ssAuto;
    UserChange.Buttons := [];
    UserChange.Opacity := 0.200000000000000000;
    UserChange.ElementButtonClassName := 'btn';
    UserChange.ElementDialogClassName := 'shadow-lg p-3 mb-5 bg-white rounded';
    UserChange.ElementTitleClassName := 'text-body';
    UserChange.ElementContentClassName := 'text-body';
    UserDataSource.SetParentComponent(Self);
    UserDataSource.Name := 'UserDataSource';
    UserDataSource.Left := 440;
    UserDataSource.Top := 40;
  finally
    InternalIdLabel.AfterLoadDFMValues;
    UserEditMessage.AfterLoadDFMValues;
    GivenNameEdit.AfterLoadDFMValues;
    LastNameEdit.AfterLoadDFMValues;
    TitleEdit.AfterLoadDFMValues;
    PhoneNumberEdit.AfterLoadDFMValues;
    EmailEdit.AfterLoadDFMValues;
    HMRCPostCodeEdit.AfterLoadDFMValues;
    HMRCPhoneEdit.AfterLoadDFMValues;
    UserScopeEdit.AfterLoadDFMValues;
    UserStatusEdit.AfterLoadDFMValues;
    CanSubmitEdit.AfterLoadDFMValues;
    HMRCRegEdit.AfterLoadDFMValues;
    UserEditCancelButton.AfterLoadDFMValues;
    UserEditSaveButton.AfterLoadDFMValues;
    UserChange.AfterLoadDFMValues;
    UserDataSource.AfterLoadDFMValues;
  end;
end;

end.
