unit CommunityBuildingEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WebForm.Core, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.DBCtrls, WEBLib.ExtCtrls, Data.DB, WEBLib.DB;

type
  TCommunityBuildingEditForm = class(TCoreWebForm)
    CancelButtonCB: TButton;
    SaveButtonCB: TButton;
    CloseTimer: TTimer;
    BuildingNameEdit: TDBEdit;
    AddressEdit: TDBEdit;
    PostCodeEdit: TDBEdit;
    ExternalIdEdit: TDBEdit;
    StatusCombo: TDBComboBox;
    FormCaption: TLabel;
    MessageLabel: TLabel;
    DataSource: TDataSource;
    procedure AddressEditExit(Sender: TObject);
    procedure BuildingNameEditExit(Sender: TObject);
    procedure CancelButtonCBClick(Sender: TObject);
    procedure PostCodeEditExit(Sender: TObject);
    procedure SaveButtonCBClick(Sender: TObject);
  private
    { Private declarations }
    FPostCodeValid: boolean;
    procedure SetDataSet(Value: TDataSet);
    procedure Validate;
    procedure ValidatePostCode;
  public
    { Public declarations }
    property EditDataSet: TDataset write SetDataSet;
  protected procedure LoadDFMValues; override; end;

var
  CommunityBuildingEditForm: TCommunityBuildingEditForm;

implementation

uses
  SMX.Web.Document.Utils, SMX.CRM.Utils;

{$R *.dfm}

procedure TCommunityBuildingEditForm.AddressEditExit(Sender: TObject);
begin
  if AddressEdit.Text <> '' then
    TDocUtils.setControlValidity('AddressEdit', vsValid)
  else
    TDocUtils.setControlValidity('AddressEdit', vsInvalid);
  Validate;
end;

procedure TCommunityBuildingEditForm.BuildingNameEditExit(Sender: TObject);
begin
  if BuildingNameEdit.Text <> '' then
    TDocUtils.setControlValidity('BuildingNameEdit', vsValid)
  else
    TDocUtils.setControlValidity('BuildingNameEdit', vsInvalid);
  Validate;
end;

procedure TCommunityBuildingEditForm.CancelButtonCBClick(Sender: TObject);
begin
  inherited;
  ModalResult := mrCancel;
end;

procedure TCommunityBuildingEditForm.PostCodeEditExit(Sender: TObject);
begin
  ValidatePostCode;
end;

procedure TCommunityBuildingEditForm.SaveButtonCBClick(Sender: TObject);
begin
  inherited;
  ModalResult := mrOk;
end;

procedure TCommunityBuildingEditForm.SetDataSet(Value: TDataSet);
begin
  DataSource.DataSet := Value;

  //Sets Status to Active
  if DataSource.DataSet.State = dsInsert then
    StatusCombo.ItemIndex := 0;
  SaveButtonCB.Enabled := false;
end;

procedure TCommunityBuildingEditForm.Validate;
begin
  if (BuildingNameEdit.Text <> '') and (AddressEdit.Text <> '') and
     (PostCodeEdit.Text <> '') and (StatusCombo.ItemIndex > -1) and
     (FPostCodeValid = True) then
    SaveButtonCB.Enabled := True
  else
    SaveButtonCB.Enabled := False;
end;

procedure TCommunityBuildingEditForm.ValidatePostCode;
begin
  if (Trim(PostCodeEdit.Text) = '') then
  begin
    TDocUtils.setControlValidity('PostCodeEdit', vsInvalid);
    FPostCodeValid := false;
    Exit;
  end;

  PostCodeEdit.Text := TCRMUtils.FormatPostCode(PostCodeEdit.Text);
  if TCRMUtils.IsPostCode(PostCodeEdit.text) <> pcFull then
  begin
    TDocUtils.setControlValidity('PostCodeEdit', vsInvalid);
    FPostCodeValid := false;
    Exit;
  end;
  TDocUtils.setControlValidity('PostCodeEdit', vsValid);
  FPostCodeValid := True;
  Validate;
end;


procedure TCommunityBuildingEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormCaption := TLabel.Create('FormCaptionCB');
  MessageLabel := TLabel.Create('MessageLabel');
  CancelButtonCB := TButton.Create('CancelButtonCB');
  SaveButtonCB := TButton.Create('SaveButtonCB');
  BuildingNameEdit := TDBEdit.Create('BuildingNameEdit');
  AddressEdit := TDBEdit.Create('AddressEdit');
  PostCodeEdit := TDBEdit.Create('PostCodeEdit');
  ExternalIdEdit := TDBEdit.Create('ExternalIdEdit');
  StatusCombo := TDBComboBox.Create('StatusCombo');
  CloseTimer := TTimer.Create(Self);
  DataSource := TDataSource.Create(Self);

  FormCaption.BeforeLoadDFMValues;
  MessageLabel.BeforeLoadDFMValues;
  CancelButtonCB.BeforeLoadDFMValues;
  SaveButtonCB.BeforeLoadDFMValues;
  BuildingNameEdit.BeforeLoadDFMValues;
  AddressEdit.BeforeLoadDFMValues;
  PostCodeEdit.BeforeLoadDFMValues;
  ExternalIdEdit.BeforeLoadDFMValues;
  StatusCombo.BeforeLoadDFMValues;
  CloseTimer.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  try
    FormCaption.SetParentComponent(Self);
    FormCaption.Name := 'FormCaption';
    FormCaption.Left := 72;
    FormCaption.Top := 12;
    FormCaption.Width := 139;
    FormCaption.Height := 15;
    FormCaption.Margins.Left := 5;
    FormCaption.Margins.Top := 5;
    FormCaption.Margins.Right := 5;
    FormCaption.Margins.Bottom := 5;
    FormCaption.Caption := 'Community Buildings Edit';
    FormCaption.ElementClassName := 'HeadlineClass';
    FormCaption.ElementFont := efCSS;
    FormCaption.ElementPosition := epIgnore;
    FormCaption.HeightStyle := ssAuto;
    FormCaption.HeightPercent := 100.000000000000000000;
    FormCaption.HTMLType := tDIV;
    FormCaption.WidthStyle := ssAuto;
    FormCaption.WidthPercent := 100.000000000000000000;
    MessageLabel.SetParentComponent(Self);
    MessageLabel.Name := 'MessageLabel';
    MessageLabel.Left := 68;
    MessageLabel.Top := 41;
    MessageLabel.Width := 74;
    MessageLabel.Height := 15;
    MessageLabel.Margins.Left := 5;
    MessageLabel.Margins.Top := 5;
    MessageLabel.Margins.Right := 5;
    MessageLabel.Margins.Bottom := 5;
    MessageLabel.Caption := 'MessageLabel';
    MessageLabel.ElementFont := efCSS;
    MessageLabel.ElementPosition := epIgnore;
    MessageLabel.HeightStyle := ssAuto;
    MessageLabel.HeightPercent := 100.000000000000000000;
    MessageLabel.HTMLType := tDIV;
    MessageLabel.WidthStyle := ssAuto;
    MessageLabel.WidthPercent := 100.000000000000000000;
    CancelButtonCB.SetParentComponent(Self);
    CancelButtonCB.Name := 'CancelButtonCB';
    CancelButtonCB.Left := 236;
    CancelButtonCB.Top := 442;
    CancelButtonCB.Width := 144;
    CancelButtonCB.Height := 38;
    CancelButtonCB.Margins.Left := 5;
    CancelButtonCB.Margins.Top := 5;
    CancelButtonCB.Margins.Right := 5;
    CancelButtonCB.Margins.Bottom := 5;
    CancelButtonCB.Caption := 'Cancel';
    CancelButtonCB.ChildOrder := 3;
    CancelButtonCB.ElementClassName := 'btn btn-primary';
    CancelButtonCB.ElementFont := efCSS;
    CancelButtonCB.ElementPosition := epIgnore;
    CancelButtonCB.HeightStyle := ssAuto;
    CancelButtonCB.HeightPercent := 100.000000000000000000;
    CancelButtonCB.WidthStyle := ssAuto;
    CancelButtonCB.WidthPercent := 100.000000000000000000;
    SetEvent(CancelButtonCB, Self, 'OnClick', 'CancelButtonCBClick');
    SaveButtonCB.SetParentComponent(Self);
    SaveButtonCB.Name := 'SaveButtonCB';
    SaveButtonCB.Left := 82;
    SaveButtonCB.Top := 442;
    SaveButtonCB.Width := 144;
    SaveButtonCB.Height := 38;
    SaveButtonCB.Margins.Left := 5;
    SaveButtonCB.Margins.Top := 5;
    SaveButtonCB.Margins.Right := 5;
    SaveButtonCB.Margins.Bottom := 5;
    SaveButtonCB.Caption := 'Save';
    SaveButtonCB.ChildOrder := 2;
    SaveButtonCB.ElementClassName := 'btn btn-secondary';
    SaveButtonCB.ElementFont := efCSS;
    SaveButtonCB.ElementPosition := epIgnore;
    SaveButtonCB.HeightStyle := ssAuto;
    SaveButtonCB.HeightPercent := 100.000000000000000000;
    SaveButtonCB.WidthStyle := ssAuto;
    SaveButtonCB.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButtonCB, Self, 'OnClick', 'SaveButtonCBClick');
    BuildingNameEdit.SetParentComponent(Self);
    BuildingNameEdit.Name := 'BuildingNameEdit';
    BuildingNameEdit.Left := 54;
    BuildingNameEdit.Top := 128;
    BuildingNameEdit.Width := 297;
    BuildingNameEdit.Height := 22;
    BuildingNameEdit.ChildOrder := 3;
    BuildingNameEdit.ElementClassName := 'form-control';
    BuildingNameEdit.ElementPosition := epIgnore;
    BuildingNameEdit.HeightStyle := ssAuto;
    BuildingNameEdit.HeightPercent := 100.000000000000000000;
    BuildingNameEdit.Text := 'BuildingNameEdit';
    BuildingNameEdit.WidthStyle := ssAuto;
    BuildingNameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(BuildingNameEdit, Self, 'OnExit', 'BuildingNameEditExit');
    BuildingNameEdit.DataField := 'BuildingName';
    BuildingNameEdit.DataSource := DataSource;
    AddressEdit.SetParentComponent(Self);
    AddressEdit.Name := 'AddressEdit';
    AddressEdit.Left := 54;
    AddressEdit.Top := 156;
    AddressEdit.Width := 297;
    AddressEdit.Height := 22;
    AddressEdit.ChildOrder := 4;
    AddressEdit.ElementClassName := 'form-control';
    AddressEdit.ElementPosition := epIgnore;
    AddressEdit.HeightStyle := ssAuto;
    AddressEdit.HeightPercent := 100.000000000000000000;
    AddressEdit.TabOrder := 1;
    AddressEdit.Text := 'AddressEdit';
    AddressEdit.WidthStyle := ssAuto;
    AddressEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AddressEdit, Self, 'OnExit', 'AddressEditExit');
    AddressEdit.DataField := 'Address';
    AddressEdit.DataSource := DataSource;
    PostCodeEdit.SetParentComponent(Self);
    PostCodeEdit.Name := 'PostCodeEdit';
    PostCodeEdit.Left := 54;
    PostCodeEdit.Top := 184;
    PostCodeEdit.Width := 89;
    PostCodeEdit.Height := 22;
    PostCodeEdit.ChildOrder := 5;
    PostCodeEdit.ElementClassName := 'form-control';
    PostCodeEdit.ElementPosition := epIgnore;
    PostCodeEdit.HeightStyle := ssAuto;
    PostCodeEdit.HeightPercent := 100.000000000000000000;
    PostCodeEdit.TabOrder := 2;
    PostCodeEdit.Text := 'PostCodeEdit';
    PostCodeEdit.WidthStyle := ssAuto;
    PostCodeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(PostCodeEdit, Self, 'OnExit', 'PostCodeEditExit');
    PostCodeEdit.DataField := 'PostCode';
    PostCodeEdit.DataSource := DataSource;
    ExternalIdEdit.SetParentComponent(Self);
    ExternalIdEdit.Name := 'ExternalIdEdit';
    ExternalIdEdit.Left := 54;
    ExternalIdEdit.Top := 212;
    ExternalIdEdit.Width := 297;
    ExternalIdEdit.Height := 22;
    ExternalIdEdit.ChildOrder := 6;
    ExternalIdEdit.ElementClassName := 'form-control';
    ExternalIdEdit.ElementPosition := epIgnore;
    ExternalIdEdit.HeightStyle := ssAuto;
    ExternalIdEdit.HeightPercent := 100.000000000000000000;
    ExternalIdEdit.TabOrder := 3;
    ExternalIdEdit.Text := 'ExternalIdEdit';
    ExternalIdEdit.WidthStyle := ssAuto;
    ExternalIdEdit.WidthPercent := 100.000000000000000000;
    ExternalIdEdit.DataField := 'ExternalId';
    ExternalIdEdit.DataSource := DataSource;
    StatusCombo.SetParentComponent(Self);
    StatusCombo.Name := 'StatusCombo';
    StatusCombo.Left := 54;
    StatusCombo.Top := 253;
    StatusCombo.Width := 145;
    StatusCombo.Height := 23;
    StatusCombo.ElementClassName := 'form-select';
    StatusCombo.ElementPosition := epIgnore;
    StatusCombo.HeightStyle := ssAuto;
    StatusCombo.HeightPercent := 100.000000000000000000;
    StatusCombo.Text := 'StatusCombo';
    StatusCombo.WidthStyle := ssAuto;
    StatusCombo.WidthPercent := 100.000000000000000000;
    StatusCombo.ItemIndex := -1;
    StatusCombo.Items.BeginUpdate;
    try
      StatusCombo.Items.Clear;
      StatusCombo.Items.Add('Active');
      StatusCombo.Items.Add('Inactive');
    finally
      StatusCombo.Items.EndUpdate;
    end;
    StatusCombo.DataField := 'GAStatus';
    StatusCombo.DataSource := DataSource;
    CloseTimer.SetParentComponent(Self);
    CloseTimer.Name := 'CloseTimer';
    CloseTimer.Enabled := False;
    CloseTimer.Interval := 2000;
    CloseTimer.Left := 72;
    CloseTimer.Top := 376;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.Left := 432;
    DataSource.Top := 96;
  finally
    FormCaption.AfterLoadDFMValues;
    MessageLabel.AfterLoadDFMValues;
    CancelButtonCB.AfterLoadDFMValues;
    SaveButtonCB.AfterLoadDFMValues;
    BuildingNameEdit.AfterLoadDFMValues;
    AddressEdit.AfterLoadDFMValues;
    PostCodeEdit.AfterLoadDFMValues;
    ExternalIdEdit.AfterLoadDFMValues;
    StatusCombo.AfterLoadDFMValues;
    CloseTimer.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
  end;
end;

end.