unit RegisterPending;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs;

type
  TPendingWebForm = class(TForm)
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
    class procedure DisplayPage(const AElementId: string);
  protected procedure LoadDFMValues; override; end;

var
  PendingWebForm: TPendingWebForm;

implementation

{$R *.dfm}

uses
  MainDataModule,
  MainForm;

{ TPendingWebForm }

class procedure TPendingWebForm.DisplayPage(const AElementId: string);

  procedure AfterCreate(AForm: TObject);
  begin
    //
  end;

begin
  if not Assigned(MainPage) then
  begin
    PendingWebForm := TPendingWebForm.CreateNew(AElementId, @AfterCreate);
  end

end;

procedure TPendingWebForm.WebFormShow(Sender: TObject);
begin
    MainData.SphinxLogin.Logout;
end;

procedure TPendingWebForm.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'PendingWebForm';
    Width := 640;
    Height := 480;
    SetEvent(Self, 'OnShow', 'WebFormShow');
  finally
  end;
end;

end.
