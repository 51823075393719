unit UserManager;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WebForm.Core,
  Data.DB,
  WEBLib.CDS,
  WEBLib.DB,
  Vcl.Controls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.REST,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  GA.Return.Types;

type
  TUserManagerList = class(TCoreWebForm)
    UserDataTable: TDBTableControl;
    UsersDataSource: TDataSource;
    UsersDataset: TClientDataSet;
    LicensedUserLabel: TLabel;
    ActiveUsersLabel: TLabel;
    UsersDatasetOrganisationId: TIntegerField;
    UsersDatasetOrganisationName: TStringField;
    UsersDatasetTenantId: TStringField;
    UsersDatasetJobTitle: TStringField;
    UsersDatasetJobPosition: TStringField;
    UsersDatasetTitle: TStringField;
    UsersDatasetGivenName: TStringField;
    UsersDatasetLastName: TStringField;
    UsersDatasetEmail: TStringField;
    UsersDatasetPhoneNumber: TStringField;
    UsersDatasetUserStatus: TStringField;
    UsersDatasetUserScope: TStringField;
    UsersDatasetHMRCPhone: TStringField;
    UsersDatasetHMRCPostCode: TStringField;
    UsersDatasetHMRCRegistered: TBooleanField;
    UsersDatasetCanSubmit: TBooleanField;
    UsersDatasetInternalId: TIntegerField;
    NewUserButton: TButton;
    NewUserLabel: TLabel;
    InactiveUsersLabel: TLabel;
    ArchivedUsersLabel: TLabel;
    UsersDatasetEmailConfirmed: TBooleanField;
    procedure NewUserButtonClick(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
    procedure UserDataTableGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string;
      AElement: TJSHTMLElementRecord);
    procedure UsersDatasetCanSubmitGetText(Sender: TField; var Text: string;
        DisplayText: Boolean);
    procedure UsersDatasetHMRCRegisteredGetText(Sender: TField; var Text: string;
        DisplayText: Boolean);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FUserCounts: TUserCounts;
    [Async]
    procedure LoadData; async;
    [Async]
    procedure GetUserCounts; async;
    procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);
    [Async]
    procedure EditRecord(const ARecordId: string); async;
    [Async]
    function UpdateUser: Boolean; async;

  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  UserManagerList: TUserManagerList;

implementation

{$R *.dfm}

uses
  XData.Web.Client,
  WEBLib.WebCtrls,
  MainDataModule,
  UserEdit,
  SMX.Web.Layout.Utils,
  UserManager.Utils, SMX.Web.Utils;

procedure TUserManagerList.WebFormDestroy(Sender: TObject);
begin
  if Assigned(FUserCounts) then
    FUserCounts.Free;
  inherited;
end;

procedure TUserManagerList.WebFormCreate(Sender: TObject);
begin
  inherited;
  LoadData;
  GetUserCounts;
end;

{ TUserManagerForm }

procedure TUserManagerList.AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);

  procedure EditClick(Sender: TObject);
  begin
    EditRecord(ARecordId);
  end;

//const
//  Editable_Statii = 'Active,Inactive';
var
  Span: THTMLSpan;
begin
{ TODO : Need to be much cleverer with these. have to look at their status and available seats to see if their status and/or scope can be edited }
//  if Editable_Statii.Contains(UsersDatasetUserStatus.Value) then
//  begin
    Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-edit', 'Edit');
    Span.OnClick := @EditClick;
//  end;

end;

procedure TUserManagerList.EditRecord(const ARecordId: string);
var
  AForm: TUserEditForm;
  Retval: TModalResult;
  lUpdateResult: Boolean;
begin
  AForm := TUserEditForm.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TUserEditForm, AForm.Load());

    if ARecordId = '' then
    begin
      AForm.AvailableSeats := FUserCounts.AvailableSeats;
      UsersDataset.Insert;
    end
    else
    begin
      if UsersDatasetUserStatus.Value = 'Inactive' then
        AForm.AvailableSeats := FUserCounts.AvailableSeats
      else
        AForm.AvailableSeats := 1;
      UsersDataset.Locate('InternalId', ARecordId, []);
      UsersDataset.Edit;
    end;

    AForm.Dataset := UsersDataset;
    // Aform.AfterCreated;
    Retval := await(TModalResult, AForm.Execute);
    if Retval = mrOk then
    begin
      lUpdateResult := await(UpdateUser);
      UsersDataset.Post;
    end
    else
      UsersDataset.Cancel;
  finally
    AForm.Free;
    AForm := nil;
  end;

end;

procedure TUserManagerList.GetUserCounts;
const
  svc_name = 'IUserManagerService.UserCount';
var
  lRetval: TXDataClientResponse;
  lResponse: TJSObject;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(svc_name, []));
  lResponse := lRetval.ResultAsObject;
  FUserCounts := TUserCounts.Create;
  FUserCounts.Assign(lResponse);

  ActiveUsersLabel.Caption := FUserCounts.Active.ToString;
  LicensedUserLabel.Caption := FUserCounts.Licensed.ToString;
  InactiveUsersLabel.Caption := FUserCounts.Inactive.ToString;
  ArchivedUsersLabel.Caption := FUserCounts.Archived.ToString;
  NewUserLabel.Visible := FUserCounts.AvailableSeats = 0;
end;

procedure TUserManagerList.LoadData;
const
  svc_name = 'IUserManagerService.GetUserList';
var
  lRetval: TXDataClientResponse;
  lResponse: TJSObject;
  lData: TJSArray;
begin

  UsersDataset.Active := False;
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(svc_name, []));
  lResponse := lRetval.ResultAsObject;
  lData := TJSArray(lResponse['value']);

  UsersDataset.Rows := lData;
  UsersDataset.Active := True;

end;

procedure TUserManagerList.NewUserButtonClick(Sender: TObject);
begin
  EditRecord('');
end;

function TUserManagerList.UpdateUser: Boolean;
const
  svc_name = 'IUserManagerService.UpdateUser';
var
  AUser, AResponseUser: TGiftAidUser;
  lRetval: TXDataClientResponse;
  lResponse: TJSObject;
begin
  AUser := TUserManagerUtils.DatasetToUser(UsersDataset);
  try
    lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(svc_name, [AUser]));
    lResponse := lRetval.ResultAsObject;
    AResponseUser := TGiftAidUser.Create;
    try
      AResponseUser.Assign(lResponse);
      TUserManagerUtils.UserToDataset(AResponseUser, UsersDataset);
    finally
      AResponseUser.Free;
    end;
  finally
    AUser.Free;
  end;

end;

procedure TUserManagerList.UserDataTableGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField;
  AValue: string; AElement: TJSHTMLElementRecord);
var
  RecordId: string;
begin
  if ARow = 0 then
    Exit;

  if UserDataTable.Columns[ACol].Title = 'Actions' then
  begin
    RecordId := UsersDataset.FieldByName('InternalId').AsString;
    AddRowActions(RecordId, AElement.Element);
  end;
end;

procedure TUserManagerList.UsersDatasetCanSubmitGetText(Sender: TField; var
    Text: string; DisplayText: Boolean);
begin
  if Sender.AsBoolean = true then
    Text := 'Yes'
  else
    Text := 'No';
end;

procedure TUserManagerList.UsersDatasetHMRCRegisteredGetText(Sender: TField;
    var Text: string; DisplayText: Boolean);
begin
  if Sender.AsBoolean = true then
    Text := 'Yes'
  else
    Text := 'No';
end;

procedure TUserManagerList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  LicensedUserLabel := TLabel.Create('LicensedUserLabel');
  ActiveUsersLabel := TLabel.Create('ActiveUsersLabel');
  NewUserLabel := TLabel.Create('NewUserLabel');
  InactiveUsersLabel := TLabel.Create('InactiveUsersLabel');
  ArchivedUsersLabel := TLabel.Create('ArchivedUsersLabel');
  UserDataTable := TDBTableControl.Create('UserDataTable');
  NewUserButton := TButton.Create('NewUserButton');
  UsersDataSource := TDataSource.Create(Self);
  UsersDataset := TClientDataSet.Create(Self);
  UsersDatasetInternalId := TIntegerField.Create(Self);
  UsersDatasetOrganisationId := TIntegerField.Create(Self);
  UsersDatasetOrganisationName := TStringField.Create(Self);
  UsersDatasetTenantId := TStringField.Create(Self);
  UsersDatasetJobTitle := TStringField.Create(Self);
  UsersDatasetJobPosition := TStringField.Create(Self);
  UsersDatasetTitle := TStringField.Create(Self);
  UsersDatasetGivenName := TStringField.Create(Self);
  UsersDatasetLastName := TStringField.Create(Self);
  UsersDatasetEmail := TStringField.Create(Self);
  UsersDatasetEmailConfirmed := TBooleanField.Create(Self);
  UsersDatasetPhoneNumber := TStringField.Create(Self);
  UsersDatasetUserStatus := TStringField.Create(Self);
  UsersDatasetUserScope := TStringField.Create(Self);
  UsersDatasetHMRCPhone := TStringField.Create(Self);
  UsersDatasetHMRCPostCode := TStringField.Create(Self);
  UsersDatasetHMRCRegistered := TBooleanField.Create(Self);
  UsersDatasetCanSubmit := TBooleanField.Create(Self);

  LicensedUserLabel.BeforeLoadDFMValues;
  ActiveUsersLabel.BeforeLoadDFMValues;
  NewUserLabel.BeforeLoadDFMValues;
  InactiveUsersLabel.BeforeLoadDFMValues;
  ArchivedUsersLabel.BeforeLoadDFMValues;
  UserDataTable.BeforeLoadDFMValues;
  NewUserButton.BeforeLoadDFMValues;
  UsersDataSource.BeforeLoadDFMValues;
  UsersDataset.BeforeLoadDFMValues;
  UsersDatasetInternalId.BeforeLoadDFMValues;
  UsersDatasetOrganisationId.BeforeLoadDFMValues;
  UsersDatasetOrganisationName.BeforeLoadDFMValues;
  UsersDatasetTenantId.BeforeLoadDFMValues;
  UsersDatasetJobTitle.BeforeLoadDFMValues;
  UsersDatasetJobPosition.BeforeLoadDFMValues;
  UsersDatasetTitle.BeforeLoadDFMValues;
  UsersDatasetGivenName.BeforeLoadDFMValues;
  UsersDatasetLastName.BeforeLoadDFMValues;
  UsersDatasetEmail.BeforeLoadDFMValues;
  UsersDatasetEmailConfirmed.BeforeLoadDFMValues;
  UsersDatasetPhoneNumber.BeforeLoadDFMValues;
  UsersDatasetUserStatus.BeforeLoadDFMValues;
  UsersDatasetUserScope.BeforeLoadDFMValues;
  UsersDatasetHMRCPhone.BeforeLoadDFMValues;
  UsersDatasetHMRCPostCode.BeforeLoadDFMValues;
  UsersDatasetHMRCRegistered.BeforeLoadDFMValues;
  UsersDatasetCanSubmit.BeforeLoadDFMValues;
  try
    Width := 841;
    Height := 594;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnDestroy', 'WebFormDestroy');
    LicensedUserLabel.SetParentComponent(Self);
    LicensedUserLabel.Name := 'LicensedUserLabel';
    LicensedUserLabel.Left := 64;
    LicensedUserLabel.Top := 24;
    LicensedUserLabel.Width := 49;
    LicensedUserLabel.Height := 15;
    LicensedUserLabel.Caption := 'Licensed:';
    LicensedUserLabel.ElementPosition := epIgnore;
    LicensedUserLabel.HeightStyle := ssAuto;
    LicensedUserLabel.HeightPercent := 100.000000000000000000;
    LicensedUserLabel.HTMLType := tSPAN;
    LicensedUserLabel.WidthStyle := ssAuto;
    LicensedUserLabel.WidthPercent := 100.000000000000000000;
    ActiveUsersLabel.SetParentComponent(Self);
    ActiveUsersLabel.Name := 'ActiveUsersLabel';
    ActiveUsersLabel.Left := 64;
    ActiveUsersLabel.Top := 45;
    ActiveUsersLabel.Width := 67;
    ActiveUsersLabel.Height := 15;
    ActiveUsersLabel.Caption := 'Active Users:';
    ActiveUsersLabel.ElementPosition := epIgnore;
    ActiveUsersLabel.HeightStyle := ssAuto;
    ActiveUsersLabel.HeightPercent := 100.000000000000000000;
    ActiveUsersLabel.HTMLType := tSPAN;
    ActiveUsersLabel.WidthStyle := ssAuto;
    ActiveUsersLabel.WidthPercent := 100.000000000000000000;
    NewUserLabel.SetParentComponent(Self);
    NewUserLabel.Name := 'NewUserLabel';
    NewUserLabel.Left := 376;
    NewUserLabel.Top := 72;
    NewUserLabel.Width := 303;
    NewUserLabel.Height := 15;
    NewUserLabel.Caption := 'You do not have enough seats available to add new users.';
    NewUserLabel.ElementFont := efCSS;
    NewUserLabel.ElementPosition := epIgnore;
    NewUserLabel.HeightStyle := ssAuto;
    NewUserLabel.HeightPercent := 100.000000000000000000;
    NewUserLabel.HTMLType := tSPAN;
    NewUserLabel.WidthStyle := ssAuto;
    NewUserLabel.WidthPercent := 100.000000000000000000;
    InactiveUsersLabel.SetParentComponent(Self);
    InactiveUsersLabel.Name := 'InactiveUsersLabel';
    InactiveUsersLabel.Left := 64;
    InactiveUsersLabel.Top := 66;
    InactiveUsersLabel.Width := 75;
    InactiveUsersLabel.Height := 15;
    InactiveUsersLabel.Caption := 'Inactive Users:';
    InactiveUsersLabel.ElementFont := efCSS;
    InactiveUsersLabel.ElementPosition := epIgnore;
    InactiveUsersLabel.HeightStyle := ssAuto;
    InactiveUsersLabel.HeightPercent := 100.000000000000000000;
    InactiveUsersLabel.HTMLType := tSPAN;
    InactiveUsersLabel.WidthStyle := ssAuto;
    InactiveUsersLabel.WidthPercent := 100.000000000000000000;
    ArchivedUsersLabel.SetParentComponent(Self);
    ArchivedUsersLabel.Name := 'ArchivedUsersLabel';
    ArchivedUsersLabel.Left := 64;
    ArchivedUsersLabel.Top := 87;
    ArchivedUsersLabel.Width := 67;
    ArchivedUsersLabel.Height := 15;
    ArchivedUsersLabel.Caption := 'Active Users:';
    ArchivedUsersLabel.ElementFont := efCSS;
    ArchivedUsersLabel.ElementPosition := epIgnore;
    ArchivedUsersLabel.HeightStyle := ssAuto;
    ArchivedUsersLabel.HeightPercent := 100.000000000000000000;
    ArchivedUsersLabel.HTMLType := tSPAN;
    ArchivedUsersLabel.WidthStyle := ssAuto;
    ArchivedUsersLabel.WidthPercent := 100.000000000000000000;
    UserDataTable.SetParentComponent(Self);
    UserDataTable.Name := 'UserDataTable';
    UserDataTable.Left := 32;
    UserDataTable.Top := 136;
    UserDataTable.Width := 513;
    UserDataTable.Height := 249;
    UserDataTable.HeightStyle := ssAuto;
    UserDataTable.WidthStyle := ssAuto;
    UserDataTable.BorderColor := clSilver;
    UserDataTable.ChildOrder := 4;
    UserDataTable.ElementFont := efCSS;
    UserDataTable.ElementHeaderClassName := 'thead-light';
    UserDataTable.ElementPosition := epIgnore;
    UserDataTable.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    SetEvent(UserDataTable, Self, 'OnGetCellChildren', 'UserDataTableGetCellChildren');
    UserDataTable.Columns.Clear;
    with UserDataTable.Columns.Add do
    begin
      DataField := 'InternalId';
      Title := 'User Id';
    end;
    with UserDataTable.Columns.Add do
    begin
      DataField := 'GivenName';
      Title := 'First Name';
    end;
    with UserDataTable.Columns.Add do
    begin
      DataField := 'LastName';
      Title := 'Last Name';
    end;
    with UserDataTable.Columns.Add do
    begin
      DataField := 'UserStatus';
      Title := 'Status';
    end;
    with UserDataTable.Columns.Add do
    begin
      DataField := 'UserScope';
      Title := 'Scope';
    end;
    with UserDataTable.Columns.Add do
    begin
      DataField := 'CanSubmit';
      Title := 'Can Submit';
    end;
    with UserDataTable.Columns.Add do
    begin
      DataField := 'HMRCRegistered';
      Title := 'HMRC Registered';
    end;
    with UserDataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    UserDataTable.DataSource := UsersDataSource;
    NewUserButton.SetParentComponent(Self);
    NewUserButton.Name := 'NewUserButton';
    NewUserButton.Left := 368;
    NewUserButton.Top := 41;
    NewUserButton.Width := 96;
    NewUserButton.Height := 25;
    NewUserButton.Caption := 'New User';
    NewUserButton.ChildOrder := 3;
    NewUserButton.ElementClassName := 'btn btn-primary';
    NewUserButton.ElementPosition := epIgnore;
    NewUserButton.HeightStyle := ssAuto;
    NewUserButton.HeightPercent := 100.000000000000000000;
    NewUserButton.WidthStyle := ssAuto;
    NewUserButton.WidthPercent := 100.000000000000000000;
    SetEvent(NewUserButton, Self, 'OnClick', 'NewUserButtonClick');
    UsersDataSource.SetParentComponent(Self);
    UsersDataSource.Name := 'UsersDataSource';
    UsersDataSource.DataSet := UsersDataset;
    UsersDataSource.Left := 280;
    UsersDataSource.Top := 24;
    UsersDataset.SetParentComponent(Self);
    UsersDataset.Name := 'UsersDataset';
    UsersDataset.Left := 176;
    UsersDataset.Top := 16;
    UsersDatasetInternalId.SetParentComponent(UsersDataset);
    UsersDatasetInternalId.Name := 'UsersDatasetInternalId';
    UsersDatasetInternalId.FieldName := 'InternalId';
    UsersDatasetOrganisationId.SetParentComponent(UsersDataset);
    UsersDatasetOrganisationId.Name := 'UsersDatasetOrganisationId';
    UsersDatasetOrganisationId.FieldName := 'OrganisationId';
    UsersDatasetOrganisationName.SetParentComponent(UsersDataset);
    UsersDatasetOrganisationName.Name := 'UsersDatasetOrganisationName';
    UsersDatasetOrganisationName.FieldName := 'OrganisationName';
    UsersDatasetOrganisationName.Size := 250;
    UsersDatasetTenantId.SetParentComponent(UsersDataset);
    UsersDatasetTenantId.Name := 'UsersDatasetTenantId';
    UsersDatasetTenantId.FieldName := 'TenantId';
    UsersDatasetTenantId.Size := 36;
    UsersDatasetJobTitle.SetParentComponent(UsersDataset);
    UsersDatasetJobTitle.Name := 'UsersDatasetJobTitle';
    UsersDatasetJobTitle.FieldName := 'JobTitle';
    UsersDatasetJobTitle.Size := 255;
    UsersDatasetJobPosition.SetParentComponent(UsersDataset);
    UsersDatasetJobPosition.Name := 'UsersDatasetJobPosition';
    UsersDatasetJobPosition.FieldName := 'JobPosition';
    UsersDatasetJobPosition.Size := 75;
    UsersDatasetTitle.SetParentComponent(UsersDataset);
    UsersDatasetTitle.Name := 'UsersDatasetTitle';
    UsersDatasetTitle.FieldName := 'Title';
    UsersDatasetTitle.Size := 25;
    UsersDatasetGivenName.SetParentComponent(UsersDataset);
    UsersDatasetGivenName.Name := 'UsersDatasetGivenName';
    UsersDatasetGivenName.FieldName := 'GivenName';
    UsersDatasetGivenName.Size := 50;
    UsersDatasetLastName.SetParentComponent(UsersDataset);
    UsersDatasetLastName.Name := 'UsersDatasetLastName';
    UsersDatasetLastName.FieldName := 'LastName';
    UsersDatasetLastName.Size := 50;
    UsersDatasetEmail.SetParentComponent(UsersDataset);
    UsersDatasetEmail.Name := 'UsersDatasetEmail';
    UsersDatasetEmail.FieldName := 'Email';
    UsersDatasetEmail.Size := 255;
    UsersDatasetEmailConfirmed.SetParentComponent(UsersDataset);
    UsersDatasetEmailConfirmed.Name := 'UsersDatasetEmailConfirmed';
    UsersDatasetEmailConfirmed.FieldName := 'EmailConfirmed';
    UsersDatasetPhoneNumber.SetParentComponent(UsersDataset);
    UsersDatasetPhoneNumber.Name := 'UsersDatasetPhoneNumber';
    UsersDatasetPhoneNumber.FieldName := 'PhoneNumber';
    UsersDatasetPhoneNumber.Size := 255;
    UsersDatasetUserStatus.SetParentComponent(UsersDataset);
    UsersDatasetUserStatus.Name := 'UsersDatasetUserStatus';
    UsersDatasetUserStatus.DisplayWidth := 15;
    UsersDatasetUserStatus.FieldName := 'UserStatus';
    UsersDatasetUserStatus.Size := 15;
    UsersDatasetUserScope.SetParentComponent(UsersDataset);
    UsersDatasetUserScope.Name := 'UsersDatasetUserScope';
    UsersDatasetUserScope.FieldName := 'UserScope';
    UsersDatasetHMRCPhone.SetParentComponent(UsersDataset);
    UsersDatasetHMRCPhone.Name := 'UsersDatasetHMRCPhone';
    UsersDatasetHMRCPhone.FieldName := 'HMRCPhone';
    UsersDatasetHMRCPhone.Size := 25;
    UsersDatasetHMRCPostCode.SetParentComponent(UsersDataset);
    UsersDatasetHMRCPostCode.Name := 'UsersDatasetHMRCPostCode';
    UsersDatasetHMRCPostCode.FieldName := 'HMRCPostCode';
    UsersDatasetHMRCPostCode.Size := 15;
    UsersDatasetHMRCRegistered.SetParentComponent(UsersDataset);
    UsersDatasetHMRCRegistered.Name := 'UsersDatasetHMRCRegistered';
    UsersDatasetHMRCRegistered.FieldName := 'HMRCRegistered';
    SetEvent(UsersDatasetHMRCRegistered, Self, 'OnGetText', 'UsersDatasetHMRCRegisteredGetText');
    UsersDatasetCanSubmit.SetParentComponent(UsersDataset);
    UsersDatasetCanSubmit.Name := 'UsersDatasetCanSubmit';
    UsersDatasetCanSubmit.FieldName := 'CanSubmit';
    SetEvent(UsersDatasetCanSubmit, Self, 'OnGetText', 'UsersDatasetCanSubmitGetText');
  finally
    LicensedUserLabel.AfterLoadDFMValues;
    ActiveUsersLabel.AfterLoadDFMValues;
    NewUserLabel.AfterLoadDFMValues;
    InactiveUsersLabel.AfterLoadDFMValues;
    ArchivedUsersLabel.AfterLoadDFMValues;
    UserDataTable.AfterLoadDFMValues;
    NewUserButton.AfterLoadDFMValues;
    UsersDataSource.AfterLoadDFMValues;
    UsersDataset.AfterLoadDFMValues;
    UsersDatasetInternalId.AfterLoadDFMValues;
    UsersDatasetOrganisationId.AfterLoadDFMValues;
    UsersDatasetOrganisationName.AfterLoadDFMValues;
    UsersDatasetTenantId.AfterLoadDFMValues;
    UsersDatasetJobTitle.AfterLoadDFMValues;
    UsersDatasetJobPosition.AfterLoadDFMValues;
    UsersDatasetTitle.AfterLoadDFMValues;
    UsersDatasetGivenName.AfterLoadDFMValues;
    UsersDatasetLastName.AfterLoadDFMValues;
    UsersDatasetEmail.AfterLoadDFMValues;
    UsersDatasetEmailConfirmed.AfterLoadDFMValues;
    UsersDatasetPhoneNumber.AfterLoadDFMValues;
    UsersDatasetUserStatus.AfterLoadDFMValues;
    UsersDatasetUserScope.AfterLoadDFMValues;
    UsersDatasetHMRCPhone.AfterLoadDFMValues;
    UsersDatasetHMRCPostCode.AfterLoadDFMValues;
    UsersDatasetHMRCRegistered.AfterLoadDFMValues;
    UsersDatasetCanSubmit.AfterLoadDFMValues;
  end;
end;

end.
