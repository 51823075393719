unit GiftAidDetailsForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseForm,
  WEBLib.ExtCtrls,
  WEBLib.Actions,
  Data.DB,
  WEBLib.DB,
  WEBLib.Toast,
  Vcl.Controls,
  WEBLib.WebCtrls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  WEBLib.Grids,
  XData.Web.JsonDataset,
  XData.Web.Dataset;

type
  TGiftAidDetails = class(TBaseEditForm)
    GiftAidId: TDBLabel;
    TransactionId: TDBLabel;
    ClaimRef: TDBLabel;
    ClaimStatus: TDBLabel;
    SubmissionDate: TDBLabel;
    IRMark: TDBLabel;
    R68Link: TLabel;
    ClaimStats: TXDataWebDataSet;
    ClaimStatsSource: TDataSource;
    ClaimStatsId: TIntegerField;
    ClaimStatsClaimId: TIntegerField;
    ClaimStatsGiftAid: TFloatField;
    ClaimStatsAmount: TFloatField;
    ClaimStatsClaimType: TStringField;
    ClaimStatsItemCount: TIntegerField;
    ClaimStatsTable: TDBTableControl;
    ClaimStatsClaim_Type: TStringField;
    ClaimStatsTaxYear: TIntegerField;
    IRMarkReceipt: THTMLSpan;
    procedure ClaimStatsAmountGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure ClaimStatsCalcFields(Dataset: TDataSet);
    procedure ClaimStatsGiftAidGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure ClaimStatsItemCountGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure R68LinkClick(Sender: TObject);
  private
    [async]
    procedure DownloadR68; async;
    { Private declarations }
  protected
    procedure RecordChanged; override;

  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  GiftAidDetails: TGiftAidDetails;

implementation

uses
  System.Rtti,
  XData.Web.Client,
  MainDataModule,
  GiftAid.Entities.Claims.Enumerations,
  SMX.Web.Utils,
  SMX.Web.FileSupport,
  GiftAidClaimsList,
  SMX.ReturnTypes;

{$R *.dfm}

procedure TGiftAidDetails.ClaimStatsAmountGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := FormatFloat(SYS_MONEY_FORMAT, (Sender as TFloatField).AsFloat);
end;

procedure TGiftAidDetails.ClaimStatsCalcFields(Dataset: TDataSet);
var
  lValue: string;
  lType: TGiftAidClaimType;
begin
  lValue := Dataset.FieldByName('ClaimType').AsString;
  { TODO : Should be able to do this with TRttiEnumerationType, but it doesn't work }
  if lValue = 'GAD' then
    lType := TGiftAidClaimType.GAD
  else if lValue = 'GASDS' then
    lType := TGiftAidClaimType.GASDS
  else if lValue = 'Adjustment' then
    lType := TGiftAidClaimType.Adjustment
  else if lValue = 'GASDSAdjustment' then
    lType := TGiftAidClaimType.GASDSAdjustment
  else if lValue = 'AggregatedDonations' then
    lType := TGiftAidClaimType.AggregatedDonations
  else if lValue = 'CommunityBuildings' then
    lType := TGiftAidClaimType.CommunityBuildings
  else if lValue = 'OtherIncome' then
    lType := TGiftAidClaimType.OtherIncome
  else
  begin
    Dataset.FieldByName('Claim_Type').AsString := 'unknown';
    Exit;
  end;

  Dataset.FieldByName('Claim_Type').AsString := C_GA_TYPE_DESCRIPTIONS[lType];
end;

procedure TGiftAidDetails.ClaimStatsGiftAidGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := FormatFloat(SYS_MONEY_FORMAT, (Sender as TFloatField).AsFloat);
end;

procedure TGiftAidDetails.ClaimStatsItemCountGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := FormatFloat('#,##0', (Sender as TIntegerField).AsInteger);
end;

procedure TGiftAidDetails.DownloadR68;
var
  lResponse: TXDataClientResponse;
  lResponseObject: JS.TJSObject;
  lOutcome: TResponseOutcome;
  lFileName: string;
begin

  lResponse := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync('IGiftAiderService.GetR68Report',
    [DataSource.Dataset.FieldByName('Id').AsInteger]));

  lResponseObject := lResponse.ResultAsObject;

  if lResponseObject = nil then
  begin
    ShowMessage('Failed to download report');
    Exit;
  end;

  lOutcome := TRttiEnumerationType.GetValue<TResponseOutcome>(JS.toString(lResponseObject['Outcome']));

  if lOutcome = responseFileNotFound then
  begin
    ShowMessage('Sorry, we can''t find the R68 report. Please contact the GiftAider support team');
    Exit;
  end;

  try
    lFileName := JS.toString(lResponseObject['TheFileName']);
    TFileSupport.SaveEncoded64File(lFileName, JS.toString(lResponseObject['Data']));
  except
    on E: Exception do
    begin
      ShowMessage(E.Message);
    end;
  end;
end;

procedure TGiftAidDetails.R68LinkClick(Sender: TObject);
begin
  DownloadR68;
end;

{ TGiftAidDetails }

procedure TGiftAidDetails.RecordChanged;
var
  lClaimStatus: TGiftAidClaimStatus;
begin
  inherited;
  FormCaption.Caption := DataSource.Dataset.FieldByName('ClaimRef').AsString;
  lClaimStatus := TRttiEnumerationType.GetValue<TGiftAidClaimStatus>(DataSource.Dataset.FieldByName('ClaimStatus')
    .AsString);
  R68Link.Visible := (lClaimStatus in [TGiftAidClaimStatus.Acknowledged, TGiftAidClaimStatus.Confirmed,
    TGiftAidClaimStatus.PaymentReceived, TGiftAidClaimStatus.ClaimRejected]);

  if Dataset.FieldByName('IRMarkReceipt').AsString <> '' then
  begin
    IRMarkReceipt.HTML.Text := Dataset.FieldByName('IRMarkReceipt').AsString;
    IRMarkReceipt.Visible := True;
  end
  else
    IRMarkReceipt.Visible := False;

  ClaimStats.QueryString := '$filter=ClaimId eq ' + DataSource.Dataset.FieldByName('Id').AsString;
  ClaimStats.Load;
end;

procedure TGiftAidDetails.LoadDFMValues;
begin
  inherited LoadDFMValues;

  GiftAidId := TDBLabel.Create('GiftAidId');
  TransactionId := TDBLabel.Create('TransactionId');
  ClaimRef := TDBLabel.Create('ClaimRef');
  ClaimStatus := TDBLabel.Create('ClaimStatus');
  SubmissionDate := TDBLabel.Create('SubmissionDate');
  IRMark := TDBLabel.Create('IRMark');
  R68Link := TLabel.Create('R68Link');
  ClaimStatsTable := TDBTableControl.Create('ClaimStatsTable');
  IRMarkReceipt := THTMLSpan.Create('IRMarkReceipt');
  ClaimStats := TXDataWebDataSet.Create(Self);
  ClaimStatsId := TIntegerField.Create(Self);
  ClaimStatsClaimId := TIntegerField.Create(Self);
  ClaimStatsGiftAid := TFloatField.Create(Self);
  ClaimStatsAmount := TFloatField.Create(Self);
  ClaimStatsClaimType := TStringField.Create(Self);
  ClaimStatsItemCount := TIntegerField.Create(Self);
  ClaimStatsClaim_Type := TStringField.Create(Self);
  ClaimStatsTaxYear := TIntegerField.Create(Self);
  ClaimStatsSource := TDataSource.Create(Self);

  GiftAidId.BeforeLoadDFMValues;
  TransactionId.BeforeLoadDFMValues;
  ClaimRef.BeforeLoadDFMValues;
  ClaimStatus.BeforeLoadDFMValues;
  SubmissionDate.BeforeLoadDFMValues;
  IRMark.BeforeLoadDFMValues;
  R68Link.BeforeLoadDFMValues;
  ClaimStatsTable.BeforeLoadDFMValues;
  IRMarkReceipt.BeforeLoadDFMValues;
  ClaimStats.BeforeLoadDFMValues;
  ClaimStatsId.BeforeLoadDFMValues;
  ClaimStatsClaimId.BeforeLoadDFMValues;
  ClaimStatsGiftAid.BeforeLoadDFMValues;
  ClaimStatsAmount.BeforeLoadDFMValues;
  ClaimStatsClaimType.BeforeLoadDFMValues;
  ClaimStatsItemCount.BeforeLoadDFMValues;
  ClaimStatsClaim_Type.BeforeLoadDFMValues;
  ClaimStatsTaxYear.BeforeLoadDFMValues;
  ClaimStatsSource.BeforeLoadDFMValues;
  try
    GiftAidId.SetParentComponent(Self);
    GiftAidId.Name := 'GiftAidId';
    GiftAidId.Left := 48;
    GiftAidId.Top := 104;
    GiftAidId.Width := 47;
    GiftAidId.Height := 15;
    GiftAidId.Caption := 'GiftAidId';
    GiftAidId.ElementFont := efCSS;
    GiftAidId.HeightStyle := ssAuto;
    GiftAidId.HeightPercent := 100.000000000000000000;
    GiftAidId.WidthPercent := 100.000000000000000000;
    GiftAidId.DataField := 'Id';
    GiftAidId.DataSource := DataSource;
    TransactionId.SetParentComponent(Self);
    TransactionId.Name := 'TransactionId';
    TransactionId.Left := 48;
    TransactionId.Top := 136;
    TransactionId.Width := 70;
    TransactionId.Height := 15;
    TransactionId.Caption := 'TransactionId';
    TransactionId.ElementFont := efCSS;
    TransactionId.HeightStyle := ssAuto;
    TransactionId.HeightPercent := 100.000000000000000000;
    TransactionId.WidthPercent := 100.000000000000000000;
    TransactionId.DataField := 'TransactionId';
    TransactionId.DataSource := DataSource;
    ClaimRef.SetParentComponent(Self);
    ClaimRef.Name := 'ClaimRef';
    ClaimRef.Left := 48;
    ClaimRef.Top := 150;
    ClaimRef.Width := 48;
    ClaimRef.Height := 15;
    ClaimRef.Caption := 'ClaimRef';
    ClaimRef.ElementFont := efCSS;
    ClaimRef.HeightStyle := ssAuto;
    ClaimRef.HeightPercent := 100.000000000000000000;
    ClaimRef.WidthPercent := 100.000000000000000000;
    ClaimRef.DataField := 'ClaimRef';
    ClaimRef.DataSource := DataSource;
    ClaimStatus.SetParentComponent(Self);
    ClaimStatus.Name := 'ClaimStatus';
    ClaimStatus.Left := 48;
    ClaimStatus.Top := 165;
    ClaimStatus.Width := 63;
    ClaimStatus.Height := 15;
    ClaimStatus.Caption := 'ClaimStatus';
    ClaimStatus.ElementFont := efCSS;
    ClaimStatus.HeightStyle := ssAuto;
    ClaimStatus.HeightPercent := 100.000000000000000000;
    ClaimStatus.WidthPercent := 100.000000000000000000;
    ClaimStatus.DataField := 'ClaimStatus';
    ClaimStatus.DataSource := DataSource;
    SubmissionDate.SetParentComponent(Self);
    SubmissionDate.Name := 'SubmissionDate';
    SubmissionDate.Left := 48;
    SubmissionDate.Top := 180;
    SubmissionDate.Width := 85;
    SubmissionDate.Height := 15;
    SubmissionDate.Caption := 'SubmissionDate';
    SubmissionDate.ElementFont := efCSS;
    SubmissionDate.HeightStyle := ssAuto;
    SubmissionDate.HeightPercent := 100.000000000000000000;
    SubmissionDate.WidthPercent := 100.000000000000000000;
    SubmissionDate.DataField := 'SubmissionDate';
    SubmissionDate.DataSource := DataSource;
    IRMark.SetParentComponent(Self);
    IRMark.Name := 'IRMark';
    IRMark.Left := 48;
    IRMark.Top := 195;
    IRMark.Width := 37;
    IRMark.Height := 15;
    IRMark.Caption := 'IRMark';
    IRMark.ElementFont := efCSS;
    IRMark.HeightStyle := ssAuto;
    IRMark.HeightPercent := 100.000000000000000000;
    IRMark.WidthPercent := 100.000000000000000000;
    IRMark.DataField := 'IRMark';
    IRMark.DataSource := DataSource;
    R68Link.SetParentComponent(Self);
    R68Link.Name := 'R68Link';
    R68Link.Left := 48;
    R68Link.Top := 240;
    R68Link.Width := 57;
    R68Link.Height := 15;
    R68Link.Cursor := crHandPoint;
    R68Link.Caption := 'R68 Report';
    R68Link.ElementFont := efCSS;
    R68Link.HeightStyle := ssAuto;
    R68Link.HeightPercent := 100.000000000000000000;
    R68Link.Visible := False;
    R68Link.WidthPercent := 100.000000000000000000;
    SetEvent(R68Link, Self, 'OnClick', 'R68LinkClick');
    ClaimStatsTable.SetParentComponent(Self);
    ClaimStatsTable.Name := 'ClaimStatsTable';
    ClaimStatsTable.Left := 560;
    ClaimStatsTable.Top := 136;
    ClaimStatsTable.Width := 300;
    ClaimStatsTable.Height := 200;
    ClaimStatsTable.HeightStyle := ssAuto;
    ClaimStatsTable.WidthStyle := ssAuto;
    ClaimStatsTable.BorderColor := clSilver;
    ClaimStatsTable.ChildOrder := 18;
    ClaimStatsTable.ElementPosition := epIgnore;
    ClaimStatsTable.Columns.Clear;
    with ClaimStatsTable.Columns.Add do
    begin
      DataField := 'Claim_Type';
      Title := 'Claim Type';
    end;
    with ClaimStatsTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'Amount';
      Title := 'Amount';
      TitleElementClassName := 'text-end';
    end;
    with ClaimStatsTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'GiftAid';
      Title := 'Gift Aid';
      TitleElementClassName := 'text-end';
    end;
    with ClaimStatsTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'TaxYear';
      Title := 'Tax Year';
      TitleElementClassName := 'text-end';
    end;
    with ClaimStatsTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'ItemCount';
      Title := 'Count';
      TitleElementClassName := 'text-end';
    end;
    ClaimStatsTable.DataSource := ClaimStatsSource;
    IRMarkReceipt.SetParentComponent(Self);
    IRMarkReceipt.Name := 'IRMarkReceipt';
    IRMarkReceipt.Left := 252;
    IRMarkReceipt.Top := 240;
    IRMarkReceipt.Width := 100;
    IRMarkReceipt.Height := 40;
    IRMarkReceipt.WidthStyle := ssAuto;
    IRMarkReceipt.ChildOrder := 18;
    IRMarkReceipt.ElementPosition := epIgnore;
    IRMarkReceipt.Role := '';
    ClaimStats.SetParentComponent(Self);
    ClaimStats.Name := 'ClaimStats';
    SetEvent(ClaimStats, Self, 'OnCalcFields', 'ClaimStatsCalcFields');
    ClaimStats.EntitySetName := 'GiftAidClaimStats';
    ClaimStats.Connection := MainData.DataConnection;
    ClaimStats.Left := 520;
    ClaimStats.Top := 24;
    ClaimStatsId.SetParentComponent(ClaimStats);
    ClaimStatsId.Name := 'ClaimStatsId';
    ClaimStatsId.FieldName := 'Id';
    ClaimStatsId.Required := True;
    ClaimStatsClaimId.SetParentComponent(ClaimStats);
    ClaimStatsClaimId.Name := 'ClaimStatsClaimId';
    ClaimStatsClaimId.FieldName := 'ClaimId';
    ClaimStatsClaimId.Required := True;
    ClaimStatsGiftAid.SetParentComponent(ClaimStats);
    ClaimStatsGiftAid.Name := 'ClaimStatsGiftAid';
    ClaimStatsGiftAid.FieldName := 'GiftAid';
    ClaimStatsGiftAid.Required := True;
    SetEvent(ClaimStatsGiftAid, Self, 'OnGetText', 'ClaimStatsGiftAidGetText');
    ClaimStatsAmount.SetParentComponent(ClaimStats);
    ClaimStatsAmount.Name := 'ClaimStatsAmount';
    ClaimStatsAmount.FieldName := 'Amount';
    ClaimStatsAmount.Required := True;
    SetEvent(ClaimStatsAmount, Self, 'OnGetText', 'ClaimStatsAmountGetText');
    ClaimStatsClaimType.SetParentComponent(ClaimStats);
    ClaimStatsClaimType.Name := 'ClaimStatsClaimType';
    ClaimStatsClaimType.FieldName := 'ClaimType';
    ClaimStatsClaimType.Required := True;
    ClaimStatsClaimType.Size := 19;
    ClaimStatsItemCount.SetParentComponent(ClaimStats);
    ClaimStatsItemCount.Name := 'ClaimStatsItemCount';
    ClaimStatsItemCount.FieldName := 'ItemCount';
    ClaimStatsItemCount.Required := True;
    SetEvent(ClaimStatsItemCount, Self, 'OnGetText', 'ClaimStatsItemCountGetText');
    ClaimStatsClaim_Type.SetParentComponent(ClaimStats);
    ClaimStatsClaim_Type.Name := 'ClaimStatsClaim_Type';
    ClaimStatsClaim_Type.FieldKind := fkCalculated;
    ClaimStatsClaim_Type.FieldName := 'Claim_Type';
    ClaimStatsClaim_Type.Size := 30;
    ClaimStatsClaim_Type.Calculated := True;
    ClaimStatsTaxYear.SetParentComponent(ClaimStats);
    ClaimStatsTaxYear.Name := 'ClaimStatsTaxYear';
    ClaimStatsTaxYear.FieldName := 'TaxYear';
    ClaimStatsTaxYear.Required := True;
    ClaimStatsSource.SetParentComponent(Self);
    ClaimStatsSource.Name := 'ClaimStatsSource';
    ClaimStatsSource.DataSet := ClaimStats;
    ClaimStatsSource.Left := 608;
    ClaimStatsSource.Top := 24;
  finally
    GiftAidId.AfterLoadDFMValues;
    TransactionId.AfterLoadDFMValues;
    ClaimRef.AfterLoadDFMValues;
    ClaimStatus.AfterLoadDFMValues;
    SubmissionDate.AfterLoadDFMValues;
    IRMark.AfterLoadDFMValues;
    R68Link.AfterLoadDFMValues;
    ClaimStatsTable.AfterLoadDFMValues;
    IRMarkReceipt.AfterLoadDFMValues;
    ClaimStats.AfterLoadDFMValues;
    ClaimStatsId.AfterLoadDFMValues;
    ClaimStatsClaimId.AfterLoadDFMValues;
    ClaimStatsGiftAid.AfterLoadDFMValues;
    ClaimStatsAmount.AfterLoadDFMValues;
    ClaimStatsClaimType.AfterLoadDFMValues;
    ClaimStatsItemCount.AfterLoadDFMValues;
    ClaimStatsClaim_Type.AfterLoadDFMValues;
    ClaimStatsTaxYear.AfterLoadDFMValues;
    ClaimStatsSource.AfterLoadDFMValues;
  end;
end;

end.
