unit Sphinx.Utils;

interface

uses
  System.SysUtils, System.Types, System.Generics.Collections, System.Classes,
{$IFDEF PAS2JS}
   JS, Web,
{$ELSE}
  System.Hash,
  System.RegularExpressions,
  Bcl.Logging,
  Bcl.Json.Classes,
  Bcl.Json,
  Sparkle.Uri,
{$ENDIF}
  System.StrUtils,
  Bcl.Utils,
  Bcl.Json.Common,
  Sparkle.Utils;

type
  TValidateScopeFunc = reference to function(const ScopeToken: string): Boolean;

  TSpacedTokens = record
  strict private
    FTokens: TArray<string>;
  public
    constructor Create(const Value: string);
    function Contains(const Token: string): Boolean;
    function Equals(const Tokens: TArray<string>): Boolean; overload;
    function Equals(const Value: string): Boolean; overload;
    function Count: Integer;
    property Tokens: TArray<string> read FTokens;
  end;

  IResponseParams = interface
  ['{03BEFEFF-923C-4A99-80D9-744444DF3D1F}']
    function Count: Integer;
    function GetName(Index: Integer): string;
    function GetValue(Index: Integer): string; overload;
    function GetValue(const Name: string): string; overload;
  end;

  IModifiableParams = interface(IResponseParams)
  ['{C3667980-F047-46E6-AB0F-F0F45B0E9F8E}']
    function Add(const Name, Value: string): IModifiableParams;
    procedure SetValue(const Name, Value: string);
  end;

  TModifiableParams = class(TInterfacedObject, IModifiableParams)
  strict private
    FParams: TList<TPair<string, string>>;
    function IndexOf(const Name: string): Integer;
  public
    constructor Create;
    destructor Destroy; override;
    function Add(const Name, Value: string): IModifiableParams;
    function Count: Integer;
    function GetName(Index: Integer): string;
    function GetValue(Index: Integer): string; overload;
    function GetValue(const Name: string): string; overload;
    procedure SetValue(const Name, Value: string);
  end;

function NewLowerGuid: string;
{$IFNDEF PAS2JS}
function IsMatch(const AExpr, AInput: string): Boolean;
function IsEmail(const AEmail: string): Boolean;
function LogSafeError(Logger: ILogger; const Msg: string): string;
{$ENDIF}
function AppendString(const S1, S2: string; const Separator: string = ' - '): string;
function AppendTrace(const Msg, StackTrace: string; const Separator: string = #13#10): string;
function GetValidScope(const Scope, ValidScope: string): string; overload;
function GetValidScope(const Scope: string; const ValidScopeTokens: TArray<string>): string; overload;
function GetValidScope(const ScopeTokens: TArray<string>; const ValidScope: string): string; overload;
function GetValidScope(const ScopeTokens, ValidScopeTokens: TArray<string>): string; overload;
function GetMissingScope(const Scope, ValidatedScope: string): string; overload;
function GetMissingScope(const ScopeTokens, ValidatedScopeTokens: TArray<string>): string; overload;
function GetFilteredScope(const Scope: string; const Filter: TValidateScopeFunc): string; overload;
function GetFilteredScope(const ScopeTokens: TArray<string>; const Filter: TValidateScopeFunc): string; overload;
function StringsAsScope(const ScopeTokens: TArray<string>): string; overload;
function StringsAsScope(const ScopeTokens: TStrings): string; overload;

function AddUrlParams(const Url: string; Parameters: IResponseParams): string;
function AddUrlQuery(const Url, QueryString: string): string;

function AddQueryToUrl(const Url, Query: string): string;
function AddFragmentToUrl(const Url, Fragment: string): string;

function BuildQueryParams(Params: IResponseParams): string;
function ParamsFromUri(const ResponseUri: string): TQueryString;
function GetJwtPayload(const AToken: string): TJObject;
function Sha256(const Value: string): string;

var
  AddStackTraceToSafeLog: Boolean;

implementation

function AppendString(const S1, S2: string; const Separator: string = ' - '): string;
begin
  Result := S1;
  if S2 <> '' then
    Result := S1 + Separator + S2;
end;

function AppendTrace(const Msg, StackTrace: string; const Separator: string = #13#10): string;
begin
  if AddStackTraceToSafeLog then
    Result := AppendString(Msg, StackTrace, Separator)
  else
    Result := Msg;
end;

function GetMissingScope(const Scope, ValidatedScope: string): string; overload;
begin
  Result := GetMissingScope(
    TArray<string>(SplitString(Scope, ' ')),
    TArray<string>(SplitString(ValidatedScope, ' ')));
end;

function GetMissingScope(const ScopeTokens, ValidatedScopeTokens: TArray<string>): string; overload;
begin
  Result := GetFilteredScope(ScopeTokens,
    function(const ScopeToken: string): Boolean
    var
      I: Integer;
    begin
      for I := 0 to Length(ValidatedScopeTokens) - 1 do
        if ScopeToken = ValidatedScopeTokens[I] then
          Exit(False);
      Result := True;
    end);
end;

function GetValidScope(const Scope, ValidScope: string): string;
begin
  Result := GetValidScope(TArray<string>(SplitString(Scope, ' ')), ValidScope);
end;

function GetValidScope(const Scope: string; const ValidScopeTokens: TArray<string>): string;
begin
  Result := GetValidScope(TArray<string>(SplitString(Scope, ' ')), ValidScopeTokens);
end;

function GetFilteredScope(const Scope: string; const Filter: TValidateScopeFunc): string;
begin
  Result := GetFilteredScope(TArray<string>(SplitString(Scope, ' ')), Filter);
end;

function GetValidScope(const ScopeTokens: TArray<string>; const ValidScope: string): string;
begin
  Result := GetValidScope(ScopeTokens, TArray<string>(SplitString(ValidScope, ' ')));
end;

function GetValidScope(const ScopeTokens, ValidScopeTokens: TArray<string>): string;
begin
  Result := GetFilteredScope(ScopeTokens,
    function(const ScopeToken: string): Boolean
    var
      I: Integer;
    begin
      for I := 0 to Length(ValidScopeTokens) - 1 do
        if ScopeToken = ValidScopeTokens[I] then
          Exit(True);
      Result := False;
    end);
end;

function GetFilteredScope(const ScopeTokens: TArray<string>; const Filter: TValidateScopeFunc): string;
var
  ScopeToken: string;
begin
  Result := '';
  for ScopeToken in ScopeTokens do
    if (ScopeToken <> '') and (not Assigned(Filter) or Filter(ScopeToken)) then
    begin
      if Result <> '' then
        Result := Result + ' ';
      Result := Result + ScopeToken;
    end;
end;

function StringsAsScope(const ScopeTokens: TArray<string>): string; overload;
begin
  Result := GetFilteredScope(ScopeTokens, nil);
end;

function StringsAsScope(const ScopeTokens: TStrings): string; overload;
begin
  Result := StringsAsScope(ScopeTokens.ToStringArray);
end;

function NewLowerGuid: string;
begin
  Result := Copy(GuidToString(TGuid.NewGuid), 2, 36).ToLower;
end;

{$IFNDEF PAS2JS}
function IsEmail(const AEmail: string): Boolean;
begin
  Result := IsMatch('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+$', AEmail);
end;
{$ENDIF}

{$IFNDEF PAS2JS}
function IsMatch(const AExpr, AInput: string): Boolean;
var
  Reg: TRegEx;
begin
  Reg := TRegEx.Create(AExpr);
  Result := Reg.IsMatch(AInput);
end;
{$ENDIF}

{$IFNDEF PAS2JS}
function LogSafeError(Logger: ILogger; const Msg: string): string;
var
  Value: string;
  Hash: string;
begin
  Value := TBclUtils.DateTimeToISO(Now, True) + ': ' + Msg;
  Hash := Sha256(Value);
  Logger.Error(Hash + ': ' + Msg);
  Result := 'Internal server error: ' + Hash;
end;
{$ENDIF}

{$IFDEF PAS2JS}
function Sha256(const Value: string): string; assembler;
asm
  var hash = CryptoJS.SHA256(Value);
  return CryptoJS.enc.Base64url.stringify(hash);
end;
{$ELSE}
function Sha256(const Value: string): string;
var
  Hash: THashSHA2;
begin
  Hash := THashSHA2.Create;
  Hash.Update(Value);
  Result := TBclUtils.EncodeBase64Url(Hash.HashAsBytes);
end;
{$ENDIF}

function BuildQueryParams(Params: IResponseParams): string;
var
  I: Integer;
begin
  Result := '';
  for I := 0 to Params.Count - 1 do
    if Params.GetValue(I) <> '' then
    begin
      if Result <> '' then
        Result := Result + '&';
      Result := Result + Params.GetName(I) + '=' + TBclUtils.PercentEncode(Params.GetValue(I));
    end;
end;

{ TScopeTokens }

function TSpacedTokens.Contains(const Token: string): Boolean;
var
  I: Integer;
begin
  for I := 0 to Length(FTokens) - 1 do
    if Token = FTokens[I] then
      Exit(True);
  Result := False;
end;

function TSpacedTokens.Count: Integer;
begin
  Result := Length(FTokens);
end;

constructor TSpacedTokens.Create(const Value: string);
begin
  FTokens := TArray<string>(SplitString(Trim(Value), ' '));
end;

function TSpacedTokens.Equals(const Value: string): Boolean;
begin
  Result := Equals(TArray<string>(SplitString(Value, ' ')));
end;

function TSpacedTokens.Equals(const Tokens: TArray<string>): Boolean;
var
  I: Integer;
begin
  if Length(Tokens) <> Length(FTokens) then
    Exit(False);

  for I := 0 to Length(Tokens) - 1 do
    if not Self.Contains(Tokens[I]) then
      Exit(False);

  Result := True;
end;

function AddFragmentToUrl(const Url, Fragment: string): string;
begin
  Result := Url;
  if Pos('#', Url) = 0 then
    Result := Result + '#';
  Result := Result + Fragment;
end;

function AddQueryToUrl(const Url, Query: string): string;
begin
  Result := Url;
  if Pos('?', Result) = 0 then
    Result := Result + '?'
  else
  if (Length(Url) = 0) or (Url[Length(Url)] <> '&') then
    Result := Result + '&';
  Result := Result + Query;
end;

function AddUrlQuery(const Url, QueryString: string): string;
var
  Separator: char;
begin
  Result := TBclUtils.PercentDecode(Url);
  if QueryString <> '' then
  begin
    if Pos('?', Result) > Pos('#', Result) then
      Separator := '&'
    else
      Separator := '?';
    Result := Result + Separator + QueryString;
  end;
end;

function AddUrlParams(const Url: string; Parameters: IResponseParams): string;
begin
  Result := AddUrlQuery(Url, BuildQueryParams(Parameters));
end;

function ParamsFromUri(const ResponseUri: string): TQueryString;
const
  CDummyUrlBase = 'https://dummy.com';
var
{$IFDEF PAS2JS}
  CalledUrl: TJSURL;
{$ELSE}
  CalledUrl: IUri;
{$ENDIF}
  Params: string;
  Query: string;
begin
{$IFDEF PAS2JS}
  CalledUrl := TJSURL.new(ResponseUri, CDummyUrlBase);
  Params := CalledUrl.hash;
{$ELSE}
  CalledUrl := TUri.Create(ResponseUri);
  Params := CalledUrl.Fragment;
{$ENDIF}
  if (Length(Params) > 0) and (Params[1] = '#') then
    Params := Copy(Params, 2);

{$IFDEF PAS2JS}
  Query := CalledUrl.search;
{$ELSE}
  Query := CalledUrl.Query;
{$ENDIF}
  if (Length(Query) > 0) and (Query[1] = '?') then
    Query := Copy(Query, 2);

  if Query <> '' then
  begin
    if Params <> '' then
      Params := Params + '&';
    Params := Params + Query;
  end;

  Result := TQueryString.Create(Params);
end;

function GetJwtPayload(const AToken: string): TJObject;
var
  Parts: TStringDynArray;
begin
  Parts := SplitString(AToken, '.');
  if Length(Parts) <> 3 then
    Exit(nil);
  try
{$IFDEF PAS2JS}
    Result := TJSJson.parseObject(window.atob(Parts[1]));
{$ELSE}
    Result := TJson.Deserialize<TJObject>(TEncoding.UTF8.GetString(TSparkleUtils.DecodeBase64(Parts[1])));
{$ENDIF}
  except
    raise Exception.Create('Token payload is not a valid JSON');
  end;
end;

{ TModifiableParams }

function TModifiableParams.Add(const Name, Value: string): IModifiableParams;
var
  Pair: TPair<string, string>;
begin
  Pair.Key := Name;
  Pair.Value := Value;
  FParams.Add(Pair);
  Result := Self;
end;

function TModifiableParams.Count: Integer;
begin
  Result := FParams.Count;
end;

constructor TModifiableParams.Create;
begin
  inherited Create;
  FParams := TList<TPair<string, string>>.Create;
end;

destructor TModifiableParams.Destroy;
begin
  FParams.Free;
  inherited;
end;

function TModifiableParams.GetName(Index: Integer): string;
begin
  Result := FParams[Index].Key;
end;

function TModifiableParams.GetValue(const Name: string): string;
var
  Pair: TPair<string, string>;
begin
  for Pair in FParams do
    if Pair.Key = Name then
      Exit(Pair.Value);
  Result := '';
end;

function TModifiableParams.IndexOf(const Name: string): Integer;
var
  I: Integer;
begin
  for I := 0 to FParams.Count - 1 do
    if FParams[I].Key = Name then
      Exit(I);
  Result := -1;
end;

procedure TModifiableParams.SetValue(const Name, Value: string);
var
  I: Integer;
begin
  I := IndexOf(Name);
  if I >= 0 then
    FParams[I] := TPair<string, string>.Create(Name, Value)
  else
    Add(Name, Value);
end;

function TModifiableParams.GetValue(Index: Integer): string;
begin
  Result := FParams[Index].Value;
end;

initialization
  AddStackTraceToSafeLog := False;
end.
