unit SimpleDashboard;

interface

uses
  System.Generics.Collections,
  JS,
  XData.Web.Client,
  Simple.Dashboard.Return.Types;

type

  TSimpleDashboard = class
  private
    FWebClient: TXDataWebClient;
    FItemList: TDictionary<string, TDashboardResponse>;
    procedure SetWebClient(const Value: TXDataWebClient);
  public
    constructor Create(AWebClient: TXDataWebClient);
    destructor Destroy; override;
    [ASync]
    function GetDashboardItem(const AKey: string; const AParams: string = ''):
        TDashboardResponse; async;
    property WebClient: TXDataWebClient read FWebClient write SetWebClient;
  end;

implementation

uses
  System.SysUtils,
  System.Rtti;

const
  SVC_GET_ITEM = 'ISimpleDashboardService.GetDashboardItem';

  { TSimpleDashboard }

constructor TSimpleDashboard.Create(AWebClient: TXDataWebClient);
begin
  FWebClient := AWebClient;
  FItemList := TDictionary<string, TDashboardResponse>.Create;
end;

destructor TSimpleDashboard.Destroy;
begin
  FItemList.Free;
  inherited;
end;

function TSimpleDashboard.GetDashboardItem(const AKey: string; const AParams: string): TDashboardResponse;
var
  lRetval: TXDataClientResponse;
  lObject: JS.TJSObject;
begin

  if FItemList.TryGetValue(AKey, Result) then
  begin
    if Result.Expires > Now then
      Exit
    else
      Result := nil;
  end;

  lRetval := Await(TXDataClientResponse, FWebClient.RawInvokeAsync(SVC_GET_ITEM, [AKey, AParams]));
  lObject := lRetval.ResultAsObject;

  Result := TDashboardResponse.Create;
  Result.Assign(lObject);

  FItemList.AddOrSetValue(AKey, Result);

end;

procedure TSimpleDashboard.SetWebClient(const Value: TXDataWebClient);
begin
  FWebClient := Value;
end;

end.
