unit SMX.JWT.Web.Helper;

interface

uses JS;

type

  TJwtHelper = class
  private
    class function DecodePayload(const AToken: string): string;
  public
    class function PayloadAsObject(const AToken: string): TJSObject;
    class function ClaimValue(const AToken, AName: string): string; overload;
    class function ClaimValue(const APayload: TJSObject; const AName: string): string; overload;
  end;

implementation

uses SysUtils;

{ TJwtHelper }

class function TJwtHelper.ClaimValue(const AToken, AName: string): string;
begin
  Result := ClaimValue(PayloadAsObject(AToken), AName);
end;

class function TJwtHelper.ClaimValue(const APayload: TJSObject; const AName: string): string;
begin
 if APayload.hasOwnProperty(AName) then
    Result := JS.toString(APayload.Properties[AName])
  else
    Result := '';
end;

class function TJwtHelper.DecodePayload(const AToken: string): string;
begin
  if Trim(AToken) = '' then
    Exit('');
  Result := '';

  asm
    var Token = AToken.split('.');
    if (Token.length = 3) {
    Result = Token[1];
    Result = atob(Result);
     }
  end;
end;

class function TJwtHelper.PayloadAsObject(const AToken: string): TJSObject;
var
  Payload: string;
begin
  Payload := DecodePayload(AToken);
  Result := TJSObject(TJSJSON.parse(Payload));
end;

end.
