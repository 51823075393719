unit GiftAidClaimForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  JobBaseForm,
  WEBLib.ExtCtrls,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.WebCtrls,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.StdCtrls,
  Vcl.Mask,
  WEBLib.Mask,
  WEBLib.CDS;

type
  TGiftAidClaimJob = class(TJobBase)
    AgreeCheckBox: TCheckBox;
    AuthorisedOfficial: TLookupComboBox;
    AuthOfficials: TXDataWebDataSet;
    AuthOfficialsId: TIntegerField;
    AuthOfficialsFirstName: TStringField;
    AuthOfficialsLastName: TStringField;
    ConfigDataset: TXDataWebDataSet;
    ConfigDatasetId: TIntegerField;
    ConfigDatasetHMRCOfficial: TIntegerField;
    ConfigDatasetCharityRef: TStringField;
    ConfigDatasetCharityType: TStringField;
    ConfigDatasetGatewayId: TStringField;
    ConfigDatasetGatewayPassword: TStringField;
    ConfigDatasetRegulator: TStringField;
    ConfigDatasetRegulatorNumber: TStringField;
    ConfigDatasetEndOfYear: TStringField;
    ConfigDatasetOrganisationType: TStringField;
    ConfigDatasetOrganisationName: TStringField;
    ConfigDatasetSubmissionMethod: TStringField;
    ErrorHandling: TLookupComboBox;
    JobsPlacedDateAdded: TDateTimeField;
    JobsPlacedAddedBy: TIntegerField;
    JobsPlacedLastUpdatedBy: TIntegerField;
    JobsPlacedLastUpdated: TDateTimeField;
    JobsPlacedJobCategory: TStringField;
    JobsPlacedLastPolled: TDateTimeField;
    JobsPlacedPollInterval: TLargeintField;
    JobsPlacedPollCount: TIntegerField;
    HMRCPassword: TEdit;
    GAUsers: TClientDataSet;
    UserClaimRef: TEdit;
    procedure WebFormCreate(Sender: TObject);
    procedure AgreeCheckBoxClick(Sender: TObject);
    procedure AuthOfficialsAfterOpen(Dataset: TDataSet);
    procedure AuthorisedOfficialChange(Sender: TObject);
    procedure AuthorisedOfficialExit(Sender: TObject);
    procedure ConfigDatasetAfterOpen(Dataset: TDataSet);
    procedure HMRCPasswordExit(Sender: TObject);
    procedure UserClaimRefExit(Sender: TObject);
  private
    FUnclaimedAmount: Currency;
    FUnclaimedCount: Integer;
    [async]
    procedure LoadAuthOfficials; async;
    [async]
    procedure GetUnclaimedValues; async;
    [async]
    procedure SearchForAuthOfficials; async;

    { Private declarations }
  protected
    procedure JobParams(AJobParams: JS.TJSObject); override;
    function MinimumProcessLevel: string; override;
    function JobDescription: string; override;
    function JobDisplayName: string; override;
    function JobClass: string; override;
    function CanPlaceJob: Boolean; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses
  MainDataModule,
  XData.Web.Client,
  SMX.Web.Document.Utils,
  jsdelphisystem;

procedure TGiftAidClaimJob.WebFormCreate(Sender: TObject);
begin
  inherited;

  OutputOption.Visible := False;
  JobRunType.Visible := False;
  HMRCPassword.Text := '';

  GetUnclaimedValues;

  ErrorHandling.LookupValues.AddPair('OmitRecord', 'Omit Record');
  ErrorHandling.LookupValues.AddPair('CancelSubmission', 'Cancel Submission');
  ErrorHandling.ItemIndex := 0;

  SearchForAuthOfficials;
  // ConfigDataset.Load;
  HMRCPassword.Text := '';
end;

procedure TGiftAidClaimJob.AgreeCheckBoxClick(Sender: TObject);
begin
  ValidateJob;
end;

procedure TGiftAidClaimJob.AuthOfficialsAfterOpen(Dataset: TDataSet);
begin
  PlaceJobButton.Enabled := False;
  LoadAuthOfficials;
end;

procedure TGiftAidClaimJob.AuthorisedOfficialChange(Sender: TObject);
begin
  inherited;
  CanPlaceJob;
end;

procedure TGiftAidClaimJob.AuthorisedOfficialExit(Sender: TObject);
begin
  inherited;
end;

{ TGiftAidClaimJob }

function TGiftAidClaimJob.CanPlaceJob: Boolean;
var
  sPassword: string;

begin
  result := True;

  if (AuthorisedOfficial.ItemIndex > -1) then
    TDocUtils.setControlValidity(AuthorisedOfficial.ElementID, TValidityState.vsValid)
  else
  begin
    TDocUtils.setControlValidity(AuthorisedOfficial.ElementID, TValidityState.vsInvalid);
    result := result and (not AuthorisedOfficial.Visible);
  end;

  if (OutputOption.ItemIndex > -1) then
    TDocUtils.setControlValidity(OutputOption.ElementID, TValidityState.vsValid)
  else
  begin
    TDocUtils.setControlValidity(OutputOption.ElementID, TValidityState.vsInvalid);
    result := result and (not OutputOption.Visible);
  end;

  if (UserClaimRef.Text <> '') then
    TDocUtils.setControlValidity(UserClaimRef.ElementID, TValidityState.vsValid)
  else
  begin
    TDocUtils.setControlValidity(UserClaimRef.ElementID, TValidityState.vsInvalid);
    result := result and (not UserClaimRef.Visible);
  end;

  if (HMRCPassword.Text <> '') then
    TDocUtils.setControlValidity(HMRCPassword.ElementID, TValidityState.vsValid)
  else
  begin
    TDocUtils.setControlValidity(HMRCPassword.ElementID, TValidityState.vsInvalid);
    result := result and (not HMRCPassword.Visible);
  end;

  if result and AgreeCheckBox.Checked then
  begin
    sPassword := HMRCPassword.Text;

    if sPassword.Trim.IsEmpty then
    begin
      TDocUtils.setControlValidity(HMRCPassword.ElementID, TValidityState.vsInvalid);
      SetJobMessage('You must enter your HMRC Gateway password.');
      PlaceJobButton.Enabled := False;
      AgreeCheckBox.Checked := False;
      Exit(False);
    end;

    TDocUtils.setControlValidity(HMRCPassword.ElementID, TValidityState.vsValid);

    if StrToIntDef(AuthorisedOfficial.Value, 0) = 0 then
    begin
      TDocUtils.setControlValidity(AuthorisedOfficial.ElementID, TValidityState.vsInvalid);
      SetJobMessage('You must select the authorised official to be named on the submission.');
      PlaceJobButton.Enabled := False;
      AgreeCheckBox.Checked := False;
      Exit(False);
    end;

    TDocUtils.setControlValidity(AuthorisedOfficial.ElementID, TValidityState.vsValid);

    result := AgreeCheckBox.Checked;

    if result = True then
      PlaceJobButton.Enabled := True;
  end
  else
  begin
    PlaceJobButton.Enabled := False;
  end;
end;

procedure TGiftAidClaimJob.ConfigDatasetAfterOpen(Dataset: TDataSet);
const
  _Filter = '$filter=(Status eq ''Active'') and (HMRCValidation eq ''Validated'')';
begin
  inherited;
  AuthOfficials.QueryString := _Filter;
  AuthOfficials.Load;
end;

procedure TGiftAidClaimJob.GetUnclaimedValues;
var
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;
begin
  lRetval := await(TXDataClientResponse,
    MainData.WebClient.RawInvokeAsync('IGiftAiderService.UnclaimedDonationSummary', []));
  lResult := lRetval.ResultAsObject;

end;

procedure TGiftAidClaimJob.HMRCPasswordExit(Sender: TObject);
begin
  inherited;
  CanPlaceJob;
end;

function TGiftAidClaimJob.JobClass: string;
begin
  result := 'TPrepareClaimJob'; // 'TClaimGiftAidJob';
end;

function TGiftAidClaimJob.JobDescription: string;
begin
  result := 'Claiming Gift Aid - Preparation';
end;

function TGiftAidClaimJob.JobDisplayName: string;
begin
  result := 'Gift Aid Claim Preparation';
end;

procedure TGiftAidClaimJob.JobParams(AJobParams: JS.TJSObject);
begin
  inherited;
  AJobParams.Properties['HMRCPassword'] := HMRCPassword.Text;
  AJobParams.Properties['HMRCOfficial'] := StrToIntDef(AuthorisedOfficial.Value, 0);
  AJobParams.Properties['ErrorHandling'] := ErrorHandling.Value;
  AJobParams.Properties['ClaimRef'] := UserClaimRef.Text;
end;

procedure TGiftAidClaimJob.LoadAuthOfficials;
begin
  while not AuthOfficials.Eof do
  begin
    AuthorisedOfficial.LookupValues.AddPair(AuthOfficialsId.AsString, AuthOfficialsFirstName.Value + ' ' +
      AuthOfficialsLastName.Value);
    AuthOfficials.Next;
  end;

  if AuthOfficials.RecordCount = 1 then
    AuthorisedOfficial.ItemIndex := 0
  else
    AuthorisedOfficial.Value := ConfigDatasetHMRCOfficial.AsString;

end;

function TGiftAidClaimJob.MinimumProcessLevel: string;
begin
  result := 'AnyAdministrator';
end;

procedure TGiftAidClaimJob.SearchForAuthOfficials;
const
  _Filter = '$filter=(Status eq ''Active'') and (HMRCValidation eq ''Validated'')';
var
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;

  lList: JS.TJSArray;

  lIdx: Integer;
  lInternalId: Integer;
  lFirstName: string;
  lSecondName: string;
  lUserName: string;
  OffUsers: JS.TJSObject;
begin

  lRetval := await(TXDataClientResponse,
    MainData.WebClient.RawInvokeAsync('IUserManagerService.GetHMRCAuthorisedUsers', [30]));
  lResult := lRetval.ResultAsObject;
  lList := JS.toArray(lResult['value']);

  if lList <> nil then
  begin
    for lIdx := 0 to lList.Length do
    begin
      OffUsers := JS.toObject(lList.Elements[lIdx]);

      lInternalId := JS.toInteger(OffUsers['InternalId']);
      lFirstName := JS.toString(OffUsers['GivenName']);
      lSecondName := JS.toString(OffUsers['LastName']);

      lUserName := lFirstName + ' ' + lSecondName;
      AuthorisedOfficial.LookupValues.AddPair(inttostr(lInternalId), lUserName);
    end;
  end;
end;

procedure TGiftAidClaimJob.UserClaimRefExit(Sender: TObject);
begin
  inherited;
  CanPlaceJob;
end;

procedure TGiftAidClaimJob.LoadDFMValues;
begin
  inherited LoadDFMValues;

  AgreeCheckBox := TCheckBox.Create('AgreeCheckBox');
  AuthorisedOfficial := TLookupComboBox.Create('AuthorisedOfficial');
  ErrorHandling := TLookupComboBox.Create('ErrorHandling');
  HMRCPassword := TEdit.Create('HMRCPassword');
  UserClaimRef := TEdit.Create('UserClaimRef');
  JobsPlacedDateAdded := TDateTimeField.Create(Self);
  JobsPlacedAddedBy := TIntegerField.Create(Self);
  JobsPlacedLastUpdatedBy := TIntegerField.Create(Self);
  JobsPlacedLastUpdated := TDateTimeField.Create(Self);
  JobsPlacedJobCategory := TStringField.Create(Self);
  JobsPlacedLastPolled := TDateTimeField.Create(Self);
  JobsPlacedPollInterval := TLargeintField.Create(Self);
  JobsPlacedPollCount := TIntegerField.Create(Self);
  AuthOfficials := TXDataWebDataSet.Create(Self);
  AuthOfficialsId := TIntegerField.Create(Self);
  AuthOfficialsFirstName := TStringField.Create(Self);
  AuthOfficialsLastName := TStringField.Create(Self);
  ConfigDataset := TXDataWebDataSet.Create(Self);
  ConfigDatasetId := TIntegerField.Create(Self);
  ConfigDatasetHMRCOfficial := TIntegerField.Create(Self);
  ConfigDatasetCharityRef := TStringField.Create(Self);
  ConfigDatasetCharityType := TStringField.Create(Self);
  ConfigDatasetGatewayId := TStringField.Create(Self);
  ConfigDatasetGatewayPassword := TStringField.Create(Self);
  ConfigDatasetRegulator := TStringField.Create(Self);
  ConfigDatasetRegulatorNumber := TStringField.Create(Self);
  ConfigDatasetEndOfYear := TStringField.Create(Self);
  ConfigDatasetOrganisationType := TStringField.Create(Self);
  ConfigDatasetOrganisationName := TStringField.Create(Self);
  ConfigDatasetSubmissionMethod := TStringField.Create(Self);
  GAUsers := TClientDataSet.Create(Self);

  JobsPlacedTable.BeforeLoadDFMValues;
  OutputOption.BeforeLoadDFMValues;
  JobVisibility.BeforeLoadDFMValues;
  WaitMessage.BeforeLoadDFMValues;
  JobRunType.BeforeLoadDFMValues;
  AgreeCheckBox.BeforeLoadDFMValues;
  AuthorisedOfficial.BeforeLoadDFMValues;
  ErrorHandling.BeforeLoadDFMValues;
  HMRCPassword.BeforeLoadDFMValues;
  UserClaimRef.BeforeLoadDFMValues;
  JobsPlaced.BeforeLoadDFMValues;
  JobsPlacedDateAdded.BeforeLoadDFMValues;
  JobsPlacedAddedBy.BeforeLoadDFMValues;
  JobsPlacedLastUpdatedBy.BeforeLoadDFMValues;
  JobsPlacedLastUpdated.BeforeLoadDFMValues;
  JobsPlacedJobCategory.BeforeLoadDFMValues;
  JobsPlacedLastPolled.BeforeLoadDFMValues;
  JobsPlacedPollInterval.BeforeLoadDFMValues;
  JobsPlacedPollCount.BeforeLoadDFMValues;
  CloseTimer.BeforeLoadDFMValues;
  AuthOfficials.BeforeLoadDFMValues;
  AuthOfficialsId.BeforeLoadDFMValues;
  AuthOfficialsFirstName.BeforeLoadDFMValues;
  AuthOfficialsLastName.BeforeLoadDFMValues;
  ConfigDataset.BeforeLoadDFMValues;
  ConfigDatasetId.BeforeLoadDFMValues;
  ConfigDatasetHMRCOfficial.BeforeLoadDFMValues;
  ConfigDatasetCharityRef.BeforeLoadDFMValues;
  ConfigDatasetCharityType.BeforeLoadDFMValues;
  ConfigDatasetGatewayId.BeforeLoadDFMValues;
  ConfigDatasetGatewayPassword.BeforeLoadDFMValues;
  ConfigDatasetRegulator.BeforeLoadDFMValues;
  ConfigDatasetRegulatorNumber.BeforeLoadDFMValues;
  ConfigDatasetEndOfYear.BeforeLoadDFMValues;
  ConfigDatasetOrganisationType.BeforeLoadDFMValues;
  ConfigDatasetOrganisationName.BeforeLoadDFMValues;
  ConfigDatasetSubmissionMethod.BeforeLoadDFMValues;
  GAUsers.BeforeLoadDFMValues;
  try
    JobsPlacedTable.Left := 64;
    OutputOption.Top := 263;
    JobVisibility.Visible := False;
    WaitMessage.Left := 104;
    WaitMessage.Top := 425;
    JobRunType.Visible := False;
    AgreeCheckBox.SetParentComponent(Self);
    AgreeCheckBox.Name := 'AgreeCheckBox';
    AgreeCheckBox.Left := 64;
    AgreeCheckBox.Top := 350;
    AgreeCheckBox.Width := 113;
    AgreeCheckBox.Height := 22;
    AgreeCheckBox.Caption := 'I Agree';
    AgreeCheckBox.ChildOrder := 10;
    AgreeCheckBox.ElementClassName := 'custom-control custom-checkbox';
    AgreeCheckBox.ElementButtonClassName := 'from-control-input';
    AgreeCheckBox.ElementLabelClassName := 'form-control-label';
    AgreeCheckBox.ElementFont := efCSS;
    AgreeCheckBox.ElementPosition := epIgnore;
    AgreeCheckBox.HeightStyle := ssAuto;
    AgreeCheckBox.HeightPercent := 100.000000000000000000;
    AgreeCheckBox.WidthStyle := ssAuto;
    AgreeCheckBox.WidthPercent := 100.000000000000000000;
    SetEvent(AgreeCheckBox, Self, 'OnClick', 'AgreeCheckBoxClick');
    AuthorisedOfficial.SetParentComponent(Self);
    AuthorisedOfficial.Name := 'AuthorisedOfficial';
    AuthorisedOfficial.Left := 352;
    AuthorisedOfficial.Top := 384;
    AuthorisedOfficial.Width := 249;
    AuthorisedOfficial.Height := 22;
    AuthorisedOfficial.ElementClassName := 'form-select';
    AuthorisedOfficial.ElementFont := efCSS;
    AuthorisedOfficial.HeightPercent := 100.000000000000000000;
    AuthorisedOfficial.WidthPercent := 100.000000000000000000;
    SetEvent(AuthorisedOfficial, Self, 'OnChange', 'AuthorisedOfficialChange');
    SetEvent(AuthorisedOfficial, Self, 'OnExit', 'AuthorisedOfficialExit');
    AuthorisedOfficial.ItemIndex := -1;
    ErrorHandling.SetParentComponent(Self);
    ErrorHandling.Name := 'ErrorHandling';
    ErrorHandling.Left := 63;
    ErrorHandling.Top := 380;
    ErrorHandling.Width := 249;
    ErrorHandling.Height := 22;
    ErrorHandling.ElementClassName := 'form-select';
    ErrorHandling.ElementFont := efCSS;
    ErrorHandling.HeightPercent := 100.000000000000000000;
    ErrorHandling.WidthPercent := 100.000000000000000000;
    ErrorHandling.ItemIndex := -1;
    HMRCPassword.SetParentComponent(Self);
    HMRCPassword.Name := 'HMRCPassword';
    HMRCPassword.Left := 352;
    HMRCPassword.Top := 350;
    HMRCPassword.Width := 249;
    HMRCPassword.Height := 22;
    HMRCPassword.AutoSelect := False;
    HMRCPassword.ChildOrder := 13;
    HMRCPassword.ElementClassName := 'form-control';
    HMRCPassword.ElementPosition := epIgnore;
    HMRCPassword.HeightStyle := ssAuto;
    HMRCPassword.HeightPercent := 100.000000000000000000;
    HMRCPassword.PasswordChar := '*';
    HMRCPassword.WidthStyle := ssAuto;
    HMRCPassword.WidthPercent := 100.000000000000000000;
    SetEvent(HMRCPassword, Self, 'OnExit', 'HMRCPasswordExit');
    UserClaimRef.SetParentComponent(Self);
    UserClaimRef.Name := 'UserClaimRef';
    UserClaimRef.Left := 134;
    UserClaimRef.Top := 408;
    UserClaimRef.Width := 121;
    UserClaimRef.Height := 22;
    UserClaimRef.CharCase := wecUpperCase;
    UserClaimRef.ChildOrder := 13;
    UserClaimRef.ElementPosition := epIgnore;
    UserClaimRef.HeightStyle := ssAuto;
    UserClaimRef.HeightPercent := 100.000000000000000000;
    UserClaimRef.MaxLength := 32;
    UserClaimRef.WidthStyle := ssAuto;
    UserClaimRef.WidthPercent := 100.000000000000000000;
    SetEvent(UserClaimRef, Self, 'OnExit', 'UserClaimRefExit');
    JobsPlacedDateAdded.SetParentComponent(JobsPlaced);
    JobsPlacedDateAdded.Name := 'JobsPlacedDateAdded';
    JobsPlacedDateAdded.FieldName := 'DateAdded';
    JobsPlacedDateAdded.Required := True;
    JobsPlacedAddedBy.SetParentComponent(JobsPlaced);
    JobsPlacedAddedBy.Name := 'JobsPlacedAddedBy';
    JobsPlacedAddedBy.FieldName := 'AddedBy';
    JobsPlacedAddedBy.Required := True;
    JobsPlacedLastUpdatedBy.SetParentComponent(JobsPlaced);
    JobsPlacedLastUpdatedBy.Name := 'JobsPlacedLastUpdatedBy';
    JobsPlacedLastUpdatedBy.FieldName := 'LastUpdatedBy';
    JobsPlacedLastUpdated.SetParentComponent(JobsPlaced);
    JobsPlacedLastUpdated.Name := 'JobsPlacedLastUpdated';
    JobsPlacedLastUpdated.FieldName := 'LastUpdated';
    JobsPlacedJobCategory.SetParentComponent(JobsPlaced);
    JobsPlacedJobCategory.Name := 'JobsPlacedJobCategory';
    JobsPlacedJobCategory.FieldName := 'JobCategory';
    JobsPlacedJobCategory.Size := 8;
    JobsPlacedLastPolled.SetParentComponent(JobsPlaced);
    JobsPlacedLastPolled.Name := 'JobsPlacedLastPolled';
    JobsPlacedLastPolled.FieldName := 'LastPolled';
    JobsPlacedPollInterval.SetParentComponent(JobsPlaced);
    JobsPlacedPollInterval.Name := 'JobsPlacedPollInterval';
    JobsPlacedPollInterval.FieldName := 'PollInterval';
    JobsPlacedPollCount.SetParentComponent(JobsPlaced);
    JobsPlacedPollCount.Name := 'JobsPlacedPollCount';
    JobsPlacedPollCount.FieldName := 'PollCount';
    CloseTimer.Left := 32;
    CloseTimer.Top := 424;
    AuthOfficials.SetParentComponent(Self);
    AuthOfficials.Name := 'AuthOfficials';
    AuthOfficials.AfterOpen := AuthOfficialsAfterOpen;
    AuthOfficials.EntitySetName := 'User';
    AuthOfficials.Connection := MainData.DataConnection;
    AuthOfficials.Left := 456;
    AuthOfficials.Top := 64;
    AuthOfficialsId.SetParentComponent(AuthOfficials);
    AuthOfficialsId.Name := 'AuthOfficialsId';
    AuthOfficialsId.FieldName := 'Id';
    AuthOfficialsFirstName.SetParentComponent(AuthOfficials);
    AuthOfficialsFirstName.Name := 'AuthOfficialsFirstName';
    AuthOfficialsFirstName.FieldName := 'FirstName';
    AuthOfficialsFirstName.Size := 50;
    AuthOfficialsLastName.SetParentComponent(AuthOfficials);
    AuthOfficialsLastName.Name := 'AuthOfficialsLastName';
    AuthOfficialsLastName.FieldName := 'LastName';
    AuthOfficialsLastName.Size := 50;
    ConfigDataset.SetParentComponent(Self);
    ConfigDataset.Name := 'ConfigDataset';
    ConfigDataset.AfterOpen := ConfigDatasetAfterOpen;
    ConfigDataset.EntitySetName := 'GiftAidParams';
    ConfigDataset.Connection := MainData.DataConnection;
    ConfigDataset.Left := 544;
    ConfigDataset.Top := 64;
    ConfigDatasetId.SetParentComponent(ConfigDataset);
    ConfigDatasetId.Name := 'ConfigDatasetId';
    ConfigDatasetId.FieldName := 'Id';
    ConfigDatasetId.Required := True;
    ConfigDatasetHMRCOfficial.SetParentComponent(ConfigDataset);
    ConfigDatasetHMRCOfficial.Name := 'ConfigDatasetHMRCOfficial';
    ConfigDatasetHMRCOfficial.FieldName := 'HMRCOfficial';
    ConfigDatasetHMRCOfficial.Required := True;
    ConfigDatasetCharityRef.SetParentComponent(ConfigDataset);
    ConfigDatasetCharityRef.Name := 'ConfigDatasetCharityRef';
    ConfigDatasetCharityRef.FieldName := 'CharityRef';
    ConfigDatasetCharityRef.Size := 50;
    ConfigDatasetCharityType.SetParentComponent(ConfigDataset);
    ConfigDatasetCharityType.Name := 'ConfigDatasetCharityType';
    ConfigDatasetCharityType.FieldName := 'CharityType';
    ConfigDatasetCharityType.Required := True;
    ConfigDatasetCharityType.Size := 15;
    ConfigDatasetGatewayId.SetParentComponent(ConfigDataset);
    ConfigDatasetGatewayId.Name := 'ConfigDatasetGatewayId';
    ConfigDatasetGatewayId.FieldName := 'GatewayId';
    ConfigDatasetGatewayId.Size := 50;
    ConfigDatasetGatewayPassword.SetParentComponent(ConfigDataset);
    ConfigDatasetGatewayPassword.Name := 'ConfigDatasetGatewayPassword';
    ConfigDatasetGatewayPassword.FieldName := 'GatewayPassword';
    ConfigDatasetGatewayPassword.Size := 64;
    ConfigDatasetRegulator.SetParentComponent(ConfigDataset);
    ConfigDatasetRegulator.Name := 'ConfigDatasetRegulator';
    ConfigDatasetRegulator.FieldName := 'Regulator';
    ConfigDatasetRegulator.Required := True;
    ConfigDatasetRegulator.Size := 5;
    ConfigDatasetRegulatorNumber.SetParentComponent(ConfigDataset);
    ConfigDatasetRegulatorNumber.Name := 'ConfigDatasetRegulatorNumber';
    ConfigDatasetRegulatorNumber.FieldName := 'RegulatorNumber';
    ConfigDatasetRegulatorNumber.Size := 50;
    ConfigDatasetEndOfYear.SetParentComponent(ConfigDataset);
    ConfigDatasetEndOfYear.Name := 'ConfigDatasetEndOfYear';
    ConfigDatasetEndOfYear.FieldName := 'EndOfYear';
    ConfigDatasetEndOfYear.Size := 5;
    ConfigDatasetOrganisationType.SetParentComponent(ConfigDataset);
    ConfigDatasetOrganisationType.Name := 'ConfigDatasetOrganisationType';
    ConfigDatasetOrganisationType.FieldName := 'OrganisationType';
    ConfigDatasetOrganisationType.Required := True;
    ConfigDatasetOrganisationType.Size := 16;
    ConfigDatasetOrganisationName.SetParentComponent(ConfigDataset);
    ConfigDatasetOrganisationName.Name := 'ConfigDatasetOrganisationName';
    ConfigDatasetOrganisationName.FieldName := 'OrganisationName';
    ConfigDatasetOrganisationName.Size := 250;
    ConfigDatasetSubmissionMethod.SetParentComponent(ConfigDataset);
    ConfigDatasetSubmissionMethod.Name := 'ConfigDatasetSubmissionMethod';
    ConfigDatasetSubmissionMethod.FieldName := 'SubmissionMethod';
    ConfigDatasetSubmissionMethod.Required := True;
    ConfigDatasetSubmissionMethod.Size := 4;
    GAUsers.SetParentComponent(Self);
    GAUsers.Name := 'GAUsers';
    GAUsers.Left := 8;
    GAUsers.Top := 16;
  finally
    JobsPlacedTable.AfterLoadDFMValues;
    OutputOption.AfterLoadDFMValues;
    JobVisibility.AfterLoadDFMValues;
    WaitMessage.AfterLoadDFMValues;
    JobRunType.AfterLoadDFMValues;
    AgreeCheckBox.AfterLoadDFMValues;
    AuthorisedOfficial.AfterLoadDFMValues;
    ErrorHandling.AfterLoadDFMValues;
    HMRCPassword.AfterLoadDFMValues;
    UserClaimRef.AfterLoadDFMValues;
    JobsPlaced.AfterLoadDFMValues;
    JobsPlacedDateAdded.AfterLoadDFMValues;
    JobsPlacedAddedBy.AfterLoadDFMValues;
    JobsPlacedLastUpdatedBy.AfterLoadDFMValues;
    JobsPlacedLastUpdated.AfterLoadDFMValues;
    JobsPlacedJobCategory.AfterLoadDFMValues;
    JobsPlacedLastPolled.AfterLoadDFMValues;
    JobsPlacedPollInterval.AfterLoadDFMValues;
    JobsPlacedPollCount.AfterLoadDFMValues;
    CloseTimer.AfterLoadDFMValues;
    AuthOfficials.AfterLoadDFMValues;
    AuthOfficialsId.AfterLoadDFMValues;
    AuthOfficialsFirstName.AfterLoadDFMValues;
    AuthOfficialsLastName.AfterLoadDFMValues;
    ConfigDataset.AfterLoadDFMValues;
    ConfigDatasetId.AfterLoadDFMValues;
    ConfigDatasetHMRCOfficial.AfterLoadDFMValues;
    ConfigDatasetCharityRef.AfterLoadDFMValues;
    ConfigDatasetCharityType.AfterLoadDFMValues;
    ConfigDatasetGatewayId.AfterLoadDFMValues;
    ConfigDatasetGatewayPassword.AfterLoadDFMValues;
    ConfigDatasetRegulator.AfterLoadDFMValues;
    ConfigDatasetRegulatorNumber.AfterLoadDFMValues;
    ConfigDatasetEndOfYear.AfterLoadDFMValues;
    ConfigDatasetOrganisationType.AfterLoadDFMValues;
    ConfigDatasetOrganisationName.AfterLoadDFMValues;
    ConfigDatasetSubmissionMethod.AfterLoadDFMValues;
    GAUsers.AfterLoadDFMValues;
  end;
end;

end.
