unit Sphinx.OidcClient.AuthRequest;

interface

uses
  SysUtils;

type
  TClientAuthRequest = class
  private
    FRedirectUri: string;
    FResponseType: string;
    FScope: string;
    FResponseMode: string;
    FState: string;
    FNonce: string;
    FCodeChallenge: string;
    FCodeVerifier: string;
  private
    function GetIsOpenId: Boolean;
    function GetIsCodeFlow: Boolean;
  public
    function GetRequestUrl(const AuthorizationEndpoint, ClientId: string): string;
    property State: string read FState write FState;
    property RedirectUri: string read FRedirectUri write FRedirectUri;
    property ResponseType: string read FResponseType write FResponseType;
    property Scope: string read FScope write FScope;
    property ResponseMode: string read FResponseMode write FResponseMode;
    property Nonce: string read FNonce write FNonce;
    property CodeChallenge: string read FCodeChallenge write FCodeChallenge;
    property CodeVerifier: string read FCodeVerifier write FCodeVerifier;
    property IsOpenId: Boolean read GetIsOpenId;
    property IsCodeFlow: Boolean read GetIsCodeFlow;
  end;

implementation

uses
  Sphinx.Consts,
  Sphinx.Utils;

{ TClientAuthRequest }

function TClientAuthRequest.GetIsCodeFlow: Boolean;
begin
  Result := TSpacedTokens.Create(ResponseType).Contains('code');
end;

function TClientAuthRequest.GetIsOpenId: Boolean;
begin
  Result := TSpacedTokens.Create(Scope).Contains('openid');
end;

function TClientAuthRequest.GetRequestUrl(const AuthorizationEndpoint, ClientId: string): string;
var
  Params: IModifiableParams;
begin
  if AuthorizationEndpoint = '' then
    raise Exception.Create('AuthorizationEndpoint required');
  if ResponseType = '' then
    raise Exception.Create('ResponseType required');
  if ClientId = '' then
    raise Exception.Create('ClientId required');
  if State = '' then
    raise Exception.Create('State required');
  if IsOpenId and (Nonce = '') then
    raise Exception.Create('Nonce required for OpenId Connect');

  Params := TModifiableParams.Create;
  Params.Add(AuthorizeRequestParams.ResponseType, ResponseType);
  Params.Add(AuthorizeRequestParams.ClientId, ClientId);
  Params.Add(AuthorizeRequestParams.State, State);

  if RedirectUri <> '' then
    Params.Add(AuthorizeRequestParams.RedirectUri, RedirectUri);
  if Scope <> '' then
    Params.Add(AuthorizeRequestParams.Scope, Scope);
  if IsOpenId then
    Params.Add(AuthorizeRequestParams.Nonce, Nonce);
  if ResponseMode <> '' then
    Params.Add(AuthorizeRequestParams.ResponseMode, ResponseMode);
  if CodeChallenge <> '' then
  begin
    Params.Add(AuthorizeRequestParams.CodeChallenge, CodeChallenge);
    Params.Add(AuthorizeRequestParams.CodeChallengeMethod, CodeChallengeMethods.Sha256);
  end;
  Result := AddUrlParams(AuthorizationEndpoint, Params);
end;

end.
