unit Sphinx.OidcClient;

{$I Sphinx.inc}

interface

uses
  SysUtils, DateUtils, StrUtils,
{$IFDEF PAS2JS}
  JS, Web,
  Sphinx.Web.Http,
{$ELSE}
  Generics.Collections,
  Rtti,
  Bcl.Json,
  Bcl.Json.Classes,
  Sparkle.Http.Client,
{$ENDIF}
  Bcl.Json.Common,
  Bcl.Utils,
  Sparkle.Utils,
  Sphinx.Discovery.Metadata,
  Sphinx.OidcClient.AuthRequest,
  Sphinx.OidcClient.AuthState,
  Sphinx.OidcClient.AuthResult,
  Sphinx.OidcClient.AuthResultEntry,
  Sphinx.OidcClient.Profile,
  Sphinx.OidcClient.Storage;

type
  TAuthState = Sphinx.OidcClient.AuthState.TAuthState;
  TAuthResult = Sphinx.OidcClient.AuthResult.TAuthResult;
  ITokenResult = Sphinx.OidcClient.AuthResult.ITokenResult;

  EOidcClientException = class(Exception);

  EOidcClientError = class(EOidcClientException)
  public
    constructor Create(const Error, ErrorDescription: string); overload;
  end;

  TClientAuthResponse = class
  private
    FState: string;
    FError: string;
    FErrorDescription: string;
    FIdToken: string;
    FCode: string;
    FAccessToken: string;
    FTokenType: string;
    FExpiresIn: Integer;
    FExpiration: TDateTime;
    FScope: string;
  public
    property State: string read FState write FState;
    property IdToken: string read FIdToken write FIdToken;
    property Code: string read FCode write FCode;
    property AccessToken: string read FAccessToken write FAccessToken;
    property TokenType: string read FTokenType write FTokenType;
    property ExpiresIn: Integer read FExpiresIn write FExpiresIn;
    property Scope: string read FScope write FScope;
    property Expiration: TDateTime read FExpiration;
    property Error: string read FError write FError;
    property ErrorDescription: string read FErrorDescription write FErrorDescription;
  end;

  TProviderInformation = class
  private
    FIssuer: string;
    FAuthorizationEndpoint: string;
    FTokenEndpoint: string;
  public
    property Issuer: string read FIssuer write FIssuer;
    property AuthorizationEndpoint: string read FAuthorizationEndpoint write FAuthorizationEndpoint;
    property TokenEndpoint: string read FTokenEndpoint write FTokenEndpoint;
  end;

  TOidcClient = class
  strict private
    FStorage: TClientStorage;
    FClientId: string;
    FClientSecret: string;
    FScope: string;
    FRedirectUri: string;
    FProviderInfo: TProviderInformation;
    FValidProviderInfo: TProviderInformation;
    FAuthority: string;
    FAutoDiscover: Boolean;
    procedure SetAuthority(const Value: string);
    function AuthStateKey: string;
    function IsCallbackUri(const ResponseUri, ExpectedState: string): Boolean;
    function ResponseFromParams(Params: TQueryString): TClientAuthResponse;
    procedure ValidateIdToken(Entry: TAuthResultEntry; AuthState: TAuthState);
    procedure ValidateAccessToken(Response: TClientAuthResponse; AuthState: TAuthState);
    procedure ValidateResponse(Entry: TAuthResultEntry; Response: TClientAuthResponse; AuthState: TAuthState); {$IFDEF PAS2JS}async;{$ENDIF}
    procedure ProcessCodeResponse(Entry: TAuthResultEntry; Response: TClientAuthResponse; AuthState: TAuthState); {$IFDEF PAS2JS}async;{$ENDIF}
    procedure ValidateIdTokenJwt(Payload: TJObject; const Issuer, Audience: string);
    function ProcessAuthResponse(const CallbackUrl: string): TAuthResult; {$IFDEF PAS2JS}async;{$ENDIF}
    function HttpRequestToken(const Params, AuthHeader: string): TJObject; {$IFDEF PAS2JS}async;{$ENDIF}
    procedure RequestTokenEntry(Entry: TAuthResultEntry); {$IFDEF PAS2JS}async;{$ENDIF}
    function ExpiresInFromString(const ExpiresInParam: string): Integer;
    procedure CheckProviderInformation; {$IFDEF PAS2JS}async;{$ENDIF}
    procedure MetadataToProvider(Metadata: TOidcProviderMetadata; Info: TProviderInformation);
    function GetProviderMetadata: TOidcProviderMetadata; {$IFDEF PAS2JS}async;{$ENDIF}
    function GetJsonProviderMetadata(const Url: string): TJObject; {$IFDEF PAS2JS}async;{$ENDIF}
    function RemoveTrailingSlash(const S: string): string;
  strict protected
    property ValidProviderInfo: TProviderInformation read FValidProviderInfo;
  public
    constructor Create;
    destructor Destroy; override;

    function StartAuthorize(const AppState: string = ''): TAuthState; {$IFDEF PAS2JS}async;{$ENDIF}
    function FinishAuthorize(const CallbackUrl: string): TAuthResult; overload; {$IFDEF PAS2JS}async;{$ENDIF}
{$IFDEF PAS2JS}
    function FinishAuthorize: TAuthResult; overload; {$IFDEF PAS2JS}async;{$ENDIF}
{$ENDIF}
    function IsValidCallback(const CallbackUrl: string): Boolean; overload;
{$IFDEF PAS2JS}
    function IsValidCallback: Boolean; overload;
{$ENDIF}
    function CreateState(Request: TClientAuthRequest; const AppState: string = ''): TAuthState; {$IFDEF PAS2JS}async;{$ENDIF}

    function RequestToken: ITokenResult; {$IFDEF PAS2JS}async;{$ENDIF}

    property Authority: string read FAuthority write SetAuthority;
    property ClientId: string read FClientId write FClientId;
    property ClientSecret: string read FClientSecret write FClientSecret;
    property Scope: string read FScope write FScope;
    property RedirectUri: string read FRedirectUri write FRedirectUri;
    property AutoDiscover: Boolean read FAutoDiscover write FAutoDiscover;
    property ProviderInfo: TProviderInformation read FProviderInfo;
  end;

implementation

uses
  Bcl.Lang,
  Sphinx.Consts,
  Sphinx.Utils;

{ TOidcClient }

procedure TOidcClient.CheckProviderInformation;
var
  Metadata: TOidcProviderMetadata;
begin
  if Authority = '' then
    raise EOidcClientException.CreateFmt(_(SMissingParameter), ['Authority']);
  if ClientId = '' then
    raise EOidcClientException.CreateFmt(_(SMissingParameter), ['ClientId']);

  if FValidProviderInfo = nil then
  begin
    FValidProviderInfo := TProviderInformation.Create;
    if AutoDiscover then
    begin
      Metadata := {$IFDEF PAS2JS}await{$ENDIF}(GetProviderMetadata);
      try
        if Metadata <> nil then
          MetadataToProvider(Metadata, FValidProviderInfo);
      finally
{$IFNDEF PAS2JS}
        Metadata.Free;
{$ENDIF}
      end;
    end;

    // Load custom provider data
    if ProviderInfo <> nil then
    begin
      if FValidProviderInfo.Issuer = '' then
        FValidProviderInfo.Issuer := ProviderInfo.Issuer;
      if FValidProviderInfo.AuthorizationEndpoint = '' then
        FValidProviderInfo.AuthorizationEndpoint := ProviderInfo.AuthorizationEndpoint;
      if FValidProviderInfo.TokenEndpoint = '' then
        FValidProviderInfo.FTokenEndpoint := ProviderInfo.TokenEndpoint;
    end;

    if FValidProviderInfo.Issuer = '' then
      FValidProviderInfo.Issuer := Authority;
  end;

  if FValidProviderInfo.Issuer = '' then
    raise EOidcClientException.CreateFmt(_(SMissingParameter), ['Issuer']);
  if RemoveTrailingSlash(FValidProviderInfo.Issuer) <> RemoveTrailingSlash(Authority) then
    raise EOidcClientException.CreateFmt(_(SInvalidParameter), ['Issuer', FValidProviderInfo.Issuer]);
end;

constructor TOidcClient.Create;
begin
  inherited Create;
  FStorage := TClientStorage.Create;
  FProviderInfo := TProviderInformation.Create;
  FAutoDiscover := True;
end;

function TOidcClient.CreateState(Request: TClientAuthRequest; const AppState: string = ''): TAuthState;
var
  AuthState: TAuthState;
  AuthorizeUrl: string;
begin
{$IFDEF PAS2JS}
  await(CheckProviderInformation);
{$ELSE}
  CheckProviderInformation;
{$ENDIF}
  if FValidProviderInfo.AuthorizationEndpoint = '' then
    raise EOidcClientException.CreateFmt(_(SMissingParameter), ['AuthorizationEndpoint']);
  if Request.ResponseType = ResponseTypes.Code then
    if FValidProviderInfo.TokenEndpoint = '' then
      raise EOidcClientException.CreateFmt(_(SMissingParameter), ['TokenEndpoint']);

  // Create needed random strings (state, nonce, code verifier...)
  if Request.State = '' then
    Request.State := TBclUtils.RandomString;
  if Request.IsOpenId and (Request.Nonce = '') then
    Request.Nonce := TBclUtils.RandomString;
  if Request.IsCodeFlow then
  begin
    if Request.CodeVerifier = '' then
      Request.CodeVerifier := TBclUtils.RandomString(96);
    if Request.CodeChallenge = '' then
      Request.CodeChallenge := Sha256(Request.CodeVerifier);
  end
  else
  begin
    Request.CodeVerifier := '';
    Request.CodeChallenge := '';
  end;
  AuthorizeUrl := Request.GetRequestUrl(ValidProviderInfo.AuthorizationEndpoint, ClientId);

  // Create state object and save it in storage
{$IFDEF PAS2JS}
  AuthState := TAuthState.new;
{$ELSE}
  AuthState := TAuthState.Create;
{$ENDIF}
  try
    AuthState.AuthorizeUrl := AuthorizeUrl;
    AuthState.State := Request.State;
    AuthState.Scope := Request.Scope;
    AuthState.ClientId := ClientId;
    AuthState.RedirectUri := Request.RedirectUri;
    AuthState.Nonce := Request.Nonce;
    AuthState.CreatedOn := Now;
    AuthState.ResponseMode := Request.ResponseMode;
    AuthState.CodeVerifier := Request.CodeVerifier;
    AuthState.CodeChallenge := Request.CodeChallenge;
    AuthState.AppState := AppState;
    FStorage.Save<TAuthState>(AuthStateKey, AuthState);
  except
{$IFNDEF PAS2JS}
    AuthState.Free;
{$ENDIF}
    raise;
  end;
  Result := AuthState;
end;

destructor TOidcClient.Destroy;
begin
  FStorage.Free;
  FValidProviderInfo.Free;
  FProviderInfo.Free;
  inherited;
end;

function TOidcClient.ExpiresInFromString(const ExpiresInParam: string): Integer;
begin
  if ExpiresInParam = '' then
    Result := 0
  else
    if not TryStrToInt(ExpiresInParam, Result) then
      raise EOidcClientException.CreateFmt(_(SInvalidParameter), [TokenResponseParams.ExpiresIn, ExpiresInParam]);
end;

function TOidcClient.FinishAuthorize(const CallbackUrl: string): TAuthResult;
begin
{$IFDEF PAS2JS}
  Result := await(ProcessAuthResponse(CallbackUrl));
{$ELSE}
  Result := ProcessAuthResponse(CallbackUrl);
{$ENDIF}
end;

function TOidcClient.GetJsonProviderMetadata(const Url: string): TJObject;
{$IFDEF PAS2JS}
var
  Request: IHttpRequest;
  Response: THttpResponse;
begin
  Result := nil;
  Request := THttpRequest.Create;
  Request.Uri := Url;
  Request.Method := 'GET';
  try
    Response := await(SendHttpRequestAsync(Request));
    if Response.StatusCode = 200 then
      Result := TJSJson.parseObject(Response.ContentAsText);
  except
    on E: Exception do
      console.log(E);
  end;
end;
{$ELSE}
var
  Client: THttpClient;
  Response: THttpResponse;
begin
  Result := nil;
  Client := THttpClient.Create;
  try
    Response := Client.Get(Url);
    try
      if Response.StatusCode = 200 then
        Result := TJson.Deserialize<TJObject>(TEncoding.UTF8.GetString(Response.ContentAsBytes))
    finally
      Response.Free;
    end;
  finally
    Client.Free;
  end;
end;
{$ENDIF}

function TOidcClient.GetProviderMetadata: TOidcProviderMetadata;
var
  Url: string;
  JObject: TJObject;
begin
  Url := TSparkleUtils.CombineUrlFast(Authority, '.well-known/openid-configuration');
  JObject := {$IFDEF PAS2JS}await{$ENDIF}(GetJsonProviderMetadata(Url));
  try
    Result := TOidcProviderMetadata.Create;
    try
      if JObject <> nil then
        MetadataFromJObject(Result, JObject);
    except
      Result.Free;
      raise;
    end;
  finally
{$IFNDEF PAS2JS}
    JObject.Free;
{$ENDIF}
  end;
end;

function TOidcClient.HttpRequestToken(const Params, AuthHeader: string): TJObject;
{$IFDEF PAS2JS}
var
  Request: IHttpRequest;
  Response: THttpResponse;
  ContentType: string;
begin
  await(CheckProviderInformation);
  if FValidProviderInfo.TokenEndpoint = '' then
    raise EOidcClientException.CreateFmt(_(SMissingParameter), ['TokenEndpoint']);

  Request := THttpRequest.Create;
  Request.Uri := ValidProviderInfo.TokenEndpoint;
  Request.Method := 'POST';
  Request.Headers.AddValue('Content-Type', 'application/x-www-form-urlencoded');
  if AuthHeader <> '' then
    Request.Headers.AddValue('Authorization', AuthHeader);
  Request.Content := Params;

  Response := await(SendHttpRequestAsync(Request));
  ContentType := Response.Headers.Get('Content-Type');
  if SameText(ContentType, 'application/json') or StartsText('application/json;', ContentType) then
    Result := TJSJson.parseObject(Response.ContentAsText)
  else
    Result := nil;
end;
{$ELSE}
var
  Client: THttpClient;
  Request: THttpRequest;
  Response: THttpResponse;
begin
  CheckProviderInformation;
  Client := THttpClient.Create;
  try
    Request := THttpRequest.Create;
    try
      Request.Uri := ValidProviderInfo.TokenEndpoint;
      Request.Method := 'POST';
      Request.Headers.AddValue('Content-Type', 'application/x-www-form-urlencoded');
      if AuthHeader <> '' then
        Request.Headers.AddValue('Authorization', AuthHeader);
      Request.SetContent(TEncoding.UTF8.GetBytes(Params));

      Response := Client.Send(Request);
      try
        if SameText(Response.ContentType, 'application/json') or StartsText('application/json;', Response.ContentType) then
          Result := TJson.Deserialize<TJObject>(TEncoding.UTF8.GetString(Response.ContentAsBytes))
        else
          Result := nil;
      finally
        Response.Free;
      end;
    finally
      Request.Free;
    end;
  finally
    Client.Free;
  end;
end;
{$ENDIF}

{$IFDEF PAS2JS}
function TOidcClient.FinishAuthorize: TAuthResult;
var
  CallbackUrl: string;
begin
  CallbackUrl := window.location.href;
  Result := await(ProcessAuthResponse(CallbackUrl));
end;
{$ENDIF}

function TOidcClient.StartAuthorize(const AppState: string = ''): TAuthState;
var
  Request: TClientAuthRequest;
begin
  Request := TClientAuthRequest.Create;
  try
    Request.RedirectUri := RedirectUri;
    Request.Scope := Scope;
    Request.ResponseType := 'code';
{$IFDEF PAS2JS}
    Result := await(CreateState(Request, AppState));
{$ELSE}
    Result := CreateState(Request, AppState);
{$ENDIF}
  finally
    Request.Free;
  end;
end;

function TOidcClient.IsCallbackUri(const ResponseUri, ExpectedState: string): Boolean;
var
  Params: TQueryString;
begin
  Params := ParamsFromUri(ResponseUri);
  if Params.GetValue(AuthorizeResponseParams.State) = '' then
    Exit(False);
  if (Params.GetValue(AuthorizeResponseParams.AccessToken) = '')
    and (Params.GetValue(AuthorizeResponseParams.Error) = '')
    and (Params.GetValue(AuthorizeResponseParams.IdentityToken) = '')
    and (Params.GetValue(AuthorizeResponseParams.Code) = '') then
    Exit(False);
  Result := (ExpectedState = '') or (ExpectedState = Params.GetValue(AuthorizeResponseParams.State));
end;

function TOidcClient.IsValidCallback(const CallbackUrl: string): Boolean;
var
  AuthState: TAuthState;
begin
  AuthState := FStorage.Get<TAuthState>(AuthStateKey);
  if AuthState = nil then
    Exit(False);
  Result := IsCallbackUri(CallbackUrl, AuthState.State);
end;

procedure TOidcClient.MetadataToProvider(Metadata: TOidcProviderMetadata; Info: TProviderInformation);
begin
  Info.Issuer := Metadata.Issuer;
  Info.AuthorizationEndpoint := Metadata.AuthorizationEndpoint;
  Info.TokenEndpoint := Metadata.TokenEndpoint;
end;

{$IFDEF PAS2JS}
function TOidcClient.IsValidCallback: Boolean;
begin
  Result := IsValidCallback(window.location.href);
end;
{$ENDIF}

procedure TOidcClient.ProcessCodeResponse(Entry: TAuthResultEntry; Response: TClientAuthResponse; AuthState: TAuthState);
var
  Params: IModifiableParams;
  AuthHeader: string;
  RequestParams: string;
  TokenResponse: TJObject;
  Error: string;
  ExpiresIn: Integer;
begin
  if (AuthState.CodeVerifier = '') then
    raise EOidcClientException.Create(Format(_(SMissingParameter), [TokenRequestParams.CodeVerifier]));

  // build request params
  Params := TModifiableParams.Create;
  Params.Add(TokenRequestParams.GrantType, GrantTypes.AuthorizationCode);
  Params.Add(TokenRequestParams.Code, Response.Code);
  Params.Add(TokenRequestParams.RedirectUri, AuthState.RedirectUri);
  Params.Add(TokenRequestParams.CodeVerifier, AuthState.CodeVerifier);
  if Self.ClientSecret = '' then // no authentication
  begin
    Params.Add(AuthorizeRequestParams.ClientId, AuthState.ClientId);
    AuthHeader := '';
  end
  else
    AuthHeader := TSparkleUtils.BasicAuthHeaderValue(AuthState.ClientId, Self.ClientSecret);
  RequestParams := BuildQueryParams(Params);

{$IFDEF PAS2JS}
  TokenResponse := await(HttpRequestToken(RequestParams, AuthHeader));
{$ELSE}
  TokenResponse := HttpRequestToken(RequestParams, AuthHeader);
{$ENDIF}
  try
    if TokenResponse = nil then
      raise EOidcClientException.Create('Could not retrieve token');

    Error := JStringOrDefault(TokenResponse[TokenResponseParams.Error]);
    if Error <> '' then
      raise EOidcClientError.Create(Error, JStringOrDefault(TokenResponse[TokenResponseParams.ErrorDescription]));

    Entry.AccessToken := JStringOrDefault(TokenResponse[TokenResponseParams.AccessToken]);
    Entry.RefreshToken := JStringOrDefault(TokenResponse[TokenResponseParams.RefreshToken]);
    Entry.IdToken := JStringOrDefault(TokenResponse[TokenResponseParams.IdentityToken]);
    Entry.TokenType := JStringOrDefault(TokenResponse[TokenResponseParams.TokenType]);
    Entry.Scope := JStringOrDefault(TokenResponse[TokenResponseParams.Scope]);
    if Entry.Scope = '' then
      Entry.Scope := AuthState.Scope;
    ExpiresIn := JIntegerOrDefault(TokenResponse[TokenResponseParams.ExpiresIn]);
    if ExpiresIn = 0 then
      Entry.ExpiresAt := MaxDateTime
    else
      Entry.ExpiresAt := IncSecond(Now, ExpiresIn);
  finally
{$IFNDEF PAS2JS}
    TokenResponse.Free;
{$ENDIF}
  end;
end;

function TOidcClient.ProcessAuthResponse(const CallbackUrl: string): TAuthResult;
var
  AuthState: TAuthState;
  Response: TClientAuthResponse;
  Entry: TAuthResultEntry;
begin
{$IFDEF PAS2JS}
  await(CheckProviderInformation);
{$ELSE}
  CheckProviderInformation;
{$ENDIF}

{$IFNDEF DELPHITOKYO_LVL} // W1035 warning in Seattle and Berlin
  Result := nil;
{$ENDIF}

  // Repeated code from IsValidCallback
  AuthState := FStorage.Get<TAuthState>(AuthStateKey);
  if AuthState = nil then
    raise EOidcClientException.Create(_(SAuthStateNotExpected));
  if not IsCallbackUri(CallbackUrl, AuthState.State) then
    raise EOidcClientException.Create(_(SInvalidCallbackUrl));

  try
    // Retrieve the response
    Response := ResponseFromParams(ParamsFromUri(CallbackUrl));
    try
{$IFDEF PAS2JS}
      Entry := TAuthResultEntry.new;
      await(ValidateResponse(Entry, Response, AuthState));
{$ELSE}
      Entry := TAuthResultEntry.Create;
      try
        ValidateResponse(Entry, Response, AuthState);
      except
        Entry.Free;
        raise;
      end;
{$ENDIF}
    finally
      Response.Free;
    end;

    Result := TAuthResult.Create(Entry);
{$IFDEF PAS2JS}
    // Remove query parameters from URL
    window.history.replaceState(TJSObject.new, document.Title, window.location.pathname);
{$ENDIF}
  finally
    // AuthState finished
    FStorage.Remove(AuthStateKey);
  end;
end;

function TOidcClient.RemoveTrailingSlash(const S: string): string;
begin
  if (S <> '') and (S[Length(S)] = '/') then
    Result := Copy(S, 1, Length(S) - 1)
  else
    Result := S;
end;

function TOidcClient.RequestToken: ITokenResult;
var
  Entry: TAuthResultEntry;
begin
{$IFDEF PAS2JS}
  Entry := TAuthResultEntry.new;
  await(RequestTokenEntry(Entry));
{$ELSE}
  Entry := TAuthResultEntry.Create;
  try
    RequestTokenEntry(Entry);
  except
    Entry.Free;
    raise;
  end;
{$ENDIF}
  Result := TAuthResult.Create(Entry);
end;

procedure TOidcClient.RequestTokenEntry(Entry: TAuthResultEntry);
var
  Params: IModifiableParams;
  AuthHeader: string;
  RequestParams: string;
  TokenResponse: TJObject;
  Error: string;
  ExpiresIn: Integer;
begin
  // build request params
  Params := TModifiableParams.Create;
  Params.Add(TokenRequestParams.GrantType, GrantTypes.ClientCredentials);
  Params.Add(TokenRequestParams.Scope, Self.Scope);
  if Self.ClientSecret = '' then // no authentication
  begin
    Params.Add(AuthorizeRequestParams.ClientId, Self.ClientId);
    AuthHeader := '';
  end
  else
    AuthHeader := TSparkleUtils.BasicAuthHeaderValue(Self.ClientId, Self.ClientSecret);
  RequestParams := BuildQueryParams(Params);

{$IFDEF PAS2JS}
  TokenResponse := await(HttpRequestToken(RequestParams, AuthHeader));
{$ELSE}
  TokenResponse := HttpRequestToken(RequestParams, AuthHeader);
{$ENDIF}
  try
    if TokenResponse = nil then
      raise EOidcClientException.Create('Could not retrieve token');

    Error := JStringOrDefault(TokenResponse[TokenResponseParams.Error]);
    if Error <> '' then
      raise EOidcClientError.Create(Error, JStringOrDefault(TokenResponse[TokenResponseParams.ErrorDescription]));

    Entry.AccessToken := JStringOrDefault(TokenResponse[TokenResponseParams.AccessToken]);
    Entry.RefreshToken := JStringOrDefault(TokenResponse[TokenResponseParams.RefreshToken]);
    Entry.IdToken := JStringOrDefault(TokenResponse[TokenResponseParams.IdentityToken]);
    Entry.TokenType := JStringOrDefault(TokenResponse[TokenResponseParams.TokenType]);
    Entry.Scope := JStringOrDefault(TokenResponse[TokenResponseParams.Scope]);
    if Entry.Scope = '' then
      Entry.Scope := Self.Scope;
    ExpiresIn := JIntegerOrDefault(TokenResponse[TokenResponseParams.ExpiresIn]);
    if ExpiresIn = 0 then
      Entry.ExpiresAt := MaxDateTime
    else
      Entry.ExpiresAt := IncSecond(Now, ExpiresIn);
  finally
{$IFNDEF PAS2JS}
    TokenResponse.Free;
{$ENDIF}
  end;
  Entry.ClientId := Self.ClientId;
end;

function TOidcClient.ResponseFromParams(Params: TQueryString): TClientAuthResponse;
begin
  Result := TClientAuthResponse.Create;
  try
    Result.State := Params.GetValue(AuthorizeResponseParams.State);
    Result.IdToken := Params.GetValue(AuthorizeResponseParams.IdentityToken);
    Result.AccessToken := Params.GetValue(AuthorizeResponseParams.AccessToken);
    Result.TokenType := Params.GetValue(AuthorizeResponseParams.TokenType);
    Result.Scope := Params.GetValue(AuthorizeResponseParams.Scope);
    Result.Code := Params.GetValue(AuthorizeResponseParams.Code);
    Result.ExpiresIn := ExpiresInFromString(Params.GetValue(AuthorizeResponseParams.ExpiresIn));
    Result.Error := Params.GetValue(AuthorizeResponseParams.Error);
    Result.ErrorDescription := Params.GetValue(AuthorizeResponseParams.ErrorDescription);
  except
    Result.Free;
    raise;
  end;
end;

procedure TOidcClient.SetAuthority(const Value: string);
begin
  if FAuthority <> Value then
  begin
    FAuthority := Value;
    FreeAndNil(FValidProviderInfo);
  end;
end;

function TOidcClient.AuthStateKey: string;
begin
  Result := 'st::' + ClientId;
end;

procedure TOidcClient.ValidateAccessToken(Response: TClientAuthResponse; AuthState: TAuthState);
begin
  // TODO: Validate access token (at_hash)
end;

procedure TOidcClient.ValidateIdToken(Entry: TAuthResultEntry; AuthState: TAuthState);
var
  Payload: TJObject;
begin
  if AuthState.Nonce = '' then
    raise EOidcClientException.Create(_(SMissingNonceInState));

  Payload := GetJwtPayload(Entry.IdToken);
  try
    if Payload = nil then
      raise EOidcClientException.Create(_(SIdTokenInvalidPayload));

    if AuthState.Nonce <> JToStr(Payload[JwtClaimNames.Nonce]) then
      raise EOidcClientException.CreateFmt(_(SInvalidClaim), [JwtClaimNames.Nonce, JToStr(Payload[JwtClaimNames.Nonce])]);

    ValidateIdTokenJwt(Payload, ValidProviderInfo.Issuer, AuthState.ClientId);
  finally
{$IFNDEF PAS2JS}
    Payload.Free;
{$ENDIF}
  end;

  // Todo: validate JWT signature
end;

procedure TOidcClient.ValidateIdTokenJwt(Payload: TJObject; const Issuer, Audience: string);
const
  ClockSkew = 5 * 60;  // 5 minutes
var
  LowerNow, UpperNow: NativeInt;
  NowEpoch: NativeInt;
  IssuedAt: NativeInt;
  Expiration: NativeInt;
  NotBefore: NativeInt;
  Found: Boolean;
  Audiences: TJArray;
  I: Integer;
  AudiencesValue: string;
begin
  if not JIsString(Payload[JwtClaimNames.Subject]) then
    raise EOidcClientException.CreateFmt(_(SMissingClaim), [JwtClaimNames.Subject]);

  if not JIsString(Payload[JwtClaimNames.Issuer]) then
    raise EOidcClientException.CreateFmt(_(SMissingClaim), [JwtClaimNames.Issuer]);

  if RemoveTrailingSlash(Issuer) <> RemoveTrailingSlash(JToStr(Payload[JwtClaimNames.Issuer])) then
    raise EOidcClientException.CreateFmt(_(SInvalidClaim), [JwtClaimNames.Issuer, JToStr(Payload[JwtClaimNames.Issuer])]);

  if JIsString(Payload[JwtClaimNames.Audience]) then
  begin
    if Audience <> JToStr(Payload[JwtClaimNames.Audience]) then
      raise EOidcClientException.CreateFmt(_(SInvalidClaim), [JwtClaimNames.Audience, JToStr(Payload[JwtClaimNames.Audience])]);
  end
  else
  if JIsArray(Payload[JwtClaimNames.Audience]) then
  begin
    Audiences := AsJArray(Payload[JwtClaimNames.Audience]);
    AudiencesValue := '';
    Found := False;
    for I := 0 to Audiences.Length - 1 do
    begin
      if JIsString(Audiences[I]) and (JToStr(Audiences[I]) = Audience) then
      begin
        Found := True;
        Break;
      end;
      if AudiencesValue <> '' then
        AudiencesValue := AudiencesValue + ',';
      AudiencesValue := AudiencesValue + JToStr(Audiences[I]);
    end;
    if not Found then
      raise EOidcClientException.CreateFmt(_(SInvalidClaim), [JwtClaimNames.Audience, AudiencesValue]);
  end
  else
    raise EOidcClientException.CreateFmt(_(SMissingClaim), [JwtClaimNames.Audience]);

  if JIsString(Payload[JwtClaimNames.AuthorizedParty]) then
    if Audience <> JToStr(Payload[JwtClaimNames.AuthorizedParty]) then
      raise EOidcClientException.CreateFmt(_(SInvalidClaim), [JwtClaimNames.AuthorizedParty, JToStr(Payload[JwtClaimNames.AuthorizedParty])]);

  // Validate expiration and times
{$IFDEF PAS2JS}
  NowEpoch := TJSDate.Now div 1000;
{$ELSE}
  NowEpoch := DateTimeToUnix(Now, False);
{$ENDIF}
  LowerNow := NowEpoch + ClockSkew;
  UpperNow := NowEpoch - ClockSkew;

  if not JIsInteger(Payload[JwtClaimNames.IssuedAt]) then
    raise EOidcClientException.CreateFmt(_(SMissingClaim), [JwtClaimNames.IssuedAt]);
  IssuedAt := JToInt(Payload[JwtClaimNames.IssuedAt]);
  if LowerNow < IssuedAt then
    raise EOidcClientException.CreateFmt(_(SInvalidClaim), [JwtClaimNames.IssuedAt, IntToStr(IssuedAt)]);

  if not JIsInteger(Payload[JwtClaimNames.Expiration]) then
    raise EOidcClientException.CreateFmt(_(SMissingClaim), [JwtClaimNames.Expiration]);
  Expiration := JToInt(Payload[JwtClaimNames.Expiration]);
  if Expiration < upperNow then
    raise EOidcClientException.Create(_(SIdTokenExpired));

  if JIsInteger(Payload[JwtClaimNames.NotBefore]) then
  begin
    NotBefore := JToInt(Payload[JwtClaimNames.NotBefore]);
    if LowerNow < NotBefore then
      raise EOidcClientException.CreateFmt(_(SInvalidClaim), [JwtClaimNames.NotBefore, IntToStr(NotBefore)]);
  end;
end;

procedure TOidcClient.ValidateResponse(Entry: TAuthResultEntry; Response: TClientAuthResponse; AuthState: TAuthState);
begin
  if Response.State <> AuthState.State then
    raise EOidcClientException.CreateFmt(_(SInvalidState), [Response.State]);

  if Response.Error <> '' then
    raise EOidcClientError.Create(Response.Error, Response.ErrorDescription);

  if AuthState.ClientId = '' then
    raise EOidcClientException.Create(Format(_(SMissingParameter), [AuthorizeRequestParams.ClientId]));

  if AuthState.ClientId <> Self.ClientId then
    raise EOidcClientException.Create(Format(_(SInvalidParameter), [AuthorizeRequestParams.ClientId, AuthState.ClientId]));

  if Response.Code <> '' then // code flow
{$IFDEF PAS2JS}
    await(ProcessCodeResponse(Entry, Response, AuthState))
{$ELSE}
    ProcessCodeResponse(Entry, Response, AuthState)
{$ENDIF}
  else
  begin
    if (AuthState.CodeVerifier <> '') then
      raise EOidcClientException.Create(Format(_(SMissingParameter), [TokenRequestParams.CodeVerifier]));

    Entry.AccessToken := Response.AccessToken;
    Entry.IdToken := Response.IdToken;
    Entry.TokenType := Response.TokenType;
    if Response.Scope <> '' then
      Entry.Scope := Response.Scope
    else
      Entry.Scope := AuthState.Scope;
    if Response.ExpiresIn = 0 then
      Entry.ExpiresAt := MaxDateTime
    else
      Entry.ExpiresAt := IncSecond(Now, Response.ExpiresIn);
  end;
  Entry.ClientId := Self.ClientId;
  Entry.AppState := AuthState.AppState;

  if (AuthState.Nonce <> '') and (Entry.IdToken = '') then
    raise EOidcClientException.Create(Format(_(SMissingParameter), [AuthorizeResponseParams.IdentityToken]));

  if (AuthState.Nonce = '') and (Entry.IdToken <> '') then
    raise EOidcClientException.Create(Format(_(SMissingParameter), [AuthorizeRequestParams.Nonce]));

  { Validate tokens }
  if Entry.IdToken <> '' then
  begin
    ValidateIdToken(Entry, AuthState);
    if Entry.AccessToken <> '' then
      ValidateAccessToken(Response, AuthState);
  end;
end;

{ EOidcClientError }

constructor EOidcClientError.Create(const Error, ErrorDescription: string);
begin
  if ErrorDescription <> '' then
    inherited CreateFmt(_(SOAuthError), [Error, ErrorDescription])
  else
    inherited CreateFmt(_(SOAuthErrorWithoutDescription), [Error]);
end;

end.
