unit DonationDataEdit;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Data.DB,
  WEBLib.DB,
  WEBLib.DBCtrls,
  WebForm.Core;

type
  TDonationDataEditForm = class(TCoreWebForm)
    AmountDonation: TDBEdit;
    DonorPostCode: TDBEdit;
    SaveButton: TButton;
    CancelButton: TButton;
    CloseButton: TButton;
    ClaimStatus: TDBEdit;
    EditDataSource: TDataSource;
    DonationDateEdit: TDBDateTimePicker;
    ErrorMessageDonationDate: TLabel;
    procedure AmountDonationChange(Sender: TObject);
    procedure AmountDonationKeyPress(Sender: TObject; var Key: Char);
    procedure CancelButtonClick(Sender: TObject);
    procedure CloseButtonClick(Sender: TObject);
    procedure DonationDateEditChange(Sender: TObject);
    procedure EditDataSourceDataChange(Sender: TObject; Field: TField);
    procedure SaveButtonClick(Sender: TObject);
  private
    { Private declarations }
    FAmountValid: boolean;
    FEditDataApplyUpdates: boolean;
    FTransactionDateValid: boolean;
    procedure SetDataSet(Value: TDataSet);
    procedure ResetButtons(Value: boolean);
    procedure CheckValidations;
    procedure SetTransactionDateValid(AValid: boolean);
    procedure ValidateAmount;
    procedure ValidateDonationDate;
  public
    { Public declarations }
    procedure CheckEdit;
    property DataSet: TDataSet write SetDataSet;
    property EditDataApplyUpdates: boolean read fEditDataApplyUpdates;
    property TransactionDateValid: boolean read fTransactionDateValid write SetTransactionDateValid;
  protected procedure LoadDFMValues; override; end;

var
  DonationDataEditForm: TDonationDataEditForm;

implementation

{$R *.dfm}

uses
  DonationList, SMX.Web.Document.Utils, GA.Web.Utils;

procedure TDonationDataEditForm.AmountDonationChange(Sender: TObject);
begin
  ValidateAmount;
  CheckValidations;
end;

procedure TDonationDataEditForm.AmountDonationKeyPress(Sender: TObject; var
    Key: Char);
begin
  if (Pos('.', AmountDonation.Text) > 0) and (Key = '.') then
  begin
    Key := #0;
    exit;
  end;

  if (not (Key in ['0'..'9','.','-']) or (Length(TEdit(Sender).Text) > 7)) then
  begin
    TDocUtils.setControlValidity('AmountDonation', vsInvalid);
    Key := #0;
  end
  else
  begin
    TDocUtils.setControlValidity('AmountDonation', vsValid);
  end;
end;

procedure TDonationDataEditForm.CancelButtonClick(Sender: TObject);
begin
  inherited;
  EditDataSource.DataSet.Cancel;
  ResetButtons(False);
end;

procedure TDonationDataEditForm.CloseButtonClick(Sender: TObject);
begin
  inherited;
  ModalResult := mrOk;
end;

procedure TDonationDataEditForm.EditDataSourceDataChange(Sender: TObject; Field: TField);
begin
  //if Field <> nil then
  //begin
    //ResetButtons(EditDataSource.DataSet.Modified);
  //end;
end;

procedure TDonationDataEditForm.ResetButtons(Value: boolean);
begin
  SaveButton.Enabled := Value;
  CancelButton.Enabled := Value;
end;

procedure TDonationDataEditForm.SaveButtonClick(Sender: TObject);
begin
  inherited;
  EditDataSource.DataSet.FieldByName('ClaimStatus').AsString := 'Unclaimed';
  EditDataSource.DataSet.Post;
  fEditDataApplyUpdates := true;
  ResetButtons(False);
  ModalResult := mrOk;
end;

procedure TDonationDataEditForm.SetDataSet(Value: TDataSet);
begin
  EditDataSource.DataSet := Value;
  FAmountValid := false;
  FTransactionDateValid := false;
end;

procedure TDonationDataEditForm.CheckValidations;
var
  lValid: boolean;
begin
  lValid := True;

  if FAmountValid = False then
    lValid := False;

  if FTransactionDateValid = False then
    lValid := False;

  SaveButton.Enabled := lValid;
end;

procedure TDonationDataEditForm.DonationDateEditChange(Sender: TObject);
begin
  ValidateDonationDate;
  CheckValidations;
end;

procedure TDonationDataEditForm.ValidateAmount;
var
  AdjValue: currency;
begin
  if AmountDonation.Text <> '' then
  begin
    AdjValue := strtofloat(AmountDonation.Text);

    if AdjValue > 0 then
    begin
      TDocUtils.setControlValidity('AmountDonation', vsValid);
      FAmountValid := True;
    end
    else if AdjValue < 0 then
    begin
      TDocUtils.setControlValidity('AmountDonation', vsValid);
      FAmountValid := True;
    end;
  end
  else
  begin
    TDocUtils.setControlValidity('AmountDonation', vsInvalid);
    FAmountValid := False;
  end;
end;

procedure TDonationDataEditForm.ValidateDonationDate;
var
  EarliestDateStr: string;
  EarliestDate: TDateTime;
begin
  FTransactionDateValid := True;

  if DonationDateEdit.Date = 0 then
  begin
    ErrorMessageDonationDate.Caption := 'Donation Date cannot be blank';
    TDocUtils.setControlValidity('DonationDateEdit', vsInvalid);
    FTransactionDateValid := False;
  end
  else if DonationDateEdit.Date > Date then
  begin
    ErrorMessageDonationDate.Caption := 'Donation Date in the future';
    TDocUtils.setControlValidity('DonationDateEdit', vsInvalid);
    FTransactionDateValid := False;
  end
  else
  begin
    EarliestDate := TGAWebUtils.EarliestClaimableDate(Now);

    if EarliestDate > DonationDateEdit.Date then
    begin
      ErrorMessageDonationDate.Caption := 'Donation Date can not be before ' + FormatDateTime('DD/MM/YYYY', EarliestDate);
      TDocUtils.setControlValidity('DonationDateEdit', vsInvalid);
      FTransactionDateValid := False;
    end;
  end;

  if FTransactionDateValid then
    TDocUtils.setControlValidity('DonationDateEdit', vsValid);
end;

procedure TDonationDataEditForm.CheckEdit;
begin
  ValidateAmount;
  ValidateDonationDate;
  CheckValidations;
end;

procedure TDonationDataEditForm.SetTransactionDateValid(AValid: boolean);
begin
  fTransactionDateValid := AValid;
  TDocUtils.setControlValidity('DonationDateEdit', vsValid);
  CheckValidations;
end;

procedure TDonationDataEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ErrorMessageDonationDate := TLabel.Create('ErrorMessageDonationDate');
  AmountDonation := TDBEdit.Create('AmountDonation');
  DonorPostCode := TDBEdit.Create('DonorPostCode');
  SaveButton := TButton.Create('SaveButton');
  CancelButton := TButton.Create('CancelButton');
  CloseButton := TButton.Create('CloseButton');
  ClaimStatus := TDBEdit.Create('ClaimStatus');
  DonationDateEdit := TDBDateTimePicker.Create('DonationDateEdit');
  EditDataSource := TDataSource.Create(Self);

  ErrorMessageDonationDate.BeforeLoadDFMValues;
  AmountDonation.BeforeLoadDFMValues;
  DonorPostCode.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  ClaimStatus.BeforeLoadDFMValues;
  DonationDateEdit.BeforeLoadDFMValues;
  EditDataSource.BeforeLoadDFMValues;
  try
    ErrorMessageDonationDate.SetParentComponent(Self);
    ErrorMessageDonationDate.Name := 'ErrorMessageDonationDate';
    ErrorMessageDonationDate.Left := 344;
    ErrorMessageDonationDate.Top := 8;
    ErrorMessageDonationDate.Width := 144;
    ErrorMessageDonationDate.Height := 15;
    ErrorMessageDonationDate.Caption := 'ErrorMessageDonationDate';
    ErrorMessageDonationDate.HeightPercent := 100.000000000000000000;
    ErrorMessageDonationDate.WidthPercent := 100.000000000000000000;
    AmountDonation.SetParentComponent(Self);
    AmountDonation.Name := 'AmountDonation';
    AmountDonation.Left := 40;
    AmountDonation.Top := 8;
    AmountDonation.Width := 121;
    AmountDonation.Height := 22;
    AmountDonation.ChildOrder := 1;
    AmountDonation.ElementClassName := 'form-control';
    AmountDonation.ElementFont := efCSS;
    AmountDonation.ElementPosition := epIgnore;
    AmountDonation.HeightStyle := ssAuto;
    AmountDonation.HeightPercent := 100.000000000000000000;
    AmountDonation.Text := 'AmountDonation';
    AmountDonation.WidthStyle := ssAuto;
    AmountDonation.WidthPercent := 100.000000000000000000;
    SetEvent(AmountDonation, Self, 'OnChange', 'AmountDonationChange');
    SetEvent(AmountDonation, Self, 'OnExit', 'AmountDonationChange');
    SetEvent(AmountDonation, Self, 'OnKeyPress', 'AmountDonationKeyPress');
    AmountDonation.DataField := 'Amount';
    AmountDonation.DataSource := EditDataSource;
    DonorPostCode.SetParentComponent(Self);
    DonorPostCode.Name := 'DonorPostCode';
    DonorPostCode.Left := 40;
    DonorPostCode.Top := 64;
    DonorPostCode.Width := 121;
    DonorPostCode.Height := 22;
    DonorPostCode.ChildOrder := 3;
    DonorPostCode.ElementClassName := 'form-control';
    DonorPostCode.ElementFont := efCSS;
    DonorPostCode.ElementPosition := epIgnore;
    DonorPostCode.HeightStyle := ssAuto;
    DonorPostCode.HeightPercent := 100.000000000000000000;
    DonorPostCode.TabOrder := 2;
    DonorPostCode.Text := 'DonorPostCode';
    DonorPostCode.Visible := False;
    DonorPostCode.WidthStyle := ssAuto;
    DonorPostCode.WidthPercent := 100.000000000000000000;
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.Left := 40;
    SaveButton.Top := 168;
    SaveButton.Width := 96;
    SaveButton.Height := 25;
    SaveButton.Caption := 'Save';
    SaveButton.ChildOrder := 4;
    SaveButton.ElementClassName := 'btn btn-light';
    SaveButton.ElementFont := efCSS;
    SaveButton.ElementPosition := epIgnore;
    SaveButton.Enabled := False;
    SaveButton.HeightStyle := ssAuto;
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.WidthStyle := ssAuto;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CancelButton.SetParentComponent(Self);
    CancelButton.Name := 'CancelButton';
    CancelButton.Left := 160;
    CancelButton.Top := 168;
    CancelButton.Width := 96;
    CancelButton.Height := 25;
    CancelButton.Caption := 'Cancel';
    CancelButton.ChildOrder := 5;
    CancelButton.ElementClassName := 'btn btn-light';
    CancelButton.ElementFont := efCSS;
    CancelButton.ElementPosition := epIgnore;
    CancelButton.Enabled := False;
    CancelButton.HeightStyle := ssAuto;
    CancelButton.HeightPercent := 100.000000000000000000;
    CancelButton.WidthStyle := ssAuto;
    CancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(CancelButton, Self, 'OnClick', 'CancelButtonClick');
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.Left := 272;
    CloseButton.Top := 168;
    CloseButton.Width := 96;
    CloseButton.Height := 25;
    CloseButton.Caption := 'Close';
    CloseButton.ChildOrder := 6;
    CloseButton.ElementClassName := 'btn btn-light';
    CloseButton.ElementFont := efCSS;
    CloseButton.ElementPosition := epIgnore;
    CloseButton.HeightStyle := ssAuto;
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.WidthStyle := ssAuto;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    ClaimStatus.SetParentComponent(Self);
    ClaimStatus.Name := 'ClaimStatus';
    ClaimStatus.Left := 40;
    ClaimStatus.Top := 92;
    ClaimStatus.Width := 300;
    ClaimStatus.Height := 22;
    ClaimStatus.ChildOrder := 4;
    ClaimStatus.ElementClassName := 'form-control';
    ClaimStatus.ElementFont := efCSS;
    ClaimStatus.ElementPosition := epIgnore;
    ClaimStatus.HeightStyle := ssAuto;
    ClaimStatus.HeightPercent := 100.000000000000000000;
    ClaimStatus.TabOrder := 3;
    ClaimStatus.Text := 'ClaimStatus';
    ClaimStatus.Visible := False;
    ClaimStatus.WidthStyle := ssAuto;
    ClaimStatus.WidthPercent := 100.000000000000000000;
    DonationDateEdit.SetParentComponent(Self);
    DonationDateEdit.Name := 'DonationDateEdit';
    DonationDateEdit.Left := 40;
    DonationDateEdit.Top := 36;
    DonationDateEdit.Width := 121;
    DonationDateEdit.Height := 22;
    DonationDateEdit.ElementClassName := 'form-control';
    DonationDateEdit.HeightStyle := ssAuto;
    DonationDateEdit.WidthStyle := ssAuto;
    DonationDateEdit.BorderStyle := bsSingle;
    DonationDateEdit.ChildOrder := 8;
    DonationDateEdit.Color := clWhite;
    DonationDateEdit.Date := 45191.330952881940000000;
    DonationDateEdit.ElementFont := efCSS;
    DonationDateEdit.ElementPosition := epIgnore;
    DonationDateEdit.Role := '';
    DonationDateEdit.Text := 'DonationDateEdit';
    SetEvent(DonationDateEdit, Self, 'OnChange', 'DonationDateEditChange');
    DonationDateEdit.DataField := 'TransactionDate';
    DonationDateEdit.DataSource := EditDataSource;
    EditDataSource.SetParentComponent(Self);
    EditDataSource.Name := 'EditDataSource';
    SetEvent(EditDataSource, Self, 'OnDataChange', 'EditDataSourceDataChange');
    EditDataSource.Left := 216;
    EditDataSource.Top := 24;
  finally
    ErrorMessageDonationDate.AfterLoadDFMValues;
    AmountDonation.AfterLoadDFMValues;
    DonorPostCode.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    ClaimStatus.AfterLoadDFMValues;
    DonationDateEdit.AfterLoadDFMValues;
    EditDataSource.AfterLoadDFMValues;
  end;
end;

end.
