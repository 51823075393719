unit WEBLib.Ctrls.SVG;

interface

const
  ICON_SEARCH = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAPJJREFUOE+l0i0sR1EUAPDfv0ka0UemEhUzhU0yjea'+
    'j0YxJiCrDRqFoPpqPbjRTbHRVQLO73bfd//Mue3s3nt3zO+eee1oanlbDfFXAJFYwFPF77OCyqlgZ2MYqXnEdE8bQjy2sl5EUCJXPcYQlfMXLHdjFHMKdtk5S4A49GEiSi4IBecYbRtMu'+
    'UuADJ1jIDHYfM+j8CzjFfAY4wBS6csAtev95wgvGc8AELnCMxdIQ9zCLaZzlgBDfxFocVvqNfTHpKQ7xvUCqFil0soxhfOMRh9jAINqQOqvcjZuIPBSbWgcIXQfkCp8YCYG6wK8fbgz8A'+
    'H9+LhG4lkRRAAAAAElFTkSuQmCC';

  ICON_SEARCH_SVG = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMzIgMzIiIGlkPSJHbHlwaCIgdmV'+
    'yc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMzIgMzIiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6'+
    'Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0yNy40MTQsMjQuNTg2bC01LjA3Ny01LjA3N0MyMy4zODYsMTcuOTI4LDI0LDE2LjAzNSwyNCwxNGMwLTUuNTE0LTQuNDg2L'+
    'TEwLTEwLTEwUzQsOC40ODYsNCwxNCAgczQuNDg2LDEwLDEwLDEwYzIuMDM1LDAsMy45MjgtMC42MTQsNS41MDktMS42NjNsNS4wNzcsNS4wNzdjMC43OCwwLjc4MSwyLjA0OCwwLjc4MS'+
    'wyLjgyOCwwICBDMjguMTk1LDI2LjYzMywyOC4xOTUsMjUuMzY3LDI3LjQxNCwyNC41ODZ6IE03LDE0YzAtMy44NiwzLjE0LTcsNy03czcsMy4xNCw3LDdzLTMuMTQsNy03LDdTNywxNy4'+
    '4Niw3LDE0eiIgaWQ9IlhNTElEXzIyM18iLz48L3N2Zz4=';

  ICON_FILTER = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAPhJREFUOE/N078rxWEUx/HXpYz+ABN2A4q6ZTBZkCy'+
    '3rMomm7qDukWxir9BpKRYLW7Rne5kpvwHRj+io+dbt2/fr68fi7M9z/mcd+d8zvPU/DFqGMYu+n/IekMzAKPoYgAX34Qs4BnjAYiYwyW2sFcBaWIbS1GTAaJmHftYwXEJZBmn2MBhaHoB'+
    'cT7AKmbRyUEmcI0jrGW5PCCMPMckpvGYhEMJeId5vJYB4n4QN8mkmSRsJ5PreOrtLN9BlovV3iNmjjjDCB7y3pQBQveORio4KfDrM/W/AS+4wi1avxlhETsYSz4UjvuVB1HXlzYxhc2i1'+
    '1kFqPxbH57RKFOftCADAAAAAElFTkSuQmCC';

  ICON_FILTER_SVG = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0'+
    'IDI0OyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpb'+
    'ms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6IzAwMDAwMDtzdHJva2Utd2lkdGg6MS42Nz'+
    'I0O3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxMDt9Cgkuc3Qxe2ZpbGw6bm9uZTtzdHJva2U6IzAwMDAwMDtzdHJva2U'+
    'td2lkdGg6MS41O3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxMDt9Cgkuc3Qye2ZpbGw6bm9uZTtzdHJva2U6IzAwMDAw'+
    'MDtzdHJva2Utd2lkdGg6MS41O3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxMDt9Cjwvc3R5bGU+PGc+PGc+PGc+PHBhdGggZD0iTTIyLjIsMS41bC04LjUsM'+
    'TEuMmMtMC4yLDAuMy0wLjMsMC42LTAuMywwLjl2Ny44bC0yLjcsMC44di04LjZjMC0wLjMtMC4xLTAuNi0wLjMtMC45TDEuOCwxLjVIMjIuMiBNMjIuOCwwSDEuMiAgICAgYy0xLDAtMS'+
    '41LDEuMS0wLjksMS45bDguOSwxMS44djkuNmMwLDAuNCwwLjMsMC43LDAuNywwLjdjMC4xLDAsMC4xLDAsMC4yLDBsNC4zLTEuM2MwLjMtMC4xLDAuNS0wLjQsMC41LTAuN3YtOC4zbDg'+
    'uOS0xMS44ICAgICBDMjQuMywxLjEsMjMuOCwwLDIyLjgsMEwyMi44LDB6Ii8+PC9nPjwvZz48L2c+PC9zdmc+';

  ICON_EYE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAQJJREFUOE+1080qxVEUBfDfnZABGWNCyUDJSDyCJ2BiJu'+
    'V7aoKYMJCirq8p5QV4CMxkyIjyBsiEto7a3e4tt5sz+f/P3uuss9Y651S0OCotrvcvBN1YwBQG8YUnXOEYz1l1rYJZ7KOzgbU3rBfMDyQTbGCrLPwswIsyn8E22sr8EKuZYBqXadc1POC'+
    '0WJjDCHYTZhFHoWAIt+hKzZ5S6yu18D2G14T5wHgQDOMOHanZixtkggm81COI2goOaizc4yxZGMVOwiyh+htifE8QXmNEiBHqeYMQq1gO8nwK8R/hbaK9wTG+l/5eUVb3JvZjHpMYKESP'+
    'uP7LRWr6afzLW2hKxTf9UTFvsYQFoAAAAABJRU5ErkJggg==';


  ICON_EYE_SVG = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cD'+
    'ovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI0IDI0IiBoZWlnaHQ9IjI0cHgiIGlkPSJMYXl'+
    'lcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0cHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2'+
    'ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnPjxnPjxwYXRoIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEyLDRDNC4wNjMsNC0wLjAxMiwxMi0wL'+
    'jAxMiwxMlMzLjA2MywyMCwxMiwyMCAgICBjOC4wOTMsMCwxMi4wMTEtNy45NjksMTIuMDExLTcuOTY5UzIwLjA2Miw0LDEyLDR6IE0xMi4wMTgsMTdjLTIuOTAyLDAtNS0yLjE4OC01LT'+
    'VjMC0yLjgxMywyLjA5OC01LDUtNWMyLjkwMiwwLDUsMi4xODcsNSw1ICAgIEMxNy4wMTgsMTQuODEyLDE0LjkyLDE3LDEyLjAxOCwxN3ogTTEyLjAxOCw5Yy0xLjY1OCwwLjAwMy0zLDE'+
    'uMzkzLTMsM2MwLDEuNjA2LDEuMzQyLDMsMywzYzEuNjU4LDAsMy0xLjM5NSwzLTMgICAgQzE1LjAxOCwxMC4zOTIsMTMuNjc2LDguOTk3LDEyLjAxOCw5eiIgZmlsbC1ydWxlPSJldmVu'+
    'b2RkIi8+PC9nPjwvZz48L3N2Zz4=';

implementation

end.
