unit OtherClaimsAdjustmentsEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, OtherClaimsEdit, Data.DB, WEBLib.DB,
  Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.DBCtrls, Vcl.Controls, WEBLib.ExtCtrls,
  WEBLib.WebCtrls;

type
  TOtherClaimsAdjustmentsEditForm = class(TOtherClaimsEditForm)
    AdjustmentTypeCombo: TDBLookupComboBox;
    procedure AdjustmentTypeComboChange(Sender: TObject);
    procedure AmountEditChange(Sender: TObject);
    procedure EditDataSourceDataChange(Sender: TObject; Field: TField);
    procedure TransactionDateEditChange(Sender: TObject);
    procedure TransactionDateEditExit(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure NoteMemoChange(Sender: TObject);
  private
    { Private declarations }
    FAmountOk: boolean;
    FTransactionDateValid: boolean;
    FNotesPresent: boolean;
  protected
    [async]
    procedure GetRules; async; override;
    procedure CheckValidations;override;
  public
    { Public declarations }
    procedure SetValidationForEdit;
  protected procedure LoadDFMValues; override; end;

var
  OtherClaimsAdjustmentsEditForm: TOtherClaimsAdjustmentsEditForm;

implementation

uses
  SMX.Web.Document.Utils, GA.Web.Utils, MainDataModule;

{$R *.dfm}

procedure TOtherClaimsAdjustmentsEditForm.AdjustmentTypeComboChange(Sender:
    TObject);
begin
  CheckValidations;
end;

procedure TOtherClaimsAdjustmentsEditForm.AmountEditChange(Sender: TObject);
var
  AdjValue: currency;
begin
  if AmountEdit.Text <> '' then
  begin
    AdjValue := strtofloat(AmountEdit.Text);

    if AdjValue > 0 then
    begin
      AdjValue := AdjValue * -1;
      AmountEdit.Text := FloatToStr(AdjValue);
      TDocUtils.setControlValidity('AmountEdit', vsValid);
      FAmountOk := True;
    end
    else if AdjValue < 0 then
    begin
      TDocUtils.setControlValidity('AmountEdit', vsValid);
      FAmountOk := True;
    end;
  end
  else
  begin
    TDocUtils.setControlValidity('AmountEdit', vsInvalid);
    FAmountOk := False;
  end;
end;

procedure TOtherClaimsAdjustmentsEditForm.EditDataSourceDataChange(Sender:
    TObject; Field: TField);
begin
  inherited;
  //
end;

procedure TOtherClaimsAdjustmentsEditForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  AdjustmentTypeCombo.LookupValues.AddPair('Refund','Refund');
  AdjustmentTypeCombo.LookupValues.AddPair('NotGiftAidable','Not Gift-Aidable');
  AdjustmentTypeCombo.LookupValues.AddPair('Other','Other');
end;

procedure TOtherClaimsAdjustmentsEditForm.GetRules;
begin
  OtherClaimsType := 'Adjustment';
  inherited;
end;

procedure TOtherClaimsAdjustmentsEditForm.NoteMemoChange(Sender: TObject);
begin
  if NoteMemo.Text <> '' then
  begin
    FNotesPresent := True;
    TDocUtils.setControlValidity('NotesEdit', vsValid);
  end
  else
  begin
    FNotesPresent := False;
    TDocUtils.setControlValidity('NotesEdit', vsInvalid);
  end;
  CheckValidations;
end;

procedure TOtherClaimsAdjustmentsEditForm.SetValidationForEdit;
begin
  if EditDataSource.DataSet.State = dsEdit then
  begin
    FAmountOk := True;
    FTransactionDateValid := True;
    FNotesPresent := True;
    CheckValidations;
    ResetButtons(True);
  end;
end;

procedure TOtherClaimsAdjustmentsEditForm.CheckValidations;
var
  lValid: boolean;
begin
  lValid := True;

  if (FAmountOk = False) or (FTransactionDateValid = False) or (FNotesPresent = False) then
    lValid := False;

//  if FTransactionDateValid = False then
//    lValid := False;

  if (AdjustmentTypeCombo.ItemIndex > -1) then
    TDocUtils.setControlValidity('AdjustmentTypeCombo', vsValid)
  else
  begin
    TDocUtils.setControlValidity('AdjustmentTypeCombo', vsInvalid);
    lValid := false;
  end;

//  if FNotesPresent = False then

  SaveButton.Enabled := lValid;
end;

procedure TOtherClaimsAdjustmentsEditForm.TransactionDateEditChange(Sender:
    TObject);
var
  EarliestDateStr: string;
  EarliestDate: TDateTime;
begin
  FTransactionDateValid := True;

  if TransactionDateEdit.Date = 0 then
  begin
    ErrorMessageTransactionDate.Caption := 'Transaction Date cannot be blank';
    TDocUtils.setControlValidity('TransactionDateEdit', vsInvalid);
    FTransactionDateValid := False;
  end
  else if TransactionDateEdit.Date > Date then
  begin
    ErrorMessageTransactionDate.Caption := 'Transaction Date in the future';
    TDocUtils.setControlValidity('TransactionDateEdit', vsInvalid);
    FTransactionDateValid := False;
  end
  else
  begin
    EarliestDate := TGAWebUtils.EarliestClaimableDate(Now);

    if EarliestDate > TransactionDateEdit.Date then
    begin
      ErrorMessageTransactionDate.Caption := 'Transaction Date can not be before ' + FormatDateTime('DD/MM/YYYY', EarliestDate);
      TDocUtils.setControlValidity('TransactionDateEdit', vsInvalid);
      FTransactionDateValid := False;
    end;
  end;

  if FTransactionDateValid then
    TDocUtils.setControlValidity('TransactionDateEdit', vsValid);
  CheckValidations;
end;

procedure TOtherClaimsAdjustmentsEditForm.TransactionDateEditExit(Sender:
    TObject);
var
  AdjValue: double;
begin
(*  if AmountEdit.Text <> '' then
  begin
    AdjValue := strtofloat(AmountEdit.Text);

    if AdjValue > 0 then
    begin
      TDocUtils.setControlValidity('AmountEdit', vsInvalid);
      FAmountOk := False;
    end;
  end;*)
end;


procedure TOtherClaimsAdjustmentsEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  AdjustmentTypeCombo := TDBLookupComboBox.Create('AdjustmentTypeCombo');

  ErrorMessageTransactionDate.BeforeLoadDFMValues;
  AmountEdit.BeforeLoadDFMValues;
  TransactionDateEdit.BeforeLoadDFMValues;
  NoteMemo.BeforeLoadDFMValues;
  AdjustmentTypeCombo.BeforeLoadDFMValues;
  MessageDialog.BeforeLoadDFMValues;
  try
    ErrorMessageTransactionDate.Visible := False;
    AmountEdit.Text := '';
    AmountEdit.DataField := 'GiftAid';
    SetEvent(TransactionDateEdit, Self, 'OnExit', 'TransactionDateEditExit');
    SetEvent(NoteMemo, Self, 'OnChange', 'NoteMemoChange');
    AdjustmentTypeCombo.SetParentComponent(Self);
    AdjustmentTypeCombo.Name := 'AdjustmentTypeCombo';
    AdjustmentTypeCombo.Left := 16;
    AdjustmentTypeCombo.Top := 267;
    AdjustmentTypeCombo.Width := 185;
    AdjustmentTypeCombo.Height := 22;
    AdjustmentTypeCombo.ElementClassName := 'form-select';
    AdjustmentTypeCombo.ElementPosition := epIgnore;
    AdjustmentTypeCombo.HeightStyle := ssAuto;
    AdjustmentTypeCombo.HeightPercent := 100.000000000000000000;
    AdjustmentTypeCombo.WidthStyle := ssAuto;
    AdjustmentTypeCombo.WidthPercent := 100.000000000000000000;
    SetEvent(AdjustmentTypeCombo, Self, 'OnChange', 'AdjustmentTypeComboChange');
    AdjustmentTypeCombo.DataField := 'AdjustmentType';
    AdjustmentTypeCombo.DataSource := EditDataSource;
    AdjustmentTypeCombo.ListSource := MainData.DummySource;
  finally
    ErrorMessageTransactionDate.AfterLoadDFMValues;
    AmountEdit.AfterLoadDFMValues;
    TransactionDateEdit.AfterLoadDFMValues;
    NoteMemo.AfterLoadDFMValues;
    AdjustmentTypeCombo.AfterLoadDFMValues;
    MessageDialog.AfterLoadDFMValues;
  end;
end;

end.