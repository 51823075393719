unit DashboardForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WEBLib.DB,
  WEBLib.ExtCtrls,
  WEBLib.WebCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  Vcl.Grids,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Data.DB;

type
  TDashboard = class(TForm)
    WhatsNew: THTMLDiv;
    JobsSource: TDataSource;
    JobsList: TDBResponsiveGrid;
    ThingsToDo: TResponsiveGrid;
    GiftAidToClaim: TLabel;
    TotalGiftAidClaimed: TLabel;
    TotalGiftAidClaimedLabel: TLabel;
    Graph1: THTMLSpan;
    Graph2: THTMLSpan;
    procedure GiftAidToClaimClick(Sender: TObject);
    procedure JobsListItemClick(Sender: TObject; Index: Integer);
    procedure JobsListItemCreated(Sender: TObject; Index: Integer);
    procedure JobsListItemGetFieldValue(Sender: TObject; Index: Integer; AFieldName: string; var AValue: string);
    procedure ThingsToDoItemClick(Sender: TObject; Index: Integer);
    procedure WebFormCreate(Sender: TObject);
  private

    [async]
    procedure LoadDashboard; async;
    [async]
    procedure LoadThingsToDo; async;
    procedure SetJobsDataset(const Value: TDataset);
    [async]
    procedure ShowJobDetail; async;
    [async]
    procedure GetDashboardLabel(const AKey: string; ALabel: TLabel); async;
    [async]
    procedure GetDashboardCurrencyLabel(const AKey: string; ALabel: TLabel; const ZeroValueMessage: string;
      const CanClickHint: string); async;
    [async]
    procedure GetDashboardHTML(const AKey, AElementId: string); async;
    [async]
    procedure GetDonationsChart; async;
    [async]
    procedure GetClaimsBreakdown; async;
    [async]
    procedure GetTotalGiftAidClaimed; async;

    procedure LoadHTML(const AElementId, AURL: string);
    { Private declarations }
  public
    { Public declarations }
    procedure RefreshJobsList;
    property JobsDataset: TDataset write SetJobsDataset;
  protected procedure LoadDFMValues; override; end;

implementation

uses
  XData.Web.Client,
  MainForm,
  MainDataModule,
  SMX.Web.Service.Consts,
  SharedDataModule,
  Job.DetailForm.Manager,
  JobDetailBaseForm,
  SMX.Web.Utils,
  SMX.Web.Document.Utils,
  Simple.Dashboard.Return.Types,
  SMX.Web.Table.Simple,
  DateUtils;

resourcestring
  SNothingToClaimAtTheMoment = 'Nothing to claim at the moment';

var
  lClickTime: TTime;

{$R *.dfm}

procedure TDashboard.GetClaimsBreakdown;
var
  AResponse: TDashboardResponse;
  AValue: JS.TJSObject;
  Values: JS.TJSArray;
  I: Integer;
  lTable: TsmwSimpleTable;
begin
  AResponse := Await(SharedData.Dashboard.GetDashboardItem(CLAIMS_BREAKDOWN));

  if AResponse.ItemType = 'NoResult' then
  begin
    Graph2.HTML.Text := 'No donations to show for ' + AResponse.Description;
    Exit;
  end;

  Values := JS.toArray(TJSJSON.parse(AResponse.Value));
  lTable := TsmwSimpleTable.Create(Values.Length, 2, 'dashboard', '');
  try
    lTable.Title := 'Claims by Type - ' + AResponse.Description;
    lTable.Titles.Add('Claim Type');
    lTable.Titles.Add('Amount');
    for I := 0 to Values.Length - 1 do
    begin
      AValue := JS.toObject(Values[I]);
      lTable.Cell[I, 0] := String(AValue['Label']);
      lTable.Cell[I, 1] := '£' + Double(AValue['Value']).ToString;
    end;
    Graph2.HTML.Text := lTable.Table;
  finally
    lTable.Free;
  end;

  // AddPiePoint(AValue: Double; ALabel: string = ‘’; Offset: Double = 0; Color: TColor = clNone);
end;

procedure TDashboard.GetDashboardCurrencyLabel(const AKey: string; ALabel: TLabel; const ZeroValueMessage: string;
  const CanClickHint: string);
var
  AResponse: TDashboardResponse;
  lValue: string;
begin
  AResponse := Await(SharedData.Dashboard.GetDashboardItem(AKey));
  lValue := AResponse.Value;
  if (ZeroValueMessage <> '') and ((lValue = '£0.00') or (AResponse.ItemType = 'NoResult')) then
  begin
    lValue := ZeroValueMessage;
    //ALabel.Hint := '';
    if Assigned(ALabel.OnClick) then
      ALabel.Cursor := crDefault;
  end
  else if Assigned(ALabel.OnClick) then
  begin
    ALabel.Cursor := crHandPoint;
    //ALabel.Hint := CanClickHint;
  end;

  ALabel.Caption := '£' + lValue;

end;

procedure TDashboard.GetDashboardHTML(const AKey, AElementId: string);
begin

end;

procedure TDashboard.GetDashboardLabel(const AKey: string; ALabel: TLabel);
var
  AResponse: TDashboardResponse;
begin
  AResponse := Await(SharedData.Dashboard.GetDashboardItem(AKey));
  ALabel.Caption := AResponse.Value;
end;

procedure TDashboard.GetDonationsChart;
var
  AResponse: TDashboardResponse;
  AValue: JS.TJSObject;
  Values: JS.TJSArray;
  I: Integer;
  lTable: TsmwSimpleTable;
begin
  AResponse := Await(SharedData.Dashboard.GetDashboardItem(DONATIONS_BY_MONTH));

  if AResponse.ItemType = 'NoResult' then
  begin
    Graph1.HTML.Text := 'No donations to show for ' + AResponse.Description;
    Exit;
  end;

  Values := JS.toArray(TJSJSON.parse(AResponse.Value));
  asm debugger; end;  lTable := TsmwSimpleTable.Create(Values.Length, 3, 'dashboard', '');
  try
    lTable.Title := 'Donations - ' + AResponse.Description;
    lTable.Titles.Add('Year');
    lTable.Titles.Add('Month');
    lTable.Titles.Add('Amount');
  asm debugger; end;    for I := 0 to Values.Length - 1 do
    begin
      AValue := JS.toObject(Values[I]);
      lTable.Cell[I, 0] := Integer(AValue['YEAR_VAL']).ToString;
      lTable.Cell[I, 1] := String(AValue['MONTH_NAME']);
      lTable.Cell[I, 2] := '£' + Double(AValue['AMOUNT']).ToString;
    end;
    Graph1.HTML.Text := lTable.Table;
  finally
    lTable.Free;
  end;

end;

procedure TDashboard.GetTotalGiftAidClaimed;
var
  AResponse: TDashboardResponse;
  lValue: string;
begin
  AResponse := Await(SharedData.Dashboard.GetDashboardItem(TOTAL_GIFTAID_CLAIMED));
  lValue := AResponse.Value;

  if (lValue = '0.00') or (AResponse.ItemType = 'NoResult') then
  begin
    TotalGiftAidClaimedLabel.Caption := '';
    TotalGiftAidClaimed.Caption := 'Nothing claimed yet';
  end
  else
  begin
    TotalGiftAidClaimedLabel.Caption := AResponse.Description;
    TotalGiftAidClaimedLabel.Hint := AResponse.Hint;
    TotalGiftAidClaimedLabel.ShowHint := False;//(TotalGiftAidClaimedLabel.Hint <> '');
    TotalGiftAidClaimed.Caption := '£' + AResponse.Value;
  end;

end;

procedure TDashboard.GiftAidToClaimClick(Sender: TObject);
begin
  if GiftAidToClaim.Caption = SNothingToClaimAtTheMoment then
    Exit;
  MainPage.MakeAClaimLinkClick(self);
end;

procedure TDashboard.JobsListItemClick(Sender: TObject; Index: Integer);
begin
  { TODO : Need to check if we need this }
  // MainPage.LockJobsData;
  if IncSecond(lClickTime, 1) < Time then
  begin
    try
      JobsList.DataSource.DataSet.First;
      JobsList.DataSource.DataSet.MoveBy(index);
    finally
      ShowJobDetail;
    end;
  end;
  lClickTime := Time;
end;

procedure TDashboard.JobsListItemCreated(Sender: TObject; Index: Integer);
begin
//  if JobsList.DataSource.Dataset.FieldByName(). then

end;

procedure TDashboard.JobsListItemGetFieldValue(Sender: TObject; Index: Integer; AFieldName: string; var AValue: string);
var
  lProgress: Double;
begin
  if AFieldName = 'RunLevel' then
  begin
    if AValue = 'Preview' then
      AValue := '(Preview)'
    else
      AValue := '';
  end

  else if AFieldName = 'Progress' then
  begin
    // lProgress := StrToFloatDef(AValue, 0);
    // if lProgress > 0 then
    // AValue := '(' + FormatFloat('##0.0', lProgress) + '% Complete)'
    // else
    AValue := '';
  end

  else if AFieldName = 'HasErrors' then
  begin
    if AValue = 'True' then
      AValue := '[with errors]'
    else
      AValue := '';
  end

  else if AFieldName = 'JobCategory' then
  begin
    if AValue = 'Poll' then
      { TODO : Maybe include last poll time? }
      AValue := '[Poll]'
    else
      AValue := '';
  end;

end;

procedure TDashboard.LoadDashboard;
begin
  GetDashboardCurrencyLabel(OUTSTANDING_CLAIMS, GiftAidToClaim, SNothingToClaimAtTheMoment, 'Click to submit a claim');
  GetDonationsChart;
  GetTotalGiftAidClaimed;
  GetClaimsBreakdown;
end;

procedure TDashboard.LoadHTML(const AElementId, AURL: string);
begin
  TDocUtils.LoadHTML(AElementId, AURL);
end;

procedure TDashboard.LoadThingsToDo;
var
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;
  lToDoList: JS.TJSArray;
begin
  ThingsToDo.BeginUpdate;
  try
    ThingsToDo.Items.Clear;
    lRetval := Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(SVC_GET_THINGS_TODO, []));
    lResult := TJSObject(lRetval.Result);
    lToDoList := JS.toArray(lResult['value']);
    if (lToDoList <> nil) and (lToDoList.Length > 0) then
    begin
      ThingsToDo.LoadFromJSON(lResult, 'value');
      ThingsToDo.Visible := True;
    end
    else
      ThingsToDo.Visible := False;

  finally
    ThingsToDo.EndUpdate;
  end;
end;

procedure TDashboard.RefreshJobsList;
begin
  JobsList.Refresh;
end;

procedure TDashboard.SetJobsDataset(const Value: TDataset);
begin
  JobsSource.DataSet := Value;
end;

procedure TDashboard.ShowJobDetail;
var
  AForm: TJobDetailBase;
  lRetval: TModalResult;
begin
  AForm := FindJobDetailForm(self, JobsSource.DataSet.FieldByName('JobType').AsString); // TJobDetail.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    Await(TJobDetailBase, AForm.Load());
    AForm.DataSet := JobsSource.DataSet;
    lRetval := Await(TModalResult, AForm.Execute);
  finally
    AForm.Free;
    AForm := nil;
    SharedData.UnlockJobsData(lRetval = mrOK);
  end;
end;

procedure TDashboard.ThingsToDoItemClick(Sender: TObject; Index: Integer);
var
  lItem: TResponsiveGridItem;
  lLinkType, x: string;
begin
  lItem := ThingsToDo.Items[index];
  lLinkType := lItem.JSONElementValue['LinkType'];
  if lLinkType = 'Dummy' then
  begin
    ShowMessage('Nothing to do here');
  end;
  // else if lLinkType = 'Refund' then
  // begin
  // ShowRefund(lItem.JSONElementValue['LinkedId']);
  // end;
end;

procedure TDashboard.WebFormCreate(Sender: TObject);
begin

  // LoadHTML('WhatsNew', 'Support/WhatsNew.html');

  if not MainData.AppUser.CanSubmit then
  begin
    GiftAidToClaim.OnClick := nil;
  end;

  LoadDashboard;

  JobsList.Options.ItemTemplate :=
    '<div class="card mb-2 (%Status%)"><div class="card-body"><h4 class="card-title">(%Title%) (%RunLevel%) (%JobCategory%)</h4>'
    + '<h6 class="text-muted card-subtitle mb-2">Job No: (%Id%)</h6><p class="card-text">Submitted: (%DateSubmitted%)</p>'
    + '<p class="card-text">Status: (%Status%) (%DateCompleted%) (%HasErrors%)</p></div></div>';

  ThingsToDo.Options.ItemTemplate := '<div class="card mb-2 (%ItemStatus%)"><div class="card-body">' +
    '<h4 class="card-title">(%Title%)</h4>' + '<h6 class="text-muted card-subtitle mb-2">(%Caption%)</h6>' +
    '<p class="card-text">(%ItemStatus%)</p>' + '</div></div>';

  LoadThingsToDo;

end;

procedure TDashboard.LoadDFMValues;
begin
  inherited LoadDFMValues;

  GiftAidToClaim := TLabel.Create('GiftAidToClaim');
  TotalGiftAidClaimed := TLabel.Create('TotalGiftAidClaimed');
  TotalGiftAidClaimedLabel := TLabel.Create('TotalGiftAidClaimedLabel');
  WhatsNew := THTMLDiv.Create('WhatsNew');
  JobsList := TDBResponsiveGrid.Create('JobsList');
  ThingsToDo := TResponsiveGrid.Create('ThingsToDo');
  Graph1 := THTMLSpan.Create('Graph1');
  Graph2 := THTMLSpan.Create('Graph2');
  JobsSource := TDataSource.Create(Self);

  GiftAidToClaim.BeforeLoadDFMValues;
  TotalGiftAidClaimed.BeforeLoadDFMValues;
  TotalGiftAidClaimedLabel.BeforeLoadDFMValues;
  WhatsNew.BeforeLoadDFMValues;
  JobsList.BeforeLoadDFMValues;
  ThingsToDo.BeforeLoadDFMValues;
  Graph1.BeforeLoadDFMValues;
  Graph2.BeforeLoadDFMValues;
  JobsSource.BeforeLoadDFMValues;
  try
    Name := 'Dashboard';
    Width := 1093;
    Height := 721;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    GiftAidToClaim.SetParentComponent(Self);
    GiftAidToClaim.Name := 'GiftAidToClaim';
    GiftAidToClaim.Left := 32;
    GiftAidToClaim.Top := 168;
    GiftAidToClaim.Width := 27;
    GiftAidToClaim.Height := 15;
    GiftAidToClaim.Caption := #163'0.00';
    GiftAidToClaim.ElementFont := efCSS;
    GiftAidToClaim.HeightStyle := ssAuto;
    GiftAidToClaim.HeightPercent := 100.000000000000000000;
    GiftAidToClaim.HTMLType := tSPAN;
    GiftAidToClaim.ShowHint := True;
    GiftAidToClaim.WidthStyle := ssAuto;
    GiftAidToClaim.WidthPercent := 100.000000000000000000;
    SetEvent(GiftAidToClaim, Self, 'OnClick', 'GiftAidToClaimClick');
    TotalGiftAidClaimed.SetParentComponent(Self);
    TotalGiftAidClaimed.Name := 'TotalGiftAidClaimed';
    TotalGiftAidClaimed.Left := 120;
    TotalGiftAidClaimed.Top := 168;
    TotalGiftAidClaimed.Width := 27;
    TotalGiftAidClaimed.Height := 15;
    TotalGiftAidClaimed.Caption := #163'0.00';
    TotalGiftAidClaimed.ElementFont := efCSS;
    TotalGiftAidClaimed.HeightStyle := ssAuto;
    TotalGiftAidClaimed.HeightPercent := 100.000000000000000000;
    TotalGiftAidClaimed.HTMLType := tSPAN;
    TotalGiftAidClaimed.WidthStyle := ssAuto;
    TotalGiftAidClaimed.WidthPercent := 100.000000000000000000;
    SetEvent(TotalGiftAidClaimed, Self, 'OnClick', 'GiftAidToClaimClick');
    TotalGiftAidClaimedLabel.SetParentComponent(Self);
    TotalGiftAidClaimedLabel.Name := 'TotalGiftAidClaimedLabel';
    TotalGiftAidClaimedLabel.Left := 120;
    TotalGiftAidClaimedLabel.Top := 147;
    TotalGiftAidClaimedLabel.Width := 134;
    TotalGiftAidClaimedLabel.Height := 15;
    TotalGiftAidClaimedLabel.Caption := 'TotalGiftAidClaimedLabel';
    TotalGiftAidClaimedLabel.ElementFont := efCSS;
    TotalGiftAidClaimedLabel.HeightStyle := ssAuto;
    TotalGiftAidClaimedLabel.HeightPercent := 100.000000000000000000;
    TotalGiftAidClaimedLabel.HTMLType := tSPAN;
    TotalGiftAidClaimedLabel.WidthPercent := 100.000000000000000000;
    WhatsNew.SetParentComponent(Self);
    WhatsNew.Name := 'WhatsNew';
    WhatsNew.Left := 16;
    WhatsNew.Top := 32;
    WhatsNew.Width := 177;
    WhatsNew.Height := 105;
    WhatsNew.HeightStyle := ssAuto;
    WhatsNew.WidthStyle := ssAuto;
    WhatsNew.ChildOrder := 1;
    WhatsNew.ElementPosition := epIgnore;
    WhatsNew.ElementFont := efCSS;
    WhatsNew.Role := '';
    JobsList.SetParentComponent(Self);
    JobsList.Name := 'JobsList';
    JobsList.Left := 840;
    JobsList.Top := 24;
    JobsList.Width := 200;
    JobsList.Height := 393;
    JobsList.HeightStyle := ssAuto;
    JobsList.WidthStyle := ssAuto;
    JobsList.ChildOrder := 1;
    JobsList.ElementFont := efCSS;
    JobsList.ElementPosition := epIgnore;
    JobsList.ItemIndex := 0;
    JobsList.Options.ItemClassName := 'JobItem';
    JobsList.Options.ItemWidthMin := 200;
    SetEvent(JobsList, Self, 'OnItemCreated', 'JobsListItemCreated');
    SetEvent(JobsList, Self, 'OnItemGetFieldValue', 'JobsListItemGetFieldValue');
    SetEvent(JobsList, Self, 'OnItemClick', 'JobsListItemClick');
    JobsList.DataSource := JobsSource;
    ThingsToDo.SetParentComponent(Self);
    ThingsToDo.Name := 'ThingsToDo';
    ThingsToDo.Left := 625;
    ThingsToDo.Top := 24;
    ThingsToDo.Width := 184;
    ThingsToDo.Height := 393;
    ThingsToDo.HeightStyle := ssAuto;
    ThingsToDo.WidthStyle := ssAuto;
    ThingsToDo.ChildOrder := 2;
    ThingsToDo.ElementFont := efCSS;
    ThingsToDo.ElementPosition := epIgnore;
    ThingsToDo.ItemIndex := 0;
    ThingsToDo.Options.ItemWidthMin := 200;
    SetEvent(ThingsToDo, Self, 'OnItemClick', 'ThingsToDoItemClick');
    Graph1.SetParentComponent(Self);
    Graph1.Name := 'Graph1';
    Graph1.Left := 47;
    Graph1.Top := 224;
    Graph1.Width := 100;
    Graph1.Height := 40;
    Graph1.HeightStyle := ssAuto;
    Graph1.WidthStyle := ssAuto;
    Graph1.ChildOrder := 6;
    Graph1.ElementPosition := epIgnore;
    Graph1.ElementFont := efCSS;
    Graph1.Role := '';
    Graph2.SetParentComponent(Self);
    Graph2.Name := 'Graph2';
    Graph2.Left := 167;
    Graph2.Top := 224;
    Graph2.Width := 100;
    Graph2.Height := 40;
    Graph2.HeightStyle := ssAuto;
    Graph2.WidthStyle := ssAuto;
    Graph2.ChildOrder := 6;
    Graph2.ElementPosition := epIgnore;
    Graph2.ElementFont := efCSS;
    Graph2.Role := '';
    JobsSource.SetParentComponent(Self);
    JobsSource.Name := 'JobsSource';
    JobsSource.Left := 560;
    JobsSource.Top := 16;
  finally
    GiftAidToClaim.AfterLoadDFMValues;
    TotalGiftAidClaimed.AfterLoadDFMValues;
    TotalGiftAidClaimedLabel.AfterLoadDFMValues;
    WhatsNew.AfterLoadDFMValues;
    JobsList.AfterLoadDFMValues;
    ThingsToDo.AfterLoadDFMValues;
    Graph1.AfterLoadDFMValues;
    Graph2.AfterLoadDFMValues;
    JobsSource.AfterLoadDFMValues;
  end;
end;

end.
