unit Sphinx.OidcClient.Profile;

{$I Sphinx.inc}

interface

uses
  Bcl.Json.Common,
  Sphinx.Consts;

type
  /// <summary>
  ///   Holds information about the user profile provided in the identity token.
  /// </summary>
  TOidcProfile = class
  strict private
    FSource: TJObject;
    FSubject: string;
    FName: string;
    FGivenName: string;
    FFamilyName: string;
    FMiddleName: string;
    FNickName: string;
    FPreferredUserName: string;
    FProfile: string;
    FPicture: string;
    FWebsite: string;
    FEmail: string;
    FEmailVerified: Boolean;
    FGender: string;
    FBirthDate: string;
    FZoneInfo: string;
    FLocale: string;
    FPhoneNumber: string;
    FPhoneNumberVerified: Boolean;
  strict private
    procedure LoadProperties;
    property Source: TJObject read FSource;
  public
    constructor Create(ASource: TJObject);
    destructor Destroy; override;

    /// <summary>
    ///   A string value that uniquely identifies the user.
    /// </summary>
    property Subject: string read FSubject write FSubject;

    /// <summary>
    ///   The full name of the user.
    /// </summary>
    property Name: string read FName write FName;

    /// <summary>
    ///   The given name of the user.
    /// </summary>
    property GivenName: string read FGivenName write FGivenName;

    /// <summary>
    ///   The family name of the user.
    /// </summary>
    property FamilyName: string read FFamilyName write FFamilyName;

    /// <summary>
    ///   The middle name of the user.
    /// </summary>
    property MiddleName: string read FMiddleName write FMiddleName;

    /// <summary>
    ///   The nickname of the user.
    /// </summary>
    property NickName: string read FNickName write FNickName;

    /// <summary>
    ///   The preferred user name of the user.
    /// </summary>
    property PreferredUserName: string read FPreferredUserName write FPreferredUserName;

    /// <summary>
    ///   The URL of the user's profile.
    /// </summary>
    property Profile: string read FProfile write FProfile;

    /// <summary>
    ///   The URL of the user's picture.
    /// </summary>
    property Picture: string read FPicture write FPicture;

    /// <summary>
    ///   The URL of the  user's website.
    /// </summary>
    property Website: string read FWebsite write FWebsite;

    /// <summary>
    ///   The email address of the user.
    /// </summary>
    property Email: string read FEmail write FEmail;

    /// <summary>
    ///   Indicates if the user email has been verified.
    /// </summary>
    property EmailVerified: Boolean read FEmailVerified write FEmailVerified;

    /// <summary>
    ///   The gender of the user.
    /// </summary>
    property Gender: string read FGender write FGender;

    /// <summary>
    ///   The birthday date of the user.
    /// </summary>
    property BirthDate: string read FBirthDate write FBirthDate;

    /// <summary>
    ///   String from zoneinfo time zone database representing the user's time zone.
    /// </summary>
    property ZoneInfo: string read FZoneInfo write FZoneInfo;

    /// <summary>
    ///   User's locale, represented as a BCP47 [RFC5646] language tag.
    /// </summary>
    property Locale: string read FLocale write FLocale;

    /// <summary>
    ///   The phone number of the user.
    /// </summary>
    property PhoneNumber: string read FPhoneNumber write FPhoneNumber;

    /// <summary>
    ///   Indicates if the user phone number has been verified.
    /// </summary>
    property PhoneNumberVerified: Boolean read FPhoneNumberVerified write FPhoneNumberVerified;
  end;

implementation

{ TOidcProfile }

constructor TOidcProfile.Create(ASource: TJObject);
begin
  inherited Create;
  FSource := ASource;
  LoadProperties;
end;

destructor TOidcProfile.Destroy;
begin
{$IFNDEF PAS2JS}
  FSource.Free;
{$ENDIF}
  inherited;
end;

procedure TOidcProfile.LoadProperties;
begin
  if Source = nil then Exit;
  
  Subject := JStringOrDefault(Source[JwtClaimNames.Subject]);
  Email := JStringOrDefault(Source[JwtClaimNames.Email]);
  Name := JStringOrDefault(Source[JwtClaimNames.Name]);
  GivenName := JStringOrDefault(Source[JwtClaimNames.GivenName]);
  FamilyName := JStringOrDefault(Source[JwtClaimNames.FamilyName]);
  MiddleName := JStringOrDefault(Source[JwtClaimNames.MiddleName]);
  NickName := JStringOrDefault(Source[JwtClaimNames.NickName]);
  PreferredUserName := JStringOrDefault(Source[JwtClaimNames.PreferredUserName]);
  Profile := JStringOrDefault(Source[JwtClaimNames.Profile]);
  Picture := JStringOrDefault(Source[JwtClaimNames.Picture]);
  Website := JStringOrDefault(Source[JwtClaimNames.Website]);
  Email := JStringOrDefault(Source[JwtClaimNames.Email]);
  EmailVerified := JBooleanOrDefault(Source[JwtClaimNames.EmailVerified]);
  Gender := JStringOrDefault(Source[JwtClaimNames.Gender]);
  BirthDate := JStringOrDefault(Source[JwtClaimNames.BirthDate]);
  ZoneInfo := JStringOrDefault(Source[JwtClaimNames.ZoneInfo]);
  Locale := JStringOrDefault(Source[JwtClaimNames.Locale]);
  PhoneNumber := JStringOrDefault(Source[JwtClaimNames.PhoneNumber]);
  PhoneNumberVerified := JBooleanOrDefault(Source[JwtClaimNames.PhoneNumberVerified]);
end;

end.
