unit Sphinx.Discovery.Metadata;

interface

uses
{$IFNDEF PAS2JS}
  Bcl.Json.Attributes,
  Bcl.Json.NamingStrategies,
{$ENDIF}
  Bcl.Json.Common;

type
{$IFNDEF PAS2JS}
  [JsonNamingStrategy(TSnakeCaseNamingStrategy)]
  [JsonInclude(TInclusionMode.NonDefault)]
{$ENDIF}
  TOidcProviderMetadata = class
  private
    FIssuer: string;
    FAuthorizationEndpoint: string;
    FTokenEndpoint: string;
    FUserinfoEndpoint: string;
    FJwksUri: string;
    FResponseTypesSupported: TArray<string>;
  public
    property Issuer: string read FIssuer write FIssuer;
    property AuthorizationEndpoint: string read FAuthorizationEndpoint write FAuthorizationEndpoint;
    property TokenEndpoint: string read FTokenEndpoint write FTokenEndpoint;
    property UserinfoEndpoint: string read FUserinfoEndpoint write FUserinfoEndpoint;
    property JwksUri: string read FJwksUri write FJwksUri;
    property ResponseTypesSupported: TArray<string> read FResponseTypesSupported write FResponseTypesSupported;
  end;

procedure MetadataFromJObject(Metadata: TOidcProviderMetadata; Obj: TJObject);

implementation

uses
  Sphinx.Consts;

procedure MetadataFromJObject(Metadata: TOidcProviderMetadata; Obj: TJObject);

  function ToStrArray(Element: TJElement): TArray<string>;
  var
    Arr: TJArray;
    I: Integer;
  begin
    SetLength(Result, 0);
    if not JIsArray(Element) then Exit;
    Arr := AsJArray(Element);
    SetLength(Result, Arr.Length);
    for I := 0 to Arr.Length - 1 do
      Result[I] := JStringOrDefault(Arr[I]);
  end;

begin
  Metadata.Issuer := JStringOrDefault(Obj[MetadataProperties.Issuer]);
  Metadata.AuthorizationEndpoint := JStringOrDefault(Obj[MetadataProperties.AuthorizationEndpoint]);
  Metadata.TokenEndpoint := JStringOrDefault(Obj[MetadataProperties.TokenEndpoint]);
  Metadata.UserinfoEndpoint := JStringOrDefault(Obj[MetadataProperties.UserInfoEndpoint]);
  Metadata.JwksUri := JStringOrDefault(Obj[MetadataProperties.JwksUri]);
  Metadata.ResponseTypesSupported := ToStrArray(Obj[MetadataProperties.ResponseTypesSupported]);
end;

end.
