unit CommunityBuildingsList;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, BaseList, WEBLib.ExtCtrls, WEBLib.Actions,
  Data.DB, WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.Grids, WEBLib.DBCtrls, Vcl.Controls, WEBLib.Lists,
  smx.webcore.types, WEBLib.WebCtrls;

type
  TCommunityBuildingsListForm = class(TBaseListForm)
    MainDatasetId: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetExternalId: TStringField;
    MainDatasetCharityTenant: TStringField;
    MainDatasetBuildingName: TStringField;
    MainDatasetAddress: TStringField;
    MainDatasetPostCode: TStringField;
    RefreshTimer: TTimer;
    MainDatasetMatchHash: TStringField;
    MainDatasetGAStatus: TStringField;
    procedure AddButtonClick(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
    procedure DataTableGetCellChildren(Sender: TObject; ACol, ARow: Integer;
        AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure MainDatasetAfterDelete(DataSet: TDataSet);
    procedure MainDatasetAfterPost(DataSet: TDataSet);
    procedure RefreshTimerTimer(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
    FCommunityBuildingIds: string;
  protected
    function GetEditFormClass: TFormClass; override;
    procedure PrepareForm; override;
    function RecordViewOption: TViewOption; override;
    function InitSortCol: Integer; override;
    function InitSortDirection: TSortDirection; override;
    procedure RefreshControls;
    function CreateGridInFormCreate: boolean; override;
    procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);override;
    //procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement; ABuildingId: integer);
    procedure DeleteSelectedRecord(RecordId: integer);
    function CheckOkToDelete(ACommunityBuildingId: integer):boolean;
    [async]
    procedure GetCommunityBuildingIdsWithClaims; async;
    [async]
    procedure AddCommunityBuilding(AId: integer); async;
    [async]
    procedure EditRecord(const ARecordId: string); async; override;

  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  CommunityBuildingsListForm: TCommunityBuildingsListForm;

implementation

{$R *.dfm}
uses
  CommunityBuildingEdit, SMX.Web.Layout.Utils, XData.Web.Client, MainDataModule;

procedure TCommunityBuildingsListForm.AddButtonClick(Sender: TObject);
begin
  AddCommunityBuilding(0);
end;

procedure TCommunityBuildingsListForm.WebFormDestroy(Sender: TObject);
begin
  MainDataset.Active := false;
  inherited;
end;

function TCommunityBuildingsListForm.CreateGridInFormCreate: boolean;
begin
  result := false;
end;

function TCommunityBuildingsListForm.GetEditFormClass: TFormClass;
begin
  result := TCommunityBuildingEditForm;
end;

procedure TCommunityBuildingsListForm.MainDatasetAfterPost(DataSet: TDataSet);
begin
  inherited;
  RefreshTimer.Enabled := true;
end;

procedure TCommunityBuildingsListForm.PrepareForm;
begin
  FIdField := 'Id';
end;

function TCommunityBuildingsListForm.RecordViewOption: TViewOption;
begin
  Result := TViewOption.voCreateAndEdit
end;

procedure TCommunityBuildingsListForm.RefreshControls;
begin
  FGridPlugin.Load;
end;

function TCommunityBuildingsListForm.InitSortCol: Integer;
begin
  result := 0;
end;

function TCommunityBuildingsListForm.InitSortDirection: TSortDirection;
begin
  result := sdAsc
end;

procedure TCommunityBuildingsListForm.RefreshTimerTimer(Sender: TObject);
begin
  RefreshTimer.Enabled := false;
  RefreshControls;
end;

procedure TCommunityBuildingsListForm.WebFormShow(Sender: TObject);
begin
  CreateGridPlugin;
  GetCommunityBuildingIdsWithClaims;
  RefreshControls;
end;

procedure TCommunityBuildingsListForm.AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);

  procedure EditClick(Sender: TObject);
  begin
    EditRecord(ARecordId);
  end;

  procedure DeleteClick(Sender: TObject);
  begin
    DeleteSelectedRecord(ARecordId.ToInteger);
  end;

var
  Span: THTMLSpan;
begin

  if (RecordViewOption in [voEdit, voCreateAndEdit]) then
  begin
    Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-edit', 'Edit');
    Span.OnClick := @EditClick;
  end
  else if RecordViewOption = voReadOnly then
  begin
    Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-eye', 'View');
    Span.OnClick := @EditClick;
  end;

  if ARecordId <> '' then
  begin
    if CheckOkToDelete(ARecordId.ToInteger()) then
    begin
      Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-trash', 'Delete');
      Span.OnClick := @DeleteClick;
    end;
  end;
end;

procedure TCommunityBuildingsListForm.DeleteSelectedRecord(RecordId: integer);
begin
  if MainDataSet.Locate('Id', RecordId, []) then
  begin
    try
      MainDataSet.Edit;
      MainDataSet.Delete;
    finally
      //MainDataSet.ApplyUpdates;
    end;
  end;
end;

function TCommunityBuildingsListForm.CheckOkToDelete(ACommunityBuildingId: integer):boolean;
var
  lId: string;
begin
  lId := Format('"%d"', [ACommunityBuildingId]);
  result := Pos(lId, FCommunityBuildingIds) = 0;
end;

procedure TCommunityBuildingsListForm.DataTableGetCellChildren(Sender: TObject;
    ACol, ARow: Integer; AField: TField; AValue: string; AElement:
    TJSHTMLElementRecord);
var
  RecordId: string;
begin
  if ARow = 0 then
    Exit;

  if DataTable.Columns[ACol].Title = 'Actions' then
  begin
    RecordId := MainDataset.FieldByName(FIdField).AsString;
    AddRowActions(RecordId, AElement.Element);
  end;
end;

procedure TCommunityBuildingsListForm.GetCommunityBuildingIdsWithClaims;
var
  lJobParams: JS.TJSObject;
  lRetval: TXDataClientResponse;
  lCommunityBuildingIdsStr: string;
begin
  lRetval := await(TXDataClientResponse,
    MainData.WebClient.RawInvokeAsync('IGiftAiderUtilsService.RegisteredCommunityBuildingId',[]));

  lCommunityBuildingIdsStr := JS.ToString(lRetval.ResultAsObject['value']);
  FCommunityBuildingIds := lCommunityBuildingIdsStr;
end;

procedure TCommunityBuildingsListForm.MainDatasetAfterDelete(DataSet: TDataSet);
begin
  inherited;
  MainDataSet.ApplyUpdates;
end;

procedure TCommunityBuildingsListForm.EditRecord(const ARecordId: string);
begin
  AddCommunityBuilding(ARecordId.ToInteger);
end;

procedure TCommunityBuildingsListForm.AddCommunityBuilding(AId: integer);
var
  Aform: TCommunityBuildingEditForm;

begin
  AForm := TCommunityBuildingEditForm.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());
    if AId = 0 then
      MainDataSet.Insert
    else
    begin
      MainDataSet.Locate('Id', AId, []);
    end;
    AForm.EditDataSet := MainDataSet;
    AForm.AfterCreated;
    await(TModalResult, AForm.Execute);
  finally
    if AForm.ModalResult = mrOk then
    begin
      MainDataSet.Post;
    end;
    AForm.Free;
    AForm := nil;
  end;
end;


procedure TCommunityBuildingsListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetExternalId := TStringField.Create(Self);
  MainDatasetCharityTenant := TStringField.Create(Self);
  MainDatasetBuildingName := TStringField.Create(Self);
  MainDatasetAddress := TStringField.Create(Self);
  MainDatasetPostCode := TStringField.Create(Self);
  MainDatasetMatchHash := TStringField.Create(Self);
  MainDatasetGAStatus := TStringField.Create(Self);
  RefreshTimer := TTimer.Create(Self);

  DataTable.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetExternalId.BeforeLoadDFMValues;
  MainDatasetCharityTenant.BeforeLoadDFMValues;
  MainDatasetBuildingName.BeforeLoadDFMValues;
  MainDatasetAddress.BeforeLoadDFMValues;
  MainDatasetPostCode.BeforeLoadDFMValues;
  MainDatasetMatchHash.BeforeLoadDFMValues;
  MainDatasetGAStatus.BeforeLoadDFMValues;
  RefreshTimer.BeforeLoadDFMValues;
  try
    Caption := 'CommunityBuilding';
    SetEvent(Self, 'OnShow', 'WebFormShow');
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'BuildingName';
      Title := 'BuildingName';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Address';
      Title := 'Address';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'PostCode';
      Title := 'Post Code';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    MainDataset.AfterDelete := MainDatasetAfterDelete;
    MainDataset.EntitySetName := 'CommunityBuilding';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetId.Required := True;
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetDateAdded.Required := True;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetAddedBy.Required := True;
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetExternalId.SetParentComponent(MainDataset);
    MainDatasetExternalId.Name := 'MainDatasetExternalId';
    MainDatasetExternalId.FieldName := 'ExternalId';
    MainDatasetExternalId.Size := 64;
    MainDatasetCharityTenant.SetParentComponent(MainDataset);
    MainDatasetCharityTenant.Name := 'MainDatasetCharityTenant';
    MainDatasetCharityTenant.FieldName := 'CharityTenant';
    MainDatasetBuildingName.SetParentComponent(MainDataset);
    MainDatasetBuildingName.Name := 'MainDatasetBuildingName';
    MainDatasetBuildingName.FieldName := 'BuildingName';
    MainDatasetBuildingName.Size := 255;
    MainDatasetAddress.SetParentComponent(MainDataset);
    MainDatasetAddress.Name := 'MainDatasetAddress';
    MainDatasetAddress.FieldName := 'Address';
    MainDatasetAddress.Size := 255;
    MainDatasetPostCode.SetParentComponent(MainDataset);
    MainDatasetPostCode.Name := 'MainDatasetPostCode';
    MainDatasetPostCode.FieldName := 'PostCode';
    MainDatasetPostCode.Size := 15;
    MainDatasetMatchHash.SetParentComponent(MainDataset);
    MainDatasetMatchHash.Name := 'MainDatasetMatchHash';
    MainDatasetMatchHash.FieldName := 'MatchHash';
    MainDatasetMatchHash.Size := 64;
    MainDatasetGAStatus.SetParentComponent(MainDataset);
    MainDatasetGAStatus.Name := 'MainDatasetGAStatus';
    MainDatasetGAStatus.FieldName := 'GAStatus';
    MainDatasetGAStatus.Size := 10;
    RefreshTimer.SetParentComponent(Self);
    RefreshTimer.Name := 'RefreshTimer';
    RefreshTimer.Enabled := False;
    RefreshTimer.Interval := 3000;
    SetEvent(RefreshTimer, Self, 'OnTimer', 'RefreshTimerTimer');
    RefreshTimer.Left := 536;
    RefreshTimer.Top := 16;
  finally
    DataTable.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetExternalId.AfterLoadDFMValues;
    MainDatasetCharityTenant.AfterLoadDFMValues;
    MainDatasetBuildingName.AfterLoadDFMValues;
    MainDatasetAddress.AfterLoadDFMValues;
    MainDatasetPostCode.AfterLoadDFMValues;
    MainDatasetMatchHash.AfterLoadDFMValues;
    MainDatasetGAStatus.AfterLoadDFMValues;
    RefreshTimer.AfterLoadDFMValues;
  end;
end;

end.
