unit SMX.Auth.Shared;

interface

uses
  Sphinx.Consts;

{$SCOPEDENUMS ON}


{$IFDEF PAS2JS}
type
  TUserScope = (None, HMRCOnly, readonly, User, AdminUser, Administrator, SuperUser);
{$ENDIF}

const
  CLAIM_FIRSTNAME = JwtClaimNames.GivenName; // 'given_name';
  CLAIM_LASTNAME = JwtClaimNames.FamilyName;
  CLAIM_FULLNAME = JwtClaimNames.Name; // 'name';

  CLAIM_USERID = 'userid';
  CLAIM_LOCATIONTYPE = 'locationtype';
  CLAIM_SCOPE = 'scope';
  CLAIM_ROLE = 'role';
  CLAIM_TENANT_ID = 'tenant-id';  //DO NOT CHANGE THIS IS USED BY XData!!!//
  CLAIM_ORGANISATION_ID = 'org-id';
  CLAIM_ORGANISATION_NAME = 'org-name';
  CLAIM_ORGANISATION_TENANT_ID = 'org-tenant-id';

  ORGANISATION_NOT_CREATED = 'not-created';
  ORGANISATION_SETUP_PENDING = 'setup-pending';
  ORGANISATION_MULTI_TENANT = 'multi-tenant';

  HEADER_TENANT_ID = 'tenant-id';

  //This is added to the Id Token by Sphinx as "email"
  CLAIM_EMAIL = 'email';

  SCOPE_NONE = 'None';
  SCOPE_HMRC_ONLY = 'HMRCOnly';
  SCOPE_READ_ONLY = 'ReadOnly';
  SCOPE_USER = 'User';
  SCOPE_ADMIN_USER = 'AdminUser';
  SCOPE_ADMIN = 'Administrator';
  SCOPE_SUPERUSER = 'SuperUser';

implementation

{$SCOPEDENUMS OFF}

end.
