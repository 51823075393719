unit SMX.Shared;

interface

Type

TUserScope = (usNone, usRead, usShopuser, usAdminuser, usAdministrator, usSuperuser, usSysUser);

const
  CLAIM_FIRSTNAME = 'firstname';
  CLAIM_FULLNAME = 'fullname';
  CLAIM_USERID = 'userid';
  CLAIM_LOCATIONTYPE = 'locationtype';
  CLAIM_SCOPE = 'scope';
  CLAIM_ROLE = 'role';

  SCOPE_NONE = 'none';
  SCOPE_READONLY = 'read';
  SCOPE_USER = 'user';
  SCOPE_ADMIN = 'adminuser';
  SCOPE_SUPERUSER = 'superuser';

  SCOPE_ANY_ADMIN = 'adminuser,superuser';
  SCOPE_ALL_USERS = 'user,adminuser,superuser';

  JOBS_UPLOADED_FILE = 'UploadedFile';


function CreateSearchHash(const AFirstName, ALastName, APostCode, Add1: string): string;

implementation

function CreateSearchHash(const AFirstName, ALastName, APostCode, Add1: string): string;
var
  HashSource: string;
begin
  if Length(AFirstName) > 0 then
    HashSource := Copy(AFirstName, 1, 1)
  else
    HashSource := '-';

  Result := HashSource;

end;

end.

