unit SMX.Web.Service.Consts;

interface

const
  IJOBSVC_USERS = 'IJobsService.GetJobUsers'; // SubmittedBy, OwnedBy, CompletedBy
  IJOBSVC_GETFILE = 'IJobsService.GetFile'; // JobId returns Stream
  IJOBSVC_SCHEDULEJOB = 'IJobsService.ScheduleJob';
  // (JobClass; JobVisibility; AParams; OutputOption,RunLevel): JobId(Integer)
  IJOBSVC_SCHEDULE_FILE_JOB = 'IJobsService.ScheduleJobWithFile';

  {$IFDEF STANDALONE}
  SVC_CHECK_USERNAME = 'ISMXUtilsService.IsUserNameUnique';
  SVC_UPDATEPASSWORD = 'ISMXUtilsService.UpdatePassword'; // (const CurrentPassword, NewPassword: String): String;
  SVC_SETPASSWORD = 'ISMXUtilsService.SetPassword'; // UserId, Password
  SVC_GENERATEPASSWORD = 'ISMXUtilsService.GeneratePassword'; // UserId
  {$ENDIF}

  SVC_GETNEXTID = 'ISMXUtilsService.GetNextSequenceNo'; // (const ASequenceName: String): Integer;
  SVC_GETRECUSERS = 'ISMXUtilsService.GetRecordUser'; // (const AAddedBy, AUpdatedBy: Integer): TAuditUsers;
  SVC_GET_THINGS_TODO = 'ISMXUtilsService.GetThingsToDo'; // : TList<TThingsToDo>;
  SVC_FILEEXISTS = 'ISMXUtilsService.FileExists'; //(const AStore, APath, AFile: String): Boolean;

implementation

end.
