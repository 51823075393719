unit Sphinx.Web.Http;

interface

uses
  JS, Web, Types, SysUtils,
  XData.Web.Request, XData.Web.Response;

{ Code in this unit is more or less duplicated from XData.Web.Connection. Unify the code later and remove this unit. }

type
  IHttpRequest = XData.Web.Request.IHttpRequest;
  THttpRequest = XData.Web.Request.THttpRequest;
  THttpResponse = XData.Web.Response.THttpResponse;

  EEventException = class(Exception)
  strict private
    FEvent: JSValue;
  public
    constructor Create(const Msg: string; AEvent: JSValue);
    property Event: JSValue read FEvent;
  end;

function SendHttpRequestAsync(Req: IHttpRequest): THttpResponse; async;

implementation

type
  TLocalHttpResponseProc = reference to procedure(Response: THttpResponse);
  THttpEventProc = reference to procedure(Event: JSValue);

procedure SendHttpRequest(Req: IHttpRequest; SuccessProc: TLocalHttpResponseProc;
  ErrorProc: THttpEventProc = nil);
var
  Xhr: TJSXmlHttpRequest;

  procedure XhrLoad;
  var
    Resp: THttpResponse;
  begin
    Resp := TXhrHttpResponse.Create(Xhr);
    if Assigned(SuccessProc) then
      SuccessProc(Resp);
  end;

  procedure XhrError(Event: JSValue);
  begin
    if Assigned(ErrorProc) then
      ErrorProc(Event);
  end;

var
  HeaderNames: TStringDynArray;
  I: Integer;
begin
  Xhr := TJSXMLHttpRequest.new;
  Xhr.open(Req.Method, Req.Uri, True);
  HeaderNames := Req.Headers.HeaderNames;
  for I := 0 to Length(HeaderNames) - 1 do
    Xhr.setRequestHeader(HeaderNames[I], Req.Headers.Get(HeaderNames[I]));

  Xhr.addEventListener('load', @XhrLoad);
  Xhr.addEventListener('error', @XhrError);
  Xhr.addEventListener('timeout', @XhrError);
  if Req.Timeout > 0 then
    Xhr.timeout := Req.Timeout;
  if not Js.IsNull(Req.Content) then
    Xhr.send(Req.Content)
  else
    Xhr.send;
end;

function SendHttpRequestAsync(Req: IHttpRequest): THttpResponse;
var
  Promise: TJSPromise;
begin
  Promise := TJSPromise.new(
    procedure(resolve, reject: TJSPromiseResolver)
    begin
      SendHttpRequest(Req,
        procedure(Response: THttpResponse)
        begin
          resolve(Response);
        end,
        procedure(Event: JSValue)
        begin
          reject(EEventException.Create('XHR request failed', Event));
        end
      );
    end
  );
  Exit(Promise);
end;

{ EEventException }

constructor EEventException.Create(const Msg: string; AEvent: JSValue);
begin
  inherited Create(Msg);
  FEvent := AEvent;
end;

end.
