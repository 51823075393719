unit OtherClaimsEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WebForm.Core, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Data.DB, WEBLib.DB, WEBLib.DBCtrls, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.ExtCtrls, WEBLib.WebCtrls, System.DateUtils;

type
  TOtherClaimsEditForm = class(TCoreWebForm)
    CloseButton: TButton;
    SaveButton: TButton;
    CancelButton: TButton;
    EditDataSource: TDataSource;
    AmountEdit: TDBEdit;
    TransactionDateEdit: TDBDateTimePicker;
    NoteMemo: TDBMemo;
    RulesMemo: THTMLSpan;
    MessageDialog: TMessageDlg;
    FormCaption: TLabel;
    ErrorMessageTransactionDate: TLabel;
    procedure AmountEditChange(Sender: TObject);
    procedure AmountEditKeyPress(Sender: TObject; var Key: Char);
    procedure CancelButtonClick(Sender: TObject);
    procedure CloseButtonClick(Sender: TObject);
    procedure NoteMemoExit(Sender: TObject);
    procedure NoteMemoKeyPress(Sender: TObject; var Key: Char);
    procedure SaveButtonClick(Sender: TObject);
    procedure TransactionDateEditChange(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FEditDataApplyUpdates: boolean;
    FOtherClaimsType: string;
    FRecordId: integer;
    FValid: boolean;
    FTransactionDate: boolean;
    FTransactionDateValid: boolean;
    FAmountOk: boolean;
    procedure SetOtherClaimsType(AOtherClaimsType: string);
    procedure SetDataSet(Value: TDataSet);
    procedure SetTransactionDateValid(AValid: boolean);
  protected
    [async]
    procedure GetRules; async; virtual;
    procedure ResetButtons(value: boolean);virtual;
    procedure SetClaimsTypeCaption(ACaption: string);virtual;
    [async]
    procedure CheckCurrentDonationLimit(AYear: integer); async;virtual;
    procedure CheckValidations;virtual;
    function GetBaseValidation:boolean;virtual;
    procedure SetBaseValidations(AValid: boolean);virtual;
  public
    { Public declarations }
    procedure AfterCreated; override;
    property EditDataApplyUpdates: boolean read fEditDataApplyUpdates;
    property OtherClaimsType: string read fOtherClaimsType write SetOtherClaimsType;
    property DataSet: TDataSet write SetDataSet;
    property TransactionDateValid: boolean read fTransactionDateValid write SetTransactionDateValid;
    property ClaimsTypeCaption: string write SetClaimsTypeCaption;
  protected procedure LoadDFMValues; override; end;

var
  OtherClaimsEditForm: TOtherClaimsEditForm;

implementation

uses
  MainDataModule, XData.Web.Client, SMX.Web.Document.Utils,
  GA.Web.Utils;

{$R *.dfm}

procedure TOtherClaimsEditForm.CancelButtonClick(Sender: TObject);
begin
  inherited;
  EditDataSource.DataSet.Cancel;
  ModalResult := mrOk;
end;

procedure TOtherClaimsEditForm.CheckValidations;
begin
  FValid := True;
  if FTransactionDateValid = False then
  begin
    FValid := False;
  end;

  if FAmountOk = False then
  begin
    FValid := False;
  end;

  SaveButton.Enabled := FValid;
end;

procedure TOtherClaimsEditForm.CloseButtonClick(Sender: TObject);
begin
  inherited;
  ModalResult := mrOk;
end;

procedure TOtherClaimsEditForm.SaveButtonClick(Sender: TObject);
begin
  //inherited;
  EditDataSource.DataSet.FieldByName('ClaimStatus').AsString := 'Unclaimed';
  EditDataSource.DataSet.Post;
  fEditDataApplyUpdates := true;
  ResetButtons(False);
  ModalResult := mrOk;
end;

procedure TOtherClaimsEditForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  fEditDataApplyUpdates := false;
end;

procedure TOtherClaimsEditForm.ResetButtons(value: boolean);
begin
  SaveButton.Enabled := Value;
  CancelButton.Enabled := Value;
  //FTransactionDateValid := False;
  //FAmountOk := False;
end;

procedure TOtherClaimsEditForm.SetOtherClaimsType(AOtherClaimsType: string);
begin
  fOtherClaimsType := AOtherClaimsType;
end;

procedure TOtherClaimsEditForm.SetTransactionDateValid(AValid: boolean);
begin
  fTransactionDateValid := AValid;
  CheckValidations;
end;

procedure TOtherClaimsEditForm.AfterCreated;
begin
  GetRules;
end;

procedure TOtherClaimsEditForm.AmountEditChange(Sender: TObject);
var
  lAmount: double;
begin
  FAmountOk := False;
  if AmountEdit.Text <> '' then
  begin
    lAmount := strtofloat(AmountEdit.text);
    if lAmount > 0.00 then
    begin
      TDocUtils.setControlValidity('AmountEdit', vsValid);
      FAmountOk := True;
    end
    else
      TDocUtils.setControlValidity('AmountEdit', vsInvalid);
  end
  else
    TDocUtils.setControlValidity('AmountEdit', vsInvalid);
  CheckValidations;
end;

procedure TOtherClaimsEditForm.AmountEditKeyPress(Sender: TObject; var Key:
    Char);
begin
  //FAmountOk := false;
  if (Pos('.', AmountEdit.Text) > 0) and (Key = '.') then
  begin
    Key := #0;
    exit;
  end;

  if (not (Key in ['0'..'9','.','-']) or (Length(TEdit(Sender).Text) > 7)) then
  begin
    TDocUtils.setControlValidity('AmountEdit', vsInvalid);
    Key := #0;
  end
  else
  begin
    TDocUtils.setControlValidity('AmountEdit', vsValid);
    FAmountOk := false;
  end;
end;

procedure TOtherClaimsEditForm.GetRules;
var
  lJobParams: JS.TJSObject;
  lRetval: TXDataClientResponse;
  lRulesStr: string;
begin
  lRetval := await(TXDataClientResponse,
    MainData.WebClient.RawInvokeAsync('IGiftAiderUtilsService.OtherClaimsRules',[fOtherClaimsType]));

  lRulesStr := JS.ToString(lRetval.ResultAsObject['value']);
  RulesMemo.HTML.Text := lRulesStr;

  TransactionDateEdit.SetFocus;
end;

procedure TOtherClaimsEditForm.NoteMemoKeyPress(Sender: TObject; var Key: Char);
var
  NoteLength: integer;
const
  MaxNoteLength = 250;
begin
  NoteLength := Length(NoteMemo.Lines.Text);
  if NoteLength > MaxNoteLength then
  begin
    TDocUtils.setControlValidity('NotesEdit', vsInvalid);
    Key := #0;
  end;
end;

procedure TOtherClaimsEditForm.SetClaimsTypeCaption(ACaption: string);
begin
  FormCaption.Caption := ACaption + ' Edit';
end;

procedure TOtherClaimsEditForm.SetDataSet(Value: TDataSet);
begin
  EditDataSource.DataSet := Value;
  //FTransactionDateValid := False;
  //FAmountOk := False;
end;

procedure TOtherClaimsEditForm.TransactionDateEditChange(Sender: TObject);
var
  EarliestDateStr: string;
  EarliestDate: TDateTime;
begin
  FTransactionDateValid := True;

  if TransactionDateEdit.Date = 0 then
  begin
    ErrorMessageTransactionDate.Caption := 'Transaction Date cannot be blank';
    TDocUtils.setControlValidity('TransactionDateEdit', vsInvalid);
    FTransactionDateValid := False;
  end
  else if TransactionDateEdit.Date > Date then
  begin
    ErrorMessageTransactionDate.Caption := 'Transaction Date in the future';
    TDocUtils.setControlValidity('TransactionDateEdit', vsInvalid);
    FTransactionDateValid := False;
  end
  else
  begin
    EarliestDate := TGAWebUtils.EarliestClaimableDate(Now);

    if EarliestDate > TransactionDateEdit.Date then
    begin
      ErrorMessageTransactionDate.Caption := 'Transaction Date can not be before ' + FormatDateTime('DD/MM/YYYY', EarliestDate);
      TDocUtils.setControlValidity('TransactionDateEdit', vsInvalid);
      FTransactionDateValid := False;
    end;
  end;

  if FTransactionDateValid then
    TDocUtils.setControlValidity('TransactionDateEdit', vsValid);
  CheckValidations;
end;

procedure TOtherClaimsEditForm.CheckCurrentDonationLimit(AYear: integer);
var
  lJobParams: JS.TJSObject;
  lRetval: TXDataClientResponse;
  lDonationLimit: currency;
  lMaxLimit: currency;
  lYear: integer;
begin
  lRetval := await(TXDataClientResponse,
    MainData.WebClient.RawInvokeAsync('IGiftAiderUtilsService.CurrentOtherClaimsLimit',[fOtherClaimsType, AYear]));

  lDonationLimit := JS.toNumber(lRetval.ResultAsObject['CurrentDonationAmount']);
  lMaxLimit := JS.ToNumber(lRetval.ResultAsObject['Maxlimit']);
  lYear := JS.ToInteger(lRetval.ResultAsObject['Maxlimit']);

  //RulesMemo.HTML.Text := lRulesStr;

  TransactionDateEdit.SetFocus;
end;

function TOtherClaimsEditForm.GetBaseValidation: boolean;
begin
  result := FValid;
end;

procedure TOtherClaimsEditForm.SetBaseValidations(AValid: boolean);
begin
  FTransactionDateValid := True;
  FAmountOk := True;
  FValid := True;
end;

procedure TOtherClaimsEditForm.NoteMemoExit(Sender: TObject);
begin
  inherited;
  CheckValidations;
end;

procedure TOtherClaimsEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormCaption := TLabel.Create('FormCaption');
  ErrorMessageTransactionDate := TLabel.Create('ErrorMessageTransactionDate');
  CloseButton := TButton.Create('CloseButton');
  SaveButton := TButton.Create('SaveButton');
  CancelButton := TButton.Create('CancelButton');
  AmountEdit := TDBEdit.Create('AmountEdit');
  TransactionDateEdit := TDBDateTimePicker.Create('TransactionDateEdit');
  NoteMemo := TDBMemo.Create('NotesEdit');
  RulesMemo := THTMLSpan.Create('RulesMemo');
  MessageDialog := TMessageDlg.Create(Self);
  EditDataSource := TDataSource.Create(Self);

  FormCaption.BeforeLoadDFMValues;
  ErrorMessageTransactionDate.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  AmountEdit.BeforeLoadDFMValues;
  TransactionDateEdit.BeforeLoadDFMValues;
  NoteMemo.BeforeLoadDFMValues;
  RulesMemo.BeforeLoadDFMValues;
  MessageDialog.BeforeLoadDFMValues;
  EditDataSource.BeforeLoadDFMValues;
  try
    Caption := 'Comment';
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    FormCaption.SetParentComponent(Self);
    FormCaption.Name := 'FormCaption';
    FormCaption.Left := 16;
    FormCaption.Top := 44;
    FormCaption.Width := 73;
    FormCaption.Height := 15;
    FormCaption.Margins.Left := 5;
    FormCaption.Margins.Top := 5;
    FormCaption.Margins.Right := 5;
    FormCaption.Margins.Bottom := 5;
    FormCaption.Caption := 'Form Caption';
    FormCaption.ElementClassName := 'HeadlineClass';
    FormCaption.ElementFont := efCSS;
    FormCaption.ElementPosition := epIgnore;
    FormCaption.HeightStyle := ssAuto;
    FormCaption.HeightPercent := 100.000000000000000000;
    FormCaption.HTMLType := tDIV;
    FormCaption.WidthStyle := ssAuto;
    FormCaption.WidthPercent := 100.000000000000000000;
    ErrorMessageTransactionDate.SetParentComponent(Self);
    ErrorMessageTransactionDate.Name := 'ErrorMessageTransactionDate';
    ErrorMessageTransactionDate.Left := 16;
    ErrorMessageTransactionDate.Top := 8;
    ErrorMessageTransactionDate.Width := 155;
    ErrorMessageTransactionDate.Height := 15;
    ErrorMessageTransactionDate.Caption := 'ErrorMessageTransactionDate';
    ErrorMessageTransactionDate.HeightPercent := 100.000000000000000000;
    ErrorMessageTransactionDate.WidthPercent := 100.000000000000000000;
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.Left := 170;
    CloseButton.Top := 352;
    CloseButton.Width := 96;
    CloseButton.Height := 25;
    CloseButton.Caption := 'Close';
    CloseButton.ChildOrder := 12;
    CloseButton.ElementClassName := 'btn btn-light';
    CloseButton.ElementFont := efCSS;
    CloseButton.ElementPosition := epIgnore;
    CloseButton.HeightStyle := ssAuto;
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 14;
    CloseButton.WidthStyle := ssAuto;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.Left := 272;
    SaveButton.Top := 352;
    SaveButton.Width := 96;
    SaveButton.Height := 25;
    SaveButton.Caption := 'Save';
    SaveButton.ChildOrder := 13;
    SaveButton.ElementClassName := 'btn btn-light';
    SaveButton.ElementFont := efCSS;
    SaveButton.ElementPosition := epIgnore;
    SaveButton.Enabled := False;
    SaveButton.HeightStyle := ssAuto;
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 12;
    SaveButton.WidthStyle := ssAuto;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CancelButton.SetParentComponent(Self);
    CancelButton.Name := 'CancelButton';
    CancelButton.Left := 374;
    CancelButton.Top := 352;
    CancelButton.Width := 96;
    CancelButton.Height := 25;
    CancelButton.Caption := 'Cancel';
    CancelButton.ChildOrder := 14;
    CancelButton.ElementClassName := 'btn btn-light';
    CancelButton.ElementFont := efCSS;
    CancelButton.ElementPosition := epIgnore;
    CancelButton.Enabled := False;
    CancelButton.HeightStyle := ssAuto;
    CancelButton.HeightPercent := 100.000000000000000000;
    CancelButton.TabOrder := 13;
    CancelButton.WidthStyle := ssAuto;
    CancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(CancelButton, Self, 'OnClick', 'CancelButtonClick');
    AmountEdit.SetParentComponent(Self);
    AmountEdit.Name := 'AmountEdit';
    AmountEdit.Left := 24;
    AmountEdit.Top := 116;
    AmountEdit.Width := 121;
    AmountEdit.Height := 22;
    AmountEdit.ChildOrder := 6;
    AmountEdit.ElementClassName := 'form-control';
    AmountEdit.ElementPosition := epIgnore;
    AmountEdit.HeightStyle := ssAuto;
    AmountEdit.HeightPercent := 100.000000000000000000;
    AmountEdit.Text := 'AmountEdit';
    AmountEdit.WidthStyle := ssAuto;
    AmountEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AmountEdit, Self, 'OnChange', 'AmountEditChange');
    SetEvent(AmountEdit, Self, 'OnExit', 'AmountEditChange');
    SetEvent(AmountEdit, Self, 'OnKeyPress', 'AmountEditKeyPress');
    AmountEdit.DataField := 'Amount';
    AmountEdit.DataSource := EditDataSource;
    TransactionDateEdit.SetParentComponent(Self);
    TransactionDateEdit.Name := 'TransactionDateEdit';
    TransactionDateEdit.Left := 16;
    TransactionDateEdit.Top := 80;
    TransactionDateEdit.Width := 170;
    TransactionDateEdit.Height := 22;
    TransactionDateEdit.ElementClassName := 'form-control';
    TransactionDateEdit.HeightStyle := ssAuto;
    TransactionDateEdit.WidthStyle := ssAuto;
    TransactionDateEdit.BorderStyle := bsSingle;
    TransactionDateEdit.ChildOrder := 7;
    TransactionDateEdit.Color := clWhite;
    TransactionDateEdit.Date := 45226.369823090280000000;
    TransactionDateEdit.ElementPosition := epIgnore;
    TransactionDateEdit.Role := '';
    TransactionDateEdit.Text := '';
    SetEvent(TransactionDateEdit, Self, 'OnChange', 'TransactionDateEditChange');
    SetEvent(TransactionDateEdit, Self, 'OnExit', 'TransactionDateEditChange');
    TransactionDateEdit.DataField := 'TransactionDate';
    TransactionDateEdit.DataSource := EditDataSource;
    NoteMemo.SetParentComponent(Self);
    NoteMemo.Name := 'NoteMemo';
    NoteMemo.Left := 16;
    NoteMemo.Top := 160;
    NoteMemo.Width := 185;
    NoteMemo.Height := 89;
    NoteMemo.AutoSize := False;
    NoteMemo.ElementClassName := 'form-control';
    NoteMemo.ElementPosition := epIgnore;
    NoteMemo.HeightStyle := ssAuto;
    NoteMemo.HeightPercent := 100.000000000000000000;
    NoteMemo.Lines.BeginUpdate;
    try
      NoteMemo.Lines.Clear;
      NoteMemo.Lines.Add('NoteMemo');
    finally
      NoteMemo.Lines.EndUpdate;
    end;
    NoteMemo.SelLength := 0;
    NoteMemo.SelStart := 0;
    NoteMemo.WidthStyle := ssAuto;
    NoteMemo.WidthPercent := 100.000000000000000000;
    SetEvent(NoteMemo, Self, 'OnExit', 'NoteMemoExit');
    SetEvent(NoteMemo, Self, 'OnKeyPress', 'NoteMemoKeyPress');
    NoteMemo.DataField := 'Comment';
    NoteMemo.DataSource := EditDataSource;
    RulesMemo.SetParentComponent(Self);
    RulesMemo.Name := 'RulesMemo';
    RulesMemo.Left := 207;
    RulesMemo.Top := 80;
    RulesMemo.Width := 185;
    RulesMemo.Height := 249;
    RulesMemo.HeightStyle := ssAuto;
    RulesMemo.WidthStyle := ssAuto;
    RulesMemo.ChildOrder := 7;
    RulesMemo.ElementPosition := epIgnore;
    RulesMemo.ElementFont := efCSS;
    RulesMemo.Role := '';
    MessageDialog.SetParentComponent(Self);
    MessageDialog.Name := 'MessageDialog';
    MessageDialog.Left := 488;
    MessageDialog.Top := 80;
    MessageDialog.Width := 24;
    MessageDialog.Height := 24;
    MessageDialog.HeightStyle := ssAuto;
    MessageDialog.WidthStyle := ssAuto;
    MessageDialog.Buttons := [];
    MessageDialog.Opacity := 0.200000000000000000;
    EditDataSource.SetParentComponent(Self);
    EditDataSource.Name := 'EditDataSource';
    EditDataSource.Left := 486;
    EditDataSource.Top := 16;
  finally
    FormCaption.AfterLoadDFMValues;
    ErrorMessageTransactionDate.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    AmountEdit.AfterLoadDFMValues;
    TransactionDateEdit.AfterLoadDFMValues;
    NoteMemo.AfterLoadDFMValues;
    RulesMemo.AfterLoadDFMValues;
    MessageDialog.AfterLoadDFMValues;
    EditDataSource.AfterLoadDFMValues;
  end;
end;

end.

