unit smx.webcore.types;

interface

uses
  System.Generics.Collections;

type

  TSortDirection = (sdAsc, sdDesc);
  TSortPair = TPair<integer, TSortDirection>;
  TRefStatus = (RefEmpty, RefNotChecked, RefChecking, RefUnique, RefInUse);
  TViewOption = (voNone, voReadOnly, voEdit, voCreateAndEdit);
  TValueChangeState = (vcsNoChange, vcsValid, vcsInvalid);

const
  POPUP_FORM_CLASS = 'PopUpForm';

implementation

end.
