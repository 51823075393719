{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit libsentry;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses
  classes, JS, Web;

type

  TBeforeSendHint = class(TJSObject)
    originalException: TJSObject; external name 'originalException';
  end;

  TBeforeSendEventExceptionMechanism = class(TJSObject)
    synthetic: Boolean; external name 'synthetic';
  end;

  TBeforeSendEventExceptionStackTrace = class(TJSObject)
    frames: TJSArray; external name 'frames';
  end;

  TBeforeSendEventExceptionValue = class(TJSObject)
    value: String; external name 'value';
    mechanism: TBeforeSendEventExceptionMechanism; external name 'mechanism';
    stacktrace: TBeforeSendEventExceptionStackTrace; external name 'stacktrace';
  end;

  TBeforeSendEventException = class(TJSObject)
    FValues: TJSArray; external name 'values';
  end;

  TBeforeSendEvent = class(TJSObject)
    // The following may not be present
    stacktrace: TBeforeSendEventExceptionStackTrace; external name 'stacktrace';
    exception: TBeforeSendEventException; external name 'exception';
  end;

  TJSSentryScope = class(TJSObject)
    procedure setTag(aLey: String; aValue: String); external name 'setTag';
    procedure setUser(username: JSValue); external name 'setUser';
  end;

  TJSSentryScopeCallback = reference to Procedure(scope: TJSSentryScope);

  TJSSentry = class external name 'Sentry' (TJSObject)
  public
    class procedure init(options: TJSObject);
    class procedure captureException(anObj: TJSObject); overload;
    class procedure captureException(anObj: TJSObject; extraData: TJSObject); overload;
    class procedure captureMessage(aMsg: String);
    class procedure configureScope(scopeCallback: TJSSentryScopeCallback);
    class procedure addBreadcrumb(params: TJSObject);
  end;

implementation

end.
