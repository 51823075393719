unit GiftAid.Entities.Claims.Enumerations;

interface

{$IFNDEF PAS2JS}

uses
  Aurelius.Mapping.Attributes;
{$ENDIF}
{$SCOPEDENUMS ON}

type
{$IFNDEF PAS2JS}[Enumeration(TEnumMappingType.emString, 'Unknown,Open,Cancelled,Scheduled,WaitingAuth,Submitting,Acknowledged,Confirmed,PaymentReceived,ClaimRejected,Error,SysError,DataError')
    ]
{$ENDIF}
  TGiftAidClaimStatus = (Unknown,
    /// <summary>
    /// The claim preparation is in progress and items are being scheduled
    /// for inclusion
    /// </summary>
    Open,
    /// <summary>
    /// Claim was cancelled before submission
    /// </summary>
    Cancelled,
    /// <summary>
    ///   The claim has been prepared and is scheduled for submission. On the
    ///   UI this is actually displayed as Prepared. The User has to manually
    ///   submit this. If all is in order, and direct submission has been
    ///   selected, then the user might not see this status.
    /// </summary>
    Scheduled,
    /// <summary>
    /// The claim has been prepared but is waiting for authorisation from
    /// SMXi, payment etc before being allowed to proceed.
    /// </summary>
    WaitingAuth,
    /// <summary>
    /// The claim is in the process of building the XML and submitting to
    /// HMRC
    /// </summary>
    Submitting,
    /// <summary>
    /// Receipt acknowledged by HMRC. Need to Poll until Confirmed
    /// </summary>
    Acknowledged,
    /// <summary>
    /// Confirmation that the claim has been received by HMRC and is waiting
    /// for approval
    /// </summary>
    Confirmed,
    /// <summary>
    /// Payment has been received. Needs manual setting by user
    /// </summary>
    PaymentReceived,
    /// <summary>
    /// Rejected by HMRC
    /// </summary>
    ClaimRejected,
    /// <summary>
    /// General error
    /// </summary>
    Error, SysError, DataError);

{$IFNDEF PAS2JS}[Enumeration(TEnumMappingType.emString, 'None,PaymentNeeded')]
{$ENDIF}
  TAuthWaitReason = (None, PaymentNeeded);

{$IFNDEF PAS2JS}[Enumeration(TEnumMappingType.emString, 'OmitRecord,CancelSubmission')]
{$ENDIF}
  TGiftAidErrorHandling = (OmitRecord, CancelSubmission);

{$IFNDEF PAS2JS}[Enumeration(TEnumMappingType.emString, 'GAD,GASDS,Adjustment,GASDSAdjustment,AggregatedDonations,CommunityBuildings,OtherIncome,ConnectedCharity')]
{$ENDIF}
  TGiftAidClaimType = (GAD, GASDS, Adjustment, GASDSAdjustment, AggregatedDonations, CommunityBuildings, OtherIncome, ConnectedCharity);

{$IFNDEF PAS2JS}[Enumeration(TEnumMappingType.emString, 'GiftAider,GiftAiderImport,Manual')]
{$ENDIF}
  TGiftAidClaimSource = (
  /// <summary>
  ///   Created by the GiftAider Online application
  /// </summary>
  GiftAider,
  /// <summary>
  ///   Imported from a GiftAider Desktop Application
  /// </summary>
  GiftAiderDesktop,
  /// <summary>
  ///   Manually created by user to provide historical information
  /// </summary>
  Manual);


  {$IFNDEF PAS2JS}[Enumeration(TEnumMappingType.emString, 'Active,Inactive')]
  {$ENDIF}
  TGAStatus = (
    Active,
    Inactive
  );


const
  C_GA_TYPE_DESCRIPTIONS: array [TGiftAidClaimType] of string = ('Donations', 'Small Donation Scheme', 'Adjustment',
    'GASDS Adjustment', 'Aggregated Donations', 'Community Buildings', 'Other Income', 'Connected Charity');

implementation

{$SCOPEDENUMS OFF}

end.
